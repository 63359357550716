import { FixedPermissions } from '@interfaces/fixedPermissions';

import { RepositoryParams, api } from '..';

export type FixedPermissionsFilters = Partial<
  FixedPermissions & RepositoryParams
>;

export const update = async (params: Partial<FixedPermissions>) => {
  return api.patch(`/permissions/fixed/establishments`, params);
};

export const retrieve = async () => {
  return api.get(`/permissions/fixed/establishments`);
};

export const listPermissions = async () => {
  return api.get('/permissions/fixed/establishments/permissions');
};
