import { Group } from '@interfaces/Group';

import { RepositoryParams, api } from '..';

export type GroupFilters = Partial<Group & RepositoryParams>;

export const list = async (params: GroupFilters) => {
  return api.get('/groups', { params });
};

export const create = async (params: Group) => {
  return api.post('/groups', { ...params });
};

export const update = async (params: Group) => {
  return api.patch(`/groups/${params.id}`, { ...params });
};

export const retrieve = async (id: string) => {
  return api.get(`/groups/${id}`);
};

export const remove = async (id: string) => {
  return api.delete(`/groups/${id}`);
};
