import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PageHeader, StatusChip, Table, TableColumn } from '@components';
import { usePermissionContext } from '@contexts/PermissionProvider';
import { Plan } from '@interfaces/Plan';
import { UserClient } from '@interfaces/UserClient';
import { RepositoryParams } from '@services/api';
import UserClientsAPICaller, {
  UserClientsFilters,
} from '@services/api/userClients';

export default function UserClientsPage() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<{ total: number; result: UserClient[] }>({
    total: 0,
    result: [],
  });
  const [filters, setFilters] = useState<UserClientsFilters>({
    joins: ['plans'],
  });

  const { hasPermission } = usePermissionContext();

  const list = (filters: RepositoryParams) => {
    setIsLoading(true);
    UserClientsAPICaller.list(filters)
      .then((values) => setData(values))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    list(filters);
  }, [filters]);

  // const deleteUserClients = (id: string) => {
  //   confirmation({
  //     title: 'Excluir usuário',
  //     confirmButtonTitle: 'Excluir',
  //     description: 'Deseja realmente excluir esse  usuários?',
  //     onSubmit: () =>
  //       UserClientsAPICaller.remove(id).then((_) => list(filters)),
  //   });
  // };

  return (
    <>
      <div className="p-s-200">
        <PageHeader title="Usuários - Clientes" />
      </div>

      <Table
        isLoading={isLoading}
        hoverable
        totalRecords={data.total}
        data={data.result}
        withPagination
        withSearchInput
        filters={filters}
        setFilters={setFilters}
        className="pointer"
        onRowClick={(row) => {
          if (hasPermission('userClients', 'read')) {
            navigate(`/userClients/${row.id}`);
          }
        }}
      >
        <TableColumn fromKey="name" header="Nome" width="25%" />
        <TableColumn fromKey="email" header="E-mail" />
        <TableColumn fromKey="phone" header="Telefone" />
        <TableColumn
          fromKey="hasPhysicalTour"
          header="Tour Físico"
          render={(hasPhysicalTour: boolean) => (
            <span>{`${hasPhysicalTour ? 'Sim' : 'Não'}`}</span>
          )}
        />
        <TableColumn
          disableSorting
          fromKey="plans"
          header="Plano ativo?"
          render={(plans: Plan[]) => {
            const isActive = plans.some(
              (it) => new Date(it.endsAt).valueOf() >= new Date().valueOf()
            );

            return (
              <StatusChip active={isActive} label={isActive ? 'Sim' : 'Não'} />
            );
          }}
        />
        {/* <TableColumn
          width="6%"
          fromKey="id"
          header="Ações"
          disableSorting
          render={(id) => {
            return (
              <PopOver
                fixedContent={<Icon>more_horiz</Icon>}
                togglableContent={
                  <PopOverMenu
                    menu={[
                      {
                        onClick: () => {
                          deleteUserClients(id);
                        },
                        content: 'Excluir',
                        show: hasPermission('userClients', 'delete'),
                      },
                    ]}
                  />
                }
              />
            );
          }}
        /> */}
      </Table>
    </>
  );
}
