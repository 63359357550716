import { MetabaseParams } from '@interfaces/Metabase';

import { RepositoryParams, api } from '../';

export const operational = async (params?: MetabaseParams) => {
  return api.get('/metabase/operational', { params });
};

export const usageData = async (params?: RepositoryParams) => {
  return api.get('/metabase/usage_data', { params });
};

export const general = async (params?: RepositoryParams) => {
  return api.get('/metabase/general', { params });
};

export const subscription = async (params?: RepositoryParams) => {
  return api.get('/metabase/subscription', { params });
};

export const usageDataEstablishment = async (params?: RepositoryParams) => {
  return api.get('/metabase/usage_data_establishment', { params });
};

export const usageDataByUnit = async (params?: RepositoryParams) => {
  return api.get('/metabase/usage_data_by_unit', { params });
};

export const usageUserEstablishmentData = async (params?: MetabaseParams) => {
  return api.get('/metabase/usage_data_user_establishment', { params });
};

export const usageDataByUnitEstablishment = async (params?: MetabaseParams) => {
  return api.get('/metabase/usage_data_by_unit_establishment', { params });
};

export const usageUserEstablishmentDataPhysical = async (
  params?: MetabaseParams
) => {
  return api.get('/metabase/usage_data_user_establishment_physycal', {
    params,
  });
};

export const usageDataByUnitEstablishmentPhysical = async (
  params?: MetabaseParams
) => {
  return api.get('/metabase/usage_data_by_unit_establishment_physycal', {
    params,
  });
};

export const discountCoupon = async (params?: RepositoryParams) => {
  return api.get('/metabase/discount_coupon', { params });
};

export const usageDataUserClient = async (params?: RepositoryParams) => {
  return api.get('/metabase/usage_data_user_client', { params });
};
