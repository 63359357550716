import { Chip, Icon } from '..';

type Props = {
  active: boolean;
  label?: string;
};

export function StatusChip(props: Props) {
  return (
    <Chip
      inverted
      className="width-fit-content"
      color="black"
      prefixes={
        <Icon className={props.active ? 'text-positive' : 'text-negative'}>
          circle
        </Icon>
      }
    >
      {props.label || (props.active ? 'Ativo' : 'Inativo')}
    </Chip>
  );
}

export function EstablishmentChip(props: { label: string; color: string }) {
  return (
    <Chip
      inverted
      className="width-fit-content"
      color="black"
      prefixes={<Icon style={{ color: props.color }}>circle</Icon>}
    >
      {props.label}
    </Chip>
  );
}
