import { useEffect, useState } from 'react';

import {
  Button,
  Icon,
  Modal,
  PageHeader,
  Restricted,
  Table,
  TableColumn,
} from '@components';
import { PopOver, PopOverMenu } from '@components/PopOver';
import { usePermissionContext } from '@contexts/PermissionProvider';
import { useConfirmationModal } from '@hooks/useConfirmationModal';
import { Notification } from '@interfaces/Notification';
import { RepositoryParams } from '@services/api';
import NotificationAPICaller, {
  NotificationFilters,
} from '@services/api/notification';

import { NotificationForm } from './NotificationForm';

export default function NotificationPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<{ total: number; result: Notification[] }>({
    total: 0,
    result: [],
  });
  const [filters, setFilters] = useState<NotificationFilters>({
    order: '-dispatchDate',
  });
  const [editId, setEditId] = useState('');
  const [isOpenModal, toggleModal] = useState(false);
  const confirmation = useConfirmationModal();

  const { hasPermission } = usePermissionContext();

  const list = (filters: RepositoryParams) => {
    setIsLoading(true);
    NotificationAPICaller.list(filters)
      .then((values) => setData(values))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    list(filters);
  }, [filters]);

  const deleteNotification = (id: string) => {
    confirmation({
      title: 'Excluir notificação',
      confirmButtonTitle: 'Excluir',
      description: 'Deseja realmente excluir essa notificação?',
      onSubmit: () =>
        NotificationAPICaller.remove(id).then((_) => list(filters)),
    });
  };

  return (
    <>
      <div className="p-s-200">
        <PageHeader
          title="Pushes"
          actions={
            <Restricted context="notifications" resource="create">
              <Button
                onClick={() => {
                  toggleModal(true);
                }}
                weight="bold"
                prefixes={<Icon>add</Icon>}
              >
                Cadastrar
              </Button>
            </Restricted>
          }
        />
      </div>

      <Table
        isLoading={isLoading}
        hoverable
        totalRecords={data.total}
        data={data.result}
        withPagination
        withSearchInput
        filters={filters}
        setFilters={setFilters}
      >
        <TableColumn fromKey="title" header="Título" />
        <TableColumn fromKey="message" header="Mensagem" />
        <TableColumn
          fromKey="status"
          header="Status"
          render={({ label }: { label: string }) => <span>{label}</span>}
        />
        <TableColumn
          fromKey="dispatchDate"
          header="Data da notificação"
          render={(dispatchDate, row) => {
            return (
              <span>
                {dispatchDate} {row.hour}
              </span>
            );
          }}
        />
        <TableColumn
          width="6%"
          fromKey="id"
          header="Ações"
          disableSorting
          render={(id) => {
            return (
              <PopOver
                fixedContent={<Icon>more_horiz</Icon>}
                togglableContent={
                  <PopOverMenu
                    menu={[
                      {
                        onClick: () => {
                          setEditId(id);
                          toggleModal(true);
                        },
                        show: hasPermission('notifications', 'update'),
                        content: 'Editar',
                      },
                      {
                        onClick: () => {
                          deleteNotification(id);
                        },
                        content: 'Excluir',
                        show: hasPermission('notifications', 'delete'),
                      },
                    ]}
                  />
                }
              />
            );
          }}
        />
      </Table>

      <Modal
        isOpen={isOpenModal}
        onClickAway={() => {
          setEditId('');
          toggleModal(false);
        }}
      >
        <NotificationForm
          editId={editId}
          onSave={() => list(filters)}
          closeModal={() => {
            setEditId('');
            toggleModal(false);
          }}
        />
      </Modal>
    </>
  );
}
