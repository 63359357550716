import { useForm } from 'react-hook-form';

import { Button, Input, Switch } from '@components';
import ConvenienceAPICaller from '@services/api/conveniences';

interface Props {
  closeModal: () => void;
  onSave: () => void;
  editId?: string;
}

export default function ConveniencesForm(props: Props) {
  const { createOrUpdate, fetch } = ConvenienceAPICaller;

  const {
    register,
    setError,
    watch,
    handleSubmit,

    formState: { errors, isSubmitting, isLoading },
  } = useForm({ defaultValues: async () => fetchData() });

  const fetchData = async () => {
    if (props.editId) {
      const convenience = await fetch(props.editId);

      return convenience;
    }

    return null;
  };

  return (
    <div className="container p-s-200">
      <div className="modal-title">
        <h3>{props.editId ? 'Editar ' : 'Cadastrar '}conveniência</h3>
      </div>
      <form
        autoComplete="off"
        className="form-max-height"
        onSubmit={handleSubmit((data) => {
          return createOrUpdate(data, setError).then((res) => {
            if (res.data.errors) return;
            props.closeModal();
            props.onSave();
          });
        })}
      >
        <div className="row grid-gap-1">
          <div className="col-md-10">
            <Input
              disabled={isLoading}
              error={!!errors.name}
              caption={errors.name?.message as string}
              label="Nome"
              placeholder="Nome"
              form={register('name', { required: 'Obrigatório' })}
            />
          </div>

          <div className="col-md-2">
            <Switch
              disabled={isLoading}
              className="mt-s-500 float-right"
              caption={watch('active', 'Ativo') ? 'Ativo' : 'Inativo'}
              error={errors.active?.message as string}
              form={register('active', { value: true })}
            />
          </div>
        </div>

        <div className="row justify-end pt-s-400" style={{ gap: 16 }}>
          <Button design="transparent" onClick={props.closeModal}>
            Cancelar
          </Button>
          <Button isLoading={isSubmitting || isLoading} type="submit">
            Salvar
          </Button>
        </div>
      </form>
    </div>
  );
}
