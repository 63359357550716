import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { SelectOptions } from '@interfaces/Utils';
import EmphasisAPICaller from '@services/api/emphasis';
import PlanAPICaller from '@services/api/plans';
import {
  Button,
  DatePickerInput,
  Input,
  SelectInput as Select,
} from '@components';

interface Props {
  closeModal: () => void;
  onSave: (id: string) => void;
  editId?: string;
}

export default function EmphasisForm(props: Props) {
  const { createOrUpdate, fetch } = EmphasisAPICaller;
  const [plans, setPlans] = useState<SelectOptions[]>([]);

  const {
    register,
    setError,
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting, isLoading },
  } = useForm({
    defaultValues: async () => await fetchData(),
  });

  const fetchData = async () => {
    PlanAPICaller.all().then((values) => {
      setPlans(values);
    });

    if (props.editId) {
      const data = await fetch(props.editId);

      return data;
    }

    return null;
  };

  return (
    <div className="container p-s-200">
      <div className="modal-title">
        <h3>{props.editId ? 'Editar ' : 'Cadastrar '}destaque</h3>
      </div>
      <form
        autoComplete="off"
        onSubmit={handleSubmit((data) => {
          return createOrUpdate(data, setError).then((res) => {
            if (res.data.errors) return;
            props.closeModal();
            props.onSave(res.data?.emphasis?.id ?? props.editId);
          });
        })}
      >
        <div className="row grid-gap-1">
          <div className="col-md-12">
            <Input
              disabled={isLoading}
              error={!!errors.name}
              caption={errors.name?.message as string}
              label="Nome"
              placeholder="Nome"
              form={register('name', { required: 'Obrigatório' })}
            />
          </div>

          <div className="col-md-6">
            <DatePickerInput
              disabled={isLoading}
              error={!!errors.startsAt}
              caption={errors.startsAt?.message as string}
              label="Data inicial"
              dateSelected={watch('startsAt')}
              onSelect={(date) => setValue('startsAt', date)}
              placeholder="Inicio"
              form={register('startsAt', { required: 'Obrigatório' })}
            />
          </div>

          <div className="col-md-6">
            <DatePickerInput
              disabled={isLoading}
              error={!!errors.endsAt}
              caption={errors.endsAt?.message as string}
              dateSelected={watch('endsAt')}
              onSelect={(date) => setValue('endsAt', date)}
              placeholder="Data final"
              label="Fim"
              form={register('endsAt', { required: 'Obrigatório' })}
            />
          </div>

          <div className="col-md-12">
            <div className="col-md-12">
              <Select
                value={watch('planId')}
                placeholder="Selecionar"
                disabled={isLoading}
                options={plans}
                onSelect={(value) => {
                  setValue('planId', value);
                }}
                form={register('planId', { required: 'Obrigatório' })}
                error={!!errors.planId}
                caption={errors.planId?.message as string}
                label="Plano"
                fromKey="name"
              />
            </div>
          </div>
        </div>

        <div className="row justify-end pt-s-400" style={{ gap: 16 }}>
          <Button design="transparent" onClick={props.closeModal}>
            Cancelar
          </Button>
          <Button isLoading={isSubmitting || isLoading} type="submit">
            Salvar
          </Button>
        </div>
      </form>
    </div>
  );
}
