import { useEffect, useState } from 'react';

import {
  Button,
  Icon,
  Modal,
  PageHeader,
  Restricted,
  SelectedKeys,
  StatusChip,
  Table,
  TableColumn,
} from '@components';
import { AccessGroup } from '@interfaces/AccessGroup';
import { RepositoryParams } from '@services/api';
import AccessGroupAPICaller, {
  AccessGroupFilters,
} from '@services/api/accessGroups';

import { AccessForm } from './AccessForm';
import { AccessUsersTable } from './AccessUsersTable';
import { FixedForm } from './FixedForm';

export default function AccessPage() {
  const [selectedGroups, setSelectedGroups] = useState<SelectedKeys>({});
  const [invertSelection, setInvertSelection] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<{ total: number; result: AccessGroup[] }>({
    total: 0,
    result: [],
  });
  const [filters, setFilters] = useState<AccessGroupFilters>({ order: 'name' });
  const [isOpenModalAccess, setIsOpenModalAccess] = useState(false);
  const [editId, setEditId] = useState('');
  const [expandedId, setExpandedId] = useState('');
  const [isOpenModalFixed, setIsOpenModalFixed] = useState(false);

  const fetch = (filters: RepositoryParams) => {
    setIsLoading(true);
    AccessGroupAPICaller.fetchGroups(filters)
      .then((values) => setData(values))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetch(filters);
  }, [filters]);

  return (
    <>
      <div className="p-s-200">
        <PageHeader
          title="Níveis de Acesso"
          actions={
            <>
              <Restricted context="fixedPermissions" resource="update">
                <Button
                  onClick={() => {
                    setIsOpenModalFixed(true);
                  }}
                  design="outlined"
                  weight="bold"
                  prefixes={<Icon>edit</Icon>}
                >
                  Permissões fixas
                </Button>
              </Restricted>
              <Restricted context="groups" resource="create">
                <Button
                  onClick={() => {
                    setEditId('');
                    setIsOpenModalAccess(true);
                  }}
                  weight="bold"
                  prefixes={<Icon>add</Icon>}
                >
                  Cadastrar
                </Button>
              </Restricted>
            </>
          }
        />

        <Table
          isLoading={isLoading}
          hoverable
          selectedKeys={selectedGroups}
          invertSelection={invertSelection}
          expandedRow={expandedId}
          totalRecords={data.total}
          data={data.result}
          withPagination
          withSearchInput
          filters={filters}
          setFilters={setFilters}
          renderExpandedRow={(group) => (
            <AccessUsersTable
              key={group.id}
              groupName={group.name}
              groupId={group.id}
              onUpdate={() => fetch(filters)}
            />
          )}
          onSelectionChange={(selectedKeys, invertSelection) => {
            setSelectedGroups(selectedKeys);
            setInvertSelection(invertSelection);
          }}
          onRowClick={(row) => {
            if (expandedId === row.id) return setExpandedId('');
            else setExpandedId(row.id);
          }}
        >
          <TableColumn
            fromKey="id"
            header=""
            disableSorting
            width="0"
            render={() => <Icon>group</Icon>}
          />
          <TableColumn fromKey="name" header="Nome" />
          <TableColumn
            fromKey="active"
            header="Status"
            render={(active: boolean) => <StatusChip active={active} />}
          />
          <TableColumn
            width="6%"
            fromKey="id"
            header="Ações"
            disableSorting
            render={(id) => {
              return (
                <div className="d-flex">
                  <Button
                    weight="bold"
                    design="transparent"
                    prefixes={
                      <Icon>
                        {expandedId === id ? 'expand_less' : 'expand_more'}
                      </Icon>
                    }
                    onClick={() => {
                      if (expandedId === id) return setExpandedId('');
                      else setExpandedId(id);
                    }}
                  />
                  <Restricted context="groups" resource="update">
                    <Button
                      weight="bold"
                      design="transparent"
                      prefixes={<Icon>edit</Icon>}
                      onClick={() => {
                        setEditId(id);
                        setIsOpenModalAccess(true);
                      }}
                    />
                  </Restricted>
                </div>
              );
            }}
          />
        </Table>
      </div>

      <Modal
        size="large"
        isOpen={isOpenModalAccess}
        onClickAway={() => {
          setEditId('');
          setIsOpenModalAccess(false);
        }}
      >
        <AccessForm
          editId={editId}
          onSave={() => fetch(filters)}
          closeModal={() => {
            setEditId('');
            setIsOpenModalAccess(false);
          }}
        />
      </Modal>

      <Modal
        size="large"
        isOpen={isOpenModalFixed}
        onClickAway={() => {
          setIsOpenModalFixed(false);
        }}
      >
        <FixedForm
          onSave={() => fetch(filters)}
          closeModal={() => setIsOpenModalFixed(false)}
        />
      </Modal>
    </>
  );
}
