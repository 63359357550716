import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Icon, PageHeader, Table, TableColumn } from '@components';
import { PopOver, PopOverMenu } from '@components/PopOver';
import { usePermissionContext } from '@contexts/PermissionProvider';
import { useConfirmationModal } from '@hooks/useConfirmationModal';
import { RepositoryParams } from '@services/api';
import DevicesAPICaller, {
  UserClientsFilters,
  deviceStatusMap,
} from '@services/api/userClients';

export default function DevicesPage() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<{ total: number; result: [] }>({
    total: 0,
    result: [],
  });
  const [filters, setFilters] = useState<UserClientsFilters>({
    order: '-createdAt',
    joins: [],
  });

  const confirmation = useConfirmationModal();

  const { hasPermission } = usePermissionContext();

  const list = (filters: RepositoryParams) => {
    setIsLoading(true);
    DevicesAPICaller.fetchDevices(filters)
      .then((values) => setData(values))
      .finally(() => setIsLoading(false));
  };

  const updateDevice = (
    userCliendUUID: string,
    deviceHistoryId: string,
    status: keyof typeof deviceStatusMap
  ) => {
    confirmation({
      title: 'Atualizar o status do aparelho',
      confirmButtonTitle: 'Atualizar',
      description: `Deseja realmente atualizar o status do aparelho para ${deviceStatusMap[
        status
      ].toUpperCase()}?`,
      onSubmit: () =>
        DevicesAPICaller.updateDevice(userCliendUUID, deviceHistoryId, {
          status: status as string,
        }).then((_) => list(filters)),
    });
  };

  useEffect(() => {
    list(filters);
  }, [filters]);

  return (
    <>
      <div className="p-s-200">
        <PageHeader title="Troca de Aparelhos" />
      </div>

      <Table
        isLoading={isLoading}
        totalRecords={data.total}
        data={data.result || []}
        showColumnSelector={false}
        hoverable
        withPagination
        withSearchInput
        filters={filters}
        setFilters={setFilters}
        className="pointer"
        onRowClick={({ userClientId }) => {
          navigate(`/userClients/${userClientId}?tab=4`);
        }}
      >
        {/* <TableColumn
          fromKey="mobileDevice"
          header="Aparelho"
          render={({ deviceModel }: { deviceModel: string }) => (
            <span>{deviceModel}</span>
          )}
        /> */}
        <TableColumn
          fromKey="userClient"
          header="Usuário - Cliente"
          nestedOrder="name"
          render={({ name }: { name: string }) => <span>{name}</span>}
        />
        <TableColumn
          fromKey="userClient"
          header="Email"
          nestedOrder="email"
          render={({ email }: { email: string }) => <span>{email}</span>}
        />
        <TableColumn
          fromKey="userClient"
          header="Telefone"
          nestedOrder="phone"
          render={({ phone }: { phone: string }) => <span>{phone}</span>}
        />
        <TableColumn
          fromKey="status"
          header="Status"
          ellipsis
          render={(status) => {
            let color = 'red';

            if (status === deviceStatusMap['APPROVED']) {
              color = 'green';
            } else if (status === deviceStatusMap['PENDING']) {
              color = 'darkgoldenrod';
            }

            return <span style={{ color: color }}>{status}</span>;
          }}
        />
        <TableColumn fromKey="createdAt" header="Criado Em" />
        <TableColumn
          width="6%"
          fromKey="id"
          header="Ações"
          disableSorting
          render={(id, { status, userClient }) => {
            if (status !== deviceStatusMap['PENDING']) return <></>;

            return (
              <>
                <PopOver
                  fixedContent={<Icon>more_horiz</Icon>}
                  togglableContent={
                    <PopOverMenu
                      menu={[
                        {
                          onClick: () => {
                            updateDevice(userClient.id, id, 'APPROVED');
                          },
                          show: hasPermission(
                            'userClients',
                            'updateStatusUserClientMobileDevice'
                          ),
                          content: (
                            <div className="d-flex align-items-center justify-center">
                              <Icon style={{ color: 'green', marginRight: 4 }}>
                                check
                              </Icon>
                              <span>Liberar o uso do aparelho</span>
                            </div>
                          ),
                        },
                        {
                          onClick: () => {
                            updateDevice(userClient.id, id, 'DECLINED');
                          },
                          show: hasPermission(
                            'userClients',
                            'updateStatusUserClientMobileDevice'
                          ),
                          content: (
                            <div className="d-flex align-items-center justify-center">
                              <Icon style={{ color: 'red', marginRight: 4 }}>
                                close
                              </Icon>
                              <span>Negar o uso do aparelho</span>
                            </div>
                          ),
                        },
                      ]}
                    />
                  }
                />
              </>
            );
          }}
        />
      </Table>
    </>
  );
}
