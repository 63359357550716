import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { usePermissionContext } from '@contexts/PermissionProvider';
import MetabaseAPICaller from '@services/api/metabase';
import { useAuthContext } from '@contexts/AuthProvider';

type Props = {
  report?: string;
};

export default function ReportPage(props: Props) {
  const [searchParams] = useSearchParams();
  const [iframeUrl, setIframeUrl] = useState('');
  const dashboardEl = useRef(null);

  const { userIsEstablishment } = useAuthContext();
  const { establishmentAccess, unitAccess } = usePermissionContext();

  const fetchUserDataEstablishmentPhysycal = () => {
    const params: {
      establishment?: string[];
      unit?: string[];
    } = {};

    params.establishment = establishmentAccess?.map(
      (it) => it.establishment.name
    );
    params.unit = unitAccess?.map((it) => it.unit!.name!);
    const establishmentUnits = establishmentAccess?.flatMap(
      (it) => it!.establishment.units!.map((unit) => unit.name!)!
    );

    params.unit = [...params.unit!, ...establishmentUnits!];

    MetabaseAPICaller.fetchUsageUserEstablishmentDataPhysical(params)
      .then((iframeUrl) => {
        setIframeUrl(iframeUrl);
      })
      .catch((error) => console.log(error));
  };

  const fetch = useCallback(
    (report: string) => {
      const dashParams: {
        establishment?: string[] | null;
        unit?: string[] | null;
      } = {
        establishment: null,
        unit: null,
      };

      if (userIsEstablishment) {
        dashParams.establishment = establishmentAccess?.map(
          (it) => it.establishment.name
        );

        dashParams.unit = unitAccess?.map((it) => it.unit!.name!);
        const establishmentUnits = establishmentAccess?.flatMap(
          (it) => it!.establishment.units!.map((unit) => unit.name!)!
        );

        const allEstablishments = unitAccess?.map(
          (it) => it.unit!.establishment!.name!
        );
        // const establishmentFromUnits = allEstablishments.filter(
        //   (x, i, a) => a.indexOf(x) == i
        // );

        dashParams.unit = [...dashParams.unit!, ...establishmentUnits!];
        dashParams.establishment = [
          ...dashParams.establishment!,
          ...allEstablishments!,
        ];
      }

      switch (report) {
        case 'operational':
          MetabaseAPICaller.fetchOperational(dashParams).then((iframeUrl) => {
            setIframeUrl(iframeUrl);
          });
          break;

        case 'general':
          MetabaseAPICaller.fetchGeneral().then((iframeUrl) => {
            setIframeUrl(iframeUrl);
          });
          break;

        case 'subscription':
          MetabaseAPICaller.fetchSubscription().then((iframeUrl) => {
            setIframeUrl(iframeUrl);
          });
          break;

        case 'usage_data':
          MetabaseAPICaller.fetchUsageData().then((iframeUrl) => {
            setIframeUrl(iframeUrl);
          });
          break;

        case 'usage_data_establishment':
          MetabaseAPICaller.fetchUsageDataEstablishment().then((iframeUrl) => {
            setIframeUrl(iframeUrl);
          });
          break;

        case 'usage_data_by_unit':
          MetabaseAPICaller.fetchUsageDataByUnit().then((iframeUrl) => {
            setIframeUrl(iframeUrl);
          });
          break;

        case 'discount_coupon':
          MetabaseAPICaller.fetchDiscountCoupon().then((iframeUrl) => {
            setIframeUrl(iframeUrl);
          });
          break;

        case 'usage_data_user_client':
          MetabaseAPICaller.fetchUsageDataUserClient().then((iframeUrl) => {
            setIframeUrl(iframeUrl);
          });
          break;

        case 'usage_data_by_unit_establishment':
          MetabaseAPICaller.fetchUsageDataByUnitEstablishment(dashParams).then(
            (iframeUrl) => {
              setIframeUrl(iframeUrl);
            }
          );
          break;

        //UserType Establishment
        case 'usage_data_user_establishment':
          MetabaseAPICaller.fetchUsageUserEstablishmentData(dashParams)
            .then((iframeUrl) => {
              setIframeUrl(iframeUrl);
            })
            .catch((error) => console.log(error));
          break;

        case 'usage_data_by_unit_establishment_physical':
          MetabaseAPICaller.fetchUsageDataByUnitEstablishmentPhysical(
            dashParams
          ).then((iframeUrl) => {
            setIframeUrl(iframeUrl);
          });
          break;

        case 'usage_data_user_establishment_physical':
          fetchUserDataEstablishmentPhysycal();
          break;

        default:
          break;
      }
    },
    [establishmentAccess, unitAccess, userIsEstablishment]
  );

  useEffect(() => {
    fetch((props.report || searchParams.get('report')) as string);
  }, [fetch, searchParams, props.report]);

  return (
    <div className="row" ref={dashboardEl}>
      {iframeUrl && (
        <iframe
          src={iframeUrl}
          title="metabase"
          width={(dashboardEl?.current as unknown as HTMLElement)?.offsetWidth}
          height="800"
          allowTransparency
        ></iframe>
      )}
    </div>
  );
}
