import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { useEffect, useState } from 'react';

import { Button, LoginPageContainer, PasswordInput } from '@components';
import { handleErrorForm } from '@services/api';
import AuthAPICaller from 'services/api/auth';

export default function ResetPasswordPage() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    if (!token) return navigate('/login');
    AuthAPICaller.validatePasswordResetToken(token).catch(() => {
      navigate('/login');
    });
  }, [token, navigate]);

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (async (data: any) => {
    setIsLoading(true);
    if (!token) return navigate('/login');
    await AuthAPICaller.sendPasswordReset(token, data.password)
      .then(() => navigate('/login'))
      .catch(handleErrorForm(setError));
    setIsLoading(false);
  }) as SubmitHandler<FieldValues>;

  return (
    <div className="bg-neutral-95">
      <div className="d-flex justify-center" style={{ height: '100vh' }}>
        <div className="col-sm-12 col-md-10 d-flex justify-center background-login-page">
          <form
            autoComplete="off"
            className="form-max-height"
            onSubmit={handleSubmit(onSubmit)}
          >
            <LoginPageContainer>
              <h3 className="!w100 d-flex justify-center p-s-300 text-center">
                Insira uma nova senha
              </h3>
              <PasswordInput
                form={register('password', { required: 'Obrigatório' })}
                placeholder="Senha"
                error={!!errors.password}
                caption={errors.password?.message as string}
              />

              <Button
                type="submit"
                isLoading={isLoading}
                className="!w-100 !mt-s-200"
                alignText="center"
              >
                Alterar senha
              </Button>

              <hr className="!mt-s-300" />
            </LoginPageContainer>
          </form>
        </div>
      </div>
    </div>
  );
}
