import { useCallback, useState } from 'react';

interface genericAPICallerList<T, CallParams extends Array<any>> {
  (...filters: CallParams): Promise<{ total: number; result: Array<T> }>;
}

export const useFetchList = <T, CallParams extends Array<any>>(
  apiCallerList: genericAPICallerList<T, CallParams>
) => {
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState<Array<T>>([]);
  const [total, setTotal] = useState(0);

  const fetchData = useCallback(
    async (...params: Parameters<typeof apiCallerList>) => {
      setFetching(true);
      const { total: fetchedTotal, result } = await apiCallerList(...params);
      setData(result);
      setTotal(fetchedTotal);
      setFetching(false);
    },
    [apiCallerList]
  );

  return {
    data,
    total,
    fetching,
    fetchData,
  };
};

type FetchMethod<T> = (...params: Array<any>) => Promise<T>;

export const useFetchEntity = <T>(apiCallerRetrieve: FetchMethod<T>) => {
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState<T | null>(null);

  const fetchData = useCallback(
    async (...params: Parameters<typeof apiCallerRetrieve>) => {
      setData(null);
      setFetching(true);
      const response = await apiCallerRetrieve(...params);
      setData(response);
      setFetching(false);
    },
    [apiCallerRetrieve]
  );

  return {
    data,
    fetching,
    fetchData,
  };
};
