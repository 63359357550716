import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Icon,
  Modal,
  PageHeader,
  Restricted,
  StatusChip,
  Table,
  TableColumn,
} from '@components';
import { PopOver, PopOverMenu } from '@components/PopOver';
import { usePermissionContext } from '@contexts/PermissionProvider';
import { useConfirmationModal } from '@hooks/useConfirmationModal';
import { DiscountCoupon } from '@interfaces/DiscountCoupon';
import { RepositoryParams } from '@services/api';
import DiscountCouponAPICaller, {
  DiscountCouponFilters,
} from '@services/api/discountCoupons';

import { DiscountCouponForm } from './DiscountCouponForm';

export default function DiscountCouponPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<{ total: number; result: DiscountCoupon[] }>(
    {
      total: 0,
      result: [],
    }
  );
  const [filters, setFilters] = useState<DiscountCouponFilters>({
    order: '-active,-timesUsed',
    joins: ['plans'],
  });
  const [editId, setEditId] = useState('');
  const [isOpenModal, toggleModal] = useState(false);
  const confirmation = useConfirmationModal();
  const navigate = useNavigate();

  const { hasPermission } = usePermissionContext();

  const list = (filters: RepositoryParams) => {
    setIsLoading(true);
    DiscountCouponAPICaller.list(filters)
      .then((values) => setData(values))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    list(filters);
  }, [filters]);

  const deleteDiscountCoupon = (id: string) => {
    confirmation({
      title: 'Excluir cupom de desconto',
      confirmButtonTitle: 'Excluir',
      description: 'Deseja realmente excluir esse cupom de desconto?',
      onSubmit: () =>
        DiscountCouponAPICaller.remove(id).then((_) => list(filters)),
    });
  };

  return (
    <>
      <div className="p-s-200">
        <PageHeader
          title="Cupom de desconto"
          actions={
            <Restricted context="discountCoupons" resource="create">
              <Button
                onClick={() => {
                  toggleModal(true);
                }}
                weight="bold"
                prefixes={<Icon>add</Icon>}
              >
                Cadastrar
              </Button>
            </Restricted>
          }
        />
      </div>

      <Table
        isLoading={isLoading}
        hoverable
        totalRecords={data.total}
        data={data.result}
        withPagination
        withSearchInput
        filters={filters}
        setFilters={setFilters}
        className="pointer"
        onRowClick={(row) => {
          if (hasPermission('discountCoupons', 'read')) {
            navigate(`/discountCoupons/${row.id}`);
          }
        }}
      >
        <TableColumn
          fromKey="plans"
          header="Plano"
          disableSorting
          render={(plans) => {
            if (Array.isArray(plans)) {
              return plans.map((it) => it.name).join(', ');
            }

            return plans?.name;
          }}
        />
        <TableColumn fromKey="code" header="Código" width="6%" />
        <TableColumn fromKey="name" header="Nome" />
        <TableColumn
          fromKey="discountPercentage"
          header="Desconto"
          width="6%"
          render={(percent) => <span>{percent}%</span>}
        />
        <TableColumn fromKey="timesUsed" header="Qtd usado" />
        <TableColumn fromKey="expirationDate" header="Data expiração" />
        <TableColumn
          fromKey="active"
          header="Status"
          render={(active: boolean) => <StatusChip active={active} />}
        />
        <TableColumn
          width="6%"
          fromKey="id"
          header="Ações"
          disableSorting
          render={(id) => {
            return (
              <PopOver
                fixedContent={<Icon>more_horiz</Icon>}
                togglableContent={
                  <PopOverMenu
                    menu={[
                      {
                        onClick: () => {
                          navigate(`/discountCoupons/${id}`);
                        },
                        show: hasPermission('discountCoupons', 'read'),
                        content: 'Acessar',
                      },
                      {
                        onClick: () => {
                          setEditId(id);
                          toggleModal(true);
                        },
                        show: hasPermission('discountCoupons', 'update'),
                        content: 'Editar',
                      },
                      {
                        onClick: () => {
                          deleteDiscountCoupon(id);
                        },
                        content: 'Excluir',
                        show: hasPermission('discountCoupons', 'delete'),
                      },
                    ]}
                  />
                }
              />
            );
          }}
        />
      </Table>

      <Modal
        isOpen={isOpenModal}
        onClickAway={() => {
          setEditId('');
          toggleModal(false);
        }}
      >
        <DiscountCouponForm
          editId={editId}
          onSave={() => list(filters)}
          closeModal={() => {
            setEditId('');
            toggleModal(false);
          }}
        />
      </Modal>
    </>
  );
}
