import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import defaultImage from '@assets/defaultImage.png';
import { DataLoader, PageHeader, Tab } from '@components';
import { Tips } from '@interfaces/Tips';
import { SelectOptions } from '@interfaces/Utils';
import TipsAPICaller from '@services/api/tips';
import { validateUUIDFormat } from 'utils/string/uuid';
import { fileToBlobURL } from '@services/io/file';

import CommentsView from './CommentsView';
import InfoView from './InfoView';

export const TipsView = () => {
  const { uuid } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [data, setData] = useState<Tips | null>(null);

  const fetch = (uuid: string) => {
    TipsAPICaller.fetch(uuid).then((values) => setData(values as Tips));
  };

  useEffect(() => {
    if (uuid) {
      if (validateUUIDFormat(uuid)) {
        fetch(uuid);
      } else {
        toast.error('ID informado inválido');
        navigate(-1);
      }
    }
  }, [uuid, navigate]);

  return (
    <>
      <div className="p-s-200">
        <PageHeader backButton title="Dica" />

        <div className="row pb-s-200">
          <div className="col-md-2 mb-s-150">
            <DataLoader
              data={
                <img
                  src={
                    data?.images?.length
                      ? fileToBlobURL(data?.images?.at(0) as File)
                      : defaultImage
                  }
                  alt="logo"
                  width={150}
                  className="border-radius-100"
                />
              }
              height={120}
              isLoading={!data}
            />
          </div>

          <div className="col-md-10 align-center">
            <div className="col-md-12">
              <h4>Nome</h4>
              <span>
                <DataLoader data={data?.name} isLoading={!data} />
              </span>
            </div>
            <div className="col-md-6 mt-s-250">
              <h4>Tipo</h4>
              <span>
                <DataLoader
                  data={(data?.type as SelectOptions)?.label}
                  isLoading={!data}
                />
              </span>
            </div>
          </div>
        </div>

        <Tab
          tabSelected={Number(searchParams?.get('tab')) || 0}
          tabs={[
            {
              component: <InfoView data={data} fetch={fetch} />,
              title: 'Informações',
            },
            {
              component: <CommentsView data={data} fetch={fetch} />,
              title: 'Avaliação e Comentários',
            },
          ]}
        />
      </div>
    </>
  );
};
