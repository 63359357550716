import { PropsWithChildren } from 'react';

import { Drawer } from '../Drawer';

type Props = {
  collapsed?: boolean;
  className?: string;
};
export default function Sidebar(props: PropsWithChildren<Props>) {
  const sidebarClasses = ['sidebar'];
  if (props.collapsed) sidebarClasses.push('sidebar--collapsed');
  if (props.className) sidebarClasses.push(props.className);
  return (
    <Drawer
      className={sidebarClasses.join(' ')}
      size={props.collapsed ? 'xs' : 'md'}
      open={true}
      float="left"
      overlay={false}
    >
      {props.children}
    </Drawer>
  );
}
