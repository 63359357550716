import { useCallback, useEffect, useState } from 'react';

import {
  Button,
  DataLoader,
  Icon,
  StatusChip,
  Table,
  TableColumn,
} from '@components';
import { Voucher } from '@interfaces/Voucher';
import { RepositoryParams } from '@services/api';
import UserClientsAPICaller, {
  voucherActionMap,
} from '@services/api/userClients';

type Props = {
  uuid?: string;
};

export function UserClientsPhysicalVouchers(props: Props) {
  const [expandedId, setExpandedId] = useState('');
  const [filters, setFilters] = useState<RepositoryParams>({
    page: 1,
  });
  const [data, setData] = useState<{ total: number; result: Voucher[] }>({
    total: 0,
    result: [],
  });

  const fetchVouchers = useCallback(
    (uuid: string) => {
      UserClientsAPICaller.fetchPhysicalVouchers(uuid, filters).then((values) =>
        setData(values)
      );
    },
    [filters]
  );

  useEffect(() => {
    if (props.uuid) {
      fetchVouchers(props.uuid);
    }
  }, [props.uuid, filters, fetchVouchers]);

  return (
    <>
      <div
        className="card card__body p-s-350 bg-white"
        style={{ minHeight: '50vh' }}
      >
        <div className="row">
          <Table
            data={data.result || []}
            totalRecords={data.total}
            withPagination
            setFilters={setFilters}
            showColumnSelector={false}
            hoverable
            expandedRow={expandedId}
            onRowClick={({ id }) => {
              if (expandedId === id) return setExpandedId('');
              else {
                setExpandedId(id);
              }
            }}
            className="pointer"
            renderExpandedRow={(data) => (
              <div className="container mt-s-200">
                <div className="row grid-gap-1">
                  <div className="col-md-6">
                    <h4>Estabelecimento</h4>
                    <span>
                      <DataLoader
                        data={data?.voucher?.establishment?.name}
                        isLoading={!data}
                      />
                    </span>
                  </div>
                  <div className="col-md-6">
                    <h4>Unidade</h4>
                    <span>{data?.establishmentUnit?.name}</span>
                  </div>
                  <div className="col-md-6">
                    <h4>Categoria</h4>
                    <span>{data?.voucher?.category?.name}</span>
                  </div>
                  <div className="col-md-6">
                    <h4>Avaliação</h4>
                    <span>{data?.rating ?? '-'}</span>
                  </div>
                  <div className="col-md-6">
                    <h4>Total da conta</h4>
                    <span>{data?.totalPrice ?? '-'}</span>
                  </div>
                  <div className="col-md-6">
                    <h4>Valor da economia</h4>
                    <span>{data?.moneySaved ?? '-'}</span>
                  </div>
                  <div className="col-md-6">
                    <h4>Primeira vez?</h4>
                    <span>
                      {data?.firstTime == null ? (
                        '-'
                      ) : (
                        <StatusChip
                          active={data.firstTime}
                          label={data.firstTime ? 'Sim' : 'Não'}
                        />
                      )}
                    </span>
                  </div>
                  <div className="col-md-6">
                    <h4>Comentários</h4>
                    <span>{data?.comments ?? '-'}</span>
                  </div>
                </div>
              </div>
            )}
          >
            <TableColumn
              width="6%"
              fromKey="voucher"
              header="Código"
              render={({ validationCode }) => <span>{validationCode}</span>}
            />
            <TableColumn fromKey="voucherName" header="Nome" ellipsis />
            <TableColumn
              fromKey="voucher"
              header="Plano"
              render={(voucher) => <span>{voucher?.plan?.name}</span>}
            />
            <TableColumn fromKey="createdAt" header="Data" />
            <TableColumn
              fromKey="action"
              header="Status"
              render={(status) => (
                <StatusChip
                  active={status === voucherActionMap['USE']}
                  label={status}
                />
              )}
            />
            <TableColumn
              width="6%"
              fromKey="id"
              header="Ações"
              disableSorting
              render={(id) => {
                return (
                  <>
                    <div className="d-flex">
                      <Button
                        className="mr-s-200"
                        weight="bold"
                        design="transparent"
                        prefixes={
                          <Icon>
                            {expandedId === id ? 'expand_less' : 'expand_more'}
                          </Icon>
                        }
                        onClick={() => {
                          if (expandedId === id) {
                            setExpandedId('');
                          } else {
                            setExpandedId(id);
                          }
                        }}
                      />
                    </div>
                  </>
                );
              }}
            />
          </Table>
        </div>
      </div>
    </>
  );
}
