import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';

import { Button, SelectInput as Select } from '@components';
import { Voucher } from '@interfaces/Voucher';
import VoucherAPICaller from '@services/api/voucher';
import { downloadFile } from '@services/io/file';

interface Props {
  closeModal: () => void;
  unitId: string;
  unitName: string;
  voucherList: Voucher[];
}

export default function EstablishmentUnitsVoucherForm(props: Props) {
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting, isLoading },
  } = useForm();

  const [voucherList, setVoucherList] = useState(props.voucherList);

  const fetchQRCode = async (voucherId: string) => {
    const qrCode = await VoucherAPICaller.fetchQRCode(voucherId, props.unitId);

    return downloadFile(qrCode, 'application/pdf', props.unitName);
  };

  useEffect(() => {
    setVoucherList(
      props.voucherList.map((voucher) => {
        return { ...voucher, name: `${voucher.name} - ${voucher.plan?.name}` };
      })
    );
  }, [props.voucherList]);

  return (
    <div className="container p-s-200">
      <div className="modal-title">
        <h3>Selecionar voucher</h3>
      </div>
      <form
        autoComplete="off"
        onSubmit={handleSubmit((data) => {
          fetchQRCode(data.voucherId.value);
          props.closeModal();
        })}
      >
        <div className="row grid-gap-1">
          <div className="col-md-12">
            <Select
              value={watch('voucherId')}
              placeholder="Digite para filtrar os vouchers que podem ser utilizados"
              disabled={isLoading}
              options={voucherList}
              onSelect={(value) => {
                setValue('voucherId', value);
              }}
              form={register('voucherId', { required: 'Obrigatório' })}
              error={!!errors.voucherId}
              caption={errors.voucherId?.message as string}
              label="Voucher"
              fromKey="name"
            />
          </div>
        </div>

        <div className="row justify-end pt-s-400" style={{ gap: 16 }}>
          <Button design="transparent" onClick={props.closeModal}>
            Fechar
          </Button>
          <Button isLoading={isSubmitting} type="submit">
            Gerar QR Code
          </Button>
        </div>
      </form>
    </div>
  );
}
