import { FieldValues, UseFormSetError } from 'react-hook-form';

import { Emphasis } from '@interfaces/Emphasis';
import { handleErrorForm } from '@services/api';
import { encodeDate, parseDate } from 'utils/string/dates';

import {
  EmphasisFilters,
  addEmphasisedVoucher,
  create,
  list,
  listVouchersEmphasisable,
  remove,
  removeVoucher,
  retrieve,
  update,
  updateOrders,
} from './calls';

export * from './calls';

export default class EmphasisAPICaller {
  static adaptFromAPI = (data: Emphasis) => {
    const emphasis = { ...data };

    emphasis.startsAt = parseDate(data.startsAt);
    emphasis.endsAt = parseDate(data.endsAt);
    emphasis.planId = { value: data.plan?.id ?? '', label: data.plan?.name };

    return emphasis as FieldValues;
  };
  static adaptToAPI = (data: FieldValues) => {
    const emphasis = { ...data };

    emphasis.startsAt = encodeDate(data.startsAt);
    emphasis.endsAt = encodeDate(data.endsAt);

    emphasis.planId = data.planId.value;

    return emphasis as Emphasis;
  };

  static list = async (filters: EmphasisFilters) => {
    const {
      data: { emphasis },
    } = await list(filters);

    return {
      ...emphasis,
      result: emphasis.result.map(this.adaptFromAPI),
    };
  };

  static fetch = async (editId: string) => {
    const {
      data: { emphasis },
    } = await retrieve(editId);
    return this.adaptFromAPI(emphasis);
  };

  static createOrUpdate = async (
    data: FieldValues,
    setError: UseFormSetError<FieldValues>
  ) => {
    const method = data.id ? update : create;

    const result = method(this.adaptToAPI(data)).catch(
      handleErrorForm(setError)
    );

    return result;
  };

  static remove = async (id: string) => {
    const { data } = await remove(id);

    return data;
  };

  static updateOrders = async (emphasisId: string, ids: string[]) => {
    return await updateOrders(emphasisId, ids);
  };

  static listVouchersEmphasisable = async (emphasisId: string) => {
    const {
      data: { vouchers },
    } = await listVouchersEmphasisable(emphasisId);

    return vouchers;
  };

  static addEmphasisedVoucher = async (
    emphasisId: string,
    voucherId: string
  ) => {
    return await addEmphasisedVoucher(emphasisId, voucherId);
  };

  static removeVoucher = async (id: string) => {
    const { data } = await removeVoucher(id);

    return data;
  };
}
