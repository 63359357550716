import { Plan } from '@interfaces/Plan';

import { RepositoryParams, api } from '..';

export type PlanFilters = Partial<Plan & RepositoryParams>;

export const list = async (params: PlanFilters) => {
  return api.get('/plans', { params });
};
export const listAll = async () => {
  return api.get('/plans/all');
};

export const create = async (params: Plan) => {
  return api.post('/plans', { ...params });
};

export const update = async (params: Plan) => {
  return api.patch(`/plans/${params.id}`, { ...params });
};

export const retrieve = async (id: string) => {
  return api.get(`/plans/${id}`);
};

export const remove = async (id: string) => {
  return api.delete(`/plans/${id}`);
};
