import { useEffect, useState } from 'react';

import {
  Button,
  Icon,
  Modal,
  PageHeader,
  Restricted,
  StatusChip,
  Table,
  TableColumn,
} from '@components';
import { PopOver, PopOverMenu } from '@components/PopOver';
import { usePermissionContext } from '@contexts/PermissionProvider';
import { useConfirmationModal } from '@hooks/useConfirmationModal';
import { Benefit } from '@interfaces/Benefit';
import { RepositoryParams } from '@services/api';
import BenefitAPICaller, { BenefitFilters } from '@services/api/benefits';

import { BenefitsForm } from './BenefitsForm';

export default function BenefitPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<{ total: number; result: Benefit[] }>({
    total: 0,
    result: [],
  });
  const [filters, setFilters] = useState<BenefitFilters>({
    order: 'name',
  });
  const [editId, setEditId] = useState('');
  const [isOpenModal, toggleModal] = useState(false);
  const confirmation = useConfirmationModal();
  const { hasPermission } = usePermissionContext();

  const list = (filters: RepositoryParams) => {
    setIsLoading(true);
    BenefitAPICaller.list(filters)
      .then((values) => setData(values))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    list(filters);
  }, [filters]);

  const deleteBenefit = (id: string) => {
    confirmation({
      title: 'Excluir benefício',
      description: 'Deseja realmente excluir esse benefício?',
      confirmButtonTitle: 'Excluir',
      onSubmit: () => BenefitAPICaller.remove(id).then((_) => list(filters)),
    });
  };

  return (
    <>
      <div className="p-s-200">
        <PageHeader
          title="Benefícios"
          actions={
            <Restricted context="benefits" resource="create">
              <Button
                onClick={() => {
                  toggleModal(true);
                }}
                weight="bold"
                prefixes={<Icon>add</Icon>}
              >
                Cadastrar
              </Button>
            </Restricted>
          }
        />
      </div>

      <Table
        isLoading={isLoading}
        hoverable
        totalRecords={data.total}
        data={data.result}
        withPagination
        withSearchInput
        filters={filters}
        setFilters={setFilters}
      >
        <TableColumn fromKey="name" header="Nome" />
        <TableColumn
          fromKey="active"
          header="Status"
          render={(active: boolean) => <StatusChip active={active} />}
        />
        <TableColumn
          width="6%"
          fromKey="id"
          header="Ações"
          disableSorting
          render={(id) => {
            return (
              <PopOver
                fixedContent={<Icon>more_horiz</Icon>}
                togglableContent={
                  <PopOverMenu
                    menu={[
                      {
                        onClick: () => {
                          setEditId(id);
                          toggleModal(true);
                        },
                        show: hasPermission('benefits', 'update'),
                        content: 'Editar',
                      },
                      {
                        onClick: () => {
                          deleteBenefit(id);
                        },
                        content: 'Excluir',
                        show: hasPermission('benefits', 'delete'),
                      },
                    ]}
                  />
                }
              />
            );
          }}
        />
      </Table>

      <Modal
        isOpen={isOpenModal}
        onClickAway={() => {
          setEditId('');
          toggleModal(false);
        }}
      >
        <BenefitsForm
          editId={editId}
          onSave={() => list(filters)}
          closeModal={() => {
            setEditId('');
            toggleModal(false);
          }}
        />
      </Modal>
    </>
  );
}
