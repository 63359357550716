import { useForm } from 'react-hook-form';
import { useLayoutEffect, useState } from 'react';

import { Button, SelectInput as Select } from '@components';
import UserClientsAPICaller from '@services/api/userClients';
import { ClientAccessReason } from '@interfaces/ClientAccessReason';
import ClientAccessReasonAPICaller from '@services/api/clientAccessReason';

interface Props {
  closeModal: () => void;
  onSave: () => void;
  userClientUUID: string;
  planUUID: string;
}

export default function RemovePlanForm(props: Props) {
  const [accessReason, setAccessReason] = useState<ClientAccessReason[]>([]);

  const { removePlans } = UserClientsAPICaller;

  const {
    register,
    setError,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  useLayoutEffect(() => {
    ClientAccessReasonAPICaller.list({
      clientAccessAction: 'REMOVE',
      size: 100,
    }).then((values) => setAccessReason(values.result));
  }, []);

  return (
    <div className="container p-s-200">
      <div className="modal-title">
        <h3>Cancelar acesso</h3>
      </div>
      <form
        autoComplete="off"
        onSubmit={handleSubmit((data) => {
          const plans = { ...data };
          plans.id = props.userClientUUID;
          plans.planId = props.planUUID;
          plans.reasonId = data.reason.value;
          delete plans.reason;

          removePlans(plans, setError).then((res) => {
            if (res.data.errors) return;
            props.closeModal();
            props.onSave();
          });
        })}
      >
        <div className="row grid-gap-1">
          <div className="col-md-12">
            <span>Deseja realmente cancelar acesso do usuário - cliente?</span>
          </div>
          <div className="col-md-12">
            <Select
              value={watch('reason')}
              placeholder="Motivos de alteração de acesso"
              options={accessReason}
              onSelect={(value) => setValue('reason', value)}
              form={register('reason', { required: 'Obrigatório' })}
              error={!!errors.reason}
              caption={errors.reason?.message as string}
              label="Qual motivo"
              fromKey="name"
            />
          </div>
        </div>

        <div className="row justify-end pt-s-400" style={{ gap: 16 }}>
          <Button design="transparent" onClick={props.closeModal}>
            Cancelar
          </Button>
          <Button isLoading={isSubmitting} type="submit">
            Excluir
          </Button>
        </div>
      </form>
    </div>
  );
}
