import { FAQ } from '@interfaces/FAQ';

import { RepositoryParams, api } from '..';

export type FAQFilters = Partial<FAQ & RepositoryParams>;

export const list = async (params: FAQFilters) => {
  return api.get('/faqs', { params });
};

export const create = async (params: FAQ) => {
  return api.post('/faqs', { ...params });
};

export const update = async (params: FAQ) => {
  return api.patch(`/faqs/${params.id}`, { ...params });
};

export const updateOrders = async (ids: string[]) => {
  return api.put('/faqs/order', { newOrder: ids });
};

export const retrieve = async (id: string) => {
  return api.get(`/faqs/${id}`);
};

export const remove = async (id: string) => {
  return api.delete(`/faqs/${id}`);
};
