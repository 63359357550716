import { GroupBase, OptionProps, components } from 'react-select';
import { useMemo } from 'react';

import Tippy, { useSingleton } from '@tippyjs/react';

export const OptionWithTooltip = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>({
  tippyTarget,
  getTooltipText,
  ...props
}: OptionProps<Option, IsMulti, Group> & {
  tippyTarget: ReturnType<typeof useSingleton>[1];
  getTooltipText: (data: Option) => string;
}) => {
  const { children, data } = props;

  const tooltipText = useMemo(() => {
    return getTooltipText(data);
  }, [data, getTooltipText]);

  return (
    <components.Option {...props}>
      {props.isDisabled ? (
        <Tippy content={tooltipText} singleton={tippyTarget}>
          <div>{children}</div>
        </Tippy>
      ) : (
        children
      )}
    </components.Option>
  );
};
