import { UserClient } from '@interfaces/UserClient';

import { RepositoryParams, api } from '..';

export type UserClientsFilters = Partial<UserClient & RepositoryParams>;

export const list = async (params: UserClientsFilters) => {
  return api.get('/user_clients', { params });
};

export const update = async (params: UserClient) => {
  return api.patch(`/user_clients/${params.id}`, { ...params });
};

export const updatePassword = async (params: {
  id: string;
  password: string;
  passwordConfirm?: string;
}) => {
  return api.patch(`/user_clients/${params.id}/password`, { ...params });
};

export const retrieve = async (id: string) => {
  return api.get(`/user_clients/${id}`);
};

export const remove = async (id: string) => {
  return api.delete(`/user_clients/${id}`);
};

export const listPlans = async (id: string) => {
  return api.get(`/user_clients/${id}/plan/all_accesses`);
};

export const listPlansBuyable = async (id: string) => {
  return api.get(`/user_clients/${id}/plan/buyable`);
};

export const listPlansActive = async (id: string) => {
  return api.get(`/user_clients/${id}/plan/active`);
};

export const removePlan = async (params: any) => {
  return api.delete(`/user_clients/${params.id}/plan`, { data: params });
};

export const addPlan = async (params: any) => {
  return api.post(`/user_clients/${params.id}/plan`, { ...params });
};

export const addVoucherUsed = async (params: any) => {
  return api.post(
    `/user_clients/${params.id}/vouchers_used/${params.voucherId}`,
    { ...params }
  );
};

export const listPlanHistory = async (
  params: UserClientsFilters,
  userClientUUID: string
) => {
  return api.get(`/user_clients/${userClientUUID}/plan/history`, { params });
};

export const listVouchers = async (id: string, params: RepositoryParams) => {
  return api.get(`/user_clients/${id}/vouchers_used`, { params });
};
export const listPhysicalVouchers = async (
  id: string,
  params: RepositoryParams
) => {
  return api.get(`/user_clients/${id}/vouchers_used_physical/history`, {
    params,
  });
};

export const removeVoucher = async (id: string, voucherId: string) => {
  return api.delete(`/user_clients/${id}/vouchers_used/${voucherId}`);
};

export const listVoucherHistory = async (
  params: UserClientsFilters,
  userClientUUID: string
) => {
  return api.get(`/user_clients/${userClientUUID}/vouchers_used/history`, {
    params,
  });
};

export const getUsableVouchers = async (
  userClientUUID: string,
  params: RepositoryParams
) => {
  return api.get(`/user_clients/${userClientUUID}/vouchers_usable`, {
    params,
  });
};

export const listDevices = async (params: UserClientsFilters) => {
  return api.get(`/user_clients/mobile_device/history`, { params });
};

export const updateDevice = async (
  id: string,
  deviceHistoryId: string,
  params: { status: string }
) => {
  return api.post(
    `/user_clients/${id}/mobile_device/approve_device/${deviceHistoryId}`,
    {
      ...params,
    }
  );
};
