import { Category } from './Category';
import { Cooking } from './Cooking';
import { EstablishmentUnit } from './EstablishmentUnit';
import { Voucher } from './Voucher';
import { UserEstablishmentAccess, UserWithId } from './User';

interface EstablishmentUserWithAccess extends UserEstablishmentAccess {
  user: UserWithId;
}
export interface Establishment {
  id?: string;
  name: string;
  description: string;
  companyName?: string;
  cnpj?: string;
  logo: string;
  image: string;
  logoUrl?: string;
  imageUrl?: string;
  site: string;
  cooking?: Cooking[];
  categories: Category[];
  status: string;
  type: string;
  contactPhone?: string;
  units?: EstablishmentUnit[];
  vouchers?: Voucher[];
  usersWithAccess?: EstablishmentUserWithAccess[];
}
export const EstablishmentStatus = {
  active: 'Ativo',
  inactive: 'Inativo',
  temporarilyInactive: 'Temporariamente inativo',
} as const;

export const EstablishmentType = {
  restaurant: 'Restaurante',
  bar: 'Bar',
} as const;
