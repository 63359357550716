import { SelectOptions } from './Utils';

export enum TipsType {
  toKnow = 'Para conhecer',
  tour = 'Passeio',
}

export interface Tips {
  id?: string;
  name?: string;
  busLine?: string;
  type?: SelectOptions | string;
  description?: string;
  active: boolean;
  state: string | SelectOptions;
  city: string | SelectOptions;
  district: string | SelectOptions;
  street: string;
  number: string;
  zipCode: string;
  complement?: string;
  latitude?: number;
  longitude?: number;
  images?: { url: string }[] | File[];
  tipComments?: TipComments[];
}

interface TipComments {
  id?: string;
  personName?: string;
  rating: number;
  comment: string;
  active: boolean;
}
