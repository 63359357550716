import { useState } from 'react';

import { Modal } from '@components';
import { useConfirmationModal } from '@hooks/useConfirmationModal';
import { Establishment } from '@interfaces/Establishment';
import { VoucherTable } from '@pages/VouchersPage/VoucherTable';
import { VouchersForm } from '@pages/VouchersPage/VouchersForm';
import VoucherAPICaller from '@services/api/voucher';

interface Props {
  establishmentInfo: Establishment | null;
  fetch: (uuid: string) => void;
}

export default function EstablishmentVouchers(props: Props) {
  const confirmation = useConfirmationModal();
  const [editId, setEditId] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);

  const deleteVoucher = (id: string) => {
    confirmation({
      title: 'Excluir voucher',
      description: 'Deseja realmente excluir esse voucher?',
      confirmButtonTitle: 'Excluir',
      onSubmit: () =>
        VoucherAPICaller.remove(id).then((_) =>
          props.fetch(props.establishmentInfo?.id!)
        ),
    });
  };

  return (
    <>
      <div
        className="card card__body p-s-350 bg-white"
        style={{ minHeight: '50vh' }}
      >
        <div className="row">
          <VoucherTable
            isLoading={false}
            data={{
              result: props.establishmentInfo?.vouchers || [],
              total: props.establishmentInfo?.vouchers?.length || 0,
            }}
            showPlans
            setFilters={undefined}
            filters={undefined}
            setEditId={setEditId}
            setIsOpenModal={setIsOpenModal}
            deleteVoucher={deleteVoucher}
          />
        </div>
      </div>

      <Modal
        isOpen={isOpenModal}
        onClickAway={() => {
          setEditId('');
          setIsOpenModal(false);
        }}
      >
        <VouchersForm
          editId={editId}
          onSave={() => props.fetch(props.establishmentInfo?.id!)}
          closeModal={() => {
            setEditId('');
            setIsOpenModal(false);
          }}
        />
      </Modal>
    </>
  );
}
