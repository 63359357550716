import { useLayoutEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Button, SelectInput as Select } from '@components';
import { ClientAccessReason } from '@interfaces/ClientAccessReason';
import ClientAccessReasonAPICaller from '@services/api/clientAccessReason';
import UserClientsAPICaller from '@services/api/userClients';

interface Props {
  closeModal: () => void;
  onSave: () => void;
  userClientUUID: string;
}

export default function AddPlanForm(props: Props) {
  const [accessReason, setAccessReason] = useState<ClientAccessReason[]>([]);
  const [plans, setPlans] = useState<ClientAccessReason[]>([]);

  const { addPlans } = UserClientsAPICaller;

  const {
    register,
    setError,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting, isLoading },
  } = useForm();

  useLayoutEffect(() => {
    UserClientsAPICaller.listPlansActive(props.userClientUUID).then((values) =>
      setPlans(values)
    );

    ClientAccessReasonAPICaller.list({
      clientAccessAction: 'GRANT',
      size: 100,
    }).then((values) => setAccessReason(values.result));
  }, [props.userClientUUID]);

  return (
    <div className="container p-s-200">
      <div className="modal-title">
        <h3>Liberar acesso</h3>
      </div>
      <form
        autoComplete="off"
        onSubmit={handleSubmit((data) => {
          const plans = { ...data };
          plans.id = props.userClientUUID;
          plans.reasonId = data.reason.value;
          plans.planId = data.planId.value;
          delete plans.reason;

          addPlans(plans, setError).then((res) => {
            if (res.data.errors) return;
            props.closeModal();
            props.onSave();
          });
        })}
      >
        <div className="row grid-gap-1">
          <div className="col-md-12">
            <Select
              value={watch('planId')}
              placeholder="Plano"
              disabled={isLoading}
              options={plans}
              onSelect={(value) => {
                setValue('planId', value);
              }}
              form={register('planId', { required: 'Obrigatório' })}
              error={!!errors.planId}
              caption={errors.planId?.message as string}
              label="Plano"
              fromKey="name"
            />
          </div>
          <div className="col-md-12">
            <Select
              value={watch('reason')}
              placeholder="Motivos de alteração de acesso"
              options={accessReason}
              onSelect={(value) => setValue('reason', value)}
              form={register('reason', { required: 'Obrigatório' })}
              error={!!errors.reason}
              caption={errors.reason?.message as string}
              label="Qual motivo"
              fromKey="name"
            />
          </div>
        </div>

        <div className="row justify-end pt-s-400" style={{ gap: 16 }}>
          <Button design="transparent" onClick={props.closeModal}>
            Cancelar
          </Button>
          <Button isLoading={isSubmitting} type="submit">
            Salvar
          </Button>
        </div>
      </form>
    </div>
  );
}
