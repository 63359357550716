import { useCallback, useLayoutEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Button, SelectInput as Select } from '@components';
import { Voucher } from '@interfaces/Voucher';
import { EstablishmentUnit } from '@interfaces/EstablishmentUnit';
import UserClientsAPICaller from '@services/api/userClients';
import VoucherAPICaller from '@services/api/voucher';
import { debounce } from 'lodash';

interface Props {
  closeModal: () => void;
  onSave: () => void;
  userClientUUID: string;
}

export default function AddUsedVoucherForm(props: Props) {
  const [vouchers, setVouchers] = useState<Voucher[]>([]);
  const [establishmentUnits, setEstablishmentUnits] = useState<
    EstablishmentUnit[]
  >([]);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [isLoadingUnitRequest, setIsLoadingUnitRequest] = useState(false);

  const { addVoucherUsed } = UserClientsAPICaller;

  const {
    register,
    setError,
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting, isLoading },
  } = useForm();

  const fetchAvailableVouchers = useCallback(
    (q?: string) => {
      setIsLoadingRequest(true);
      UserClientsAPICaller.getUsableVouchers(props.userClientUUID, { q }).then(
        (values) => {
          setVouchers(values.result);
          setIsLoadingRequest(false);
        }
      );
    },
    [props.userClientUUID]
  );

  const fetchVoucherUnit = useCallback((voucherId: string) => {
    if (!voucherId) return;

    setIsLoadingUnitRequest(true);
    reset({ unitId: null });
    VoucherAPICaller.listUnits(voucherId).then((values) => {
      setEstablishmentUnits(values.units);
      setIsLoadingUnitRequest(false);
    });
  }, []);

  useLayoutEffect(() => {
    fetchAvailableVouchers();
  }, [fetchAvailableVouchers]);

  return (
    <div className="container p-s-200">
      <div className="modal-title">
        <h3>Adicionar voucher usado</h3>
      </div>
      <form
        autoComplete="off"
        onSubmit={handleSubmit((data) => {
          const voucher = {
            id: props.userClientUUID,
            voucherId: data.voucherId.value,
            unitId: data.unitId.value,
          };

          addVoucherUsed(voucher, setError).then((res) => {
            if (res.data.errors) return;
            props.closeModal();
            props.onSave();
          });
        })}
      >
        <div className="row grid-gap-1">
          <div className="col-md-12">
            <Select
              value={watch('voucherId')}
              placeholder="Digite para filtrar os vouchers que podem ser utilizados"
              disabled={isLoading}
              options={vouchers}
              isLoading={isLoadingRequest}
              selectProps={{
                onInputChange: debounce((query) => {
                  fetchAvailableVouchers(query);
                }, 500),
              }}
              onSelect={(value) => {
                setValue('voucherId', value);
                fetchVoucherUnit(value.value);
              }}
              form={register('voucherId', { required: 'Obrigatório' })}
              error={!!errors.voucherId}
              caption={errors.voucherId?.message as string}
              label="Voucher"
              fromKey="name"
            />
          </div>

          <div className="col-md-12">
            <Select
              value={watch('unitId')}
              placeholder="Digite para filtrar as unidades"
              disabled={isLoading}
              options={establishmentUnits}
              isLoading={isLoadingUnitRequest}
              onSelect={(value) => {
                setValue('unitId', value);
              }}
              form={register('unitId', { required: 'Obrigatório' })}
              error={!!errors.unitId}
              caption={errors.unitId?.message as string}
              label="Unidade"
              fromKey="name"
            />
          </div>
        </div>

        <div className="row justify-end pt-s-400" style={{ gap: 16 }}>
          <Button design="transparent" onClick={props.closeModal}>
            Cancelar
          </Button>
          <Button isLoading={isSubmitting} type="submit">
            Salvar
          </Button>
        </div>
      </form>
    </div>
  );
}
