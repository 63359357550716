import { useState } from 'react';

import { Button, Icon, Modal, PageHeader, Restricted, Tab } from '@components';

import { TipsForm } from './TipsForm';
import { InfoTable } from './InfoTable';
import { CommentsTable } from './CommentsTable';

export default function TipsPage() {
  const [isOpenModal, toggleModal] = useState(false);

  return (
    <>
      <div className="p-s-200">
        <PageHeader
          title="Dicas"
          actions={
            <Restricted context="tips" resource="create">
              <Button
                onClick={() => {
                  toggleModal(true);
                }}
                weight="bold"
                prefixes={<Icon>add</Icon>}
              >
                Cadastrar
              </Button>
            </Restricted>
          }
        />
      </div>

      <Tab
        tabSelected={0}
        tabs={[
          {
            component: <InfoTable />,
            title: 'Lugares',
          },

          {
            component: <CommentsTable />,
            title: 'Avaliações e Comentários',
          },
        ]}
      />

      <Modal
        isOpen={isOpenModal}
        onClickAway={() => {
          toggleModal(false);
        }}
      >
        <TipsForm
          onSave={() => {
            window.location.reload();
          }}
          closeModal={() => {
            toggleModal(false);
          }}
        />
      </Modal>
    </>
  );
}
