import { FieldValues, UseFormSetError } from 'react-hook-form';

import { handleErrorForm } from '@services/api';

import AccessGroupAPICaller from '../accessGroups';

import { listPermissions, retrieve, update } from './calls';
export * from './calls';

export default class FixedPermissionAPICaller {
  static retrieve = async ({
    shouldAdapt = true,
  }: {
    shouldAdapt?: boolean;
  } = {}) => {
    const { data } = await retrieve();

    if (shouldAdapt) return AccessGroupAPICaller.adaptFromAPI(data);
    return data;
  };

  static fetchPermissions = async () => {
    const { data } = await listPermissions();
    const permissions = AccessGroupAPICaller.adaptPermissionsListFromAPI(
      data.permissions
    );
    return permissions;
  };

  static update = async (
    data: FieldValues,
    setError: UseFormSetError<FieldValues>
  ) => {
    const method = update;

    const result = method(AccessGroupAPICaller.adaptToAPI(data)).catch(
      handleErrorForm(setError)
    );

    return result;
  };
}
