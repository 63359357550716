import { Substitution } from '@interfaces/Substitution';

import { RepositoryParams, api } from '..';

export type SubstitutionFilters = Partial<Substitution & RepositoryParams>;

export const list = async (params: SubstitutionFilters) => {
  return api.get('/substitutions', { params });
};
export const listAll = async () => {
  return api.get('/substitutions/all');
};

export const create = async (params: Substitution) => {
  return api.post('/substitutions', { ...params });
};

export const update = async (params: Substitution) => {
  return api.patch(`/substitutions/${params.id}`, { ...params });
};

export const retrieve = async (id: string) => {
  return api.get(`/substitutions/${id}`);
};

export const remove = async (id: string) => {
  return api.delete(`/substitutions/${id}`);
};
