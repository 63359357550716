import { Establishment } from '@interfaces/Establishment';
import { EstablishmentUnit } from '@interfaces/EstablishmentUnit';

export const establishmentUnitName = (
  unit: EstablishmentUnit & { establishment?: Establishment }
) => {
  if (unit.establishment) {
    return `${unit.establishment?.name} - ${unit.name}`;
  } else {
    return unit.name || '';
  }
};

export const joinEstablishmentsWithUnits = (
  units: EstablishmentUnit[],
  establishments: Establishment[]
) => {
  const establishmentsWithUnits = establishments.map((thisEstablishment) => {
    return {
      ...thisEstablishment,
      units: units.filter(
        (thisUnit) => thisUnit.establishmentId === thisEstablishment.id
      ),
    };
  });

  const unitsWithEstablishment = units.map((thisUnit) => {
    return {
      ...thisUnit,
      establishment: establishments.find(
        (thisEstablishment) => thisEstablishment.id === thisUnit.establishmentId
      ),
    };
  });

  return {
    establishments: establishmentsWithUnits,
    units: unitsWithEstablishment,
  };
};
