import { useForm } from 'react-hook-form';
import { useCallback, useLayoutEffect, useState } from 'react';

import { Button, SelectInput as Select } from '@components';
import VoucherAPICaller from '@services/api/voucher';
import { downloadFile } from '@services/io/file';
import PlanAPICaller from '@services/api/plans';
import { debounce } from 'lodash';
import { Plan } from '@interfaces/Plan';

interface Props {
  closeModal: () => void;
}

export default function VoucherQRCodeDownloadForm(props: Props) {
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting, isLoading },
  } = useForm();

  const [planName, setPlanName] = useState('');
  const [plans, setPlans] = useState<Plan[]>([]);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);

  const fetchQRCode = async (planId: string, planName: string) => {
    const qrCode = await VoucherAPICaller.fetchQRCode(
      undefined,
      undefined,
      planId
    );
    return downloadFile(qrCode, 'application/zip', planName);
  };

  const fetchAvailablePlans = useCallback((q?: string) => {
    setIsLoadingRequest(true);
    PlanAPICaller.list({ q }).then((values) => {
      setPlans(values.result);
      setIsLoadingRequest(false);
    });
  }, []);

  useLayoutEffect(() => {
    fetchAvailablePlans();
  }, [fetchAvailablePlans]);

  return (
    <div className="container p-s-200">
      <div className="modal-title">
        <h3>Selecionar plano</h3>
      </div>
      <form
        autoComplete="off"
        onSubmit={handleSubmit((data) => {
          fetchQRCode(data.planId.value, planName);
          props.closeModal();
        })}
      >
        <div className="row grid-gap-1">
          <div className="col-md-12">
            <Select
              value={watch('planId')}
              placeholder="Digite para filtrar os planos que podem ser utilizados"
              disabled={isLoading}
              isLoading={isLoadingRequest}
              options={plans}
              selectProps={{
                onInputChange: debounce((query) => {
                  fetchAvailablePlans(query);
                }, 500),
              }}
              onSelect={(value) => {
                setValue('planId', value);
                setPlanName(value.label);
              }}
              form={register('planId', { required: 'Obrigatório' })}
              error={!!errors.planId}
              caption={errors.planId?.message as string}
              label="Plano"
              fromKey="name"
            />
          </div>
        </div>

        <div className="row justify-end pt-s-400" style={{ gap: 16 }}>
          <Button design="transparent" onClick={props.closeModal}>
            Fechar
          </Button>
          <Button isLoading={isSubmitting} type="submit">
            Gerar QR Codes
          </Button>
        </div>
      </form>
    </div>
  );
}
