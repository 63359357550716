import { Advertisement } from '@interfaces/Advertisement';
import { objectToFormData } from '@services/io/file';

import { RepositoryParams, api } from '..';

export type AdvertisementFilters = Partial<Advertisement & RepositoryParams>;

export const list = async (params: AdvertisementFilters) => {
  return api.get('/advertisements', { params });
};

export const create = async (params: Advertisement) => {
  const payload = {
    cover: params.cover,
    payload: JSON.stringify(params),
  };

  return api.post('/advertisements', objectToFormData(payload), {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const update = async (params: Advertisement) => {
  const payload = {
    cover: params.cover,
    payload: JSON.stringify(params),
  };

  return api.patch(`/advertisements/${params.id}`, objectToFormData(payload), {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const retrieve = async (id: string) => {
  return api.get(`/advertisements/${id}`);
};

export const remove = async (id: string) => {
  return api.delete(`/advertisements/${id}`);
};
