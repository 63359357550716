import {
  ComponentPropsWithoutRef,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { Button, FileUpload, Input, SelectInput as Select } from '@components';
import { OptionWithTooltip } from '@components/Select/OptionWithTooltip';
import { UserSelect } from '@components/User/Select';
import { useAuthContext } from '@contexts/AuthProvider';
import { useFetchEstablishmentUnits } from '@hooks/establishmentUnit/useFetchEstablishmentUnits';
import {
  Establishment,
  EstablishmentStatus,
  EstablishmentType,
} from '@interfaces/Establishment';
import { UserType } from '@interfaces/User';
import { SelectOptions } from '@interfaces/Utils';
import CategoryAPICaller from '@services/api/categories';
import CookingAPICaller from '@services/api/cooking';
import EstablishmentAPICaller from '@services/api/establishment';
import Tippy, { useSingleton } from '@tippyjs/react';
import { formSitePattern } from '@validations/site';
import { followCursor } from 'tippy.js';
import { getArrayWithoutUndefinedItems } from 'utils/array';
import { toValueLabel } from 'utils/object';
import { handleSelectedAll } from 'utils/hasSelectedAll';
import { formCNPJPattern } from '@validations/cnpj';
import { isValidCNPJ } from '@brazilian-utils/brazilian-utils';
import { onlyNumber } from 'utils/number';

interface Props {
  closeModal: () => void;
  onSave: (id: string) => void;
  editId?: string;
}

interface DefaultValues {
  id?: string;
  name: string;
  description: string;
  cooking: (SelectOptions & { id?: string })[];
  categories: (SelectOptions & { id?: string })[];
  status: SelectOptions;
  type: SelectOptions;
  contactPhone?: string;
  logo: string | File | null;
  image: string | File | null;
  site: string;
  usersWithAccess: string[];
}

const { createOrUpdate, fetch, updateUsersAccess } = EstablishmentAPICaller;

export default function EstablishmentForm({ editId, ...props }: Props) {
  const aspect = { width: 500, height: 500 };
  const aspectImage = { width: 940, height: 500 };
  const [categories, setCategories] = useState<SelectOptions[]>([]);
  const [cooking, setCooking] = useState<SelectOptions[]>([]);
  const { user } = useAuthContext();

  const userFilter: ComponentPropsWithoutRef<typeof UserSelect>['usersFilter'] =
    useMemo(() => {
      return { userType: UserType.Establishment, active: true };
    }, []);

  const { fetchEstablishmentUnits, establishmentUnits } =
    useFetchEstablishmentUnits();

  const [tippySource, tippyTarget] = useSingleton();

  const {
    register,
    setError,
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting, isLoading, defaultValues },
  } = useForm<FieldValues & DefaultValues>({
    defaultValues: async () => fetchData(),
  });

  const fetchData: () => Promise<DefaultValues> = async () => {
    let initialValues: DefaultValues;
    if (editId) {
      const {
        name,
        description,
        cooking,
        categories,
        status,
        logo,
        site,
        usersWithAccess,
        id,
        image,
        type,
        contactPhone,
        ...rest
      } = await fetch<Establishment>(editId);

      initialValues = {
        ...rest,
        id,
        name,
        description,
        cooking: (cooking || []) as unknown as SelectOptions[],
        categories: (categories || []) as unknown as SelectOptions[],
        status: toValueLabel(status) as SelectOptions,
        logo,
        site,
        image,
        type: toValueLabel(type) as SelectOptions,
        contactPhone,
        usersWithAccess: usersWithAccess?.map(({ userId }) => userId) || [],
      };

      return initialValues;
    }

    initialValues = {
      status: toValueLabel(EstablishmentStatus.active) as SelectOptions,
      type: toValueLabel(EstablishmentType.restaurant) as SelectOptions,
      usersWithAccess: [],
      name: '',
      description: '',
      cooking: [],
      categories: [],
      logo: null,
      image: null,
      site: '',
    };

    return initialValues;
  };

  useLayoutEffect(() => {
    CategoryAPICaller.all().then((values) => {
      values.unshift({
        id: 'all',
        name: 'Todos',
      });

      setCategories(values);
    });

    CookingAPICaller.all().then((values) => {
      values.unshift({
        id: 'all',
        description: 'Todos',
      });

      setCooking(values);
    });
  }, []);

  useEffect(() => {
    if (editId) {
      fetchEstablishmentUnits({
        establishmentId: editId,
        joins: ['usersWithAccess'],
      });
    }
  }, [editId, fetchEstablishmentUnits]);

  return (
    <div className="container p-s-200">
      <div className="modal-title">
        <h3>{editId ? 'Editar ' : 'Cadastrar '}estabelecimento</h3>
      </div>
      <form
        autoComplete="off"
        className="form-max-height"
        onSubmit={handleSubmit(
          async ({ usersWithAccess, ...createOrUpdateFormData }) => {
            createOrUpdateFormData.cooking = handleSelectedAll(
              createOrUpdateFormData.cooking,
              cooking
            );

            createOrUpdateFormData.categories = handleSelectedAll(
              createOrUpdateFormData.categories,
              categories
            );

            const response = await createOrUpdate<
              typeof createOrUpdateFormData
            >(createOrUpdateFormData, setError);

            if (response.data.hasOwnProperty('errors')) return;

            const { data } = response as unknown as {
              data: Establishment | { establishment: Establishment };
            };

            let idEstablishment;

            if ((data as Establishment).id) {
              idEstablishment = (data as Establishment).id!;
            } else {
              idEstablishment = (data as { establishment: Establishment })
                .establishment.id!;
            }

            const { errors } = await updateUsersAccess(
              idEstablishment,
              getArrayWithoutUndefinedItems(
                defaultValues?.usersWithAccess || []
              ),
              usersWithAccess,
              setError
            );

            if (errors.length) return;

            props.closeModal();
            props.onSave(idEstablishment);
          }
        )}
      >
        <div className="row grid-gap-1">
          <div className="col-md-12">
            <Input
              disabled={isLoading}
              error={!!errors.name}
              caption={errors.name?.message as string}
              label="Nome"
              placeholder="Nome"
              form={register('name', { required: 'Obrigatório' })}
            />
          </div>

          <div className="col-md-4">
            <Input
              mask="cnpj"
              disabled={isLoading}
              error={!!errors.cnpj}
              caption={errors.cnpj?.message as string}
              label="CNPJ"
              placeholder="CNPJ"
              form={register('cnpj', {
                ...formCNPJPattern,
                validate: {
                  cnpj: (value) => {
                    const cnpj = onlyNumber(value);

                    return !cnpj || isValidCNPJ(value) || 'CNPJ inválido';
                  },
                },
              })}
            />
          </div>

          <div className="col-md-8">
            <Input
              disabled={isLoading}
              error={!!errors.companyName}
              caption={errors.companyName?.message as string}
              label="Razão Social"
              placeholder="Razão Social"
              form={register('companyName')}
            />
          </div>

          <div className="col-md-12">
            <Input
              disabled={isLoading}
              error={!!errors.description}
              caption={errors.description?.message as string}
              label="Descrição"
              placeholder="Descrição"
              form={register('description')}
            />
          </div>
          <div className="col-md-6">
            <Select
              value={watch('cooking')}
              placeholder="Culinárias"
              disabled={isLoading}
              options={cooking}
              isMulti
              onSelect={(value) => {
                setValue('cooking', value);
              }}
              form={register('cooking')}
              error={!!errors.cooking}
              caption={errors.cooking?.message as string}
              label="Culinárias"
              fromKey="description"
            />
          </div>

          <div className="col-md-6">
            <Select
              value={watch('categories')}
              placeholder="Categorias"
              disabled={isLoading}
              fromKey="name"
              options={categories}
              isMulti
              selectProps={{
                styles: {
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                },
              }}
              onSelect={(value) => setValue('categories', value)}
              form={register('categories', { required: 'Obrigatório' })}
              error={!!errors.categories}
              caption={errors.categories?.message as string}
              label="Categorias"
            />
          </div>

          <div className="col-md-6">
            <Select
              value={watch('status')}
              placeholder="Status do estabelecimento"
              disabled={isLoading}
              options={Object.values(EstablishmentStatus)}
              onSelect={(value) => setValue('status', value)}
              form={register('status', { required: 'Obrigatório' })}
              error={!!errors.status}
              caption={errors.status?.message as string}
              label="Status"
            />
          </div>

          <div className="col-md-6">
            <Input
              disabled={isLoading}
              error={!!errors.site}
              caption={errors.site?.message as string}
              label="Site"
              placeholder="https://site.com.br"
              form={register('site', {
                ...formSitePattern,
              })}
            />
          </div>

          <div className="col-md-12">
            <Input
              mask="phone"
              disabled={isLoading}
              error={!!errors.contactPhone}
              caption={errors.contactPhone?.message as string}
              label="Telefone"
              placeholder="Telefone"
              form={register('contactPhone', {
                validate: {
                  cellOrHome: (value) => {
                    if (!value) return true;
                    const isCell = /^\(\d{2}\) \d{5}-\d{4}$/.test(value);
                    const isHome = /^\(\d{2}\) \d{4}-\d{4}$/.test(value);

                    return isCell || isHome || 'Telefone inválido!';
                  },
                },
              })}
            />
          </div>

          <div className="col-md-12">
            <label className="form-input__label" children={<span>Logo</span>} />
            <FileUpload
              uploadPreview
              fileName=""
              image={watch('logo') || undefined}
              imageHeight={200}
              accept="image/*"
              maxSizeInBytes={5_000_000}
              disclaimer={`${aspect.width}x${aspect.height}px com até 5MB`}
              cropProps={{
                aspect: aspect.width / aspect.height,
              }}
              openCropImageModal
              isRemovable
              form={register('logo', { required: 'Obrigatório' })}
              onChange={([file]) => {
                setValue('logo', file);
              }}
              label="Escolher logo"
              error={errors.logo?.message as string}
            />
          </div>

          <div className="col-md-12">
            <label
              className="form-input__label"
              children={<span>Imagem</span>}
            />
            <FileUpload
              uploadPreview
              fileName=""
              image={watch('image') || undefined}
              imageHeight={200}
              accept="image/*"
              maxSizeInBytes={5_000_000}
              disclaimer={`${aspectImage.width}x${aspectImage.height}px com até 5MB`}
              cropProps={{
                aspect: aspectImage.width / aspectImage.height,
              }}
              openCropImageModal
              isRemovable
              form={register('image', { required: 'Obrigatório' })}
              onChange={([file]) => {
                setValue('image', file);
              }}
              label="Escolher imagem"
              error={errors.image?.message as string}
            />
          </div>

          {Boolean(!user || user?.userType === UserType.Global) && (
            <div className="col-md-12">
              <Tippy
                singleton={tippySource}
                arrow={false}
                followCursor
                plugins={[followCursor]}
              />
              <UserSelect
                label="Usuários com acesso a este estabelecimento"
                value={watch('usersWithAccess')}
                onChange={(value) => {
                  setValue('usersWithAccess', value);
                }}
                usersFilter={userFilter}
                menuPosition="fixed"
                disabled={isLoading}
                error={!!errors.usersWithAccess}
                caption={errors.usersWithAccess?.message}
                getOptionLabel={({ name, email }) => `${name} - ${email}`}
                isOptionDisabled={(user) => {
                  return establishmentUnits.some((establishmentUnit) => {
                    const usersWithAccess =
                      establishmentUnit.usersWithAccess || [];
                    return usersWithAccess.some((accessUser) => {
                      return accessUser.userId === user.id;
                    });
                  });
                }}
                components={{
                  Option: (props) => (
                    <OptionWithTooltip
                      {...props}
                      tippyTarget={tippyTarget}
                      getTooltipText={() =>
                        `Concedido acesso à unidades específicas deste estabelecimento. Para conceder acesso ao estabelecimento com todas as unidades, desselecione-as`
                      }
                    />
                  ),
                }}
              />
            </div>
          )}

          <div className="col-md-12">
            <Select
              value={watch('type')}
              placeholder="Tipo do estabelecimento"
              disabled={isLoading}
              options={Object.values(EstablishmentType)}
              onSelect={(value) => setValue('type', value)}
              form={register('type', { required: 'Obrigatório' })}
              error={!!errors.type}
              caption={errors.type?.message as string}
              label="Tipo do estabelecimento"
            />
          </div>
        </div>

        <div className="row justify-end pt-s-400" style={{ gap: 16 }}>
          <Button design="transparent" onClick={props.closeModal}>
            Cancelar
          </Button>
          <Button isLoading={isSubmitting || isLoading} type="submit">
            Salvar
          </Button>
        </div>
      </form>
    </div>
  );
}
