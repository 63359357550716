import { useFetchList } from '@hooks/useFetch';
import UsersAPICaller from '@services/api/users';

export const useFetchUserEstablishments = () => {
  const { fetchData, fetching, data, total } = useFetchList(
    UsersAPICaller.listEstablishments
  );

  return {
    isFetchingUserEstablishments: fetching,
    fetchUserEstablishments: fetchData,
    userEstablishments: data,
    userEstablishmentsTotal: total,
  };
};
