import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Button, Input, Switch } from '@components';
import { State, StateCities } from '@interfaces/State';
import RegionAPICaller from '@services/api/regions';
import StatesAPICaller from '@services/api/states';
import { removeAccents } from 'utils/string/sanitize';

interface Props {
  closeModal: () => void;
  onSave: () => void;
  editId?: string;
}

export default function RegionsForm(props: Props) {
  const { createOrUpdate, fetch } = RegionAPICaller;

  const [states, setStates] = useState<State[]>([]);
  const [cities, setCities] = useState<StateCities['cities']>([]);

  const {
    register,
    clearErrors,
    setError,
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isLoading, isSubmitting },
  } = useForm({
    defaultValues: async () => await fetchData(),
  });

  const fetchStates = async () => {
    const states = await StatesAPICaller.fetchStates();
    setStates(states);
  };

  const filterStates = () => {
    if (!watch('state')) return states;
    return states
      .filter((s: State) =>
        removeAccents(s.name)
          .toLowerCase()
          .includes(removeAccents(watch('state')).toLowerCase())
      )
      .slice(0, 10);
  };

  const fetchStateCities = async (stateName: string) => {
    const cities = await StatesAPICaller.fetchStateCities(stateName);
    setCities(cities);
  };

  const filterStateCities = () => {
    return cities
      .filter((c: string) =>
        removeAccents(c)
          .toLowerCase()
          .includes(removeAccents(watch('city')).toLowerCase())
      )
      .slice(0, 10);
  };

  const fetchData = async () => {
    await fetchStates();
    if (props.editId) {
      const {
        data: { region },
      } = await fetch(props.editId);

      return region;
    }
    return null;
  };

  return (
    <div className="container p-s-200">
      <div className="modal-title">
        <h3>{props.editId ? 'Editar ' : 'Cadastrar '}região</h3>
      </div>
      <form
        autoComplete="off"
        className="form-max-height"
        onSubmit={handleSubmit((data) => {
          return createOrUpdate(data, setError).then((res) => {
            if (res.data.errors) return;
            props.closeModal();
            props.onSave();
          });
        })}
      >
        <div className="row grid-gap-1">
          <div className="col-md-12">
            <Input
              disabled={isLoading}
              options={filterStates().map((s) => s.name)}
              onOptionChange={async (index) => {
                const chosenState = filterStates()[index];
                if (!chosenState) return;
                setValue('state', chosenState.name);
                setValue('city', '');
                await fetchStateCities(chosenState.name);
              }}
              error={!!errors.state}
              caption={errors.state?.message as string}
              label="Estado"
              placeholder="Estado"
              form={register('state', { required: 'Obrigatório' })}
            />
          </div>
        </div>
        <div className="row grid-gap-1">
          <div className="col-md-12">
            <Input
              options={filterStateCities()}
              onOptionChange={async (index) => {
                const chosenCity = filterStateCities()[index];
                if (!chosenCity) return;
                setValue('city', chosenCity);
              }}
              error={!!errors.city}
              caption={errors.city?.message as string}
              label="Cidade"
              placeholder="Cidade"
              form={register('city', { required: 'Obrigatório' })}
              disabled={!watch('state') || isLoading}
            />
          </div>
        </div>
        <div className="row grid-gap-1">
          <div className="col-md-10">
            <Input
              error={!!errors['state, city, district'] || !!errors['district']}
              caption={
                (errors['state, city, district']?.message as string) ||
                (errors['district']?.message as string)
              }
              label="Bairro"
              placeholder="Bairro"
              form={register('district', {
                required: 'Obrigatório',
                onChange: () => clearErrors(['state, city, district']),
              })}
              disabled={!watch('state') || !watch('city') || isLoading}
            />
          </div>

          <div className="col-md-2">
            <Switch
              disabled={isLoading}
              className="mt-s-500 float-right"
              caption={watch('active', 'Ativo') ? 'Ativo' : 'Inativo'}
              error={errors.active?.message as string}
              form={register('active', { value: true })}
            />
          </div>
        </div>

        <div className="row justify-end pt-s-400" style={{ gap: 16 }}>
          <Button design="transparent" onClick={props.closeModal}>
            Cancelar
          </Button>
          <Button isLoading={isSubmitting || isLoading} type="submit">
            Salvar
          </Button>
        </div>
      </form>
    </div>
  );
}
