import { FieldValues, UseFormSetError } from 'react-hook-form';

import { Banner } from '@interfaces/Banner';

import { handleErrorForm } from '..';

import { BannerFilters, chosen, create, list, remove, retrieve } from './calls';

export * from './calls';

export default class BannersAPICaller {
  static adaptFromAPI = (data: Banner) => data as FieldValues;
  static adaptToAPI = (data: FieldValues) => data as Banner;

  static fetchBanners = async (filters: BannerFilters) => {
    const { data } = await list(filters);
    return data.loginBanners;
  };

  static deleteBanner = async (params: Partial<Banner>) => {
    const { data } = await remove(params);

    return data;
  };

  static activeOrInactiveBanner = async (params: Partial<Banner>) => {
    const { data } = await chosen(params);

    return data;
  };

  static getBannerLogin = async () => {
    const { data } = await retrieve();

    return data.imageUrl;
  };

  static createOrUpdate = async (
    data: FieldValues,
    setError: UseFormSetError<FieldValues>
  ) => {
    const method = create;

    const result = method(this.adaptToAPI(data)).catch(
      handleErrorForm(setError)
    );

    return result;
  };
}
