import { Region } from '@interfaces/Region';

import { RepositoryParams, api } from '..';

export type RegionFilters = Partial<Region & RepositoryParams>;

export const list = async (params: RegionFilters) => {
  return api.get('/regions', { params });
};

export const listAll = async (params: RegionFilters) => {
  return api.get('/regions/all', { params });
};

export const create = async (params: Region) => {
  return api.post('/regions', { ...params });
};

export const update = async (params: Region) => {
  return api.patch(`/regions/${params.id}`, { ...params });
};

export const retrieve = async (id: string) => {
  return api.get(`/regions/${id}`);
};

export const remove = async (id: string) => {
  return api.delete(`/regions/${id}`);
};
