import { DiscountCoupon } from '@interfaces/DiscountCoupon';

import { RepositoryParams, api } from '..';

export type DiscountCouponFilters = Partial<DiscountCoupon & RepositoryParams>;

export const list = async (params: DiscountCouponFilters) => {
  return api.get('/discount_coupons', { params });
};
export const listUsed = async (id: string, params?: DiscountCouponFilters) => {
  return api.get(`/discount_coupons/${id}/uses`, { params });
};

export const create = async (params: DiscountCoupon) => {
  return api.post('/discount_coupons', { ...params });
};

export const update = async (params: DiscountCoupon) => {
  return api.patch(`/discount_coupons/${params.id}`, { ...params });
};

export const retrieve = async (id: string) => {
  return api.get(`/discount_coupons/${id}`);
};

export const remove = async (id: string) => {
  return api.delete(`/discount_coupons/${id}`);
};
