import {
  Icon,
  PopOver,
  PopOverMenu,
  StatusChip,
  Table,
  TableColumn,
} from '@components';
import { usePermissionContext } from '@contexts/PermissionProvider';
import { useConfirmationModal } from '@hooks/useConfirmationModal';
import { Tips } from '@interfaces/Tips';
import TipsAPICaller from '@services/api/tips';

interface Props {
  data: Tips | null;
  fetch: (uuid: string) => void;
}

export default function CommentsView({ data, fetch }: Props) {
  const confirmation = useConfirmationModal();
  const { hasPermission } = usePermissionContext();

  const inactiveComment = (id: string) => {
    confirmation({
      title: 'Inativar comentário',
      confirmButtonTitle: 'Inativar',
      description: 'Deseja realmente inativar esse comentário?',
      onSubmit: () =>
        TipsAPICaller.updateComment({ id: id, active: false }).then((_) =>
          fetch(data?.id!)
        ),
    });
  };

  const activeComment = (id: string) => {
    confirmation({
      title: 'Ativar comentário',
      confirmButtonTitle: 'Ativar',
      description: 'Deseja realmente ativar esse comentário?',
      onSubmit: () =>
        TipsAPICaller.updateComment({ id: id, active: true }).then((_) =>
          fetch(data?.id!)
        ),
    });
  };

  return (
    <div className="pt-s-200">
      <Table
        hoverable
        data={data?.tipComments ?? []}
        className="pointer"
        showColumnSelector={false}
      >
        <TableColumn fromKey="personName" header="Nome" />
        <TableColumn fromKey="rating" header="Avaliação" />
        <TableColumn fromKey="comment" header="Comentário" ellipsis />
        <TableColumn
          fromKey="active"
          header="Status"
          render={(active: boolean) => <StatusChip active={active} />}
        />
        <TableColumn
          width="6%"
          fromKey="id"
          header="Ações"
          disableSorting
          render={(id, row) => {
            return (
              <PopOver
                fixedContent={<Icon>more_horiz</Icon>}
                togglableContent={
                  <PopOverMenu
                    menu={[
                      {
                        onClick: () => {
                          inactiveComment(id);
                        },
                        content: 'Inativar',
                        show:
                          hasPermission('tipComments', 'update') && row.active,
                      },
                      {
                        onClick: () => {
                          activeComment(id);
                        },
                        content: 'Ativar',
                        show:
                          hasPermission('tipComments', 'update') && !row.active,
                      },
                    ]}
                  />
                }
              />
            );
          }}
        />
      </Table>
    </div>
  );
}
