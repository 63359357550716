import { SelectOptions } from './Utils';

export interface Notification {
  id?: string;
  title: string;
  message: string;
  dispatchDate: string;
  oneSignalId?: string;
  status: string | SelectOptions;
  hour: string;

  voucherUsedTimesRule?: string;

  birthdayPeople?: boolean;
  gender?: string | SelectOptions;
  voucherUsedTimes?:
    | {
        rules: string | SelectOptions;
        amount: number;
      }
    | number;

  location?: boolean;
  segments?: string | SelectOptions;
  state?: string | SelectOptions;
  city?: string | SelectOptions;
  district?: string | SelectOptions;
}

export enum NotificationStatus {
  created = 'Criada',
  edited = 'Editada',
  cancelled = 'Cancelada',
}

export enum NotificationSegments {
  subscribedUsers = 'Todos',
  paidUsers = 'Usuários pagos',
  freeUsers = 'Usuários gratuitos',
}

export enum NotificationRules {
  greaterThan = 'Maior que',
  smallerThan = 'Menor que',
  equal = 'Igual a',
}
