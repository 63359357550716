import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Icon, Modal, StatusChip, Table, TableColumn } from '@components';
import { PopOver, PopOverMenu } from '@components/PopOver';
import { usePermissionContext } from '@contexts/PermissionProvider';
import { useConfirmationModal } from '@hooks/useConfirmationModal';
import { Region } from '@interfaces/Region';
import { RepositoryParams } from '@services/api';
import TipsAPICaller, { TipsFilters } from '@services/api/tips';

import { TipsForm } from './TipsForm';

export const InfoTable = () => {
  const navigate = useNavigate();
  const [editId, setEditId] = useState('');
  const [isOpenModal, toggleModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<{ total: number; result: Region[] }>({
    total: 0,
    result: [],
  });
  const [filters, setFilters] = useState<TipsFilters>({ order: 'name' });
  const confirmation = useConfirmationModal();
  const { hasPermission } = usePermissionContext();

  const list = (filters: RepositoryParams) => {
    setIsLoading(true);
    TipsAPICaller.list(filters)
      .then((values) => setData(values))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    list(filters);
  }, [filters]);

  const deleteTip = (id: string) => {
    confirmation({
      title: 'Excluir dica',
      confirmButtonTitle: 'Excluir',
      description: 'Deseja realmente excluir essa dica?',
      onSubmit: () => TipsAPICaller.remove(id).then((_) => list(filters)),
    });
  };

  return (
    <div className="pt-s-200">
      <Table
        isLoading={isLoading}
        hoverable
        totalRecords={data.total}
        data={data.result}
        withPagination
        withSearchInput
        filters={filters}
        setFilters={setFilters}
        className="pointer"
        onRowClick={(row) => {
          if (hasPermission('tips', 'read')) {
            navigate(`/tips/${row.id}`);
          }
        }}
      >
        <TableColumn fromKey="name" header="Nome" />
        <TableColumn
          fromKey="type"
          header="Tipo"
          render={({ label }) => {
            return label;
          }}
        />
        <TableColumn
          fromKey="active"
          header="Status"
          render={(active: boolean) => <StatusChip active={active} />}
        />
        <TableColumn
          width="6%"
          fromKey="id"
          header="Ações"
          disableSorting
          render={(id) => {
            return (
              <PopOver
                fixedContent={<Icon>more_horiz</Icon>}
                togglableContent={
                  <PopOverMenu
                    menu={[
                      {
                        onClick: () => {
                          setEditId(id);
                          toggleModal(true);
                        },
                        show: hasPermission('tips', 'update'),
                        content: 'Editar',
                      },
                      {
                        onClick: () => {
                          deleteTip(id);
                        },
                        content: 'Excluir',
                        show: hasPermission('tips', 'delete'),
                      },
                    ]}
                  />
                }
              />
            );
          }}
        />
      </Table>

      <Modal
        isOpen={isOpenModal}
        onClickAway={() => {
          setEditId('');
          toggleModal(false);
        }}
      >
        <TipsForm
          editId={editId}
          onSave={() => {}}
          closeModal={() => {
            setEditId('');
            toggleModal(false);
          }}
        />
      </Modal>
    </div>
  );
};
