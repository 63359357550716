import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';

import { Button } from '@components';
import FixedPermissionsAPICaller from '@services/api/fixedPermission';
import { isEmpty } from 'utils/object';

import { PermissionsInput } from '../PermissionsInput';

interface Props {
  closeModal: () => void;
  onSave: () => void;
}

const { update, retrieve, fetchPermissions } = FixedPermissionsAPICaller;

export default function FixedForm(props: Props) {
  const [permissions, setPermissions] = useState({});

  const {
    handleSubmit,
    setValue,
    setError,
    formState: { isLoading, isSubmitting },
    watch,
  } = useForm({
    defaultValues: async () => fetchData(),
  });

  const fetchData = async () => {
    const permissions = await retrieve();

    if (permissions) return permissions;

    return null;
  };

  useEffect(() => {
    fetchPermissions().then((permissions) => {
      setPermissions(permissions);
    });
  }, []);

  return (
    <div className="container p-s-200">
      <div className="modal-title">
        <h3>Editar Permissões Fixas</h3>
      </div>
      <form
        autoComplete="off"
        className="form-max-height"
        onSubmit={handleSubmit((data) => {
          return update(data, setError).then((res) => {
            if (res.data.errors) return;
            props.closeModal();
            props.onSave();
          });
        })}
      >
        <div className="row pb-s-200"></div>
        {isEmpty(permissions) ? (
          <div className="col-sm-12">
            <Skeleton
              count={5}
              height={50}
              style={{ marginTop: 10, zIndex: 0 }}
            />
          </div>
        ) : (
          <div className="">
            <PermissionsInput
              isFixedPermission
              onChange={(permissions) => setValue('permissions', permissions)}
              value={watch('permissions', [])}
              permissions={permissions}
            />
          </div>
        )}

        <div className="row justify-end pt-s-400" style={{ gap: 16 }}>
          <Button design="transparent" onClick={props.closeModal}>
            Cancelar
          </Button>
          <Button isLoading={isSubmitting || isLoading} type="submit">
            Salvar
          </Button>
        </div>
      </form>
    </div>
  );
}
