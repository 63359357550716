import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

export function useGeolocation() {
  const [latLng, setLatLng] = useState({ latitude: 0, longitude: 0 });

  const getCurrentLocation = useCallback(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatLng(position.coords);
          return position.coords;
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            toast.error(`Permissão de localização negada.`);
            return;
          }

          toast.error('Erro ao obter a localização: ' + error.message);
          return;
        }
      );
    } else {
      toast.error(`localização não suportada.`);
    }
  }, []);

  return {
    getCurrentLocation,
    latLng,
  };
}
