import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, DataLoader, Modal, Restricted } from '@components';
import { useConfirmationModal } from '@hooks/useConfirmationModal';
import { Tips } from '@interfaces/Tips';
import { SelectOptions } from '@interfaces/Utils';
import TipsAPICaller from '@services/api/tips';
import { fileToBlobURL } from '@services/io/file';

import { TipsForm } from '../TipsForm';

interface Props {
  data: Tips | null;
  fetch: (uuid: string) => void;
}

export default function InfoView({ data, fetch }: Props) {
  const [editId, setEditId] = useState('');
  const navigate = useNavigate();
  const confirmation = useConfirmationModal();

  const deleteTip = (id: string) => {
    confirmation({
      title: 'Excluir dica',
      confirmButtonTitle: 'Excluir',
      description: `Deseja realmente excluir a dica ${data!.name}`,
      onSubmit: () => TipsAPICaller.remove(id).then((_) => navigate(-1)),
    });
  };

  return (
    <div className="card card__body p-s-350 bg-white">
      <div className="row">
        <div className="col-md-6">
          <h4>Nome</h4>
          <span>
            <DataLoader data={data?.name} isLoading={!data} />
          </span>
        </div>

        <div className="col-md-3">
          <h4>Tipo</h4>
          <span>
            <DataLoader
              data={(data?.type as SelectOptions)?.label}
              isLoading={!data}
            />
          </span>
        </div>

        <div className="col-md-3">
          <h4>Linha de ônibus</h4>
          <span>
            <DataLoader data={data?.busLine} isLoading={!data} />
          </span>
        </div>
        <div className="col-md-12  mt-s-250">
          <h4>Descrição</h4>
          <span>
            <DataLoader data={data?.description} isLoading={!data} />
          </span>
        </div>

        <div className="col-md-6 mt-s-250">
          <h4>Estado</h4>
          <span>
            <DataLoader
              data={(data?.state as SelectOptions)?.value}
              isLoading={!data}
            />
          </span>
        </div>
        <div className="col-md-6  mt-s-250">
          <h4>Cidade</h4>
          <span>
            <DataLoader
              data={(data?.city as SelectOptions)?.value}
              isLoading={!data}
            />
          </span>
        </div>

        <div className="col-md-6  mt-s-250">
          <h4>Bairro</h4>
          <span>
            <DataLoader
              data={(data?.district as SelectOptions)?.value}
              isLoading={!data}
            />
          </span>
        </div>
        <div className="col-md-6  mt-s-250">
          <h4>Rua</h4>
          <span>
            <DataLoader data={data?.street} isLoading={!data} />
          </span>
        </div>

        <div className="col-md-12  mt-s-250">
          <h4>Complemento</h4>
          <span>
            <DataLoader data={data?.complement} isLoading={!data} />
          </span>
        </div>

        <div className="col-md-6  mt-s-250">
          <h4>Status</h4>
          <div className="row d-flex align-items-center">
            <span>{data?.active ? 'Ativo' : 'Inativo'}</span>
            <div
              className={`little-ball-${data?.active ? 'green' : 'red'}`}
            ></div>
          </div>
        </div>

        <div className="col-md-12 mt-s-250">
          <hr className="pb-s-100" />
          <h4>Imagens</h4>
          <div className="row grid-gap-1">
            <DataLoader
              data={(data?.images as File[])?.map((it: File, i: number) => {
                const img = fileToBlobURL(it);

                return (
                  <div className="col-md-3" key={it.size + i}>
                    <div style={{ position: 'relative' }}>
                      {i === 0 && (
                        <div
                          style={{ fontSize: 12, padding: 4 }}
                          className="principal_image mb-s-450 ml-s-100 bg-white border-radius-100"
                        >
                          Imagem principal
                        </div>
                      )}

                      <div className="banner-image-container">
                        <img
                          src={img}
                          className="banner-image border-radius-100"
                          alt={`${it.name}`}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
              isLoading={!Boolean(data)}
            />
          </div>
        </div>
      </div>

      <DataLoader
        height={40}
        className="mt-s-300"
        isLoading={!data}
        data={
          <div className="row mt-s-300">
            <Restricted context="tips" resource="update">
              <div className="col mr-s-100">
                <Button
                  onClick={() => setEditId(data?.id!)}
                  design="outlined"
                  size="medium"
                >
                  Editar informações
                </Button>
              </div>
            </Restricted>
            <Restricted context="tips" resource="delete">
              <div className="col mr-s-100">
                <Button
                  onClick={() => deleteTip(data?.id!)}
                  design="outlined"
                  size="medium"
                  color="negative"
                >
                  Excluir dica
                </Button>
              </div>
            </Restricted>
          </div>
        }
      />

      <Modal
        isOpen={!!editId}
        onClickAway={() => {
          setEditId('');
        }}
      >
        <TipsForm
          editId={editId}
          onSave={() => {
            setEditId('');
            fetch(data?.id!);
          }}
          closeModal={() => {
            setEditId('');
          }}
        />
      </Modal>
    </div>
  );
}
