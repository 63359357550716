import EstablishmentAPICaller from '@services/api/establishment';
import { Establishment } from '@interfaces/Establishment';

import { useFetchEntity, useFetchList } from '../useFetch';

export const useFetchEstablishments = () => {
  const { data, total, fetching, fetchData } = useFetchList(
    EstablishmentAPICaller.list<Establishment>
  );

  return {
    establishments: data,
    establishmentsTotal: total,
    isFetchingEstablishments: fetching,
    fetchEstablishments: fetchData,
  };
};

export const useFetchEstablishment = () => {
  const { data, fetchData, fetching } = useFetchEntity(
    EstablishmentAPICaller.fetch<Establishment>
  );

  return {
    establishment: data,
    fetchEstablishment: fetchData,
    isFetchingEstablishment: fetching,
  };
};
