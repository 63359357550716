import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Icon, Modal, StatusChip, Table, TableColumn } from '@components';
import { PopOver, PopOverMenu } from '@components/PopOver';
import { usePermissionContext } from '@contexts/PermissionProvider';
import { useConfirmationModal } from '@hooks/useConfirmationModal';
import { Region } from '@interfaces/Region';
import { RepositoryParams } from '@services/api';
import TipsAPICaller, { TipsFilters } from '@services/api/tips';

import { TipsForm } from './TipsForm';

export const CommentsTable = () => {
  const [editId, setEditId] = useState('');
  const navigate = useNavigate();
  const [isOpenModal, toggleModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<{ total: number; result: Region[] }>({
    total: 0,
    result: [],
  });
  const [filters, setFilters] = useState<TipsFilters>({
    joins: ['tip'],
    order: 'rating',
  });
  const confirmation = useConfirmationModal();
  const { hasPermission } = usePermissionContext();

  const list = (filters: RepositoryParams) => {
    setIsLoading(true);
    TipsAPICaller.listComments(filters)
      .then((values) => setData(values))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    list(filters);
  }, [filters]);

  const inactiveComment = (id: string) => {
    confirmation({
      title: 'Inativar comentário',
      confirmButtonTitle: 'Inativar',
      description: 'Deseja realmente inativar esse comentário?',
      onSubmit: () =>
        TipsAPICaller.updateComment({ id: id, active: false }).then((_) =>
          list(filters)
        ),
    });
  };

  const activeComment = (id: string) => {
    confirmation({
      title: 'Ativar comentário',
      confirmButtonTitle: 'Ativar',
      description: 'Deseja realmente ativar esse comentário?',
      onSubmit: () =>
        TipsAPICaller.updateComment({ id: id, active: true }).then((_) =>
          list(filters)
        ),
    });
  };

  return (
    <div className="pt-s-200">
      <Table
        isLoading={isLoading}
        hoverable
        totalRecords={data.total}
        data={data.result}
        withPagination
        withSearchInput
        filters={filters}
        setFilters={setFilters}
        className="pointer"
        onRowClick={(row) => {
          navigate(`/tips/${row.tipId}?tab=1`);
        }}
      >
        <TableColumn fromKey="personName" header="Nome" />
        <TableColumn fromKey="tip" header="Lugar" render={({ name }) => name} />
        <TableColumn fromKey="rating" header="Avaliação" />
        <TableColumn fromKey="comment" header="Comentário" ellipsis />
        <TableColumn
          fromKey="active"
          header="Status"
          render={(active: boolean) => <StatusChip active={active} />}
        />
        <TableColumn
          width="6%"
          fromKey="id"
          header="Ações"
          disableSorting
          render={(id, row) => {
            return (
              <PopOver
                fixedContent={<Icon>more_horiz</Icon>}
                togglableContent={
                  <PopOverMenu
                    menu={[
                      {
                        onClick: () => {
                          inactiveComment(id);
                        },
                        content: 'Inativar',
                        show:
                          hasPermission('tipComments', 'update') && row.active,
                      },
                      {
                        onClick: () => {
                          activeComment(id);
                        },
                        content: 'Ativar',
                        show:
                          hasPermission('tipComments', 'update') && !row.active,
                      },
                    ]}
                  />
                }
              />
            );
          }}
        />
      </Table>

      <Modal
        isOpen={isOpenModal}
        onClickAway={() => {
          setEditId('');
          toggleModal(false);
        }}
      >
        <TipsForm
          editId={editId}
          onSave={() => {}}
          closeModal={() => {
            setEditId('');
            toggleModal(false);
          }}
        />
      </Modal>
    </div>
  );
};
