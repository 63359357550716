import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Icon,
  Modal,
  PopOver,
  PopOverMenu,
  Restricted,
  Table,
  TableColumn,
} from '@components';
import { useConfirmationModal } from '@hooks/useConfirmationModal';
import EmphasisAPICaller, { EmphasisFilters } from '@services/api/emphasis';
import { usePermissionContext } from '@contexts/PermissionProvider';

import EmphasisForm from '../EmphasisForm/EmphasisForm';

export default function EmpashisTable() {
  const navigate = useNavigate();

  const { hasPermission } = usePermissionContext();
  const [filters, setFilters] = useState<EmphasisFilters>({
    joins: ['plan'],
    order: '-endsAt',
  });
  const [editId, setEditId] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const confirmation = useConfirmationModal();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({
    total: 0,
    result: [],
  });

  const fetch = (filters: EmphasisFilters) => {
    setIsLoading(true);
    EmphasisAPICaller.list(filters)
      .then((values) => setData(values))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetch(filters);
  }, [filters]);

  const deleteEmphasis = (id: string) => {
    confirmation({
      title: 'Excluir destaque',
      confirmButtonTitle: 'Excluir',
      description: 'Deseja realmente excluir esse destaque?',
      onSubmit: () => EmphasisAPICaller.remove(id).then((_) => fetch(filters)),
    });
  };

  return (
    <>
      <div className="d-flex justify-end">
        <Restricted context="emphasis" resource="create">
          <Button
            onClick={() => {
              setIsOpenModal(true);
            }}
            weight="bold"
            prefixes={<Icon>add</Icon>}
          >
            Criar novo destaque
          </Button>
        </Restricted>
      </div>

      <Table
        isLoading={isLoading}
        hoverable
        totalRecords={data.total}
        data={data.result}
        withPagination
        withSearchInput
        setFilters={setFilters}
        filters={filters}
        className="pointer"
        showColumnSelector={false}
        onRowClick={(row) => {
          navigate(`/emphasis/${row.id}`);
        }}
      >
        <TableColumn fromKey="name" header="Nome" />
        <TableColumn fromKey="emphasisedVouchersAmount" header="Vouchers" />
        <TableColumn
          fromKey="plan"
          header="Plano"
          render={(plan) => plan?.name}
        />
        <TableColumn
          fromKey="startsAt"
          header="Período"
          render={(_, { startsAt, endsAt }) => {
            return `${startsAt} - ${endsAt}`;
          }}
        />
        <TableColumn
          width="6%"
          fromKey="id"
          header="Ações"
          disableSorting
          render={(id) => {
            return (
              <PopOver
                fixedContent={<Icon>more_horiz</Icon>}
                togglableContent={
                  <PopOverMenu
                    menu={[
                      {
                        onClick: () => {
                          setEditId(id);
                          setIsOpenModal(true);
                        },
                        content: 'Editar',
                        show: hasPermission('emphasis', 'update'),
                      },
                      {
                        onClick: () => {
                          deleteEmphasis(id);
                        },
                        content: 'Excluir',
                        show: hasPermission('emphasis', 'delete'),
                      },
                    ]}
                  />
                }
              />
            );
          }}
        />
      </Table>

      <Modal
        isOpen={isOpenModal}
        onClickAway={() => {
          setIsOpenModal(false);
        }}
      >
        <EmphasisForm
          closeModal={() => {
            setIsOpenModal(false);
          }}
          onSave={(id: string) => {
            setIsOpenModal(false);
            navigate(`/emphasis/${id}`);
          }}
          editId={editId}
        />
      </Modal>
    </>
  );
}
