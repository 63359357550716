import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { useState } from 'react';

import { handleErrorForm } from '@services/api';
import { Button, Input, LoginPageContainer } from '@components';
import { formEmailPattern } from '@validations/email';
import AuthAPICaller from '@services/api/auth';

export default function RecoverPasswordPage() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = ((data: { email: string }) => {
    setIsLoading(true);
    AuthAPICaller.sendEmailRecover(data.email)
      .then(() => navigate(`/email_sent?email=${data.email}`))
      .catch(handleErrorForm(setError))
      .finally(() => setIsLoading(false));
  }) as SubmitHandler<FieldValues>;

  return (
    <div className="bg-neutral-95">
      <div className="d-flex justify-center" style={{ height: '100vh' }}>
        <div className="col-sm-12 col-md-10 d-flex justify-center background-login-page">
          <form
            autoComplete="off"
            className="form-max-height"
            onSubmit={handleSubmit(onSubmit)}
          >
            <LoginPageContainer>
              <h3 className="!w100 d-flex justify-center p-s-300 text-center">
                Informe e-mail para receber link de recuperação
              </h3>
              <Input
                placeholder="Email"
                form={register('email', {
                  required: 'Obrigatório',
                  ...formEmailPattern,
                })}
                error={!!errors.email}
                caption={errors.email?.message as string}
              />

              <Button
                type="submit"
                isLoading={isLoading}
                className="!w-100 !mt-s-200"
                alignText="center"
              >
                Enviar e-mail
              </Button>

              <hr className="!mt-s-300" />

              <Button
                design="transparent"
                color="primary"
                className="!pl-0 !mt-s-100"
                alignText="center"
                textClassName="!ml-0"
                onClick={() => navigate('/login')}
              >
                Voltar ao login
              </Button>
            </LoginPageContainer>
          </form>
        </div>
      </div>
    </div>
  );
}
