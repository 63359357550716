import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Button, FileUpload, Input, SelectInput as Select } from '@components';
import { AdvertisementStatus } from '@interfaces/Advertisement';
import { SelectOptions } from '@interfaces/Utils';
import { VoucherStatus } from '@interfaces/Voucher';
import AdvertisementAPICaller from '@services/api/advertisement';
import PlanAPICaller from '@services/api/plans';
import StatesAPICaller from '@services/api/states';
import { formSitePattern } from '@validations/site';
import { handleSelectedAll } from 'utils/hasSelectedAll';
import { toValueLabel } from 'utils/object';

interface Props {
  closeModal: () => void;
  onSave: () => void;
  editId?: string;
}

export default function AdvertisementForm(props: Props) {
  const aspect = { width: 1200, height: 325 };
  const { createOrUpdate, fetch } = AdvertisementAPICaller;
  const [states, setStates] = useState<
    (SelectOptions & { abbreviation: string; name: string })[]
  >([]);
  const [cities, setCities] = useState<(SelectOptions | string)[]>([]);
  const [plans, setPlans] = useState<SelectOptions[]>([]);

  const {
    register,
    setError,
    watch,
    setFocus,
    handleSubmit,
    setValue,
    formState: { errors, isLoading, isSubmitting },
  } = useForm({
    defaultValues: async () => fetchData(),
  });

  const fetchData = async () => {
    fetchStates();
    fetchPlans();
    if (props.editId) {
      const advertisement = await fetch(props.editId);

      await fetchStateCities(advertisement.state?.label);
      setFocus('');
      return advertisement;
    }

    return {
      status: toValueLabel(VoucherStatus.active),
    };
  };

  const fetchStates = async () => {
    const statesResponse = await StatesAPICaller.fetchStates();
    setStates(statesResponse);

    return states;
  };

  const fetchPlans = async () => {
    const plansResponse = await PlanAPICaller.all();

    plansResponse.unshift({
      id: 'all',
      name: 'Todos',
    });

    setPlans(plansResponse);

    return plansResponse;
  };

  const fetchStateCities = async (stateName: string) => {
    setFocus('city');
    const cities = await StatesAPICaller.fetchStateCities(stateName);
    setCities(cities);

    return cities;
  };

  return (
    <div className="container p-s-200">
      <div className="modal-title">
        <h3>{props.editId ? 'Editar' : 'Cadastrar'} anúncio</h3>
      </div>
      <form
        autoComplete="off"
        className="form-max-height"
        onSubmit={handleSubmit((data) => {
          data.plans = handleSelectedAll(data.plans, plans);

          return createOrUpdate(data, setError).then((res) => {
            if (res.data.errors) return;
            props.closeModal();
            props.onSave();
          });
        })}
      >
        <div className="row grid-gap-1">
          <div className="col-md-12">
            <Input
              error={!!errors.name}
              caption={errors.name?.message as string}
              label="Nome"
              placeholder="Nome"
              form={register('name', { required: 'Obrigatório' })}
              disabled={isLoading}
            />
          </div>

          <div className="col-md-6">
            <Select
              value={watch('status')}
              placeholder="Status"
              disabled={isLoading}
              options={Object.values(AdvertisementStatus)}
              onSelect={(value) => setValue('status', value)}
              form={register('status', { required: 'Obrigatório' })}
              error={!!errors.status}
              caption={errors.status?.message as string}
              label="Status"
            />
          </div>

          <div className="col-md-6">
            <Input
              label="Link"
              error={!!errors.link}
              placeholder="google.com"
              caption={errors.link?.message as string}
              form={register('link', { ...formSitePattern })}
              disabled={isLoading}
            />
          </div>

          <div className="col-md-6">
            <Select
              value={watch('state')}
              placeholder="Estado"
              disabled={isLoading}
              isSearchable
              options={states}
              onSelect={(value: { label: string }) => {
                setValue('state', value);
                if (value?.label) {
                  setValue('city', '');
                  fetchStateCities(value?.label);
                }
              }}
              form={register('state', { required: 'Obrigatório' })}
              error={!!errors.state}
              caption={errors.state?.message as string}
              fromKey="name"
              label="Estado"
            />
          </div>
          <div className="col-md-6">
            <Select
              isSearchable
              value={watch('city')}
              placeholder="Cidade"
              disabled={isLoading || !watch('state')}
              options={cities}
              onSelect={(value) => {
                setValue('city', value);
              }}
              form={register('city', { required: 'Obrigatório' })}
              error={!!errors.city}
              caption={errors.city?.message as string}
              label="Cidade"
            />
          </div>

          <div className="col-md-12">
            <Select
              value={watch('plans')}
              placeholder="Planos"
              isMulti
              disabled={isLoading}
              options={plans}
              onSelect={(value) => {
                setValue('plans', value);
              }}
              form={register('plans', { required: 'Obrigatório' })}
              error={!!errors.plans}
              caption={errors.plans?.message as string}
              label="Plano"
              fromKey="name"
            />
          </div>

          <div className="col-md-12">
            <label className="form-input__label">Anúncio</label>
            <FileUpload
              uploadPreview
              fileName=""
              image={watch('cover')}
              imageHeight={200}
              accept="image/*"
              maxSizeInBytes={5_000_000}
              disclaimer={`${aspect.width}x${aspect.height}px com até 5MB`}
              cropProps={{
                aspect: aspect.width / aspect.height,
              }}
              openCropImageModal
              isRemovable
              form={register('cover')}
              onChange={([file]) => {
                setValue('cover', file);
              }}
              label="Escolher foto do anúncio"
              error={errors.cover?.message as string}
            />
          </div>
        </div>

        <div className="row justify-end pt-s-400" style={{ gap: 16 }}>
          <Button design="transparent" onClick={props.closeModal}>
            Cancelar
          </Button>
          <Button isLoading={isSubmitting || isLoading} type="submit">
            Salvar
          </Button>
        </div>
      </form>
    </div>
  );
}
