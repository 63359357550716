import { useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  Button,
  DatePickerInput,
  Input,
  SelectInput as Select,
  Switch,
} from '@components';
import { SelectOptions } from '@interfaces/Utils';
import DiscountCouponAPICaller from '@services/api/discountCoupons';
import PlanAPICaller from '@services/api/plans';
import { handleSelectedAll } from 'utils/hasSelectedAll';
import { formSitePattern } from '@validations/site';
interface Props {
  closeModal: () => void;
  onSave: () => void;
  editId?: string;
}

export default function DiscountCouponForm(props: Props) {
  const { createOrUpdate, fetch } = DiscountCouponAPICaller;
  const [plans, setPlans] = useState<SelectOptions[]>([]);

  const {
    register,
    setError,
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting, isLoading },
  } = useForm({
    defaultValues: async () => fetchData(),
  });

  const fetchData = async () => {
    PlanAPICaller.all().then((values) => {
      values.unshift({
        id: 'all',
        name: 'Todos',
      });

      setPlans(values);
    });

    if (props.editId) {
      const data = await fetch(props.editId);

      return data;
    }

    return null;
  };

  return (
    <div className="container p-s-200">
      <div className="modal-title">
        <h3>{props.editId ? 'Editar ' : 'Cadastrar '}cupom de desconto</h3>
      </div>
      <form
        autoComplete="off"
        onSubmit={handleSubmit((data) => {
          data.plans = handleSelectedAll(data.plans, plans);

          return createOrUpdate(data, setError).then((res) => {
            if (res.data.errors) return;
            props.closeModal();
            props.onSave();
          });
        })}
      >
        <div className="row grid-gap-1">
          <div className="col-md-10">
            <Input
              disabled={isLoading}
              error={!!errors.name}
              caption={errors.name?.message as string}
              label="Nome"
              placeholder="Nome"
              form={register('name', { required: 'Obrigatório' })}
            />
          </div>

          <div className="col-md-2">
            <Switch
              disabled={isLoading}
              className="mt-s-500 float-right"
              caption={watch('active', 'Ativo') ? 'Ativo' : 'Inativo'}
              error={errors.active?.message as string}
              form={register('active', { value: true })}
            />
          </div>

          <div className="col-md-6">
            <Input
              disabled={isLoading}
              error={!!errors.code}
              caption={errors.code?.message as string}
              label="Código"
              placeholder="Código para ativação do cupom"
              form={register('code', { required: 'Obrigatório' })}
            />
          </div>
          <div className="col-md-6">
            <DatePickerInput
              disabled={isLoading}
              error={!!errors.expirationDate}
              caption={errors.expirationDate?.message as string}
              label="Data de expiração"
              dateSelected={watch('expirationDate')}
              onSelect={(date) => setValue('expirationDate', date)}
              placeholder="Quando o cupom deve para de ser válido"
              form={register('expirationDate', { required: 'Obrigatório' })}
            />
          </div>

          <div className="col-md-6">
            <Input
              disabled={isLoading}
              error={!!errors.discountPercentage}
              caption={errors.discountPercentage?.message as string}
              label="Porcentagem de desconto"
              mask="decimal"
              placeholder="%"
              form={register('discountPercentage', { required: 'Obrigatório' })}
            />
          </div>
          <div className="col-md-6">
            <Input
              disabled={isLoading}
              error={!!errors.amount}
              caption={errors.amount?.message as string}
              label="Quantidade"
              mask="onlyNumbers"
              placeholder="Quantidade de cupons disponíveis"
              form={register('amount', { required: 'Obrigatório' })}
            />
          </div>

          <div className="col-md-12">
            <Input
              disabled={isLoading}
              error={!!errors.domain}
              caption={errors.domain?.message as string}
              label="Domínio"
              placeholder="google.com"
              form={register('domain', { ...formSitePattern })}
            />
          </div>

          <div className="col-md-12">
            <div className="col-md-12">
              <Select
                value={watch('plans')}
                placeholder="Planos em que o cupom vai estar disponível"
                isMulti
                disabled={isLoading}
                options={plans}
                onSelect={(value) => {
                  setValue('plans', value);
                }}
                form={register('plans', { required: 'Obrigatório' })}
                error={!!errors.plans}
                caption={errors.plans?.message as string}
                label="Plano"
                fromKey="name"
              />
            </div>
          </div>
        </div>

        <div className="row justify-end pt-s-400" style={{ gap: 16 }}>
          <Button design="transparent" onClick={props.closeModal}>
            Cancelar
          </Button>
          <Button isLoading={isSubmitting || isLoading} type="submit">
            Salvar
          </Button>
        </div>
      </form>
    </div>
  );
}
