import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import defaultImage from '@assets/defaultImage.png';
import { Button, DataLoader, Modal, PageHeader, Restricted } from '@components';
import { useConfirmationModal } from '@hooks/useConfirmationModal';
import { Advertisement } from '@interfaces/Advertisement';
import { SelectOptions } from '@interfaces/Utils';
import AdvertisementAPICaller from '@services/api/advertisement';
import { validateUUIDFormat } from 'utils/string/uuid';

import { AdvertisementForm } from '../AdvertisementForm';

export default function AdvertisementView() {
  const { uuid } = useParams();
  const [data, setData] = useState<Advertisement | null>(null);
  const [editId, setEditId] = useState('');
  const navigate = useNavigate();
  const confirmation = useConfirmationModal();

  const fetch = (uuid: string) => {
    AdvertisementAPICaller.fetch(uuid).then((values) =>
      setData(values as Advertisement)
    );
  };

  useEffect(() => {
    if (uuid) {
      if (validateUUIDFormat(uuid)) {
        fetch(uuid);
      } else {
        toast.error('ID informado inválido');
        navigate(-1);
      }
    }
  }, [uuid, navigate]);

  const deleteAdvertisement = (id: string) => {
    confirmation({
      title: 'Excluir anúncio',
      confirmButtonTitle: 'Excluir',
      description: `Deseja realmente excluir o anúncio ${data!.name}`,
      onSubmit: () =>
        AdvertisementAPICaller.remove(id).then((_) => navigate(-1)),
    });
  };

  return (
    <>
      <div className="p-s-200">
        <PageHeader backButton title="Anúncio" />
        <div className="card card__body p-s-350 bg-white">
          <div className="row">
            <div className="col-md-6">
              <h4>Nome</h4>
              <span>
                <DataLoader data={data?.name} isLoading={!data} />
              </span>
            </div>

            <div className="col-md-6">
              <h4>Link</h4>
              <span>
                <DataLoader data={data?.link} isLoading={!data} />
              </span>
            </div>

            <div className="col-md-6  mt-s-250">
              <h4>Estado</h4>
              <span>
                <DataLoader
                  data={(data?.state as SelectOptions)?.label}
                  isLoading={!data}
                />
              </span>
            </div>
            <div className="col-md-6 mt-s-250">
              <h4>Cidade</h4>
              <span>
                <DataLoader
                  data={(data?.city as SelectOptions)?.label}
                  isLoading={!data}
                />
              </span>
            </div>

            <div className="col-md-6  mt-s-250">
              <h4>Cliques</h4>
              <span>
                <DataLoader data={data?.clicks || 0} isLoading={!data} />
              </span>
            </div>
            <div className="col-md-6  mt-s-250">
              <h4>Status</h4>
              <DataLoader
                data={
                  <div className="row d-flex">
                    <span>{data?.status as string}</span>
                    <div
                      className={`little-ball-${
                        (data?.status as string) === 'Ativo' ? 'green' : 'red'
                      }`}
                    />
                  </div>
                }
                isLoading={!data}
              />
            </div>

            <div className="col-md-12  mt-s-250">
              <h4>Planos</h4>
              <span>
                <DataLoader
                  data={data?.plans?.map((it) => it.name).join(', ')}
                  isLoading={!data}
                />
              </span>
            </div>

            <div className="col-md-12  mt-s-250">
              <h4>Anúncio</h4>
              {data ? (
                <img
                  src={(data?.cover as string) || defaultImage}
                  alt="cover"
                  height={260}
                  className="border-radius-100"
                />
              ) : (
                <Skeleton height={150} style={{ width: '98%' }} />
              )}
            </div>
          </div>

          {data ? (
            <div className="row mt-s-300">
              <Restricted context="advertisement" resource="update">
                <div className="col mr-s-100">
                  <Button
                    onClick={() => setEditId(uuid!)}
                    design="outlined"
                    size="medium"
                  >
                    Editar informações
                  </Button>
                </div>
              </Restricted>
              <Restricted context="advertisement" resource="delete">
                <div className="col mr-s-100">
                  <Button
                    onClick={() => deleteAdvertisement(uuid!)}
                    design="outlined"
                    size="medium"
                    color="negative"
                  >
                    Excluir anúncio
                  </Button>
                </div>
              </Restricted>
            </div>
          ) : (
            <Skeleton
              height={40}
              style={{ width: '98%' }}
              className="mt-s-300"
            />
          )}
        </div>
      </div>

      {!!(uuid && editId) && (
        <Modal
          isOpen={!!editId}
          onClickAway={() => {
            setEditId('');
          }}
        >
          <AdvertisementForm
            editId={editId}
            onSave={() => {
              fetch(uuid);
              setEditId('');
            }}
            closeModal={() => {
              setEditId('');
            }}
          />
        </Modal>
      )}
    </>
  );
}
