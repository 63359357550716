import { BeforeMaskedStateChangeStates } from 'react-input-mask';

import { formatPhone } from 'utils/string/phone';

export default function maskPhone(newState: BeforeMaskedStateChangeStates) {
  const { value } = newState.nextState || {};

  const cleanNumber = value?.replace?.(/\D/g, '');

  const formatted = formatPhone(cleanNumber);

  newState.nextState.value = formatted;

  return newState.nextState;
}
