import { useId } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

type Props = {
  className?: string;
  caption?: string;
  label?: string;
  error?: string;
  disabled?: boolean;
  name?: string;
  form?: Partial<UseFormRegisterReturn>;
};

export default function Switch(props: Props) {
  const id = useId();
  const switchClasses = ['form-check', 'form-switch'];
  if (props.className) switchClasses.push(props.className);

  return (
    <>
      {props.label && (
        <label className="form-input__label" htmlFor={`switch-${id}`}>
          {props.label}
        </label>
      )}
      <div className={switchClasses.join(' ')}>
        <input
          name={props.name}
          id={`switch-${id}`}
          className="form-check-input"
          type="checkbox"
          role="switch"
          disabled={props.disabled}
          {...props.form}
        />
        {props.caption && (
          <label
            className="form-check-label unselectable"
            htmlFor={`switch-${id}`}
          >
            {props.caption}
          </label>
        )}
      </div>
      {props.error && (
        <div className="form-input__container form-input__container--error mb-s-100">
          <div className="form-input__caption">{props.error}</div>
        </div>
      )}
    </>
  );
}
