import { FieldValues, UseFormSetError } from 'react-hook-form';

import { handleErrorForm } from '@services/api';
import {
  Notification,
  NotificationRules,
  NotificationSegments,
  NotificationStatus,
} from '@interfaces/Notification';
import { parseDate, parseDateHourToIsoString } from 'utils/string/dates';
import { reverseKeyValueObject, toValueLabel } from 'utils/object';
import { UserClientsGender } from '@interfaces/UserClient';
import { SelectOptions } from '@interfaces/Utils';

import {
  NotificationFilters,
  create,
  list,
  remove,
  retrieve,
  update,
} from './calls';

export * from './calls';

export default class NotificationAPICaller {
  static adaptFromAPI = (data: Notification) => {
    const notification: FieldValues = {
      ...data,
    };
    const regex = /T(\d{2}:\d{2})/;

    notification.dispatchDate = parseDate(data.dispatchDate)!;
    notification.hour = data.dispatchDate?.match(regex)![1];

    notification.status = toValueLabel(
      NotificationStatus[
        data.status as string as keyof typeof NotificationStatus
      ]
    );

    if (data.segments) {
      notification.segments = toValueLabel(
        NotificationSegments[
          data.segments as string as keyof typeof NotificationSegments
        ]
      );
    }

    if (data.gender) {
      notification.genderCheck = true;
      notification.gender = toValueLabel(
        UserClientsGender[
          data.gender as string as keyof typeof UserClientsGender
        ]
      );
    }

    if (data.state && data.city && data.district) {
      notification.location = true;
      notification.state = toValueLabel(data.state as string);
      notification.city = toValueLabel(data.city as string);
      notification.district = toValueLabel(data.district as string);
    }

    if (data.birthdayPeople) {
      notification.birthdayPeople = true;
    }

    if (data.voucherUsedTimesRule) {
      notification.voucherUsedTimes = true;
      notification.rules = toValueLabel(
        NotificationRules[
          data.voucherUsedTimesRule as keyof typeof NotificationRules
        ]
      );

      notification.qtt = Number(data.voucherUsedTimes);
    }

    return notification as FieldValues;
  };

  static adaptToAPI = (data: FieldValues) => {
    const notification: FieldValues = {
      ...data,
    };

    notification.status =
      reverseKeyValueObject(NotificationStatus)[
        data.status?.value as keyof typeof NotificationStatus
      ];

    notification.dispatchDate = parseDateHourToIsoString(
      `${data.dispatchDate} ${data.hour}`
    );

    if (data.segments) {
      notification.segments =
        reverseKeyValueObject(NotificationSegments)[
          data.segments?.value as keyof typeof NotificationSegments
        ];
    }

    if (data.voucherUsedTimes) {
      notification.voucherUsedTimes = {
        rules:
          reverseKeyValueObject(NotificationRules)[
            data.rules?.value as keyof typeof NotificationRules
          ],
        amount: Number(data.qtt),
      };
    }

    if (data.birthdayPeople) {
      notification.birthdayPeople = true;
    }

    if (data.genderCheck) {
      notification.gender =
        reverseKeyValueObject(UserClientsGender)[
          data.gender?.value as keyof typeof UserClientsGender
        ];
    }

    if (data.location) {
      notification.state = (data.state as SelectOptions)?.label;
      notification.city = (data.city as SelectOptions)?.label;
      notification.district = (data.district as SelectOptions)?.label;
    }

    ///Clear object to send only correct data
    ['rules', 'genderCheck', 'qtt', 'hour'].forEach((it) => {
      delete notification[it];
    });

    return notification as Notification;
  };

  static list = async (filters: NotificationFilters) => {
    const {
      data: { notifications },
    } = await list(filters);

    return {
      ...notifications,
      result: notifications.result.map(this.adaptFromAPI),
    };
  };

  static fetch = async (editId: string) => {
    const { data } = await retrieve(editId);
    return this.adaptFromAPI(data.notification);
  };

  static createOrUpdate = async (
    data: FieldValues,
    setError: UseFormSetError<FieldValues>
  ) => {
    const method = data.id ? update : create;

    const result = method(this.adaptToAPI(data)).catch(
      handleErrorForm(setError)
    );

    return result;
  };

  static remove = async (id: string) => {
    const { data } = await remove(id);

    return data;
  };
}
