import { PropsWithChildren } from 'react';

import { usePermissionContext } from '@contexts/PermissionProvider';

type Props = {
  context: string;
  resource?: string;
  establishmentUUID?: string;
  unitUUID?: string;
};

export default function RestrictedDynamic(props: PropsWithChildren<Props>) {
  const { handleDynamicPermission } = usePermissionContext();

  const hasPermission = handleDynamicPermission({
    context: props.context,
    resource: props.resource,
    establishmentUUID: props.establishmentUUID,
    unitUUID: props.unitUUID,
  });

  if (!hasPermission) return null;

  return props.children as JSX.Element;
}
