import { useForm } from 'react-hook-form';

import { Button, Input, InputTextArea, Switch } from '@components';
import FAQAPICaller from '@services/api/faqs';

interface Props {
  closeModal: () => void;
  onSave: () => void;
  editId?: string;
}

export default function FAQForm(props: Props) {
  const { createOrUpdate, fetch } = FAQAPICaller;

  const {
    register,
    setError,
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isLoading, isSubmitting },
  } = useForm({
    defaultValues: async () => fetchData(),
  });

  const fetchData = async () => {
    if (props.editId) {
      const {
        data: { faq },
      } = await fetch(props.editId);

      return faq;
    }
    return null;
  };

  return (
    <div className="container p-s-200">
      <div className="modal-title">
        <h3>{props.editId ? 'Editar ' : 'Cadastrar '}FAQ</h3>
      </div>
      <form
        autoComplete="off"
        onSubmit={handleSubmit((data) => {
          return createOrUpdate(data, setError).then((res) => {
            if (res.data.errors) return;
            props.closeModal();
            props.onSave();
          });
        })}
      >
        <div className="row grid-gap-1">
          <div className="col-md-10">
            <Input
              disabled={isLoading}
              error={!!errors.question}
              caption={errors.question?.message as string}
              label="Pergunta"
              placeholder="Pergunta"
              form={register('question', { required: 'Obrigatório' })}
            />
          </div>

          <div className="col-md-2">
            <Switch
              disabled={isLoading}
              className="mt-s-500 float-right"
              caption={watch('active', 'Ativo') ? 'Ativo' : 'Inativo'}
              error={errors.active?.message as string}
              form={register('active', { value: true })}
            />
          </div>
        </div>

        <div className="row grid-gap-1">
          <div className="col-md-12">
            <InputTextArea
              disabled={isLoading}
              emojiPicker
              error={!!errors.answer}
              caption={errors.answer?.message as string}
              label="Resposta"
              placeholder="Resposta"
              form={register('answer', { required: 'Obrigatório' })}
              onEmojiClick={(emoji) => {
                setValue('answer', watch('answer') + emoji);
              }}
            />
          </div>
        </div>

        <div className="row justify-end pt-s-400" style={{ gap: 16 }}>
          <Button design="transparent" onClick={props.closeModal}>
            Cancelar
          </Button>
          <Button isLoading={isSubmitting || isLoading} type="submit">
            Salvar
          </Button>
        </div>
      </form>
    </div>
  );
}
