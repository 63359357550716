import { Voucher } from '@interfaces/Voucher';
import { objectToFormData } from '@services/io/file';

import { RepositoryParams, api } from '..';

export type VoucherFilters = Partial<Voucher & RepositoryParams>;

export const list = async (params: VoucherFilters) => {
  return api.get('/vouchers', { params });
};

export const create = async (params: Voucher) => {
  const payload = {
    images: params.images,
    payload: JSON.stringify(params),
  };

  return api.post('/vouchers', objectToFormData(payload), {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const update = async (params: Voucher) => {
  const payload = {
    images: params.images,
    payload: JSON.stringify(params),
  };

  return api.patch(`/vouchers/${params.id}`, objectToFormData(payload), {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const retrieve = async (id: string) => {
  return api.get(`/vouchers/${id}`);
};

export const remove = async (id: string) => {
  return api.delete(`/vouchers/${id}`);
};

export const qrCode = async (
  voucherId?: string,
  unitId?: string,
  planId?: string
) => {
  return api.post(
    `/vouchers/qrcode`,
    { voucherId, unitId, planId },
    { responseType: 'blob' }
  );
};

export const listUnits = async (id: string) => {
  return api.get(`/vouchers/${id}/units`);
};
