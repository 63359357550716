import { Convenience } from './Convenience';
import { Establishment } from './Establishment';
import { ShoppingCenter } from './ShoppingCenter';
import { UserEstablishmentUnitAccess, UserWithId } from './User';
import { SelectOptions } from './Utils';

interface EstablishmentUserWithAccess extends UserEstablishmentUnitAccess {
  user: UserWithId;
}
export interface EstablishmentUnit {
  id?: string;
  establishmentId: string;
  status: string;
  state: string;
  city: string;
  district: string;
  street: string;
  number: string;
  zipCode: string;
  complement?: string;
  latitude?: number;
  longitude?: number;
  name?: string;
  instagram?: string;
  openTimes?: OpenTimes[];
  conveniences?: Convenience[];
  contacts?: Contact[];
  establishment?: Establishment;
  usersWithAccess?: EstablishmentUserWithAccess[];
  shoppingCenter?: ShoppingCenter | SelectOptions;
}

export const EstablishmentUnitStatus = {
  active: 'Ativo',
  inactive: 'Inativo',
  temporarilyInactive: 'Temporariamente inativo',
} as const;

export const EstablishmentUnitContactType = {
  management: 'Gerente',
  operational: 'Operacional',
  marketing: 'Marketing',
  financial: 'Financeiro',
  franchisee: 'Franqueado',
  legalResponsible: 'Responsável Legal',
} as const;

export interface OpenTimes {
  start: string;
  end: string;
  days: string[];
}

export interface Contact {
  id?: string;
  contactType: string | SelectOptions;
  name: string;
  email?: string;
  phone?: string;
}

export const WeekDays = {
  sunday: 'Domingo',
  monday: 'Segunda',
  tuesday: 'Terça',
  wednesday: 'Quarta',
  thursday: 'Quinta',
  friday: 'Sexta',
  saturday: 'Sábado',
  holiday: 'Feriado',
} as const;

export const WeekDaysWithCommemorativeDate = {
  sunday: 'Domingo',
  monday: 'Segunda',
  tuesday: 'Terça',
  wednesday: 'Quarta',
  thursday: 'Quinta',
  friday: 'Sexta',
  saturday: 'Sábado',
  holiday: 'Feriado',
  commemorativeDate: 'Datas Com.',
} as const;
