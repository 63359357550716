import { useEffect, useState } from 'react';

import {
  Button,
  Icon,
  Modal,
  PageHeader,
  Restricted,
  StatusChip,
  Table,
  TableColumn,
} from '@components';
import { PopOver, PopOverMenu } from '@components/PopOver';
import { usePermissionContext } from '@contexts/PermissionProvider';
import { useConfirmationModal } from '@hooks/useConfirmationModal';
import { Cooking } from '@interfaces/Cooking';
import { RepositoryParams } from '@services/api';
import CookingAPICaller, { CookingFilters } from '@services/api/cooking';

import { CookingForm } from './CookingForm';

export default function CookingPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<{ total: number; result: Cooking[] }>({
    total: 0,
    result: [],
  });
  const [filters, setFilters] = useState<CookingFilters>({
    order: 'description',
  });
  const [editId, setEditId] = useState('');
  const [isOpenModal, toggleModal] = useState(false);
  const confirmation = useConfirmationModal();

  const { hasPermission } = usePermissionContext();

  const list = (filters: RepositoryParams) => {
    setIsLoading(true);
    CookingAPICaller.list(filters)
      .then((values) => setData(values))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    list(filters);
  }, [filters]);

  const deleteCooking = (id: string) => {
    confirmation({
      title: 'Excluir categoria de culinária',
      confirmButtonTitle: 'Excluir',
      description: (
        <>
          <span>
            Excluir este registro pode impactar em unidades que possuam este
            dado.
            <br />
            <b style={{ color: 'red' }}>Esta ação é irreversível.</b> <br />
            Tem certeza que deseja realmente excluir? <br />
            Você pode tentar inativar o registro temporariamente.
          </span>
        </>
      ),
      onSubmit: () => CookingAPICaller.remove(id).then((_) => list(filters)),
    });
  };

  return (
    <>
      <div className="p-s-200">
        <PageHeader
          title="Culinária"
          actions={
            <Restricted context="cooking" resource="create">
              <Button
                onClick={() => {
                  toggleModal(true);
                }}
                weight="bold"
                prefixes={<Icon>add</Icon>}
              >
                Cadastrar
              </Button>
            </Restricted>
          }
        />
      </div>

      <Table
        isLoading={isLoading}
        hoverable
        totalRecords={data.total}
        data={data.result}
        withPagination
        withSearchInput
        filters={filters}
        setFilters={setFilters}
      >
        <TableColumn fromKey="description" header="Descrição" />
        <TableColumn
          fromKey="active"
          header="Status"
          render={(active: boolean) => <StatusChip active={active} />}
        />
        <TableColumn
          width="6%"
          fromKey="id"
          header="Ações"
          disableSorting
          render={(id) => {
            return (
              <PopOver
                fixedContent={<Icon>more_horiz</Icon>}
                togglableContent={
                  <PopOverMenu
                    menu={[
                      {
                        onClick: () => {
                          setEditId(id);
                          toggleModal(true);
                        },
                        show: hasPermission('cooking', 'update'),
                        content: 'Editar',
                      },
                      {
                        onClick: () => {
                          deleteCooking(id);
                        },
                        content: 'Excluir',
                        show: hasPermission('cooking', 'delete'),
                      },
                    ]}
                  />
                }
              />
            );
          }}
        />
      </Table>

      <Modal
        isOpen={isOpenModal}
        onClickAway={() => {
          setEditId('');
          toggleModal(false);
        }}
      >
        <CookingForm
          editId={editId}
          onSave={() => list(filters)}
          closeModal={() => {
            setEditId('');
            toggleModal(false);
          }}
        />
      </Modal>
    </>
  );
}
