import { PropsWithChildren, useId } from 'react';

type Props = PropsWithChildren<{
  className?: string;
  isOpen: boolean;
  onClickAway: () => void;
  size: 'small' | 'medium' | 'large';
}>;

export default function Modal(props: Props) {
  const id = useId();
  const modalClassName = ['modal-box'];
  if (props.className) modalClassName.push(props.className);
  modalClassName.push(`modal-size-${props.size}`);

  return (
    <>
      {props.isOpen && (
        <div
          id={`modal-${id}`}
          className="modal-overlay"
          onMouseDown={(e) => {
            (e.target as Element)?.id === `modal-${id}` && props.onClickAway();
          }}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className={modalClassName.join(' ')}
          >
            <div className="modal-close">
              <div
                className="material-symbols-outlined"
                onClick={props.onClickAway}
              >
                close
              </div>
            </div>
            {props.children}
          </div>
        </div>
      )}
    </>
  );
}

Modal.defaultProps = {
  size: 'medium',
};
