import { FieldValues, UseFormSetError } from 'react-hook-form';

import { handleErrorForm } from '@services/api';
import { Region } from '@interfaces/Region';

import {
  RegionFilters,
  create,
  list,
  listAll,
  remove,
  retrieve,
  update,
} from './calls';

export * from './calls';

export default class RegionAPICaller {
  static adaptFromAPI = (data: Region) => data as FieldValues;
  static adaptToAPI = (data: FieldValues) => data as Region;

  static list = async (filters: RegionFilters) => {
    const {
      data: { regions },
    } = await list(filters);

    return { ...regions, result: regions.result.map(this.adaptFromAPI) };
  };

  static all = async (filters: RegionFilters) => {
    const { data } = await listAll(filters);
    return data.regions;
  };

  static fetch = async (editId: string) => {
    const response = await retrieve(editId);
    return response;
  };

  static createOrUpdate = async (
    data: FieldValues,
    setError: UseFormSetError<FieldValues>
  ) => {
    const method = data.id ? update : create;

    const result = method(this.adaptToAPI(data)).catch(
      handleErrorForm(setError)
    );

    return result;
  };

  static remove = async (id: string) => {
    const { data } = await remove(id);

    return data;
  };
}
