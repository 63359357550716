import { FieldValues, UseFormSetError } from 'react-hook-form';

import { handleErrorForm } from 'services/api';
import { Substitution } from 'interfaces/Substitution';

import {
  SubstitutionFilters,
  create,
  list,
  listAll,
  remove,
  retrieve,
  update,
} from './calls';

export * from './calls';

export default class SubstitutionAPICaller {
  static adaptFromAPI = (data: Substitution) => data as FieldValues;
  static adaptToAPI = (data: FieldValues) => data as Substitution;

  static list = async (filters: SubstitutionFilters) => {
    const {
      data: { substitutions },
    } = await list(filters);

    return {
      ...substitutions,
      result: substitutions.result.map(this.adaptFromAPI),
    };
  };

  static all = async () => {
    const { data } = await listAll();
    return data.substitutions;
  };

  static fetch = async (editId: string) => {
    const response = await retrieve(editId);
    return response;
  };

  static createOrUpdate = async (
    data: FieldValues,
    setError: UseFormSetError<FieldValues>
  ) => {
    const method = data.id ? update : create;

    const result = method(this.adaptToAPI(data)).catch(
      handleErrorForm(setError)
    );

    return result;
  };

  static remove = async (id: string) => {
    const { data } = await remove(id);

    return data;
  };
}
