import { Emphasis } from '@interfaces/Emphasis';

import { RepositoryParams, api } from '..';

export type EmphasisFilters = Partial<Emphasis & RepositoryParams>;

export const list = async (params: EmphasisFilters) => {
  return api.get('/vouchers/emphasis', { params });
};

export const create = async (params: Emphasis) => {
  return api.post('/vouchers/emphasis', { ...params });
};

export const update = async (params: Emphasis) => {
  return api.patch(`/vouchers/emphasis/${params.id}`, { ...params });
};

export const retrieve = async (id: string) => {
  return api.get(`/vouchers/emphasis/${id}`);
};

export const remove = async (id: string) => {
  return api.delete(`/vouchers/emphasis/${id}`);
};

export const updateOrders = async (emphasisId: string, ids: string[]) => {
  return api.put(`/vouchers/emphasis/${emphasisId}/order`, { newOrder: ids });
};

export const addEmphasisedVoucher = async (
  emphasisId: string,
  voucherId: string
) => {
  return api.post(`/vouchers/emphasis/${emphasisId}/voucher/${voucherId}`);
};

export const removeVoucher = async (id: string) => {
  return api.delete(`/vouchers/emphasis/emphasisedVoucher/${id}`);
};

export const listVouchersEmphasisable = async (emphasisId: string) => {
  return api.get(`/vouchers/emphasis/${emphasisId}/emphasisable_vouchers`);
};
