import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  Button,
  Icon,
  Modal,
  PageHeader,
  Restricted,
  StatusChip,
  Switch,
  Table,
  TableColumn,
} from '@components';
import { PopOver, PopOverMenu } from '@components/PopOver';
import { usePermissionContext } from '@contexts/PermissionProvider';
import { useConfirmationModal } from '@hooks/useConfirmationModal';
import { FAQ } from '@interfaces/FAQ';
import { RepositoryParams } from '@services/api';
import FAQAPICaller, { FAQFilters } from '@services/api/faqs';

import { FAQForm } from './FAQForm';

const draggableParams: RepositoryParams = {
  order: 'order',
  size: 10000,
};

export default function FAQPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<{ total: number; result: FAQ[] }>({
    total: 0,
    result: [],
  });
  const [filters, setFilters] = useState<FAQFilters>({ order: 'order' });
  const [editId, setEditId] = useState('');
  const [isOpenModal, toggleModal] = useState(false);
  const confirmation = useConfirmationModal();
  const { register, watch } = useForm();
  const { hasPermission } = usePermissionContext();
  const [dragging, setDragging] = useState<FAQ>();
  const updatingOrder = watch('updatingOrder');

  const list = useCallback(
    (filters: RepositoryParams) => {
      setIsLoading(true);
      if (updatingOrder)
        FAQAPICaller.list(draggableParams)
          .then((values) => setData(values))
          .finally(() => setIsLoading(false));
      else
        FAQAPICaller.list(filters)
          .then((values) => setData(values))
          .finally(() => setIsLoading(false));
    },
    [updatingOrder]
  );

  const updateOrders = useCallback(
    (ids: string[]) => {
      FAQAPICaller.updateOrders(ids).then(() => list(filters));
    },
    [filters, list]
  );

  useEffect(() => {
    list(filters);
  }, [filters, list]);

  const deleteFAQ = (id: string) => {
    confirmation({
      title: 'Excluir FAQ',
      confirmButtonTitle: 'Excluir',
      description: 'Deseja realmente excluir esse FAQ?',
      onSubmit: () => FAQAPICaller.remove(id).then((_) => list(filters)),
    });
  };

  const onDrag = useCallback(
    (newOrder: any) => {
      const id = dragging!.id;
      const element = data.result.find((item) => item.id === id);
      const filteredData = data.result.filter((item) => item.id !== id);
      filteredData.splice(newOrder, 0, element!);
      setData({ total: data.total, result: filteredData });
    },
    [data, dragging]
  );

  const onDragEnd = useCallback(() => {
    const ids = data.result.map((item) => item.id) as string[];
    updateOrders(ids);
  }, [data, updateOrders]);

  return (
    <>
      <div className="p-s-200">
        <PageHeader
          title="FAQs"
          actions={
            <>
              <Restricted context="conveniences" resource="updateOrders">
                <Switch caption="Ordenar" form={register('updatingOrder')} />
              </Restricted>
              <Restricted context="faqs" resource="create">
                <Button
                  onClick={() => {
                    toggleModal(true);
                  }}
                  weight="bold"
                  prefixes={<Icon>add</Icon>}
                >
                  Cadastrar
                </Button>
              </Restricted>
            </>
          }
        />
      </div>

      {updatingOrder ? (
        <Table
          isLoading={isLoading}
          setDragging={setDragging}
          onDrag={onDrag}
          onDragEnd={onDragEnd}
          rowPadding="medium"
          hoverable
          data={data.result}
        >
          <TableColumn
            width="25%"
            fromKey="question"
            header="Pergunta"
            ellipsis
          />
          <TableColumn fromKey="answer" header="Resposta" ellipsis />
          <TableColumn
            fromKey="active"
            header="Status"
            render={(active: boolean) => <StatusChip active={active} />}
          />
        </Table>
      ) : (
        <Table
          isLoading={isLoading}
          rowPadding="medium"
          hoverable
          totalRecords={data.total}
          data={data.result}
          withPagination
          withSearchInput
          filters={filters}
          setFilters={setFilters}
        >
          <TableColumn
            width="25%"
            fromKey="question"
            header="Pergunta"
            ellipsis
          />
          <TableColumn fromKey="answer" header="Resposta" ellipsis />
          <TableColumn
            fromKey="active"
            header="Status"
            render={(active: boolean) => <StatusChip active={active} />}
          />
          <TableColumn
            width="6%"
            fromKey="id"
            header="Ações"
            disableSorting
            render={(id) => {
              return (
                <PopOver
                  fixedContent={<Icon>more_horiz</Icon>}
                  togglableContent={
                    <PopOverMenu
                      menu={[
                        {
                          onClick: () => {
                            setEditId(id);
                            toggleModal(true);
                          },
                          show: hasPermission('faqs', 'update'),
                          content: 'Editar',
                        },
                        {
                          onClick: () => {
                            deleteFAQ(id);
                          },
                          content: 'Excluir',
                          show: hasPermission('faqs', 'delete'),
                        },
                      ]}
                    />
                  }
                />
              );
            }}
          />
        </Table>
      )}

      <Modal
        isOpen={isOpenModal}
        onClickAway={() => {
          setEditId('');
          toggleModal(false);
        }}
      >
        <FAQForm
          editId={editId}
          onSave={() => list(filters)}
          closeModal={() => {
            setEditId('');
            toggleModal(false);
          }}
        />
      </Modal>
    </>
  );
}
