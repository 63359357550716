import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';

import { Button, Input, Switch } from '@components';
import AccessGroupAPICaller from '@services/api/accessGroups';
import { isEmpty } from 'utils/object';

import { PermissionsInput } from '../PermissionsInput';

interface Props {
  editId?: string;
  closeModal: () => void;
  onSave: () => void;
}

export default function AccessForm(props: Props) {
  const { createOrUpdate } = AccessGroupAPICaller;
  const [permissions, setPermissions] = useState({});

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors, isLoading, isSubmitting },
    watch,
  } = useForm({
    defaultValues: async () => fetchData(),
  });

  const fetchData = async () => {
    if (props.editId) {
      const group = await AccessGroupAPICaller.fetchGroup(props.editId);

      return group;
    }

    return null;
  };

  useEffect(() => {
    AccessGroupAPICaller.fetchPermissions().then((permissions) =>
      setPermissions(permissions)
    );
  }, []);

  return (
    <div className="container p-s-200">
      <div className="modal-title">
        <h3>{props.editId ? 'Editar' : 'Cadastrar'} Grupo de Acesso</h3>
      </div>
      <form
        autoComplete="off"
        className="form-max-height"
        onSubmit={handleSubmit((data) => {
          return createOrUpdate(data, setError).then((res) => {
            if (res.data.errors) return;
            props.closeModal();
            props.onSave();
          });
        })}
      >
        <div className="row pb-s-200">
          <div className="col-md-10">
            <Input
              disabled={isLoading}
              label="Nome"
              placeholder="Nome do grupo"
              error={!!errors.name}
              caption={errors.name?.message as string}
              form={register('name', { required: 'Nome é obrigatório' })}
            />
          </div>
          <div className="col-sm-12 col-md-2">
            <Switch
              disabled={isLoading}
              className="mt-s-500 float-right"
              caption={watch('active') ? 'Ativo' : 'Inativo'}
              error={errors.active?.message as string}
              form={register('active', { value: true })}
            />
          </div>
        </div>
        {isEmpty(permissions) ? (
          <div className="col-sm-12">
            <Skeleton
              count={5}
              height={50}
              style={{ marginTop: 10, zIndex: 0 }}
            />
          </div>
        ) : (
          <div className="">
            <PermissionsInput
              isFixedPermission={false}
              onChange={(permissions) => setValue('permissions', permissions)}
              value={watch('permissions', [])}
              permissions={permissions}
            />
          </div>
        )}

        <div className="row justify-end pt-s-400" style={{ gap: 16 }}>
          <Button design="transparent" onClick={props.closeModal}>
            Cancelar
          </Button>
          <Button isLoading={isSubmitting || isLoading} type="submit">
            Salvar
          </Button>
        </div>
      </form>
    </div>
  );
}
