import { FieldValues, UseFormSetError } from 'react-hook-form';

import { handleErrorForm } from '@services/api';
import { FAQ } from '@interfaces/FAQ';

import {
  FAQFilters,
  create,
  list,
  remove,
  retrieve,
  update,
  updateOrders,
} from './calls';

export * from './calls';

export default class FAQAPICaller {
  static adaptFromAPI = (data: FAQ) => data as FieldValues;
  static adaptToAPI = (data: FieldValues) => data as FAQ;

  static list = async (filters: FAQFilters) => {
    const {
      data: { faqs },
    } = await list(filters);

    return { ...faqs, result: faqs.result.map(this.adaptFromAPI) };
  };

  static fetch = async (editId: string) => {
    const response = await retrieve(editId);
    return response;
  };

  static createOrUpdate = async (
    data: FieldValues,
    setError: UseFormSetError<FieldValues>
  ) => {
    const method = data.id ? update : create;

    const result = method(this.adaptToAPI(data)).catch(
      handleErrorForm(setError)
    );

    return result;
  };

  static remove = async (id: string) => {
    const { data } = await remove(id);

    return data;
  };

  static updateOrders = async (ids: string[]) => {
    return await updateOrders(ids);
  };
}
