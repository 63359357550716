import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Button,
  DataLoader,
  Icon,
  Modal,
  PageHeader,
  PopOver,
  PopOverMenu,
  Restricted,
  Switch,
  Table,
  TableColumn,
} from '@components';
import { usePermissionContext } from '@contexts/PermissionProvider';
import { useConfirmationModal } from '@hooks/useConfirmationModal';
import { Emphasis } from '@interfaces/Emphasis';
import { Plan } from '@interfaces/Plan';
import { Voucher } from '@interfaces/Voucher';
import EmphasisAPICaller from '@services/api/emphasis';
import { validateUUIDFormat } from 'utils/string/uuid';

import EmphasisForm from '../EmphasisForm/EmphasisForm';
import EmphasisVoucherForm from '../EmphasisVoucherForm/EmphasisVoucherForm';

export default function EmphasisView() {
  const { uuid } = useParams();

  const { hasPermission } = usePermissionContext();
  const [isLoading, setIsLoading] = useState(false);
  const { register, watch } = useForm();
  const confirmation = useConfirmationModal();
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [dragging, setDragging] = useState<Emphasis>();
  const [data, setData] = useState<Emphasis | null>(null);

  const updateOrders = useCallback(
    (ids: string[]) => {
      EmphasisAPICaller.updateOrders(uuid!, ids).then(() => fetch(uuid!));
    },
    [uuid]
  );

  const deleteVoucher = (id: string) => {
    confirmation({
      title: 'Remover voucher destacado',
      confirmButtonTitle: 'Remover',
      description: `Deseja realmente remover o voucher destacado?`,
      onSubmit: () =>
        EmphasisAPICaller.removeVoucher(id).then((_) => fetch(uuid!)),
    });
  };

  const onDrag = useCallback(
    (newOrder: any) => {
      const id = dragging!.id;
      const element = data?.emphasisedVouchers?.find((item) => item.id === id);
      const filteredData = data?.emphasisedVouchers?.filter(
        (item) => item.id !== id
      );
      filteredData!.splice(newOrder, 0, element!);
      setData({ ...data!, emphasisedVouchers: filteredData });
    },
    [data, dragging]
  );

  const onDragEnd = useCallback(() => {
    const ids = data?.emphasisedVouchers?.map((item) => item.id) as string[];
    updateOrders(ids);
  }, [data, updateOrders]);

  const fetch = (uuid: string) => {
    setIsLoading(true);
    EmphasisAPICaller.fetch(uuid)
      .then((values) => setData(values as Emphasis))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (uuid) {
      if (validateUUIDFormat(uuid)) {
        fetch(uuid);
      } else {
        toast.error('ID informado inválido');
        navigate(-1);
      }
    }
  }, [uuid, navigate]);

  return (
    <>
      <div className="p-s-200">
        <PageHeader
          title="Destaque"
          backButton
          actions={
            <>
              <Restricted context={'emphasis'} resource="updateOrders">
                <Switch caption="Ordenar" form={register('updatingOrder')} />
              </Restricted>

              <Restricted context={'emphasis'} resource="update">
                <Button
                  onClick={() => {
                    setEditModalIsOpen(true);
                  }}
                  weight="bold"
                  design="outlined"
                  prefixes={<Icon>edit</Icon>}
                >
                  Editar destaque
                </Button>
              </Restricted>
            </>
          }
        />

        <div className="col-md-4 card card__body p-s-250 bg-white">
          <div className="col-md-12">
            <h4>Nome</h4>
            <span>
              <DataLoader data={data?.name} isLoading={!data} />
            </span>
          </div>

          <div className="col-md-12 mt-s-250">
            <h4>Plano</h4>
            <span>
              <DataLoader data={(data?.plan as Plan)?.name} isLoading={!data} />
            </span>
          </div>
          <div className="col-md-12 mt-s-250">
            <h4>Duração</h4>
            <span>
              <DataLoader
                data={`${data?.startsAt} - ${data?.endsAt}`}
                isLoading={!data}
              />
            </span>
          </div>
        </div>

        <div className="card card__body p-s-350 bg-white mt-s-250">
          <div className="d-flex justify-end">
            <Restricted context="emphasis" resource="addEmphasisedVoucher">
              <Button
                onClick={() => {
                  setIsOpenModal(true);
                }}
                weight="bold"
                prefixes={<Icon>add</Icon>}
              >
                Incluir voucher
              </Button>
            </Restricted>
          </div>

          {watch('updatingOrder') ? (
            <div>
              <Table
                isLoading={isLoading}
                setDragging={setDragging}
                onDrag={onDrag}
                onDragEnd={onDragEnd}
                rowPadding="medium"
                hoverable
                showColumnSelector={false}
                data={data?.emphasisedVouchers!}
              >
                <TableColumn
                  fromKey="voucher"
                  header="Código"
                  render={(voucher: Voucher) => {
                    return voucher?.validationCode;
                  }}
                />
                <TableColumn
                  fromKey="voucher"
                  header="Nome"
                  render={(voucher) => {
                    return voucher?.name;
                  }}
                />
                <TableColumn
                  fromKey="voucher"
                  header="Estabelecimento"
                  render={(voucher) => {
                    return voucher?.establishment?.name;
                  }}
                />
              </Table>
            </div>
          ) : (
            <div>
              <Table
                isLoading={isLoading}
                rowPadding="medium"
                hoverable
                showColumnSelector={false}
                data={data?.emphasisedVouchers!}
              >
                <TableColumn
                  fromKey="voucher"
                  header="Código"
                  render={(voucher: Voucher) => {
                    return voucher?.validationCode;
                  }}
                />
                <TableColumn
                  fromKey="voucher"
                  header="Nome"
                  render={(voucher) => {
                    return voucher?.name;
                  }}
                />
                <TableColumn
                  fromKey="voucher"
                  header="Estabelecimento"
                  render={(voucher) => {
                    return voucher?.establishment?.name;
                  }}
                />
                <TableColumn
                  width="6%"
                  fromKey="id"
                  header="Ações"
                  disableSorting
                  render={(id: string) => (
                    <PopOver
                      fixedContent={<Icon>more_horiz</Icon>}
                      togglableContent={
                        <PopOverMenu
                          menu={[
                            {
                              onClick: () => {
                                deleteVoucher(id);
                              },
                              show: hasPermission(
                                'emphasis',
                                'removeEmphasisedVoucher'
                              ),
                              content: 'Remover voucher do destaque',
                            },
                          ]}
                        />
                      }
                    />
                  )}
                />
              </Table>
            </div>
          )}
        </div>
      </div>

      {Boolean(uuid && isOpenModal) && (
        <Modal
          isOpen={isOpenModal}
          onClickAway={() => {
            setIsOpenModal(false);
          }}
        >
          <EmphasisVoucherForm
            closeModal={() => {
              setIsOpenModal(false);
            }}
            onSave={() => {
              setIsOpenModal(false);
              fetch(uuid!);
            }}
            emphasisId={uuid!}
          />
        </Modal>
      )}

      {uuid! && (
        <Modal
          isOpen={editModalIsOpen}
          onClickAway={() => {
            setEditModalIsOpen(false);
          }}
        >
          <EmphasisForm
            closeModal={() => {
              setEditModalIsOpen(false);
            }}
            onSave={(id: string) => {
              setEditModalIsOpen(false);
              fetch(uuid!);
            }}
            editId={uuid!}
          />
        </Modal>
      )}
    </>
  );
}
