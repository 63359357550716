import { PropsWithChildren } from 'react';

import { usePermissionContext } from '@contexts/PermissionProvider';

type Props = {
  context: string;
  resource?: string;
};

export default function Restricted(props: PropsWithChildren<Props>) {
  const { hasPermission } = usePermissionContext();

  if (!hasPermission(props.context, props.resource)) return null;

  return props.children as JSX.Element;
}
