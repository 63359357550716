import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Icon, PopOver, PopOverMenu, Table, TableColumn } from '@components';
import { usePermissionContext } from '@contexts/PermissionProvider';
import { useConfirmationModal } from '@hooks/useConfirmationModal';
import UserClientsAPICaller, {
  deviceStatusMap,
} from '@services/api/userClients';

type Props = {
  uuid?: string;
};

export function UserClientsDevices(props: Props) {
  const navigate = useNavigate();
  const [data, setData] = useState<{ total: number; result: any[] }>({
    total: 0,
    result: [],
  });

  const confirmation = useConfirmationModal();
  const { hasPermission } = usePermissionContext();

  const fetchDevices = (uuid: string) => {
    UserClientsAPICaller.fetchDevices({
      userClientId: uuid,
      order: '-createdAt',
    }).then((values) => setData(values));
  };

  useEffect(() => {
    if (props.uuid) {
      fetchDevices(props.uuid);
    }
  }, [props.uuid, navigate]);

  const updateDevice = (id: string, status: keyof typeof deviceStatusMap) => {
    confirmation({
      title: 'Atualizar o status do aparelho',
      confirmButtonTitle: 'Atualizar',
      description: `Deseja realmente atualizar o status do aparelho para ${deviceStatusMap[
        status
      ].toUpperCase()}?`,
      onSubmit: () =>
        UserClientsAPICaller.updateDevice(props.uuid!, id, {
          status: status as string,
        }).then((_) => fetchDevices(props.uuid!)),
    });
  };

  return (
    <>
      <div
        className="card card__body p-s-350 bg-white"
        style={{ minHeight: '50vh' }}
      >
        <div className="row">
          <Table
            totalRecords={data.total}
            data={data.result || []}
            showColumnSelector={false}
            hoverable
          >
            <TableColumn
              fromKey="mobileDevice"
              header="Aparelho"
              render={(mobileDevice: { deviceModel?: string }) => (
                <span>{mobileDevice?.deviceModel ?? '-'}</span>
              )}
            />
            <TableColumn
              fromKey="status"
              header="Status"
              render={(status) => {
                let color = 'red';

                if (status === deviceStatusMap['APPROVED']) {
                  color = 'green';
                } else if (status === deviceStatusMap['PENDING']) {
                  color = 'darkgoldenrod';
                }

                return <span style={{ color: color }}>{status}</span>;
              }}
            />
            <TableColumn fromKey="createdAt" header="Solicitado Em" />
            <TableColumn fromKey="updatedAt" header="Atualizado Em" />
            <TableColumn
              width="6%"
              fromKey="id"
              header="Ações"
              disableSorting
              render={(id, { status }) => {
                if (status !== deviceStatusMap['PENDING']) return <></>;

                return (
                  <>
                    <PopOver
                      fixedContent={<Icon>more_horiz</Icon>}
                      togglableContent={
                        <PopOverMenu
                          menu={[
                            {
                              onClick: () => {
                                updateDevice(id, 'APPROVED');
                              },
                              show: hasPermission(
                                'userClients',
                                'updateStatusUserClientMobileDevice'
                              ),
                              content: (
                                <div className="d-flex align-items-center justify-center">
                                  <Icon
                                    style={{ color: 'green', marginRight: 4 }}
                                  >
                                    check
                                  </Icon>
                                  <span>Liberar o uso do aparelho</span>
                                </div>
                              ),
                            },
                            {
                              onClick: () => {
                                updateDevice(id, 'DECLINED');
                              },
                              show: hasPermission(
                                'userClients',
                                'updateStatusUserClientMobileDevice'
                              ),
                              content: (
                                <div className="d-flex align-items-center justify-center">
                                  <Icon
                                    style={{ color: 'red', marginRight: 4 }}
                                  >
                                    close
                                  </Icon>
                                  <span>Negar o uso do aparelho</span>
                                </div>
                              ),
                            },
                          ]}
                        />
                      }
                    />
                  </>
                );
              }}
            />
          </Table>
        </div>
      </div>
    </>
  );
}
