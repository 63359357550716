import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Icon,
  Modal,
  PageHeader,
  Restricted,
  StatusChip,
  Switch,
  Table,
  TableColumn,
} from '@components';
import { useConfirmationModal } from '@hooks/useConfirmationModal';
import { Category } from '@interfaces/Category';
import { RepositoryParams } from '@services/api';
import CategoryAPICaller, { CategoryFilters } from '@services/api/categories';
import { PopOver, PopOverMenu } from '@components/PopOver';
import { usePermissionContext } from '@contexts/PermissionProvider';

import { CategoriesForm } from './CategoriesForm';
import { CategoriesGroupsTable } from './CategoriesGroupsTable';

const draggableParams: RepositoryParams = {
  order: 'order',
  size: 10000,
};

export default function CategoryPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<{ total: number; result: Category[] }>({
    total: 0,
    result: [],
  });
  const [filters, setFilters] = useState<CategoryFilters>({ order: 'name' });
  const [editId, setEditId] = useState('');
  const [isOpenModal, toggleModal] = useState(false);
  const confirmation = useConfirmationModal();
  const { register, watch } = useForm();
  const { hasPermission } = usePermissionContext();
  const [dragging, setDragging] = useState<Category>();
  const updatingOrder = watch('updatingOrder');
  const [expandedId, setExpandedId] = useState('');
  const navigate = useNavigate();

  const list = useCallback(
    (filters: RepositoryParams) => {
      setIsLoading(true);
      if (updatingOrder)
        CategoryAPICaller.list(draggableParams)
          .then((values) => setData(values))
          .finally(() => setIsLoading(false));
      else
        CategoryAPICaller.list(filters)
          .then((values) => setData(values))
          .finally(() => setIsLoading(false));
    },
    [updatingOrder]
  );

  const updateOrders = useCallback(
    (ids: string[]) => {
      CategoryAPICaller.updateOrders(ids).then(() => list(filters));
    },
    [filters, list]
  );

  useEffect(() => {
    list(filters);
  }, [filters, list]);

  const deleteCategory = (id: string) => {
    confirmation({
      title: 'Excluir registro',
      confirmButtonTitle: 'Excluir',
      description: 'Deseja realmente excluir esse registro?',
      onSubmit: () => CategoryAPICaller.remove(id).then((_) => list(filters)),
    });
  };

  const onDrag = useCallback(
    (newOrder: any) => {
      const id = dragging!.id;
      const element = data.result.find((item) => item.id === id);
      const filteredData = data.result.filter((item) => item.id !== id);
      filteredData.splice(newOrder, 0, element!);
      setData({ total: data.total, result: filteredData });
    },
    [data, dragging]
  );

  const onDragEnd = useCallback(() => {
    const ids = data.result.map((item) => item.id) as string[];
    updateOrders(ids);
  }, [data, updateOrders]);

  return (
    <>
      <div className="p-s-200">
        <PageHeader
          title="Categorias"
          actions={
            <>
              <Restricted context="categories" resource="updateOrders">
                <Switch caption="Ordenar" form={register('updatingOrder')} />
              </Restricted>
              <Restricted context="categories" resource="create">
                <Button
                  onClick={() => {
                    toggleModal(true);
                  }}
                  weight="bold"
                  prefixes={<Icon>add</Icon>}
                >
                  Cadastrar
                </Button>
              </Restricted>
            </>
          }
        />
      </div>

      {updatingOrder ? (
        <Table
          isLoading={isLoading}
          setDragging={setDragging}
          onDrag={onDrag}
          onDragEnd={onDragEnd}
          rowPadding="medium"
          hoverable
          data={data.result}
        >
          <TableColumn fromKey="name" header="Nome" />
          <TableColumn
            fromKey="active"
            header="Status"
            render={(active: boolean) => <StatusChip active={active} />}
          />
        </Table>
      ) : (
        <Table
          isLoading={isLoading}
          hoverable
          totalRecords={data.total}
          data={data.result}
          withPagination
          withSearchInput
          filters={filters}
          setFilters={setFilters}
          expandedRow={expandedId}
          onRowClick={(row) => {
            if (expandedId === row.id) return setExpandedId('');
            else setExpandedId(row.id);
          }}
          renderExpandedRow={(category) => (
            <CategoriesGroupsTable
              key={category.id}
              categoryId={category.id}
              categoryName={category.name}
              onUpdate={() => list(filters)}
            />
          )}
        >
          <TableColumn fromKey="name" header="Nome" />
          <TableColumn
            fromKey="showOnTour"
            header="Exibir no Tour?"
            render={(active: boolean) => (active ? 'Sim' : 'Não')}
          />
          <TableColumn
            fromKey="active"
            header="Status"
            render={(active: boolean) => <StatusChip active={active} />}
            filterType="select"
            filterOptions={[
              { label: 'Ativo', value: 'true' },
              { label: 'Inativo', value: 'false' },
            ]}
          />
          <TableColumn
            width="6%"
            fromKey="id"
            header="Ações"
            disableSorting
            headerAlign="center"
            render={(id) => {
              return (
                <div className="d-flex mr-s-200">
                  <Button
                    className="mr-s-200"
                    weight="bold"
                    design="transparent"
                    prefixes={
                      <Icon>
                        {expandedId === id ? 'expand_less' : 'expand_more'}
                      </Icon>
                    }
                    onClick={() => {
                      if (expandedId === id) return setExpandedId('');
                      else setExpandedId(id);
                    }}
                  />
                  <PopOver
                    fixedContent={<Icon>more_horiz</Icon>}
                    togglableContent={
                      <PopOverMenu
                        menu={[
                          {
                            onClick: () => {
                              navigate(`/categories/${id}`);
                            },
                            show: hasPermission('categories', 'read'),
                            content: 'Acessar',
                          },
                          {
                            onClick: () => {
                              setEditId(id);
                              toggleModal(true);
                            },
                            show: hasPermission('categories', 'update'),
                            content: 'Editar',
                          },
                          {
                            onClick: () => {
                              deleteCategory(id);
                            },
                            content: 'Excluir',
                            show: hasPermission('categories', 'delete'),
                          },
                        ]}
                      />
                    }
                  />
                </div>
              );
            }}
          />
        </Table>
      )}

      <Modal
        isOpen={isOpenModal}
        onClickAway={() => {
          setEditId('');
          toggleModal(false);
        }}
      >
        <CategoriesForm
          editId={editId}
          onSave={() => list(filters)}
          closeModal={() => {
            setEditId('');
            toggleModal(false);
          }}
        />
      </Modal>
    </>
  );
}
