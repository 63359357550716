import { useForm } from 'react-hook-form';
import { useState } from 'react';

import {
  Button,
  DatePickerInput,
  Input,
  SelectInput as Select,
  Switch,
} from '@components';
import PlanAPICaller from '@services/api/plans';
import { formDatePattern } from '@validations/date';
import StatesAPICaller from '@services/api/states';
import { SelectOptions } from '@interfaces/Utils';

interface Props {
  closeModal: () => void;
  onSave: () => void;
  editId?: string;
}

export default function PlansForm(props: Props) {
  const { createOrUpdate, fetch } = PlanAPICaller;
  const [states, setStates] = useState<
    (SelectOptions & { abbreviation: string; name: string })[]
  >([]);
  const [cities, setCities] = useState<(SelectOptions | string)[]>([]);

  const {
    register,
    setError,
    watch,
    handleSubmit,
    setValue,
    setFocus,
    formState: { errors, isSubmitting, isLoading },
  } = useForm({ defaultValues: async () => fetchData() });

  const fetchData = async () => {
    fetchStates();
    if (props.editId) {
      const plan = await fetch(props.editId);

      return plan;
    }

    return null;
  };

  const fetchStates = async () => {
    const statesResponse = await StatesAPICaller.fetchStates();
    setStates(statesResponse);

    return statesResponse;
  };

  const fetchStateCities = async (stateName: string) => {
    setFocus('city');
    const cities = await StatesAPICaller.fetchStateCities(stateName);
    setCities(cities);

    return cities;
  };

  return (
    <div className="container p-s-200">
      <div className="modal-title">
        <h3>{props.editId ? 'Editar ' : 'Cadastrar '}plano</h3>
      </div>
      <form
        autoComplete="off"
        className="form-max-height"
        onSubmit={handleSubmit((data) => {
          return createOrUpdate(data, setError).then((res) => {
            if (res.data.errors) return;
            props.closeModal();
            props.onSave();
          });
        })}
      >
        <div className="row grid-gap-1">
          <div className="col-md-10">
            <Input
              disabled={isLoading}
              error={!!errors.name}
              caption={errors.name?.message as string}
              label="Nome"
              placeholder="Nome"
              form={register('name', { required: 'Obrigatório' })}
            />
          </div>

          <div className="col-md-2">
            <Switch
              disabled={isLoading}
              className="mt-s-500 float-right"
              caption={watch('active', 'Ativo') ? 'Ativo' : 'Inativo'}
              error={errors.active?.message as string}
              form={register('active', { value: true })}
            />
          </div>
        </div>

        <div className="row grid-gap-1">
          <div className="col-md-6">
            <Input
              disabled={isLoading}
              error={!!errors.value}
              caption={errors.value?.message as string}
              label="Valor"
              placeholder="R$"
              mask="currency"
              form={register('value', { required: 'Obrigatório' })}
            />
          </div>
          <div className="col-md-6">
            <Input
              disabled={isLoading}
              error={!!errors.amount}
              caption={errors.amount?.message as string}
              label="Quantidade de assinaturas"
              placeholder="Qtd assinaturas disponveis plano"
              mask="onlyNumbers"
              form={register('amount', { required: 'Obrigatório' })}
            />
          </div>
        </div>
        <div className="row grid-gap-1">
          <div className="col-md-6">
            <DatePickerInput
              disabled={isLoading}
              label="Início Vigência"
              dateSelected={watch('startsAt')}
              error={!!errors.startsAt}
              placeholder="__/__/____"
              caption={errors.startsAt?.message as string}
              form={register('startsAt', {
                required: 'Obrigatório',
                ...formDatePattern,
              })}
              onSelect={(date: String) => {
                setValue('startsAt', date);
              }}
            />
          </div>
          <div className="col-md-6">
            <DatePickerInput
              disabled={isLoading}
              label="Término Vigência"
              placeholder="__/__/____"
              dateSelected={watch('endsAt')}
              error={!!errors.endsAt}
              caption={errors.endsAt?.message as string}
              form={register('endsAt', {
                required: 'Obrigatório',
                ...formDatePattern,
              })}
              onSelect={(date: String) => {
                setValue('endsAt', date);
              }}
            />
          </div>
        </div>
        <div className="row grid-gap-1">
          <div className="col-md-6">
            <Select
              value={watch('state')}
              placeholder="Estado"
              disabled={isLoading}
              isSearchable
              options={states}
              onSelect={(value: { label: string }) => {
                setValue('state', value);
                if (value?.label) {
                  setValue('city', '');
                  fetchStateCities(value?.label);
                }
              }}
              form={register('state', { required: 'Obrigatório' })}
              error={!!errors.state}
              caption={errors.state?.message as string}
              fromKey="name"
              label="Estado"
            />
          </div>
          <div className="col-md-6">
            <Select
              isSearchable
              value={watch('city')}
              placeholder="Cidade"
              disabled={isLoading || !watch('state')}
              options={cities}
              onSelect={(value) => {
                setValue('city', value);
              }}
              form={register('city', { required: 'Obrigatório' })}
              error={!!errors.city}
              caption={errors.city?.message as string}
              label="Cidade"
            />
          </div>
        </div>

        <div className="row grid-gap-1">
          <div className="col-md-6">
            <Input
              disabled={isLoading}
              error={!!errors.economy}
              caption={errors.economy?.message as string}
              label="Economia"
              placeholder="Economia"
              form={register('economy', { required: 'Obrigatório' })}
            />
          </div>
          <div className="col-md-6">
            <Input
              disabled={isLoading}
              error={!!errors.productCode}
              caption={errors.productCode?.message as string}
              label="Código no BLING"
              placeholder="Código no sistema Bling"
              form={register('productCode')}
            />
          </div>
          <div className="col-md-6">
            <Input
              disabled={isLoading}
              error={!!errors.vouchersAmount}
              caption={errors.vouchersAmount?.message as string}
              label="Quantidade de vouchers"
              placeholder="Qtd vouchers disponível plano"
              mask="onlyNumbers"
              form={register('vouchersAmount', { required: 'Obrigatório' })}
            />
          </div>
          <div className="col-md-6">
            <Input
              disabled={isLoading}
              error={!!errors.productId}
              caption={errors.productId?.message as string}
              label="Id no BLING"
              placeholder="Id do produto no sistema Bling"
              form={register('productId')}
            />
          </div>
        </div>
        <div className="row grid-gap-1">
          <div className="col-md-6">
            <Switch
              disabled={isLoading}
              className="mt-s-100"
              label="Ativo venda"
              caption={watch('activeSale') ? 'Ativo' : 'Inativo'}
              error={errors.activeSale?.message as string}
              form={register('activeSale', { value: true })}
            />
          </div>
          <div className="col-md-6">
            <Switch
              disabled={isLoading}
              className="mt-s-100"
              label="Ativo pré-venda"
              caption={watch('activePreSale') ? 'Ativo' : 'Inativo'}
              error={errors.activePreSale?.message as string}
              form={register('activePreSale', { value: true })}
            />
          </div>
        </div>

        <div className="row justify-end pt-s-400" style={{ gap: 16 }}>
          <Button design="transparent" onClick={props.closeModal}>
            Cancelar
          </Button>
          <Button isLoading={isSubmitting || isLoading} type="submit">
            Salvar
          </Button>
        </div>
      </form>
    </div>
  );
}
