import { Cooking } from '@interfaces/Cooking';

import { RepositoryParams, api } from '..';

export type CookingFilters = Partial<Cooking & RepositoryParams>;

export const list = async (params: CookingFilters) => {
  return api.get('/cooking', { params });
};
export const listAll = async () => {
  return api.get('/cooking/all');
};

export const create = async (params: Cooking) => {
  return api.post('/cooking', { ...params });
};

export const update = async (params: Cooking) => {
  return api.patch(`/cooking/${params.id}`, { ...params });
};

export const retrieve = async (id: string) => {
  return api.get(`/cooking/${id}`);
};

export const remove = async (id: string) => {
  return api.delete(`/cooking/${id}`);
};
