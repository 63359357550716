import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AccountSVG from '@assets/icons/account.svg';
import { Icon } from '@components';
import { useConfirmationModal } from '@hooks/useConfirmationModal';
import { User } from '@interfaces/User';

interface Props {
  logout: () => void;
  user: User | null;
}

export const FixedContent = ({
  user,
  setShowTogglableContent,
}: {
  user: Props['user'];
  setShowTogglableContent: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <div className="d-flex justify-center align-items-center">
      <div className="d-flex flex-direction-column profile-button-user-data">
        <span className="font-s-100 font-weight-bold" title={user?.name}>
          {user?.name}
        </span>
        <span className="font-s-100" title={user?.email}>
          {user?.email}
        </span>
      </div>
      <div className="d-flex justify-center align-items-center pl-s-150">
        <div className="image-cropper border-radius-350">
          <img src={user?.imageUrl || AccountSVG} alt="user_logo" />
        </div>

        <div style={{ width: 8 }}></div>
        <div
          title="Configurações"
          className="pointer"
          onMouseEnter={() => setShowTogglableContent(true)}
        >
          <Icon>settings</Icon>
        </div>
      </div>
    </div>
  );
};

export const TogglableContent = ({
  user,
  toggleLogoutModal,
  navigate,
  closeTogglableContent,
}: {
  user: Props['user'];
  toggleLogoutModal: () => void;
  navigate: (route: string) => void;
  closeTogglableContent: () => void;
}) => {
  const actions = [
    {
      icon: 'account_circle',
      content: 'Editar perfil',
      action: () => {
        navigate('/me');
        closeTogglableContent();
      },
    },
    {
      icon: 'lock',
      content: 'Alterar sua senha',
      action: () => {
        navigate('/me?new_password=true');
        closeTogglableContent();
      },
    },
    // {
    //   icon: 'notification_important',
    //   content: 'Configurar notificações',
    //   action: () => console.log(user),
    // },
    {
      icon: 'logout',
      content: 'Sair',
      action: toggleLogoutModal,
    },
  ];

  return (
    <>
      <div className="d-flex flex-direction-column pb-s-150">
        {actions.map((a, index) => (
          <div
            key={index}
            onClick={() => a.action()}
            className="action d-flex align-items-center p-s-100"
          >
            <Icon className="pr-s-100">{a.icon}</Icon>
            <>{a.content}</>
          </div>
        ))}
      </div>
    </>
  );
};

export default function ProfileButton({ logout, user }: Props) {
  const [showTogglableContent, setShowTogglableContent] = useState(false);
  const navigate = useNavigate();
  const confirmation = useConfirmationModal();

  return (
    <div className="profile-button-container container d-flex justify-center">
      <div
        className="d-flex flex-direction-column justify-center align-items-center border-profile-button"
        onMouseLeave={() => setShowTogglableContent(false)}
      >
        <FixedContent
          setShowTogglableContent={() => setShowTogglableContent(true)}
          user={user}
        />
        {showTogglableContent && (
          <TogglableContent
            navigate={navigate}
            closeTogglableContent={() => setShowTogglableContent(false)}
            user={user}
            toggleLogoutModal={() => {
              setShowTogglableContent(false);
              confirmation({
                title: 'Sair',
                description: 'Deseja realmente sair?',
                onSubmit: () => logout(),
                cancelButtonTitle: 'Não',
                confirmButtonTitle: 'Sim',
              });
            }}
          />
        )}
      </div>
    </div>
  );
}
