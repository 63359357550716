import { listStateCities, listStates } from './calls';
export * from './calls';

export default class StatesAPICaller {
  static fetchStates = async () => {
    const { data } = await listStates();
    return data.states;
  };

  static fetchStateCities = async (state: string) => {
    const { data } = await listStateCities(state);
    return data.cities;
  };
}
