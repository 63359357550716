import { AccessGroup } from '@interfaces/AccessGroup';

import { RepositoryParams, api } from '../';

export type AccessGroupFilters = Partial<AccessGroup & RepositoryParams>;

export const list = async (params: AccessGroupFilters) => {
  return api.get('/permissions/global/access_groups', { params });
};

export const create = async (params: Partial<AccessGroup>) => {
  return api.post('/permissions/global/access_groups', { ...params });
};

export const update = async (params: Partial<AccessGroup>) => {
  return api.patch(`/permissions/global/access_groups/${params.id}`, {
    ...params,
  });
};

export const retrieve = async (id: string) => {
  return api.get(`/permissions/global/access_groups/${id}`);
};

export const listPermissions = async () => {
  return api.get('/permissions/global/access_groups/permissions');
};
