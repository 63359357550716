import { MetabaseParams } from '@interfaces/Metabase';

import { RepositoryParams } from '..';

import {
  discountCoupon,
  general,
  operational,
  subscription,
  usageData,
  usageDataByUnit,
  usageDataByUnitEstablishment,
  usageDataByUnitEstablishmentPhysical,
  usageDataEstablishment,
  usageDataUserClient,
  usageUserEstablishmentData,
  usageUserEstablishmentDataPhysical,
} from './calls';

export default class MetabaseAPICaller {
  static fetchGeneral = async (params?: RepositoryParams) => {
    const { data } = await general(params);
    return data.iframeURL;
  };

  static fetchSubscription = async (params?: RepositoryParams) => {
    const { data } = await subscription(params);
    return data.iframeURL;
  };

  static fetchOperational = async (params?: MetabaseParams) => {
    const { data } = await operational(params);
    return data.iframeURL;
  };

  static fetchUsageUserEstablishmentData = async (params?: MetabaseParams) => {
    const { data } = await usageUserEstablishmentData(params);
    return data.iframeURL;
  };

  static fetchUsageData = async (params?: RepositoryParams) => {
    const { data } = await usageData(params);
    return data.iframeURL;
  };

  static fetchUsageDataEstablishment = async (params?: RepositoryParams) => {
    const { data } = await usageDataEstablishment(params);
    return data.iframeURL;
  };

  static fetchUsageDataByUnit = async (params?: RepositoryParams) => {
    const { data } = await usageDataByUnit(params);
    return data.iframeURL;
  };

  static fetchUsageDataByUnitEstablishment = async (
    params?: MetabaseParams
  ) => {
    const { data } = await usageDataByUnitEstablishment(params);
    return data.iframeURL;
  };

  static fetchUsageDataByUnitEstablishmentPhysical = async (
    params?: MetabaseParams
  ) => {
    const { data } = await usageDataByUnitEstablishmentPhysical(params);
    return data.iframeURL;
  };

  static fetchUsageUserEstablishmentDataPhysical = async (params?: {
    establishment?: string[];
    unit?: string[];
  }) => {
    const { data } = await usageUserEstablishmentDataPhysical(params);
    return data.iframeURL;
  };

  static fetchUsageDataUserClient = async (params?: RepositoryParams) => {
    const { data } = await usageDataUserClient(params);
    return data.iframeURL;
  };

  static fetchDiscountCoupon = async (params?: RepositoryParams) => {
    const { data } = await discountCoupon(params);
    return data.iframeURL;
  };
}
