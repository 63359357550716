import React, { PropsWithChildren, isValidElement } from 'react';

import SidebarSeparator from './SidebarSeparator';
import SidebarTitle from './SidebarTitle';

export default function SidebarGroup({
  children,
  title,
}: PropsWithChildren<{ title: string }>) {
  const hasRestrictedChild = React.Children.toArray(children).some((child) => {
    if (isValidElement(child)) {
      return child && (child as JSX.Element).type(child.props);
    }

    return false;
  });

  if (hasRestrictedChild) {
    return (
      <>
        <SidebarSeparator />
        <SidebarTitle>
          <span>{title}</span>
        </SidebarTitle>
        {children}
      </>
    );
  }

  return null;
}
