import { memo, useCallback, useEffect, useId, useMemo, useState } from 'react';
import { OptionProps, components } from 'react-select';
import Select from 'react-select';

import { useFetchEstablishments } from '@hooks/establishment/useFetchEstablishments';
import { useFetchEstablishmentUnits } from '@hooks/establishmentUnit/useFetchEstablishmentUnits';
import { Spinner } from '@components/Spinner';
import { EstablishmentUnit } from '@interfaces/EstablishmentUnit';
import { Establishment } from '@interfaces/Establishment';
import {
  establishmentUnitName,
  joinEstablishmentsWithUnits,
} from 'utils/establishments';
import {
  getArrayOfValuesWithoutUndefined,
  getArrayWithoutUndefinedItems,
  sortAlphabeticallyByField,
} from 'utils/array';
import { Button, Icon } from '@components';
import Tippy, { useSingleton } from '@tippyjs/react';
import { followCursor } from 'tippy.js';

type OptionType = 'establishment' | 'unit';

type SelectOption = {
  name: string;
  id: string;
  type: OptionType;
  shouldShowAsSelected: boolean;
};

interface Props {
  label: string;
  valueEstablishment: string[];
  valueEstablishmentUnits: string[];
  formRef?: React.MutableRefObject<HTMLDivElement | null>;
  onChangeEstablishments: (values: string[]) => void;
  onChangeEstablishmentUnits: (values: string[]) => void;
}

export const optionsFromList = (
  items: { id?: string; name?: string; shouldBeDisabledOnSelct?: boolean }[]
) => {
  const itemsWithId = items.filter(({ id }) => Boolean(id)) as {
    id: string;
    name: string;
    shouldBeDisabledOnSelct?: boolean;
  }[];

  return itemsWithId.map(({ id, name, shouldBeDisabledOnSelct }) => {
    return { value: id, label: name || '', disabled: shouldBeDisabledOnSelct };
  });
};

const formatSortAndFilterUnits = (
  Option: Array<EstablishmentUnit & { establishment?: Establishment }>,
  valueEstablishment: Array<string>,
  valueEstablishmentUnits: Array<string>
) => {
  const unitsFiltered = Option.map((unit) => {
    return {
      ...unit,
      shouldBeDisabledOnSelct: valueEstablishment.includes(
        unit.establishmentId
      ),
      name: establishmentUnitName(unit),
      shouldShowAsSelected: valueEstablishmentUnits.includes(unit.id || ''),
    };
  });

  const unitsOrdered = sortAlphabeticallyByField(unitsFiltered, 'name');
  return unitsOrdered;
};

export const formatSortAndFilterEstablishments = (
  establishments: Array<Establishment & { units: EstablishmentUnit[] }>
) => {
  const establishmentsOrdered = sortAlphabeticallyByField(
    establishments,
    'name'
  );

  return establishmentsOrdered;
};

const Option = ({
  children,
  tipyTarget,
  ...props
}: OptionProps<SelectOption, true> & {
  tipyTarget: ReturnType<typeof useSingleton>[number];
}) => {
  const {
    data: { shouldShowAsSelected, type },
  } = props;

  const ActualContent = useCallback(() => {
    const containerClasses = ['d-flex align-content-center align-items-center'];

    if (type === 'unit') {
      containerClasses.push('pl-s-400');
    }
    return (
      <div className={containerClasses.join(' ')}>
        <div className="mr-s-100">
          <Icon>
            {shouldShowAsSelected ? 'check_box' : 'check_box_outline_blank'}
          </Icon>
        </div>
        <div className="flex-grow-1">{children}</div>
      </div>
    );
  }, [children, shouldShowAsSelected, type]);

  return (
    <components.Option {...props}>
      {!props.isDisabled ? (
        <ActualContent />
      ) : (
        <Tippy
          content={
            props.data.type === 'unit'
              ? 'Concedido acesso ao estabelecimento e todas as unidades'
              : 'Concedido acesso à unidades específicas. Para conceder acesso ao estabelecimento com todas as unidades, desselecione-as'
          }
          singleton={tipyTarget}
        >
          <div>
            <ActualContent />
          </div>
        </Tippy>
      )}
    </components.Option>
  );
};

const EstablishmentAndUnitsSelectBase = ({
  label,
  valueEstablishment,
  valueEstablishmentUnits,
  onChangeEstablishmentUnits,
  onChangeEstablishments,
  formRef,
}: Props) => {
  const labelId = useId();
  const [source, target] = useSingleton();

  const [isOpen, setIsOpen] = useState(false);

  const {
    isFetchingEstablishmentUnits,
    establishmentUnits,
    fetchEstablishmentUnits,
  } = useFetchEstablishmentUnits();
  const { isFetchingEstablishments, establishments, fetchEstablishments } =
    useFetchEstablishments();

  const { unitsWithEstablishments, establishmentsWithUnits } = useMemo(() => {
    const {
      units: unitsWithEstablishments,
      establishments: establishmentsWithUnits,
    } = joinEstablishmentsWithUnits(establishmentUnits, establishments);

    return { unitsWithEstablishments, establishmentsWithUnits };
  }, [establishmentUnits, establishments]);

  const establishmentsOrdered = useMemo(() => {
    return formatSortAndFilterEstablishments(establishmentsWithUnits);
  }, [establishmentsWithUnits]);

  const unitsOrdered = useMemo(() => {
    return formatSortAndFilterUnits(
      unitsWithEstablishments,
      valueEstablishment || [],
      valueEstablishmentUnits || []
    );
  }, [unitsWithEstablishments, valueEstablishmentUnits, valueEstablishment]);

  const isFetching = useMemo(() => {
    return isFetchingEstablishmentUnits || isFetchingEstablishments;
  }, [isFetchingEstablishmentUnits, isFetchingEstablishments]);

  const selectedEstablishments = useMemo(
    () =>
      getArrayWithoutUndefinedItems(
        valueEstablishment.map((id) =>
          establishments.find((establishment) => {
            return id === establishment.id;
          })
        )
      ),
    [valueEstablishment, establishments]
  );

  const selectedEstablishmentUnits = useMemo(
    () =>
      getArrayWithoutUndefinedItems(
        valueEstablishmentUnits.map((id) =>
          unitsWithEstablishments.find((establishmentUnit) => {
            return id === establishmentUnit.id;
          })
        )
      ),
    [valueEstablishmentUnits, unitsWithEstablishments]
  );

  const selectOptions = useMemo(() => {
    const options: SelectOption[] = [];
    establishmentsOrdered.forEach((establishment) => {
      options.push({
        id: establishment.id || '',
        name: `${establishment.name} - Estabelecimento`,
        type: 'establishment',
        shouldShowAsSelected: valueEstablishment.includes(
          establishment.id || ''
        ),
      });
      unitsOrdered
        .filter((establishmentUnit) => {
          return establishmentUnit.establishmentId === establishment.id || '';
        })
        .forEach((establishmentUnit) => {
          options.push({
            id: establishmentUnit.id || '',
            name: establishmentUnit.name,
            type: 'unit',
            shouldShowAsSelected: valueEstablishmentUnits.includes(
              establishmentUnit.id || ''
            ),
          });
        });
    });

    return options;
  }, [
    unitsOrdered,
    establishmentsOrdered,
    valueEstablishment,
    valueEstablishmentUnits,
  ]);

  const getUnitByOption = useCallback(
    (item: SelectOption) => {
      return unitsOrdered.find(({ id }) => id === item.id);
    },
    [unitsOrdered]
  );

  useEffect(() => {
    fetchEstablishmentUnits({ status: `not:inactive`, size: 10000 });
    fetchEstablishments({ status: `not:inactive`, size: 10000 });
  }, [fetchEstablishmentUnits, fetchEstablishments]);

  return (
    <div>
      <span className="font-s-150 text-neutral-20 font-weight-bold mb-s-50 d-block">
        Permissões
      </span>
      <p className="font-s-100 text-neutral-50 mb-s-200">
        Um usuário com acesso a Estabelecimentos poderão acessar informações de
        vouchers de todas as suas unidades. Caso o usuário tenha acesso apenas a
        um dos endereços, conecte-o exclusivamente às unidades pertinentes.
      </p>
      {isFetching ? (
        <Spinner />
      ) : (
        <>
          <Tippy
            singleton={source}
            arrow={false}
            followCursor
            plugins={[followCursor]}
          />
          <label id={labelId} className="form-input__label">
            {label}
          </label>
          <Select<SelectOption, true>
            aria-labelledby={labelId}
            onMenuClose={() => setIsOpen(false)}
            onMenuOpen={() => setIsOpen(true)}
            isMulti
            blurInputOnSelect={false}
            closeMenuOnSelect={false}
            menuShouldScrollIntoView
            menuPosition="fixed"
            menuPortalTarget={formRef ? formRef.current : undefined}
            placeholder="Estabelecimentos e unidades"
            options={selectOptions}
            formatOptionLabel={(unit) => unit.name}
            isOptionDisabled={(item) => {
              if (item.type === 'establishment') {
                return getArrayOfValuesWithoutUndefined(
                  selectedEstablishmentUnits,
                  'establishmentId'
                ).some((establishmentId) => {
                  return establishmentId === item.id;
                });
              }

              const unitData = getUnitByOption(item);
              if (!unitData) {
                return false;
              }

              return getArrayOfValuesWithoutUndefined(
                selectedEstablishments,
                'id'
              ).some((establishmentId) => {
                return establishmentId === unitData.establishmentId;
              });
            }}
            isOptionSelected={() => false}
            getOptionValue={(item) => item.id || ''}
            onChange={(selected) => {
              const units = selected.filter(({ type }) => type === 'unit');
              const changedUnits = getArrayOfValuesWithoutUndefined(
                units,
                'id'
              );

              let newEstablishmentUnitsValue = [...valueEstablishmentUnits];

              changedUnits.forEach((thisUnitId) => {
                if (newEstablishmentUnitsValue.includes(thisUnitId)) {
                  newEstablishmentUnitsValue =
                    newEstablishmentUnitsValue.filter(
                      (id) => id !== thisUnitId
                    );
                } else {
                  newEstablishmentUnitsValue.push(thisUnitId);
                }
              });

              onChangeEstablishmentUnits(newEstablishmentUnitsValue);

              const establishments = selected.filter(
                ({ type }) => type === 'establishment'
              );

              const changedEstablishments = getArrayOfValuesWithoutUndefined(
                establishments,
                'id'
              );

              let newEstablishmentsValue = [...valueEstablishment];

              changedEstablishments.forEach((thisEstablishmentId) => {
                if (newEstablishmentsValue.includes(thisEstablishmentId)) {
                  newEstablishmentsValue = newEstablishmentsValue.filter(
                    (id) => id !== thisEstablishmentId
                  );
                } else {
                  newEstablishmentsValue.push(thisEstablishmentId);
                }
              });

              onChangeEstablishments(newEstablishmentsValue);
            }}
            value={[]}
            getOptionLabel={(option) => option.name}
            components={{
              Option: (props: OptionProps<SelectOption, true>) => {
                return <Option {...props} tipyTarget={target} />;
              },
            }}
          />
          {!isOpen && (
            <section>
              {selectedEstablishments.map(({ name, id }) => (
                <SelectedValueDisplayItem
                  key={`establishment-${id}`}
                  name={name}
                  type="establishment"
                  id={id || ''}
                  onRemove={() => {
                    onChangeEstablishments(
                      valueEstablishment.filter(
                        (establishment) => establishment !== id
                      )
                    );
                  }}
                />
              ))}
              {selectedEstablishmentUnits.map((unit) => (
                <SelectedValueDisplayItem
                  key={`unit-${unit.id}`}
                  name={establishmentUnitName(unit)}
                  type="unit"
                  id={unit.id || ''}
                  onRemove={() => {
                    onChangeEstablishmentUnits(
                      valueEstablishmentUnits.filter(
                        (establishmentUnit) => establishmentUnit !== unit.id
                      )
                    );
                  }}
                />
              ))}
            </section>
          )}
        </>
      )}
    </div>
  );
};

export const SelectedValueDisplayItem = ({
  name,
  type,
  onRemove,
}: Omit<SelectOption, 'shouldShowAsSelected'> & { onRemove?: () => void }) => {
  return (
    <div className="d-flex p-s-50 border-radius-050 mt-s-100 mb-s-100 bg-neutral-variant-95 align-items-center text-neutral-variant-20 gap-x-1">
      <div className="flex-grow-1 pl-s-50 pr-s-50 line-height-small">
        {name}
      </div>
      <div className="pl-s-50 pr-s-50 line-height-small">
        {type === 'establishment' ? 'Estabelecimento' : 'Unidade'}
      </div>
      {onRemove && (
        <Button
          onClick={onRemove}
          design="transparent"
          color="neutral-variant"
          prefixes={<Icon className="btn__body__icon">close</Icon>}
        />
      )}
    </div>
  );
};

export const EstablishmentAndUnitsSelect = memo(
  EstablishmentAndUnitsSelectBase
);
