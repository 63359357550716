import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Button, Input, SelectInput as Select, Switch } from '@components';
import ClientAccessReasonAPICaller from '@services/api/clientAccessReason';
import { toValueLabel } from 'utils/object';

interface Props {
  closeModal: () => void;
  onSave: () => void;
  editId?: string;
}

export default function ClientAccessReasonForm(props: Props) {
  const { createOrUpdate, fetch } = ClientAccessReasonAPICaller;

  const [options, setOptions] = useState<{
    [key: string]: { label: string; value: string }[];
  }>({
    clientAccessAction: [],
  });

  const {
    register,
    setError,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting, isLoading },
  } = useForm({
    defaultValues: async () => fetchData(),
  });

  const fetchOptions = async () => {
    const options = await ClientAccessReasonAPICaller.fetchOptions();

    Object.keys(options).forEach((k) => {
      options[k] = options[k].map((value: string) => toValueLabel(value));
    });

    setOptions(options);
  };

  const fetchData = async () => {
    await fetchOptions();
    if (props.editId) {
      const clientAccessReason = await fetch(props.editId);
      clientAccessReason.clientAccessAction = toValueLabel(
        clientAccessReason.clientAccessAction
      );

      return clientAccessReason;
    }
    return null;
  };

  return (
    <div className="container p-s-200">
      <div className="modal-title">
        <h3>
          {props.editId ? 'Editar ' : 'Cadastrar '}motivo de alteração de acesso
        </h3>
      </div>
      <form
        autoComplete="off"
        className="form-max-height"
        onSubmit={handleSubmit((data) => {
          return createOrUpdate(data, setError).then((res) => {
            if (res.data.errors) return;
            props.closeModal();
            props.onSave();
          });
        })}
      >
        <div className="row grid-gap-1">
          <div className="col-md-12">
            <Select
              value={watch('clientAccessAction')}
              placeholder="Ação"
              disabled={isLoading}
              options={options.clientAccessAction}
              onSelect={(value) => setValue('clientAccessAction', value)}
              form={register('clientAccessAction')}
              error={!!errors.clientAccessAction}
              caption={errors.clientAccessAction?.message as string}
              label="Ação"
            ></Select>
          </div>
        </div>
        <div className="row grid-gap-1">
          <div className="col-md-10">
            <Input
              disabled={isLoading}
              error={!!errors.name}
              caption={errors.name?.message as string}
              label="Nome"
              placeholder="Nome"
              form={register('name', { required: 'Obrigatório' })}
            />
          </div>

          <div className="col-md-2">
            <Switch
              disabled={isLoading}
              className="mt-s-500 float-right"
              caption={watch('active', 'Ativo') ? 'Ativo' : 'Inativo'}
              error={errors.active?.message as string}
              form={register('active', { value: true })}
            />
          </div>
        </div>

        <div className="row justify-end pt-s-400" style={{ gap: 16 }}>
          <Button design="transparent" onClick={props.closeModal}>
            Cancelar
          </Button>
          <Button isLoading={isSubmitting || isLoading} type="submit">
            Salvar
          </Button>
        </div>
      </form>
    </div>
  );
}
