import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Button,
  DataLoader,
  Modal,
  PageHeader,
  Restricted,
  Table,
  TableColumn,
} from '@components';
import { usePermissionContext } from '@contexts/PermissionProvider';
import { useConfirmationModal } from '@hooks/useConfirmationModal';
import { DiscountCoupon } from '@interfaces/DiscountCoupon';
import { RepositoryParams } from '@services/api';
import DiscountCouponAPICaller from '@services/api/discountCoupons';
import { validateUUIDFormat } from 'utils/string/uuid';

import { DiscountCouponForm } from '../DiscountCouponForm';

export default function DiscountCouponView() {
  const { uuid } = useParams();
  const [filters, setFilters] = useState<RepositoryParams>({
    order: '-createdAt',
  });
  const [data, setData] = useState<DiscountCoupon | null>(null);
  const [couponUsed, setCouponUsed] = useState<{
    total: number;
    result: DiscountCoupon[];
  }>({
    total: 0,
    result: [],
  });
  const [editId, setEditId] = useState('');
  const navigate = useNavigate();
  const confirmation = useConfirmationModal();

  const { hasPermission } = usePermissionContext();

  const fetch = (uuid: string) => {
    DiscountCouponAPICaller.fetch(uuid).then((values) =>
      setData(values as DiscountCoupon)
    );
  };

  const fetchCouponUsed = useCallback(
    (uuid: string) => {
      DiscountCouponAPICaller.listUsed(uuid, filters).then((values) =>
        setCouponUsed(values)
      );
    },
    [filters]
  );

  useEffect(() => {
    if (uuid && validateUUIDFormat(uuid!)) {
      fetchCouponUsed(uuid as string);
    }
  }, [uuid, filters, fetchCouponUsed]);

  useEffect(() => {
    if (uuid) {
      if (validateUUIDFormat(uuid)) {
        fetch(uuid);
      } else {
        toast.error('ID informado inválido');
        navigate(-1);
      }
    }
  }, [uuid, navigate]);

  const deleteDiscountCoupon = (id: string) => {
    confirmation({
      title: 'Excluir o cupom de desconto',
      confirmButtonTitle: 'Excluir',
      description: `Deseja realmente excluir o cupom de desconto ${
        data!.name
      }?`,
      onSubmit: () =>
        DiscountCouponAPICaller.remove(id).then((_) => navigate(-1)),
    });
  };

  return (
    <>
      <div className="p-s-200">
        <PageHeader backButton title="Cupom de desconto" />
        <div className="card card__body p-s-350 bg-white">
          <div className="row">
            <div className="col-md-6">
              <h4>Nome</h4>
              <span>
                <DataLoader data={data?.name} isLoading={!data} />
              </span>
            </div>

            <div className="col-md-6">
              <h4>Código</h4>
              <span>
                <DataLoader data={data?.code} isLoading={!data} />
              </span>
            </div>

            <div className="col-md-6 mt-s-250">
              <h4>Data de expiração</h4>
              <span>
                <DataLoader data={data?.expirationDate} isLoading={!data} />
              </span>
            </div>
            <div className="col-md-6  mt-s-250">
              <h4>Porcentagem de desconto</h4>
              <span>
                <DataLoader
                  data={`${data?.discountPercentage}%`}
                  isLoading={!data}
                />
              </span>
            </div>

            <div className="col-md-6 mt-s-250">
              <h4>Quantidade</h4>
              <span>
                <DataLoader data={data?.amount} isLoading={!data} />
              </span>
            </div>
            <div className="col-md-6  mt-s-250">
              <h4>Vezes usado</h4>
              <span>
                <DataLoader data={data?.timesUsed} isLoading={!data} />
              </span>
            </div>

            <div className="col-md-6  mt-s-250">
              <h4>Domínio</h4>
              <span>
                <DataLoader data={data?.domain} isLoading={!data} />
              </span>
            </div>

            <div className="col-md-6  mt-s-250">
              <h4>Status</h4>
              <div className="row d-flex align-items-center">
                <span>
                  <DataLoader
                    data={data?.active ? 'Ativo' : 'Inativo'}
                    isLoading={!data}
                  />
                </span>
                <div
                  className={`little-ball-${data?.active ? 'green' : 'red'}`}
                />
              </div>
            </div>

            <div className="col-md-12  mt-s-250">
              <h4>Planos</h4>
              <span>
                <DataLoader
                  data={data?.plans?.map((it) => it.name)?.join(', ')}
                  isLoading={!data}
                />
              </span>
            </div>

            <div className="col-md-12 mt-s-350">
              <h4>Usuários que utilizaram o cupom</h4>
              <div
                className="mt-s-150"
                style={{ minHeight: '30vh', overflowY: 'scroll' }}
              >
                <Table
                  isLoading={!data}
                  hoverable
                  totalRecords={couponUsed.total}
                  data={couponUsed.result}
                  withPagination
                  showColumnSelector={false}
                  withSearchInput
                  filters={filters}
                  setFilters={setFilters}
                  className="pointer"
                  onRowClick={(row) => {
                    if (hasPermission('userClients', 'read')) {
                      navigate(`/userClients/${row.userClientId}`);
                    }
                  }}
                >
                  <TableColumn
                    fromKey="userClient"
                    nestedOrder="name"
                    header="Nome"
                    render={({ name }: { name: string }) => <span>{name}</span>}
                  />
                  <TableColumn
                    fromKey="userClient"
                    nestedOrder="phone"
                    header="Telefone"
                    render={({ phone }: { phone: string }) => (
                      <span>{phone}</span>
                    )}
                  />
                  <TableColumn
                    fromKey="userClient"
                    nestedOrder="email"
                    header="Email"
                    render={({ email }: { email: string }) => (
                      <span>{email}</span>
                    )}
                  />
                  <TableColumn fromKey="createdAt" header="Usado em" />
                </Table>
              </div>
            </div>
          </div>

          <DataLoader
            className="mt-s-150"
            data={
              <div className="row mt-s-300">
                <Restricted context="discountCoupon" resource="update">
                  <div className="col mr-s-100">
                    <Button
                      onClick={() => setEditId(uuid!)}
                      design="outlined"
                      size="medium"
                    >
                      Editar informações
                    </Button>
                  </div>
                </Restricted>
                <Restricted context="discountCoupon" resource="delete">
                  <div className="col mr-s-100">
                    <Button
                      onClick={() => deleteDiscountCoupon(uuid!)}
                      design="outlined"
                      size="medium"
                      color="negative"
                    >
                      Excluir cupom de desconto
                    </Button>
                  </div>
                </Restricted>
              </div>
            }
            height={40}
            isLoading={!data}
          />
        </div>
      </div>

      {!!(uuid && editId) && (
        <Modal
          isOpen={!!editId}
          onClickAway={() => {
            setEditId('');
          }}
        >
          <DiscountCouponForm
            editId={editId}
            onSave={() => {
              fetch(uuid);
              setEditId('');
            }}
            closeModal={() => {
              setEditId('');
            }}
          />
        </Modal>
      )}
    </>
  );
}
