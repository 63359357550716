import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { geocodeByAddress } from 'react-places-autocomplete';
import { toast } from 'react-toastify';

import {
  Button,
  FileUpload,
  Icon,
  Input,
  InputTextArea,
  Map,
  SelectInput as Select,
  Switch,
} from '@components';
import { useGeolocation } from '@hooks/useGeolocation';
import { Region } from '@interfaces/Region';
import { TipsType } from '@interfaces/Tips';
import { SelectOptions } from '@interfaces/Utils';
import GeneralAPICaller from '@services/api/general';
import RegionAPICaller from '@services/api/regions';
import StatesAPICaller from '@services/api/states';
import TipsAPICaller from '@services/api/tips';
import { fileToBlobURL } from '@services/io/file';
import { formZipCodePattern } from '@validations/zipCode';
import { toValueLabel } from 'utils/object';

interface Props {
  closeModal: () => void;
  onSave: () => void;
  editId?: string;
}

export default function TipsForm(props: Props) {
  const aspect = { width: 1200, height: 650 };
  const { createOrUpdate, fetch } = TipsAPICaller;

  const { getCurrentLocation, latLng } = useGeolocation();
  const [states, setStates] = useState<
    (SelectOptions & { abbreviation: string; name: string })[]
  >([]);
  const [cities, setCities] = useState<(SelectOptions | string)[]>([]);
  const [district, setDistrict] = useState<SelectOptions[] | string[]>([]);
  const [isZipCodeLoading, setIsZipCodeLoading] = useState(false);

  const [images, setImages] = useState<File[]>([]);

  const {
    register,
    setError,
    handleSubmit,
    watch,
    setValue,
    setFocus,

    clearErrors,
    formState: { errors, isSubmitting, isLoading },
  } = useForm<FieldValues>({
    defaultValues: async () => fetchData(),
  });

  const fetchStates = async () => {
    const statesResponse = await StatesAPICaller.fetchStates();
    setStates(statesResponse);

    return states;
  };

  const fetchStateCities = async (stateName: string) => {
    setFocus('city');
    const cities = await StatesAPICaller.fetchStateCities(stateName);
    setCities(cities);

    return cities;
  };

  const fetchDistrict = async (state: string, city: string) => {
    setFocus('district');
    const result: Region[] = await RegionAPICaller.all({
      state,
      city,
    });

    setDistrict(result.map((region) => region.district));

    return result;
  };

  const fetchData = async () => {
    fetchStates();

    if (props.editId) {
      const tip = await fetch(props.editId);

      await fetchStateCities(tip.state?.label);
      await fetchDistrict(tip.state?.label, tip.city?.label);
      setImages(tip.images);

      setFocus('');

      return {
        ...tip,
      };
    }

    getCurrentLocation();

    return {
      active: true,
    };
  };

  useEffect(() => {
    setValue('latitude', latLng.latitude);
    setValue('longitude', latLng.longitude);
  }, [latLng, setValue]);

  const fetchZipCode = async (zipCode: string) => {
    setIsZipCodeLoading(true);
    setValue('state', '');
    setValue('city', '');
    setValue('district', '');
    setValue('street', '');
    const result = await GeneralAPICaller.searchZipCode(zipCode);

    if (result.localidade && result.uf) {
      const state = states.filter(
        (state) => state.abbreviation === result.uf
      )[0];

      setValue('state', toValueLabel(state.name));
      await fetchStateCities(state.name);

      setValue('city', toValueLabel(result.localidade));

      const districtList = await fetchDistrict(state.name, result.localidade);

      if (result.bairro) {
        const districtSearch = districtList.filter(
          (it) => it.district === result.bairro
        )[0];

        setValue('district', {
          ...districtSearch,
          value: districtSearch.district,
          label: districtSearch.district,
        });
      }

      if (result.logradouro) {
        setValue('street', result.logradouro);
      }
    }

    fetchGeoLocation();
    setIsZipCodeLoading(false);
  };

  const fetchGeoLocation = async () => {
    const address = [
      watch('state')?.label,
      watch('city')?.label,
      watch('district')?.label,
      watch('street'),
      watch('number'),
    ];

    const result = await geocodeByAddress(address.join(', '));

    if (result && Array.isArray(result) && result.length >= 1) {
      setValue('latitude', result.at(0)?.geometry?.location?.lat());
      setValue('longitude', result.at(0)?.geometry?.location?.lng());
    }
  };

  return (
    <div className="container p-s-200">
      <div className="modal-title">
        <h3>{props.editId ? 'Editar ' : 'Cadastrar '}dica</h3>
      </div>
      <form
        autoComplete="off"
        className="form-max-height"
        onSubmit={handleSubmit((data) => {
          data.images = images;

          return createOrUpdate(data, setError).then((res) => {
            if (res.data.errors) return;
            props.closeModal();
            props.onSave();
          });
        })}
      >
        <div className="row grid-gap-1">
          <div className="col-md-6">
            <Select
              value={watch('type')}
              placeholder="Tipo da dica"
              disabled={isLoading}
              options={Object.values(TipsType)}
              onSelect={(value) => setValue('type', value)}
              form={register('type', { required: 'Obrigatório' })}
              error={!!errors.type}
              caption={errors.type?.message as string}
              label="Tipo da dica"
            />
          </div>

          <div className="col-md-2">
            <Switch
              disabled={isLoading}
              className="mt-s-500 float-right"
              caption={watch('active', 'Ativo') ? 'Ativo' : 'Inativo'}
              error={errors.active?.message as string}
              form={register('active', { value: true })}
            />
          </div>

          <div className="col-md-6">
            <Input
              disabled={isLoading}
              error={!!errors.name}
              caption={errors.name?.message as string}
              label="Nome"
              placeholder="Nome"
              form={register('name')}
            />
          </div>
          <div className="col-md-6">
            <Input
              disabled={isLoading}
              error={!!errors.busLine}
              caption={errors.busLine?.message as string}
              label="Linha de ônibus (separado por ;)"
              placeholder="Linhas de ônibus"
              form={register('busLine')}
            />
          </div>

          <div className="col-md-12">
            <InputTextArea
              disabled={isLoading}
              error={!!errors.description}
              caption={errors.description?.message as string}
              label="Descrição"
              form={register('description', { required: 'Obrigatório' })}
            />
          </div>
        </div>
        <div className="col-md-12 pt-s-200">
          <hr className="pb-s-200" />
          <h4>Localização</h4>
        </div>

        <div className="row grid-gap-1">
          <div className="col-md-6">
            <Input
              mask="zipCode"
              disabled={isLoading}
              error={!!errors.zipCode}
              caption={errors.zipCode?.message as string}
              label="CEP"
              placeholder="CEP"
              form={register('zipCode', {
                onChange: (event) => {
                  const zipCode = event.target.value as string;
                  if (zipCode.length === 9) {
                    fetchZipCode(zipCode);
                  }
                },
                required: 'Obrigatório',
                ...formZipCodePattern,
              })}
            />
          </div>

          <div className="col-md-6">
            <Select
              value={watch('state')}
              placeholder="Estado"
              disabled={isLoading || isZipCodeLoading}
              isSearchable
              options={states}
              onSelect={(value: { label: string }) => {
                setValue('state', value);
                if (value?.label) {
                  setValue('city', '');
                  fetchStateCities(value?.label);
                }
              }}
              form={register('state', { required: 'Obrigatório' })}
              error={!!errors.state}
              caption={errors.state?.message as string}
              fromKey="name"
              label="Estado"
            />
          </div>
          <div className="col-md-6">
            <Select
              isSearchable
              value={watch('city')}
              placeholder="Cidade"
              disabled={isLoading || !watch('state') || isZipCodeLoading}
              options={cities}
              onSelect={(value) => {
                setValue('city', value);
                if (value.label) {
                  setValue('district', '');
                  fetchDistrict(watch('state').label, value.label);
                }
              }}
              form={register('city', { required: 'Obrigatório' })}
              error={!!errors.city}
              caption={errors.city?.message as string}
              label="Cidade"
            />
          </div>
          <div className="col-md-6">
            <Select
              isSearchable
              value={watch('district')}
              placeholder="Bairro"
              disabled={isLoading || !watch('city') || isZipCodeLoading}
              options={district as SelectOptions[]}
              onSelect={(value) => {
                setValue('district', value);
              }}
              form={register('district', { required: 'Obrigatório' })}
              error={!!errors.district}
              caption={errors.district?.message as string}
              label="Bairro"
            />
          </div>

          <div className="col-md-12">
            <Input
              disabled={isLoading || isZipCodeLoading}
              error={
                !!errors.street ||
                !!errors['name, state, city, district, street, number']
              }
              caption={
                (errors.street?.message as string) ||
                (errors['name, state, city, district, street, number']
                  ?.message as string)
              }
              label="Rua"
              placeholder="Rua"
              form={register('street', {
                required: 'Obrigatório',
                onChange: () => {
                  clearErrors('name, state, city, district, street, number');
                },
              })}
            />
          </div>

          <div className="col-md-6">
            <Input
              disabled={isLoading || isZipCodeLoading}
              error={!!errors.number}
              caption={errors.number?.message as string}
              label="Número"
              placeholder="Número"
              form={register('number', {
                required: 'Obrigatório',
                onBlur: () => {
                  fetchGeoLocation();
                },
              })}
            />
          </div>
          <div className="col-md-6">
            <Input
              disabled={isLoading || isZipCodeLoading}
              error={!!errors.complement}
              caption={errors.complement?.message as string}
              label="Complemento"
              placeholder="Complemento"
              form={register('complement')}
            />
          </div>

          <div className="col-md-12">
            <Map
              mapWidth="45vw"
              value={{
                lat: watch('latitude'),
                lng: watch('longitude'),
              }}
              onChange={(coords) => {
                setValue('latitude', coords.lat);
                setValue('longitude', coords.lng);
              }}
            />
          </div>
        </div>

        <div>
          <div className="col-md-12 pt-s-200">
            <hr className="pb-s-200" />
            <h4>Imagens do lugar</h4>
          </div>

          <div className="row grid-gap-1">
            <div className="col-md-12">
              <FileUpload
                image={''}
                accept="image/*"
                maxSizeInBytes={5_000_000}
                disclaimer={`${aspect.width}x${aspect.height}px com até 5MB`}
                cropProps={{
                  aspect: aspect.width / aspect.height,
                }}
                openCropImageModal
                onChange={(file) => {
                  setImages((images) => [...images, ...file]);
                  toast.success('Imagem adicionada com sucesso!');
                }}
                label="Escolher uma ou mais imagens"
                error={errors.images?.message as string}
              />
            </div>

            {!!images.length && (
              <div className="col-md-12">
                <div className="col-md-12">Imagens selecionadas</div>
                <div className="col-md-12 mt-s-200 d-flex align-items-center">
                  <Icon
                    style={{
                      color: 'GrayText',
                      marginRight: 10,
                      fontSize: 20,
                    }}
                  >
                    info
                  </Icon>
                  <span style={{ fontSize: 12 }}>
                    É possível adicionar mais de uma imagem. Para definir a
                    imagem principal da dica, selecione o marcador do canto
                    superior esquerdo da imagem
                  </span>
                </div>
                <hr
                  className="mt-s-200 mb-s-200"
                  style={{
                    flex: 1,
                  }}
                />

                <div className="row grid-gap-1">
                  {images.map((it, i) => {
                    const img = fileToBlobURL(it);
                    const deleteImage = () => {
                      setImages((current) =>
                        current.filter((_, index) => index !== i)
                      );
                    };

                    const setPrincipalImage = () => {
                      setImages((current) => {
                        const principalImage = current[i];

                        const newImages = current.filter(
                          (_, index) => index !== i
                        );

                        newImages.unshift(principalImage);

                        return newImages;
                      });
                    };

                    return (
                      <div className="col-md-6" key={it.size + i}>
                        <div style={{ position: 'relative' }}>
                          <div className="checkbox-button ml-s-100 mt-s-250">
                            <div className="bg-white border-radius-100 p-s-100 d-flex align-items-center justify-center">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                onChange={setPrincipalImage}
                                checked={i === 0}
                              />
                            </div>
                          </div>
                          <div className="remove-button mr-s-200 mt-s-250">
                            <div onClick={deleteImage}>
                              <Icon
                                style={{ fontSize: 20, padding: 4 }}
                                className="bg-white border-radius-100"
                              >
                                delete
                              </Icon>
                            </div>
                          </div>
                          {i === 0 && (
                            <div
                              style={{ fontSize: 12, padding: 4 }}
                              className="principal_image mb-s-250 ml-s-100 bg-white border-radius-100"
                            >
                              Imagem principal
                            </div>
                          )}

                          <div className="banner-image-container">
                            <img
                              src={img}
                              className="banner-image border-radius-100"
                              alt={`${it.name}`}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="row justify-end pt-s-400" style={{ gap: 16 }}>
          <Button design="transparent" onClick={props.closeModal}>
            Cancelar
          </Button>
          <Button
            isLoading={isSubmitting || isLoading || isZipCodeLoading}
            type="submit"
          >
            Salvar
          </Button>
        </div>
      </form>
    </div>
  );
}
