import { useMemo, useState } from 'react';

import { Icon } from '@components';
import { Permissions } from '@interfaces/Permissions';

type Resource = {
  [context: string]: string[];
};

type Props = {
  value: Permissions;
  onChange: (value: Permissions, resourceSelected: string) => void;
  permissions: Resource;
  isFixedPermission: boolean;
};

let defaultPermissions = ['Ler', 'Criar', 'Editar', 'Excluir', 'Ordenar'];

export function checkIfAllIsTrue(
  permissions: Permissions | any[],
  resourcesList: Resource
) {
  const allPerms: { Todos: { [context: string]: boolean } } = {
    Todos: {},
  };

  if (Array.isArray(permissions) && permissions.length === 0) {
    return allPerms;
  }

  const merged = mergePermission(resourcesList, permissions as Permissions);

  defaultPermissions.forEach((perm) => {
    const allPermitionsAreTrue = Object.values(merged)
      .map((permName) => permName[perm])
      .every((it) => it || typeof it === 'undefined');

    allPerms.Todos[perm] = allPermitionsAreTrue;
  });

  return allPerms;
}

// Função para realizar o entre lista de recursos e as permissões
function mergePermission(resource: Resource, perms: Permissions) {
  const merged: any = {};

  for (const resourceKey in resource) {
    if (!merged[resourceKey]) {
      merged[resourceKey] = {};
    }

    for (const resourceValue of resource[resourceKey]) {
      if (!merged[resourceKey][resourceValue]) {
        merged[resourceKey][resourceValue] = false;
      }
    }

    merged[resourceKey] = {
      ...merged[resourceKey],
      ...perms[resourceKey],
    };
  }

  return merged as Permissions;
}

const resourceColumns = (
  value: Permissions,
  onChange: (value: Permissions, resource: string) => void,
  context: string,
  resource?: string,
  label?: string
) => {
  if (!resource) return <></>;

  return (
    <div
      key={`permission-${context}-${resource}`}
      className="form-check"
      style={{ placeContent: 'center' }}
    >
      <input
        name={`permission-${context}-${resource}`}
        id={`permission-${context}-${resource}`}
        onChange={(e) => {
          const checked = e.target.checked;
          onChange(
            {
              ...value,
              [context]: {
                ...value[context],
                [resource]: checked,
              },
            },
            resource
          );
        }}
        checked={value?.[context]?.[resource] ?? false}
        className="form-check-input"
        style={{ borderColor: '#a5b7c0' }}
        type="checkbox"
      />
      {label && (
        <label
          className="form-check-label"
          htmlFor={`permission-${context}-${resource}`}
        >
          {label}
        </label>
      )}
    </div>
  );
};

export function PermissionRow({
  context,
  resources,
  value,
  onChange,
  index,
}: {
  context: string;
  resources: string[];
  value: Permissions;
  onChange: (value: Permissions, resource: string) => void;
  index: number;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const morePermission = useMemo(
    () => resources.filter((it) => !defaultPermissions.includes(it)),
    [resources]
  );

  const shouldShowExpandMore = useMemo(() => {
    return morePermission.length;
  }, [morePermission]);

  const classNames = shouldShowExpandMore ? 'pointer' : '';

  return (
    <>
      <tr
        key={`permission-context-${context}-${index}-${Math.random()}`}
        style={{
          backgroundColor: index % 2 === 0 ? '#F0F3F5' : '',
        }}
      >
        <td
          style={{ textAlign: 'center' }}
          className={classNames}
          onClick={() => {
            if (shouldShowExpandMore) setIsOpen((p) => !p);
          }}
        >
          {!!shouldShowExpandMore &&
            (isOpen ? (
              <Icon children={'expand_less'} />
            ) : (
              <Icon children={'expand_more'} />
            ))}
        </td>
        <td
          className={classNames}
          style={{ fontWeight: 'bold' }}
          onClick={() => {
            if (shouldShowExpandMore) setIsOpen((p) => !p);
          }}
        >
          {context}
        </td>
        {defaultPermissions.map((perms, i) => (
          <td key={`permission-context-${context}-${perms}-${Math.random()}`}>
            {resourceColumns(
              value,
              onChange,
              context,
              resources.find((it) => it === perms)
            )}
          </td>
        ))}
      </tr>
      {isOpen && (
        <tr className="isOpen">
          <td
            colSpan={7}
            style={{ backgroundColor: '#FAFAFA', paddingLeft: 32 }}
          >
            <h5>Ações extras</h5>
            <div
              className="mt-s-200 row"
              style={{
                gap: 16,
              }}
            >
              {morePermission.map((resource) =>
                resourceColumns(value, onChange, context, resource, resource)
              )}
            </div>
          </td>
        </tr>
      )}
    </>
  );
}

export default function PermissionsInput(props: Props) {
  if (props.isFixedPermission) {
    defaultPermissions = defaultPermissions.filter((it) => it !== 'Ordenar');
  } else {
    if (!defaultPermissions.find((it) => it === 'Ordenar'))
      defaultPermissions.push('Ordenar');
  }

  return (
    <>
      <div
        className="border-radius-100"
        style={{ maxHeight: 400, overflowY: 'auto' }}
      >
        <table>
          <thead
            className="divider-permisson-input bg-white"
            style={{ position: 'sticky', top: 0 }}
          >
            <tr>
              <th style={{ width: '5%', textAlign: 'center' }} />
              <th style={{ width: '50%', textAlign: 'start', paddingLeft: 18 }}>
                Área
              </th>
              <th style={{ textAlign: 'center' }}>Ler</th>
              <th style={{ textAlign: 'center' }}>Criar</th>
              <th style={{ textAlign: 'center' }}>Editar</th>
              <th style={{ textAlign: 'center' }}>Excluir</th>
              {!props.isFixedPermission && (
                <th style={{ textAlign: 'center' }}>Ordenar</th>
              )}
            </tr>
          </thead>
          <tbody className="divider-permisson-input">
            <PermissionRow
              context={'Todos'}
              resources={defaultPermissions}
              value={{
                ...props.value,
                ...checkIfAllIsTrue(props.value, props.permissions),
              }}
              index={-10}
              onChange={(perms: Permissions, resourceChanged: string) => {
                const newPerms: Permissions = {};

                Object.entries(props.permissions).forEach(([permName]) => {
                  newPerms[permName] = {
                    ...props.value[permName],
                    [resourceChanged]: perms['Todos'][resourceChanged],
                  };
                });

                props?.onChange(
                  {
                    ...props.value,
                    ...newPerms,
                  },
                  resourceChanged
                );
              }}
            />
            {props.value &&
              Object.entries(props.permissions).map(
                ([context, resources], i) => (
                  <PermissionRow
                    key={context + i}
                    context={context}
                    resources={resources}
                    onChange={props.onChange}
                    value={props.value}
                    index={i + 1}
                  />
                )
              )}
          </tbody>
        </table>
      </div>
    </>
  );
}
