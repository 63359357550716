import { useCallback, useEffect, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import {
  Button,
  Icon,
  Input,
  InputAddon,
  Restricted,
  Table,
  TableColumn,
} from '@components';
import { useConfirmationModal } from '@hooks/useConfirmationModal';
import { Group } from '@interfaces/Group';
import CategoriesAPICaller from '@services/api/categories';
import GroupsAPICaller from '@services/api/groups';

type Props = {
  categoryId: string;
  categoryName: string;
  onUpdate: () => void;
};

export default function CategoriesGroupsTable(props: Props) {
  const [groupsFromCategory, setGroupsFromCategory] = useState<Group[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [isAddingGroup, setIsAddingGroup] = useState(false);
  const { watch, register, setValue, handleSubmit } = useForm();
  const listGroupsByQuery = watch('groupName');

  const confirmation = useConfirmationModal();

  const fetchGroupsFromCategory = useCallback(async () => {
    const category = await CategoriesAPICaller.fetch(props.categoryId);
    setGroupsFromCategory(category.groups);
  }, [props.categoryId]);

  const fetchGroups = useCallback(async () => {
    const response = await GroupsAPICaller.list({
      q: listGroupsByQuery,
      size: 5,
    });

    setGroups(response.result);
  }, [listGroupsByQuery]);

  useEffect(() => {
    fetchGroupsFromCategory();
  }, [fetchGroupsFromCategory]);

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  const onSubmit = (async (data: { groupId: string }) => {
    setValue('groupId', '');
    setValue('groupName', '');
    if (!data.groupId) return;
    setIsAddingGroup(true);
    await CategoriesAPICaller.includeGroup(
      props.categoryId,
      data.groupId
    ).finally(() => setIsAddingGroup(false));
    await fetchGroupsFromCategory();
    await fetchGroups();
    props.onUpdate();
  }) as SubmitHandler<FieldValues>;

  const deleteCategoryGroup = async (groupId: string) => {
    confirmation({
      title: 'Excluir registro',
      confirmButtonTitle: 'Excluir',
      description: 'Deseja realmente excluir esse registro?',
      onSubmit: async () => {
        await CategoriesAPICaller.excludeGroup(props.categoryId, groupId);
        props.onUpdate();
        await fetchGroupsFromCategory();
        await fetchGroups();
      },
    });
  };

  return (
    <div className="p-s-200">
      <h4 className="mb-s-200">
        Grupos da categoria (<span>{props.categoryName}</span>)
      </h4>
      <Restricted context="categories" resource="group">
        <form
          autoComplete="off"
          className="col-sm-4 form-max-height"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input
            options={groups.map((group) => group.name)}
            onOptionChange={(index) => {
              const group = groups[index];
              if (!group) return;
              setValue('groupName', group.name);
              setValue('groupId', group.id);
            }}
            placeholder="Pesquisar grupo"
            form={register('groupName')}
            suffixes={
              <InputAddon right>
                <Button
                  isLoading={isAddingGroup}
                  type="submit"
                  weight="bold"
                  design="outlined"
                  prefixes={<Icon>group_add</Icon>}
                >
                  Adicionar
                </Button>
              </InputAddon>
            }
          />
        </form>
      </Restricted>
      <Table data={groupsFromCategory} showColumnSelector={false}>
        <TableColumn
          fromKey="id"
          width="1px"
          header=""
          disableSorting
          render={() => <Icon>group</Icon>}
        />
        <TableColumn
          key={`${props.categoryId}-name`}
          header="Nome"
          fromKey="name"
          container
          ellipsis
          cellAlign="start"
        />
        <TableColumn
          key={`${props.categoryId}-actions`}
          header="Ações"
          disableSorting
          width="6%"
          container
          cellAlign="end"
          fromKey="id"
          render={(groupId) => (
            <div className="d-flex">
              <Restricted context="categories" resource="group">
                <Button
                  weight="bold"
                  design="transparent"
                  prefixes={<Icon>delete</Icon>}
                  onClick={async () => deleteCategoryGroup(groupId)}
                />
              </Restricted>
            </div>
          )}
        />
      </Table>
    </div>
  );
}
