import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { AuthProvider, useAuthContext } from '@contexts/AuthProvider';
import { ConfirmationModalProvider } from '@contexts/ConfirmationContext';
import {
  PermissionProvider,
  usePermissionContext,
} from '@contexts/PermissionProvider';
import {
  AccessPage,
  AdvertisementPage,
  AdvertisementView,
  BannerPage,
  BenefitsPage,
  CategoriesPage,
  CategoriesView,
  ClientAccessReasonPage,
  ClientAccessReasonsView,
  ConveniencesPage,
  CookingPage,
  DefaultPage,
  DevicesPage,
  DiscountCouponPage,
  DiscountCouponView,
  EmailSentPage,
  EstablishmentPage,
  EstablishmentView,
  FAQPage,
  GroupsPage,
  HomePage,
  LoginPage,
  MePage,
  NotFoundPage,
  NotificationPage,
  PlansPage,
  PlansView,
  RecoverPasswordPage,
  RegionsPage,
  ResetPasswordPage,
  ShoppingCentersPage,
  TipsPage,
  TipsView,
  UnitsPage,
  UserClientsPage,
  UserClientsView,
  UsersPage,
  UsersView,
  VouchersPage,
} from '@pages';
import 'react-toastify/dist/ReactToastify.css';
import 'tippy.js/dist/tippy.css';
import useScript from '@hooks/useScript';
import { EmphasisView } from '@pages/VouchersPage/EmphasisView';
import ReportPage from '@pages/ReportPage/ReportPage';

function Authenticated() {
  const location = useLocation();
  const { loading: permLoading } = usePermissionContext();
  const { user, loading: authLoading } = useAuthContext();

  if (authLoading) return <>Logging in, please wait...</>;
  if (permLoading) return <>Fetching permissions, please wait...</>;

  const isAuthenticated = !!user;
  return isAuthenticated ? (
    <DefaultPage />
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
}

function App() {
  useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=places`
  );

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        style={{ zIndex: 9999999 }}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <BrowserRouter>
        <ConfirmationModalProvider>
          <AuthProvider>
            <PermissionProvider>
              <Routes>
                <Route path="">
                  <Route path="" element={<LoginPage />} />
                  <Route path="login" element={<LoginPage />} />
                  <Route path="recover_password">
                    <Route path="" element={<RecoverPasswordPage />} />
                    <Route path=":token" element={<ResetPasswordPage />} />
                  </Route>
                  <Route path="email_sent" element={<EmailSentPage />} />
                  <Route path="default_page" element={<DefaultPage />} />
                  <Route path="homepage" element={<HomePage />} />
                  <Route path="*" element={<NotFoundPage />} />
                </Route>
                <Route path="" element={<Authenticated />}>
                  <Route path="/dashboard" element={<ReportPage />} />
                  <Route path="/report" element={<ReportPage />} />
                  <Route path="/backoffice_users" element={<UsersPage />} />
                  <Route
                    path="/backoffice_users/:uuid"
                    element={<UsersView />}
                  />
                  <Route path="/access" element={<AccessPage />} />
                  <Route path="/banner" element={<BannerPage />} />
                  <Route path="/cooking" element={<CookingPage />} />
                  <Route path="/benefits" element={<BenefitsPage />} />
                  <Route path="/regions" element={<RegionsPage />} />
                  <Route path="/plans" element={<PlansPage />} />
                  <Route path="/plans/:uuid" element={<PlansView />} />
                  <Route path="/me" element={<MePage />} />
                  <Route path="/faqs" element={<FAQPage />} />
                  <Route path="/groups" element={<GroupsPage />} />
                  <Route path="/vouchers" element={<VouchersPage />} />
                  <Route path="/emphasis/:uuid" element={<EmphasisView />} />
                  <Route
                    path="/establishment"
                    element={<EstablishmentPage />}
                  />
                  <Route path="/units" element={<UnitsPage />} />
                  <Route
                    path="/client_access_reasons"
                    element={<ClientAccessReasonPage />}
                  />
                  <Route
                    path="/client_access_reasons/:uuid"
                    element={<ClientAccessReasonsView />}
                  />
                  <Route
                    path="/establishment/:uuid"
                    element={<EstablishmentView />}
                  />
                  <Route path="/conveniences" element={<ConveniencesPage />} />
                  <Route path="/categories" element={<CategoriesPage />} />
                  <Route
                    path="/categories/:uuid"
                    element={<CategoriesView />}
                  />

                  <Route
                    path="/shopping_centers"
                    element={<ShoppingCentersPage />}
                  />
                  <Route path="/notifications" element={<NotificationPage />} />
                  <Route
                    path="/advertisement"
                    element={<AdvertisementPage />}
                  />
                  <Route
                    path="/advertisement/:uuid"
                    element={<AdvertisementView />}
                  />
                  <Route path="/userClients" element={<UserClientsPage />} />
                  <Route
                    path="/userClients/:uuid"
                    element={<UserClientsView />}
                  />
                  <Route
                    path="/discountCoupons"
                    element={<DiscountCouponPage />}
                  />
                  <Route
                    path="/discountCoupons/:uuid"
                    element={<DiscountCouponView />}
                  />
                  <Route path="/devices" element={<DevicesPage />} />
                  <Route path="/tips" element={<TipsPage />} />
                  <Route path="/tips/:uuid" element={<TipsView />} />
                </Route>
              </Routes>
            </PermissionProvider>
          </AuthProvider>
        </ConfirmationModalProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
