import UsersAPICaller from '@services/api/users';

import { useFetchList } from '../useFetch';

export const useFetchUsers = () => {
  const { data, total, fetching, fetchData } = useFetchList(
    UsersAPICaller.fetchUsers
  );

  return {
    users: data,
    usersTotal: total,
    isFetchingUsers: fetching,
    fetchUsers: fetchData,
  };
};
