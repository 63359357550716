import { useState } from 'react';

import { Button, DataLoader, Modal, Restricted } from '@components';
import { UserClient } from '@interfaces/UserClient';
import { SelectOptions } from '@interfaces/Utils';

import UserClientForm from '../UserClientsForm/UserClientsForm';
import UserClientPasswordForm from '../UserClientUpdatePasswordForm/UserClientPasswordForm';

type Props = {
  uuid?: string;
  data: UserClient | null;
  fetch: () => void;
};

export function UserClientsInfo(props: Props) {
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenPassword, setIsOpenPassword] = useState(false);

  return (
    <>
      <div className="card card__body p-s-350 bg-white">
        <div className="row">
          <div className="col-md-6 mt-s-250">
            <h4>CPF</h4>
            <span>
              <DataLoader data={props.data?.cpf} isLoading={!props.data} />
            </span>
          </div>
          <div className="col-md-6 mt-s-250">
            <h4>E-mail</h4>
            <span>
              <DataLoader data={props.data?.email} isLoading={!props.data} />
            </span>
          </div>
          <div className="col-md-6 mt-s-250">
            <h4>Gênero</h4>
            <span>
              <DataLoader
                data={(props.data?.gender as SelectOptions)?.label}
                isLoading={!props.data}
              />
            </span>
          </div>
          <div className="col-md-6  mt-s-250">
            <h4>Data de Nascimento</h4>
            <span>
              <DataLoader
                data={props.data?.dateOfBirth}
                isLoading={!props.data}
              />
            </span>
          </div>
          <div className="col-md-6  mt-s-250">
            <h4>Status</h4>
            <DataLoader
              data={
                <div className="row d-flex ">
                  <span>
                    {(props.data?.userClientStatus as SelectOptions)?.label}
                  </span>
                  <div
                    className={`little-ball-${
                      (props.data?.userClientStatus as SelectOptions)?.label ===
                      'Ativo'
                        ? 'green'
                        : 'red'
                    }`}
                  />
                </div>
              }
              isLoading={!props.data}
            />
          </div>
          <div className="col-md-6  mt-s-250 col-xs-2">
            <h4>Data da exclusão</h4>
            <span>
              <DataLoader
                data={props.data?.inactivationDate}
                isLoading={!props.data}
              />
            </span>
          </div>
          <div className="col-md-6  mt-s-250">
            <h4>Data Físico?</h4>
            <span>
              <DataLoader
                data={props.data?.hasPhysicalTour ? 'Sim' : 'Não'}
                isLoading={!props.data}
              />
            </span>
          </div>
          <div className="col-md-6  mt-s-250"></div>
          <div className="col-md-6  mt-s-250">
            <hr className="pt-s-100 pb-s-100" />
            <h4>Dispositivo Móvel - Celular</h4>
            <span>
              <DataLoader
                data={props.data?.mobileDevice?.deviceModel}
                isLoading={!props.data}
              />
            </span>
          </div>
          <div className="col-md-6  mt-s-250">
            <hr className="pt-s-100 pb-s-100" />
            <h4>Versão do App</h4>
            <span>
              <DataLoader
                data={props.data?.mobileDevice?.appVersion}
                isLoading={!props.data}
              />
            </span>
          </div>
          <div className="col-md-6  mt-s-250">
            <h4>Sistema Operacional</h4>
            <span>
              <DataLoader
                data={props.data?.mobileDevice?.operationalSystem}
                isLoading={!props.data}
              />
            </span>
          </div>
          <div className="col-md-6  mt-s-250">
            <h4>Versão Sistema Operacional</h4>
            <span>
              <DataLoader
                data={props.data?.mobileDevice?.operationalSystemVersion}
                isLoading={!props.data}
              />
            </span>
          </div>
          <div className="col-md-12  mt-s-250">
            <hr className="pt-s-100 pb-s-100" />
            <h4>Vouchers favoritados</h4>
            <span>
              <DataLoader
                data={props.data?.vouchersFavorited
                  ?.map((it) => it.name)
                  .join(', ')}
                isLoading={!props.data}
              />
            </span>
          </div>

          <DataLoader
            className="mt-s-150"
            data={
              <div className="col-md-12  mt-s-300">
                <hr className="pt-s-100 pb-s-100" />
                <div className="row responsive-buttons">
                  <Restricted context="userClients" resource="update">
                    <div className="col mr-s-100">
                      <Button
                        onClick={() => {
                          setIsOpenEditModal(true);
                        }}
                        design="outlined"
                        size="medium"
                      >
                        Editar informações
                      </Button>
                    </div>
                  </Restricted>
                  <Restricted context="userClients" resource="updatePassword">
                    <div className="col mr-s-100 mt-responsive-button">
                      <Button
                        onClick={() => {
                          setIsOpenPassword(true);
                        }}
                        design="outlined"
                        size="medium"
                        color="negative"
                      >
                        Alterar senha
                      </Button>
                    </div>
                  </Restricted>
                </div>
              </div>
            }
            height={40}
            isLoading={!props.data}
          />
        </div>
      </div>

      {Boolean(isOpenEditModal) && (
        <Modal
          isOpen={!!isOpenEditModal}
          size="medium"
          onClickAway={() => setIsOpenEditModal(false)}
        >
          <UserClientForm
            onSave={() => {
              setIsOpenEditModal(false);
              props.fetch!();
            }}
            userClientUUID={props.uuid!}
            closeModal={() => {
              setIsOpenEditModal(false);
            }}
          />
        </Modal>
      )}

      {Boolean(isOpenPassword) && (
        <Modal
          isOpen={!!isOpenPassword}
          size="medium"
          onClickAway={() => setIsOpenPassword(false)}
        >
          <UserClientPasswordForm
            userClientUUID={props.uuid!}
            closeModal={() => {
              setIsOpenPassword(false);
            }}
          />
        </Modal>
      )}
    </>
  );
}
