import { SelectOptions } from '@interfaces/Utils';

export const handleSelectedAll = (
  listSelected: (SelectOptions & { id?: string })[] = [],
  listWithAll: (SelectOptions & { id?: string })[] = []
) => {
  if (listSelected.some((it) => it.id === 'all')) {
    listSelected = listWithAll.filter((it) => it.id !== 'all');
  }

  return listSelected;
};
