import { useEffect, useState } from 'react';

import { Button, Icon, Modal, PageHeader, Restricted, Tab } from '@components';
import { useConfirmationModal } from '@hooks/useConfirmationModal';
import { Voucher } from '@interfaces/Voucher';
import { RepositoryParams } from '@services/api';
import {
  default as VoucherAPICaller,
  VoucherFilters,
  default as VouchersAPICaller,
} from '@services/api/voucher';

import { VouchersForm } from './VouchersForm';
import { VoucherTable } from './VoucherTable';
import EmpashisTable from './EmphasisTable/EmphasisTable';
import { VoucherQRCodeDownloadForm } from './VoucherQRCodeDownloadForm';

export default function VouchersPage() {
  const confirmation = useConfirmationModal();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<{ total: number; result: Voucher[] }>({
    total: 0,
    result: [],
  });
  const [filters, setFilters] = useState<VoucherFilters>({
    order: '-plan.name,name',
    joins: ['establishment', 'plan'],
  });
  const [editId, setEditId] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [openQRCodeModal, setOpenQRCodeModal] = useState(false);

  const fetch = (filters: RepositoryParams) => {
    setIsLoading(true);
    VouchersAPICaller.list(filters)
      .then((values) => {
        setData(values);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetch(filters);
  }, [filters]);

  const deleteVoucher = (id: string) => {
    confirmation({
      title: 'Excluir voucher',
      description: 'Deseja realmente excluir esse voucher?',
      confirmButtonTitle: 'Excluir',
      onSubmit: () => VoucherAPICaller.remove(id).then((_) => fetch(filters)),
    });
  };

  return (
    <>
      <div className="p-s-200">
        <PageHeader
          title="Voucher"
          actions={
            <>
              <Restricted context="vouchers" resource="generateQRCodes">
                <Button
                  onClick={() => {
                    setOpenQRCodeModal(true);
                  }}
                  design="outlined"
                  weight="bold"
                  prefixes={<Icon>download</Icon>}
                >
                  Gerar QR Codes
                </Button>
                <Button
                  onClick={() => {
                    setIsOpenModal(true);
                  }}
                  weight="bold"
                  prefixes={<Icon>add</Icon>}
                >
                  Cadastrar
                </Button>
              </Restricted>
            </>
          }
        />

        <Tab
          tabSelected={0}
          tabs={[
            {
              component: (
                <div className="mt-s-250">
                  <VoucherTable
                    data={data}
                    showPlans
                    filters={filters}
                    isLoading={isLoading}
                    setEditId={setEditId}
                    setIsOpenModal={setIsOpenModal}
                    setFilters={setFilters}
                    deleteVoucher={deleteVoucher}
                  />
                </div>
              ),
              title: 'Todos',
            },
            {
              component: (
                <div className="mt-s-250">
                  <EmpashisTable />
                </div>
              ),
              title: 'Destaques',
            },
          ]}
        />
      </div>

      <Modal
        isOpen={isOpenModal}
        onClickAway={() => {
          setEditId('');
          setIsOpenModal(false);
        }}
      >
        <VouchersForm
          editId={editId}
          onSave={() => fetch(filters)}
          closeModal={() => {
            setEditId('');
            setIsOpenModal(false);
          }}
        />
      </Modal>
      {Boolean(openQRCodeModal) && (
        <Modal
          isOpen={!!openQRCodeModal}
          onClickAway={() => setOpenQRCodeModal(false)}
        >
          <VoucherQRCodeDownloadForm
            closeModal={() => setOpenQRCodeModal(false)}
          />
        </Modal>
      )}
    </>
  );
}
