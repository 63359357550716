import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Icon,
  Modal,
  PageHeader,
  Restricted,
  StatusChip,
  Table,
  TableColumn,
} from '@components';
import { PopOver, PopOverMenu } from '@components/PopOver';
import { usePermissionContext } from '@contexts/PermissionProvider';
import { useConfirmationModal } from '@hooks/useConfirmationModal';
import { ClientAccessReason } from '@interfaces/ClientAccessReason';
import { RepositoryParams } from '@services/api';
import ClientAccessReasonAPICaller, {
  ClientAccessReasonFilters,
  clientAccessActionMap,
} from '@services/api/clientAccessReason';

import { ClientAccessReasonForm } from './ClientAccessReasonForm';

export default function ClientAccessReasonPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<{
    total: number;
    result: ClientAccessReason[];
  }>({
    total: 0,
    result: [],
  });
  const [filters, setFilters] = useState<ClientAccessReasonFilters>({
    order: '-timesUsed',
  });

  const [editId, setEditId] = useState('');
  const [isOpenModal, toggleModal] = useState(false);
  const confirmation = useConfirmationModal();
  const navigate = useNavigate();
  const { hasPermission } = usePermissionContext();

  const list = (filters: RepositoryParams) => {
    setIsLoading(true);
    ClientAccessReasonAPICaller.list(filters)
      .then((values) => setData(values))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    list(filters);
  }, [filters]);

  const deleteClientAccessReason = (id: string) => {
    confirmation({
      title: 'Excluir motivo de alteração de acesso',
      confirmButtonTitle: 'Excluir',
      description:
        'Deseja realmente excluir esse motivo de alteração de acesso?',
      onSubmit: () =>
        ClientAccessReasonAPICaller.remove(id).then((_) => list(filters)),
    });
  };

  return (
    <>
      <div className="p-s-200">
        <PageHeader
          title="Motivos de alteração de acesso"
          actions={
            <Restricted context="clientAccessReasons" resource="create">
              <Button
                onClick={() => {
                  toggleModal(true);
                }}
                weight="bold"
                prefixes={<Icon>add</Icon>}
              >
                Cadastrar
              </Button>
            </Restricted>
          }
        />
      </div>

      <Table
        isLoading={isLoading}
        hoverable
        totalRecords={data.total}
        data={data.result}
        withPagination
        withSearchInput
        filters={filters}
        className="pointer"
        onRowClick={(row) => {
          if (hasPermission('clientAccessReasons', 'read')) {
            navigate(`/client_access_reasons/${row.id}`);
          }
        }}
        setFilters={setFilters}
      >
        <TableColumn fromKey="name" header="Nome" />
        <TableColumn
          fromKey="clientAccessAction"
          header="Ação"
          render={(clientAccessAction) => {
            let color = 'red';

            if (clientAccessAction === clientAccessActionMap['GRANT']) {
              color = 'green';
            }

            return <span style={{ color: color }}>{clientAccessAction}</span>;
          }}
        />
        <TableColumn fromKey="timesUsed" header="Vezes usado" />
        <TableColumn
          fromKey="active"
          header="Status"
          render={(active: boolean) => <StatusChip active={active} />}
        />
        <TableColumn
          width="6%"
          fromKey="id"
          header="Ações"
          disableSorting
          render={(id) => {
            return (
              <PopOver
                fixedContent={<Icon>more_horiz</Icon>}
                togglableContent={
                  <PopOverMenu
                    menu={[
                      {
                        onClick: () => {
                          navigate(`/client_access_reasons/${id}`);
                        },
                        show: hasPermission('clientAccessReasons', 'read'),
                        content: 'Acessar',
                      },
                      {
                        onClick: () => {
                          setEditId(id);
                          toggleModal(true);
                        },
                        show: hasPermission('clientAccessReasons', 'update'),
                        content: 'Editar',
                      },
                      {
                        onClick: () => {
                          deleteClientAccessReason(id);
                        },
                        content: 'Excluir',
                        show: hasPermission('clientAccessReasons', 'delete'),
                      },
                    ]}
                  />
                }
              />
            );
          }}
        />
      </Table>

      <Modal
        isOpen={isOpenModal}
        onClickAway={() => {
          setEditId('');
          toggleModal(false);
        }}
      >
        <ClientAccessReasonForm
          editId={editId}
          onSave={() => list(filters)}
          closeModal={() => {
            setEditId('');
            toggleModal(false);
          }}
        />
      </Modal>
    </>
  );
}
