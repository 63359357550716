import { useForm } from 'react-hook-form';
import { useState } from 'react';

import {
  Button,
  Checkbox,
  DatePickerInput,
  Input,
  InputTextArea,
  SelectInput as Select,
} from '@components';
import NotificationAPICaller from '@services/api/notification';
import {
  NotificationRules,
  NotificationSegments,
  NotificationStatus,
} from '@interfaces/Notification';
import { toValueLabel } from 'utils/object';
import { formHourPattern } from '@validations/hour';
import { UserClientsGender } from '@interfaces/UserClient';
import { SelectOptions } from '@interfaces/Utils';
import StatesAPICaller from '@services/api/states';
import RegionAPICaller from '@services/api/regions';

interface Props {
  closeModal: () => void;
  onSave: () => void;
  editId?: string;
}

export default function NotificationForm(props: Props) {
  const { createOrUpdate, fetch } = NotificationAPICaller;
  const [states, setStates] = useState<
    (SelectOptions & { abbreviation: string; name: string })[]
  >([]);
  const [cities, setCities] = useState<(SelectOptions | string)[]>([]);
  const [district, setDistrict] = useState<(SelectOptions | string)[]>([]);

  const {
    register,
    setError,
    watch,
    setFocus,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting, isLoading },
  } = useForm({
    defaultValues: async () => fetchData(),
  });

  const fetchData = async () => {
    fetchStates();
    if (props.editId) {
      const data = await fetch(props.editId);

      return data;
    }

    return {
      status: toValueLabel(NotificationStatus.created),
    };
  };

  const fetchStates = async () => {
    const statesResponse = await StatesAPICaller.fetchStates();
    setStates(statesResponse);

    return states;
  };

  const fetchStateCities = async (stateName: string) => {
    setFocus('city');
    const cities = await StatesAPICaller.fetchStateCities(stateName);
    setCities(cities);

    return cities;
  };

  const fetchDistrict = async (state: string, city: string) => {
    setFocus('district');
    const result = await RegionAPICaller.all({
      state,
      city,
    });

    setDistrict(result.map((region: { district: string }) => region.district));

    return result;
  };

  return (
    <div className="container p-s-200">
      <div className="modal-title">
        <h3>{props.editId ? 'Editar ' : 'Cadastrar '}notificação</h3>
      </div>
      <form
        className="form-max-height"
        autoComplete="off"
        onSubmit={handleSubmit((data) => {
          return createOrUpdate(data, setError).then((res) => {
            if (res.data.errors) return;
            props.closeModal();
            props.onSave();
          });
        })}
      >
        <div className="row grid-gap-1">
          <div className="col-md-12">
            <Input
              disabled={isLoading}
              error={!!errors.title}
              caption={errors.title?.message as string}
              label="Título"
              placeholder="Título"
              form={register('title', { required: 'Obrigatório' })}
            />
          </div>
          <div className="col-md-12">
            <InputTextArea
              disabled={isLoading}
              emojiPicker
              error={!!errors.message}
              caption={errors.message?.message as string}
              label="Mensagem"
              placeholder="Mensagem da notificação"
              form={register('message', { required: 'Obrigatório' })}
              onEmojiClick={(emoji) => {
                setValue('message', watch('message') + emoji);
              }}
            />
          </div>

          <div className="col-md-6">
            <DatePickerInput
              position="top"
              disabled={isLoading}
              error={!!errors.dispatchDate}
              caption={errors.dispatchDate?.message as string}
              label="Data"
              placeholder="Data de envio"
              dateSelected={watch('dispatchDate')}
              onSelect={(date) => setValue('dispatchDate', date)}
              form={register('dispatchDate', { required: 'Obrigatório' })}
            />
          </div>

          <div className="col-md-6">
            <Input
              mask="hour"
              disabled={isLoading}
              error={!!errors.hours}
              caption={errors.hours?.message as string}
              label="Hora"
              placeholder="Ex:. 07:00"
              form={register('hour', {
                required: 'Obrigatório',
                ...formHourPattern,
              })}
            />
          </div>

          <div className="col-md-6">
            <Select
              value={watch('status')}
              placeholder="Status da notificação"
              disabled={true}
              selectProps={{
                menuPlacement: 'top',
              }}
              options={Object.values(NotificationStatus)}
              onSelect={(value) => setValue('status', value)}
              form={register('status', { required: 'Obrigatório' })}
              error={!!errors.status}
              caption={errors.status?.message as string}
              label="Status"
            />
          </div>

          <div className="col-md-12">
            <h4>Segmentação</h4>
          </div>

          <div className="col-md-6">
            <Select
              value={watch('segments')}
              placeholder="Segmento da notificação"
              disabled={isLoading}
              selectProps={{
                menuPlacement: 'top',
              }}
              options={Object.values(NotificationSegments)}
              onSelect={(value) => setValue('segments', value)}
              form={register('segments')}
              error={!!errors.segments}
              isClearable
              caption={errors.segments?.message as string}
              label="Tipo de segmento"
            />
          </div>

          <div className="col-md-12">
            <h5>Regras de notificação</h5>
          </div>

          <div className="col-md-12">
            <div className="row grid-gap-1">
              <div className="col-md-12 d-flex align-items-center">
                <Checkbox
                  label="Qtd de utilizações"
                  value={watch('voucherUsedTimes')}
                  form={register('voucherUsedTimes', { value: false })}
                />
              </div>
              <div className="col-md-4">
                <Select
                  value={watch('rules')}
                  isClearable
                  placeholder="Escolher regra"
                  disabled={isLoading || !watch('voucherUsedTimes')}
                  selectProps={{
                    menuPlacement: 'top',
                  }}
                  options={Object.values(NotificationRules)}
                  onSelect={(value) => setValue('rules', value)}
                  form={register('rules', {
                    required: watch('voucherUsedTimes') && 'Obrigatório',
                  })}
                  error={!!errors.rules}
                  caption={errors.rules?.message as string}
                />
              </div>
              <div className="col-md-4">
                <Input
                  placeholder="Quantidade"
                  mask="onlyNumbers"
                  disabled={isLoading || !watch('rules')}
                  form={register('qtt', {
                    required: watch('rules') && 'Obrigatório',
                  })}
                  error={!!errors.qtt}
                  caption={errors.qtt?.message as string}
                />
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="row grid-gap-1">
              <div className="col-md-3 d-flex align-items-center">
                <Checkbox
                  label="Gênero"
                  value={watch('genderCheck')}
                  form={register('genderCheck', {
                    onChange: () => setValue('gender', undefined),
                  })}
                />
              </div>
              <div className="col-md-4">
                <Select
                  value={watch('gender')}
                  placeholder="Escolher gênero"
                  disabled={isLoading || !watch('genderCheck')}
                  selectProps={{
                    menuPlacement: 'top',
                  }}
                  options={Object.values(UserClientsGender)}
                  onSelect={(value) => setValue('gender', value)}
                  form={register('gender', {
                    required: watch('genderCheck') && 'Obrigatório',
                  })}
                  error={!!errors.gender}
                  isClearable
                  caption={errors.gender?.message as string}
                />
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="row grid-gap-1">
              <div className="col-md-12 d-flex align-items-center">
                <Checkbox
                  label="Aniversariantes do dia"
                  value={watch('birthdayPeople')}
                  form={register('birthdayPeople')}
                />
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="row grid-gap-1">
              <div className="col-md-12 d-flex align-items-center">
                <Checkbox
                  label="Por localização"
                  disabled={
                    isLoading ||
                    watch('segments')?.value === NotificationSegments.freeUsers
                  }
                  value={watch('location')}
                  form={register('location')}
                />
              </div>

              <div className="col-md-3">
                <Select
                  value={watch('state')}
                  placeholder="Estado"
                  disabled={isLoading || !watch('location')}
                  isSearchable
                  selectProps={{
                    menuPlacement: 'top',
                  }}
                  options={states}
                  onSelect={(value: { label: string }) => {
                    setValue('state', value);
                    if (value?.label) {
                      setValue('city', '');
                      fetchStateCities(value?.label);
                    }
                  }}
                  form={register('state', {
                    required: watch('location') && 'Obrigatório',
                  })}
                  error={!!errors.state}
                  caption={errors.state?.message as string}
                  fromKey="name"
                />
              </div>
              <div className="col-md-4">
                <Select
                  isSearchable
                  value={watch('city')}
                  placeholder="Cidade"
                  disabled={isLoading || !watch('state')}
                  options={cities}
                  selectProps={{
                    menuPlacement: 'top',
                  }}
                  onSelect={(value) => {
                    fetchDistrict(watch('state').label, value.label);
                    setValue('city', value);
                  }}
                  form={register('city', {
                    required: watch('location') && 'Obrigatório',
                  })}
                  error={!!errors.city}
                  caption={errors.city?.message as string}
                />
              </div>

              <div className="col-md-5">
                <Select
                  isSearchable
                  value={watch('district')}
                  placeholder="Bairro"
                  disabled={isLoading || !watch('city')}
                  options={district as SelectOptions[]}
                  selectProps={{
                    menuPlacement: 'top',
                  }}
                  onSelect={(value) => {
                    setValue('district', value);
                  }}
                  form={register('district', {
                    required: watch('location') && 'Obrigatório',
                  })}
                  error={!!errors.district}
                  caption={errors.district?.message as string}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-end pt-s-400" style={{ gap: 16 }}>
          <Button design="transparent" onClick={props.closeModal}>
            Cancelar
          </Button>
          <Button isLoading={isSubmitting || isLoading} type="submit">
            Salvar
          </Button>
        </div>
      </form>
    </div>
  );
}
