import { FieldValues, UseFormSetError } from 'react-hook-form';

import { handleErrorForm } from '@services/api';
import { Category } from '@interfaces/Category';

import {
  CategoryFilters,
  create,
  excludeGroup,
  includeGroup,
  list,
  listAll,
  remove,
  retrieve,
  update,
  updateOrders,
} from './calls';

export * from './calls';

export default class CategoryAPICaller {
  static adaptFromAPI = (data: Category) => data as FieldValues;
  static adaptToAPI = (data: FieldValues) => data as Category;

  static list = async (filters: CategoryFilters) => {
    const { data } = await list(filters);
    return data.categories;
  };

  static all = async () => {
    const { data } = await listAll();
    return data.categories;
  };

  static fetch = async (editId: string) => {
    const { data } = await retrieve(editId);
    return this.adaptFromAPI(data.category);
  };

  static createOrUpdate = async (
    data: FieldValues,
    setError: UseFormSetError<FieldValues>
  ) => {
    const method = data.id ? update : create;

    const result = method(this.adaptToAPI(data)).catch(
      handleErrorForm(setError)
    );

    return result;
  };

  static updateOrders = async (ids: string[]) => {
    return await updateOrders(ids);
  };

  static remove = async (id: string) => {
    const { data } = await remove(id);

    return data;
  };

  static includeGroup = async (categoryId: string, groupId: string) => {
    const { data } = await includeGroup(categoryId, groupId);

    return data;
  };

  static excludeGroup = async (categoryId: string, groupId: string) => {
    const { data } = await excludeGroup(categoryId, groupId);

    return data;
  };
}
