import { useEffect, useState } from 'react';

import { Table, TableColumn } from '@components';
import { UserClient } from '@interfaces/UserClient';
import UserClientsAPICaller, {
  UserClientsFilters,
} from '@services/api/userClients';

interface Props {
  closeModal: () => void;
  userClientUUID: string;
}

export default function HistoryPlan(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<{ total: number; result: UserClient[] }>({
    total: 0,
    result: [],
  });
  const [filters, setFilters] = useState<UserClientsFilters>({
    order: '-createdAt',
  });

  const list = (filters: UserClientsFilters, userClientUUID: string) => {
    setIsLoading(true);
    UserClientsAPICaller.fetchPlanHistory(filters, userClientUUID)
      .then((values) => setData(values))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    list(filters, props.userClientUUID);
  }, [filters, props.userClientUUID]);

  return (
    <div className="container p-s-200" style={{ minHeight: '50vh' }}>
      <div className="modal-title">
        <h3>Histórico de planos</h3>
      </div>
      <Table
        isLoading={isLoading}
        hoverable
        showColumnSelector={false}
        totalRecords={data.total}
        data={data.result}
        withPagination
        withSearchInput
        filters={filters}
        setFilters={setFilters}
      >
        <TableColumn
          fromKey="planName"
          filters={filters}
          filterType="text"
          setFilters={setFilters}
          header="Nome"
        />
        <TableColumn
          fromKey="reason"
          header="Motivo"
          filters={filters}
          filterType="text"
          setFilters={setFilters}
        />
        <TableColumn fromKey="action" header="Ação" />
        <TableColumn fromKey="createdAt" header="Criado Em" />
      </Table>
    </div>
  );
}
