import { Plan } from './Plan';
import { SelectOptions } from './Utils';

export interface UserClient {
  id?: String;
  name?: string;
  phone?: string;
  email?: string;
  password?: string;
  imageUrl?: string;
  userClientStatus?: string | SelectOptions;
  gender: string | SelectOptions;
  dateOfBirth?: string;
  inactivationDate?: string;
  cpf?: string;
  hasPhysicalTour?: boolean;
  plans?: Plan[];
  vouchersUsed?: any[];
  vouchersFavorited?: any[];
  mobileDevice?: MobileDevice;
  mobileDeviceId?: string;
}

export enum UserClientsStatus {
  active = 'Ativo',
  inactive = 'Inativo',
}

export enum UserClientsGender {
  male = 'Masculino',
  female = 'Feminino',
  ratherNotTell = 'Prefiro não dizer',
}

type MobileDevice = {
  id?: string;
  deviceModel?: string;
  appVersion?: string;
  operationalSystem?: string;
  operationalSystemVersion?: string;
};
