import '@styles/index.scss';

import { useForm } from 'react-hook-form';

import {
  Icon,
  MainContent,
  Sidebar,
  SidebarButton,
  SidebarSeparator,
  SidebarTitle,
  Switch,
} from '@components';

import Aquario from '../features/Aquario';

export default function HomePage() {
  const { watch, register } = useForm({});

  return (
    <div className="App">
      <header className="App-header">
        <div className="header--left">
          <Switch form={register('collapsed')} label="Collapse sidebar" />
        </div>
        <div className="header--center">
          <img width="25" className="App-logo" alt="logo" />
        </div>
        <div className="header--right">
          <img width="270" className="App-logo" alt="logo" />
        </div>
      </header>
      <Sidebar collapsed={watch('collapsed')} className="bg-primary">
        <SidebarButton active label="Home" icon={<Icon>home</Icon>} />
        <SidebarSeparator />
        <SidebarTitle>Users</SidebarTitle>
        <SidebarButton label="Users" icon={<Icon>person</Icon>} />
        <SidebarButton label="Acess Groups" icon={<Icon>hub</Icon>} />
      </Sidebar>
      <MainContent collapsed={watch('collapsed')}>
        <Aquario />
      </MainContent>
    </div>
  );
}
