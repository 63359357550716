import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import { Permissions } from '@interfaces/Permissions';
import { ApiResponse, api } from '@services/api';
import MeAPICaller from '@services/api/me';
import {
  UserEstablishmentAccess,
  UserEstablishmentUnitAccess,
  UserType,
} from '@interfaces/User';
import FixedPermissionAPICaller from '@services/api/fixedPermission';
import { isEmpty } from 'utils/object';

type EstablishmentPermission = {
  context: string;
  resource?: string;
  establishmentUUID?: string;
  unitUUID?: string;
};

export type TPermissionContext = {
  permissions: Permissions;
  loading: boolean;
  hasPermission: (context: string, resource?: string) => boolean;
  hasPermissionEstablishment: ({
    context,
    resource,
    establishmentUUID,
    unitUUID,
  }: EstablishmentPermission) => boolean;
  handleDynamicPermission: ({
    context,
    resource,
    establishmentUUID,
    unitUUID,
  }: EstablishmentPermission) => boolean;
  refetch: () => Promise<void | ApiResponse>;
  unitAccess: UserEstablishmentUnitAccess[];
  establishmentAccess: UserEstablishmentAccess[];
};

export const PermissionContext = createContext<TPermissionContext>({
  permissions: {},
  loading: false,
  hasPermission: () => true,
  hasPermissionEstablishment: () => true,
  handleDynamicPermission: () => true,
  refetch: () => Promise.resolve(),
  unitAccess: [],
  establishmentAccess: [],
});

type Props = {
  context?: TPermissionContext;
};

export function PermissionProvider({
  children,
  context,
}: PropsWithChildren<Props>) {
  const [permissions, setPermissions] = useState<Permissions>({});
  const [admin, setAdmin] = useState(false);
  const [userType, setUserType] = useState(UserType.Global);
  const [loading, setLoading] = useState(true);
  const [unitAccess, setUnitAccess] = useState<
    Array<UserEstablishmentUnitAccess>
  >([]);
  const [establishmentAccess, setEstablishmentAccess] = useState<
    Array<UserEstablishmentAccess>
  >([]);

  const location = useLocation();

  const authorization = api.defaults.headers.common.Authorization;

  const handleRefetchProfile = useCallback(async () => {
    if (!authorization) return;

    const {
      admin,
      accessGroup,
      userType: type,
      establishmentAccess,
      establishmentUnitAccess,
    } = await MeAPICaller.fetchMe();

    setUserType(type);

    let permissions: Permissions = {};

    if (type === UserType.Global) {
      permissions = accessGroup?.permissions ?? {};
    } else if (type === UserType.Establishment) {
      setEstablishmentAccess(establishmentAccess || []);
      setUnitAccess(establishmentUnitAccess || []);
      const data = await FixedPermissionAPICaller.retrieve({
        shouldAdapt: false,
      });

      permissions = { ...data.permissions };
    }

    if (permissions) {
      setPermissions(permissions);
    }

    setAdmin(Boolean(admin));
  }, [authorization]);

  useEffect(() => {
    handleRefetchProfile().then(() => setLoading(false));
  }, [authorization, handleRefetchProfile, location]);

  const handleHasPermission = (context: string, resource: string = 'read') => {
    if (admin) return true;
    return validatePermission(context, resource);
  };

  const hasEstablishmentAccess = (establishmentId?: string) => {
    return establishmentAccess.some(
      (e) => e.establishmentId === establishmentId
    );
  };

  const hasEstablishmentUnitAccess = (establishmentUnitId?: string) => {
    const hasAccessFromEstablishment = establishmentAccess.some((e) =>
      e.establishment.units?.some((unit) => unit.id === establishmentUnitId)
    );

    const hasAccessFromUnit = unitAccess.some(
      (e) => e.unitId === establishmentUnitId
    );

    return hasAccessFromEstablishment || hasAccessFromUnit;
  };

  const validatePermission = (context: string, resource: string = 'read') => {
    if (!permissions || isEmpty(permissions)) return false;

    const hasContext = permissions[context];
    if (!hasContext) return false;

    return !!permissions[context][resource];
  };

  const handleHasPermissionEstablishment = ({
    context,
    resource = 'read',
    establishmentUUID,
    unitUUID,
  }: EstablishmentPermission) => {
    if (resource === 'read') return true;

    if (!validatePermission(context, resource)) {
      return false;
    }

    if (establishmentUUID) {
      return hasEstablishmentAccess(establishmentUUID);
    }

    if (unitUUID) {
      return hasEstablishmentUnitAccess(unitUUID);
    }

    return false;
  };

  const handleDynamicPermission = ({
    context,
    resource = 'read',
    establishmentUUID,
    unitUUID,
  }: EstablishmentPermission) => {
    if (userType === UserType.Global) {
      return handleHasPermission(context, resource);
    }

    return handleHasPermissionEstablishment({
      context,
      resource,
      establishmentUUID,
      unitUUID,
    });
  };

  const contextValue = context ?? {
    permissions,
    loading,
    refetch: handleRefetchProfile,
    handleDynamicPermission,
    hasPermissionEstablishment: handleHasPermissionEstablishment,
    hasPermission: handleHasPermission,
    unitAccess,
    establishmentAccess,
  };

  return (
    <PermissionContext.Provider value={contextValue}>
      {children}
    </PermissionContext.Provider>
  );
}

export function usePermissionContext() {
  return useContext(PermissionContext);
}
