export function readFile(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target?.result as string);
    };
    reader.onerror = reject;
    reader.readAsText(file);
  });
}

export function fileToBlobURL(file: File): string {
  return URL.createObjectURL(new Blob([file]));
}

export function objectToFormData(object: { [key: string]: any }) {
  const formData = new FormData();

  for (const key of Object.keys(object)) {
    if (Array.isArray(object[key])) {
      for (const item of object[key]) {
        formData.append(key, item);
      }
    } else {
      formData.append(key, object[key]);
    }
  }

  return formData;
}

export const downloadFile = (data: string, type: string, name: string) => {
  let url = window.URL.createObjectURL(new Blob([data], { type: type }));
  let link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
};
