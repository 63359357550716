import { UserType } from '@interfaces/User';

const userTypeNames = {
  [UserType.Global]: 'Equipe Tour',
  [UserType.Establishment]: 'Estabelecimento',
};

export const userTypeEnumToTypeName = (userType: UserType) => {
  return userTypeNames[userType];
};
