import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import defaultImage from '@assets/defaultImage.png';
import { Button, DataLoader, Modal, PageHeader, Restricted } from '@components';
import { User, UserType } from '@interfaces/User';
import UsersAPICaller from '@services/api/users';
import { establishmentUnitName } from 'utils/establishments';
import { validateUUIDFormat } from 'utils/string/uuid';
import { userTypeEnumToTypeName } from 'utils/users';

import UserPasswordForm from '../UserPasswordForm/UserPasswordForm';
import { SelectedValueDisplayItem } from '../UsersForm/EstablishmentAndUnitsSelect';
import UsersForm from '../UsersForm/UsersForm';

export default function UsersView() {
  const { uuid } = useParams();
  const [data, setData] = useState<User | null>(null);
  const [editId, setEditId] = useState('');
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState('');
  const navigate = useNavigate();

  const fetch = (uuid: string) => {
    UsersAPICaller.fetchUser(uuid).then((values) => setData(values.data.user));
  };

  useEffect(() => {
    if (uuid) {
      if (validateUUIDFormat(uuid)) {
        fetch(uuid);
      } else {
        toast.error('ID informado inválido');
        navigate(-1);
      }
    }
  }, [uuid, navigate]);

  return (
    <>
      <div className="p-s-200">
        <PageHeader backButton title="Usuário" />
        <div className="card card__body p-s-350 bg-white">
          <div className="row">
            <div className="col-md-2 mb-s-150">
              <DataLoader
                data={
                  <img
                    src={data?.imageUrl || defaultImage}
                    alt="logo"
                    height={120}
                    className="border-radius-100"
                  />
                }
                height={120}
                isLoading={!data}
              />
            </div>
            <div className="col-md-10 align-center">
              <div className="col-md-12">
                <h4>Nome</h4>
                <span>
                  <DataLoader data={data?.name} isLoading={!data} />
                </span>
              </div>
              <div className="col-md-12 mt-s-150">
                <h4>Email</h4>
                <span>
                  <DataLoader data={data?.email} isLoading={!data} />
                </span>
              </div>
            </div>

            <div className="col-md-2">
              <h4>Status</h4>
              <div className="row d-flex align-items-center">
                <span>
                  <DataLoader
                    data={data?.active ? 'Ativo' : 'Inativo'}
                    isLoading={!data}
                  />
                </span>
                <div
                  className={`little-ball-${data?.active ? 'green' : 'red'}`}
                />
              </div>
            </div>

            <div className="col-md-6">
              <h4>Tipo do usuário</h4>
              <div className="row d-flex align-items-center">
                <span>
                  <DataLoader
                    data={userTypeEnumToTypeName(data?.userType!)}
                    isLoading={!data}
                  />
                </span>
              </div>
            </div>

            {data?.userType === UserType.Establishment && (
              <div className="col-md-12 mt-s-250">
                <h4>Estabelecimentos vinculados</h4>
                <section>
                  {data?.establishmentAccess?.map(
                    ({ establishment: { name, id } }) => (
                      <SelectedValueDisplayItem
                        key={`establishment-${id}`}
                        name={name}
                        type="establishment"
                        id={id || ''}
                      />
                    )
                  )}
                  {data?.establishmentUnitAccess?.map((unit) => (
                    <SelectedValueDisplayItem
                      key={`unit-${unit.unitId}`}
                      name={establishmentUnitName(unit.unit!)}
                      type="unit"
                      id={unit.unitId || ''}
                    />
                  ))}
                </section>
              </div>
            )}
          </div>

          <DataLoader
            data={
              <div className="row mt-s-300">
                <Restricted context="users" resource="update">
                  <div className="col mr-s-100">
                    <Button
                      onClick={() => setEditId(uuid!)}
                      design="outlined"
                      size="medium"
                    >
                      Editar informações
                    </Button>
                  </div>
                </Restricted>
                <Restricted context="users" resource="updatePassword">
                  <div className="col mr-s-100">
                    <Button
                      onClick={() => setIsPasswordModalOpen(uuid!)}
                      design="outlined"
                      color="negative"
                      size="medium"
                    >
                      Alterar senha
                    </Button>
                  </div>
                </Restricted>
              </div>
            }
            isLoading={!data}
            height={40}
          />
        </div>
      </div>

      {isPasswordModalOpen && (
        <Modal
          isOpen={!!isPasswordModalOpen}
          onClickAway={() => {
            setEditId('');
            setIsPasswordModalOpen('');
          }}
        >
          <UserPasswordForm
            editId={isPasswordModalOpen}
            closeModal={() => {
              setIsPasswordModalOpen('');
            }}
          />
        </Modal>
      )}

      {!!(uuid && editId) && (
        <Modal
          isOpen={!!editId}
          onClickAway={() => {
            setEditId('');
          }}
        >
          <UsersForm
            editId={editId}
            onSave={() => {
              fetch(uuid);
              setEditId('');
            }}
            closeModal={() => {
              setEditId('');
            }}
          />
        </Modal>
      )}
    </>
  );
}
