import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Button,
  DataLoader,
  Modal,
  PageHeader,
  Restricted,
  Table,
  TableColumn,
} from '@components';
import { useConfirmationModal } from '@hooks/useConfirmationModal';
import { ClientAccessReason } from '@interfaces/ClientAccessReason';
import ClientAccessReasonAPICaller, {
  ClientAccessReasonFilters,
} from '@services/api/clientAccessReason';
import { validateUUIDFormat } from 'utils/string/uuid';

import { ClientAccessReasonForm } from '../ClientAccessReasonForm';

export default function ClientAccessReasonsView() {
  const { uuid } = useParams();
  const [filters, setFilters] = useState<
    ClientAccessReasonFilters & { clientAccessReasonId?: string }
  >({});
  const [data, setData] = useState<ClientAccessReason | null>(null);
  const [dataHistory, setDataHistory] = useState<{
    total: number;
    result: ClientAccessReason[];
  }>({
    total: 0,
    result: [],
  });
  const [editId, setEditId] = useState('');
  const navigate = useNavigate();
  const confirmation = useConfirmationModal();

  const fetch = (uuid: string) => {
    ClientAccessReasonAPICaller.fetch(uuid).then((values) =>
      setData(values as ClientAccessReason)
    );
  };

  const fetchHistory = (
    uuid: string,
    filters?: ClientAccessReasonFilters & { clientAccessReasonId?: string }
  ) => {
    filters = {
      ...filters,
      clientAccessReasonId: uuid,
    };

    ClientAccessReasonAPICaller.fetchHistory(filters).then((values) =>
      setDataHistory(values)
    );
  };

  useEffect(() => {
    if (uuid) {
      if (validateUUIDFormat(uuid)) {
        fetch(uuid);
      } else {
        toast.error('ID informado inválido');
        navigate(-1);
      }
    }
  }, [uuid, navigate]);

  useEffect(() => {
    if (uuid) fetchHistory(uuid, filters);
  }, [filters, uuid]);

  const deleteClientAccessReason = (id: string) => {
    confirmation({
      title: 'Excluir motivo de alteração de acesso',
      confirmButtonTitle: 'Excluir',
      description: `Deseja realmente excluir ${data!.name}`,
      onSubmit: () =>
        ClientAccessReasonAPICaller.remove(id).then(() => navigate(-1)),
    });
  };

  return (
    <>
      <div className="p-s-200">
        <PageHeader backButton title="Motivo de alteração de acesso" />
        <div className="card card__body p-s-350 bg-white">
          <div className="row">
            <div className="col-md-6">
              <h4>Nome</h4>
              <span>
                <DataLoader data={data?.name} isLoading={!data} />
              </span>
            </div>

            <div className="col-md-6">
              <h4>Ação</h4>
              <span>
                <DataLoader data={data?.clientAccessAction} isLoading={!data} />
              </span>
            </div>

            <div className="col-md-6 mt-s-250">
              <h4>Vezes usado</h4>
              <span>
                <DataLoader data={data?.timesUsed} isLoading={!data} />
              </span>
            </div>

            <div className="col-md-6  mt-s-250">
              <h4>Status</h4>
              <div className="row d-flex align-items-center">
                <span>{data?.active ? 'Ativo' : 'Inativo'}</span>
                <div
                  className={`little-ball-${data?.active ? 'green' : 'red'}`}
                ></div>
              </div>
            </div>

            <div className="col-md-12 mt-s-350">
              <h4>Usuários que utilizaram a alteração</h4>
              <div
                className="mt-s-150"
                style={{ minHeight: '30vh', overflowY: 'scroll' }}
              >
                <Table
                  hoverable
                  totalRecords={dataHistory.total}
                  data={dataHistory.result}
                  withPagination
                  // withSearchInput
                  showColumnSelector={false}
                  filters={filters}
                  setFilters={setFilters}
                >
                  <TableColumn
                    fromKey="userClient"
                    header="Nome"
                    render={({ name }) => name}
                  />
                  <TableColumn
                    fromKey="userClient"
                    header="E-mail"
                    render={({ email }) => email}
                  />
                  <TableColumn fromKey="createdAt" header="Utilizado em" />
                </Table>
              </div>
            </div>
          </div>

          <DataLoader
            className="mt-s-150"
            data={
              <div className="row mt-s-300">
                <Restricted context="clientAccessReasons" resource="update">
                  <div className="col mr-s-100">
                    <Button
                      onClick={() => setEditId(uuid!)}
                      design="outlined"
                      size="medium"
                    >
                      Editar informações
                    </Button>
                  </div>
                </Restricted>
                <Restricted context="clientAccessReasons" resource="delete">
                  <div className="col mr-s-100">
                    <Button
                      onClick={() => deleteClientAccessReason(uuid!)}
                      design="outlined"
                      size="medium"
                      color="negative"
                    >
                      Excluir motivo de alteração de acesso
                    </Button>
                  </div>
                </Restricted>
              </div>
            }
            height={40}
            isLoading={!data}
          />
        </div>
      </div>

      {!!(uuid && editId) && (
        <Modal
          isOpen={!!editId}
          onClickAway={() => {
            setEditId('');
          }}
        >
          <ClientAccessReasonForm
            editId={editId}
            onSave={() => {
              fetch(uuid);
              setEditId('');
            }}
            closeModal={() => {
              setEditId('');
            }}
          />
        </Modal>
      )}
    </>
  );
}
