import { FieldValues, UseFormSetError } from 'react-hook-form';

import { Advertisement, AdvertisementStatus } from '@interfaces/Advertisement';
import { handleErrorForm } from '@services/api';
import { reverseKeyValueObject, toValueLabel } from 'utils/object';
import { SelectOptions } from '@interfaces/Utils';

import {
  AdvertisementFilters,
  create,
  list,
  remove,
  retrieve,
  update,
} from './calls';

export * from './calls';

export default class AdvertisementAPICaller {
  static adaptFromAPI = (data: Partial<Advertisement>) => {
    const advertisement = {
      ...data,
    };

    advertisement.status =
      AdvertisementStatus[data.status as keyof typeof AdvertisementStatus];

    advertisement.city = toValueLabel(data.city as string);
    advertisement.state = toValueLabel(data.state as string);

    return advertisement as FieldValues;
  };

  static adaptToAPI = (data: FieldValues) => {
    const advertisement = {
      ...data,
    };

    advertisement.status =
      reverseKeyValueObject(AdvertisementStatus)[
        data.status.value || data.status
      ];

    advertisement.city = (data.city as SelectOptions)?.label;
    advertisement.state = (data.state as SelectOptions)?.label;

    return advertisement as Advertisement;
  };

  static list = async (filters: AdvertisementFilters) => {
    const {
      data: { advertisement },
    } = await list(filters);

    return {
      ...advertisement,
      result: advertisement.result.map(this.adaptFromAPI),
    };
  };

  static fetch = async (editId: string) => {
    const { data } = await retrieve(editId);
    return await this.adaptFromAPI(data.advertisement);
  };

  static createOrUpdate = async (
    data: FieldValues,
    setError: UseFormSetError<FieldValues>
  ) => {
    const method = data.id ? update : create;

    const result = method(this.adaptToAPI(data)).catch(
      handleErrorForm(setError)
    );

    return result;
  };

  static remove = async (id: string) => {
    const { data } = await remove(id);

    return data;
  };
}
