import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Icon,
  Modal,
  PageHeader,
  Restricted,
  Table,
  TableColumn,
} from '@components';
import { EstablishmentChip } from '@components/Chip/CustomChips';
import { PopOver, PopOverMenu } from '@components/PopOver';
import { useAuthContext } from '@contexts/AuthProvider';
import { usePermissionContext } from '@contexts/PermissionProvider';
import { useFetchUserEstablishments } from '@hooks/establishment/useFetchUserEstablishments';
import { useConfirmationModal } from '@hooks/useConfirmationModal';
import { Establishment } from '@interfaces/Establishment';
import { RepositoryParams } from '@services/api';
import EstablishmentAPICaller, {
  EstablishmentFilters,
} from '@services/api/establishment';
import defaultImage from '@assets/defaultImage.png';

import { EstablishmentForm } from './EstablishmentForm';

export default function EstablishmentPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<{ total: number; result: Establishment[] }>({
    total: 0,
    result: [],
  });
  const [filters, setFilters] = useState<EstablishmentFilters>({
    order: '-updatedAt',
  });
  const [editId, setEditId] = useState('');
  const [isOpenModal, toggleModal] = useState(false);
  const confirmation = useConfirmationModal();

  const navigate = useNavigate();
  const { handleDynamicPermission, hasPermission } = usePermissionContext();
  const { user, userIsEstablishment } = useAuthContext();

  const {
    fetchUserEstablishments,
    userEstablishments,
    isFetchingUserEstablishments,
    userEstablishmentsTotal,
  } = useFetchUserEstablishments();

  const list = useCallback(
    (filters: RepositoryParams) => {
      setIsLoading(true);
      if (userIsEstablishment && user?.id) {
        fetchUserEstablishments(user.id);
      } else {
        EstablishmentAPICaller.list(filters)
          .then((values) => {
            setData(values);
          })
          .finally(() => setIsLoading(false));
      }
    },
    [fetchUserEstablishments, userIsEstablishment, user]
  );

  const deleteEstablishment = (id: string) => {
    confirmation({
      title: 'Excluir estabelecimento',
      description: 'Deseja realmente excluir esse estabelecimento?',
      confirmButtonTitle: 'Excluir',
      onSubmit: () =>
        EstablishmentAPICaller.remove(id).then((_) => list(filters)),
    });
  };

  useEffect(() => {
    list(filters);
  }, [filters, list]);

  useEffect(() => {
    if (!userIsEstablishment) {
      return;
    }

    setData({
      total: userEstablishmentsTotal,
      result: userEstablishments as Establishment[],
    });
    setIsLoading(isFetchingUserEstablishments);
  }, [
    userEstablishments,
    userEstablishmentsTotal,
    isFetchingUserEstablishments,
    userIsEstablishment,
  ]);

  return (
    <>
      <div className="p-s-200">
        <PageHeader
          title="Estabelecimento"
          actions={
            <Restricted context="establishments" resource="create">
              <Button
                onClick={() => {
                  toggleModal(true);
                }}
                weight="bold"
                prefixes={<Icon>add</Icon>}
              >
                Cadastrar
              </Button>
            </Restricted>
          }
        />
      </div>

      <Table
        isLoading={isLoading}
        hoverable
        totalRecords={data.total}
        data={data.result}
        showColumnSelector={!userIsEstablishment}
        withPagination={!userIsEstablishment}
        withSearchInput={!userIsEstablishment}
        filters={userIsEstablishment ? undefined : filters}
        setFilters={userIsEstablishment ? undefined : setFilters}
        className="pointer"
        onRowClick={(row) => {
          const hasPermission = handleDynamicPermission({
            context: 'establishments',
            resource: 'read',
            establishmentUUID: row.id,
          });

          if (hasPermission) {
            navigate(`/establishment/${row.id}`);
          }
        }}
      >
        <TableColumn
          fromKey="logoUrl"
          width="75px"
          header=""
          disableSorting
          render={(value) => {
            return (
              <div className="image-cropper" style={{ borderRadius: 8 }}>
                <img
                  alt="establishment avatar"
                  src={`${value || defaultImage}`}
                />
              </div>
            );
          }}
        />
        <TableColumn fromKey="name" header="Nome" />
        <TableColumn ellipsis fromKey="description" header="Descrição" />
        <TableColumn fromKey="updatedAt" header="Atualizado Em" />
        <TableColumn
          fromKey="status"
          header="Status"
          filterType="select"
          render={(status: string) => {
            let color = 'green';

            if (status === 'Inativo') {
              color = 'red';
            } else if (status === 'Temporariamente inativo') {
              color = 'gold';
            }
            return <EstablishmentChip color={color} label={status} />;
          }}
          filterOptions={[
            { label: 'Ativo', value: 'active' },
            { label: 'Inativo', value: 'inactive' },
            { label: 'Temporariamente Inativo', value: 'temporarilyInactive' },
          ]}
        />
        <TableColumn
          width="6%"
          fromKey="id"
          header="Ações"
          disableSorting
          render={(id) => {
            return (
              <PopOver
                fixedContent={<Icon>more_horiz</Icon>}
                togglableContent={
                  <PopOverMenu
                    menu={[
                      {
                        onClick: () => {
                          navigate(`/establishment/${id}`);
                        },
                        show: handleDynamicPermission({
                          context: 'establishments',
                          resource: 'read',
                          establishmentUUID: id,
                        }),
                        content: 'Acessar',
                      },
                      {
                        onClick: () => {
                          setEditId(id);
                          toggleModal(true);
                        },
                        show: handleDynamicPermission({
                          context: 'establishments',
                          resource: 'update',
                          establishmentUUID: id,
                        }),
                        content: 'Editar',
                      },
                      {
                        onClick: () => {
                          deleteEstablishment(id);
                        },
                        content: 'Excluir',
                        show: hasPermission('establishments', 'delete'),
                      },
                    ]}
                  />
                }
              />
            );
          }}
        />
      </Table>

      <Modal
        isOpen={isOpenModal}
        onClickAway={() => {
          setEditId('');
          toggleModal(false);
        }}
      >
        <EstablishmentForm
          editId={editId}
          onSave={(id: string) => {
            if (!editId) {
              navigate(`/establishment/${id}?new_unit=true`);
            } else {
              navigate(`/establishment/${id}`);
            }
          }}
          closeModal={() => {
            setEditId('');
            toggleModal(false);
          }}
        />
      </Modal>
    </>
  );
}
