import { ObjectWithNonNullableKey } from 'utils/object';

import { AccessGroup } from './AccessGroup';
import { Establishment } from './Establishment';
import { EstablishmentUnit } from './EstablishmentUnit';

export const enum UserType {
  Global = 'global',
  Establishment = 'establishment',
}
export interface UserEstablishmentUnitAccess {
  userId: string;
  unit?: EstablishmentUnit;
  unitId: string;
}

export interface UserEstablishmentAccess {
  userId: string;
  establishmentId: string;
  establishment: Establishment;
}

export interface User {
  id?: string | null;
  accessGroupId?: string;
  accessGroup?: Partial<AccessGroup>;
  admin?: boolean;
  imageUrl?: string;
  email: string;
  name: string;
  active: boolean;
  userType: UserType;
  establishmentAccess?: Array<UserEstablishmentAccess>;
  establishmentUnitAccess?: Array<UserEstablishmentUnitAccess>;
}

export type UserWithId = ObjectWithNonNullableKey<User, 'id'>;
