import { Plan } from './Plan';
import { SelectOptions } from './Utils';

export const AdvertisementStatus = {
  active: 'Ativo',
  inactive: 'Inativo',
} as const;

export interface Advertisement {
  id?: string;
  name: string;
  status: string | SelectOptions;
  cover: string | File;
  link: string;
  state: string | SelectOptions;
  city: string | SelectOptions;
  plans: Plan[];
  clicks?: number;
}
