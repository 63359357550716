import { useCallback, useEffect, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import {
  Button,
  Icon,
  Input,
  InputAddon,
  Restricted,
  Table,
  TableColumn,
} from '@components';
import { User } from '@interfaces/User';
import AccountSVG from '@assets/icons/account.svg';
import AccessGroupAPICaller from '@services/api/accessGroups';
import UsersAPICaller from '@services/api/users';

type Props = {
  groupName: string;
  groupId: string;
  onUpdate: () => void;
};

export default function AccessUsersTable(props: Props) {
  const [usersFromGroup, setUsersFromGroup] = useState<User[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [isAddingUser, setIsAddingUser] = useState(false);
  const { watch, register, setValue, handleSubmit } = useForm();
  const listUsersByQuery = watch('userName');

  const fetchUsersFromGroup = useCallback(async () => {
    const group = await AccessGroupAPICaller.fetchGroup(props.groupId);
    setUsersFromGroup(group.users);
  }, [props.groupId]);

  const fetchUsers = useCallback(async () => {
    const response = await UsersAPICaller.fetchUsers({
      q: listUsersByQuery,
      accessGroupId: `not:${props.groupId}`,
      size: 5,
    });

    setUsers(response.result);
  }, [listUsersByQuery, props.groupId]);

  useEffect(() => {
    fetchUsersFromGroup();
  }, [fetchUsersFromGroup]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const onSubmit = (async (data: { userId: string }) => {
    setValue('userId', '');
    setValue('userName', '');
    if (!data.userId) return;
    setIsAddingUser(true);
    await UsersAPICaller.setUserGroup(data.userId, props.groupId).finally(() =>
      setIsAddingUser(false)
    );
    await fetchUsersFromGroup();
    await fetchUsers();
    props.onUpdate();
  }) as SubmitHandler<FieldValues>;

  return (
    <div className="p-s-200">
      <h4 className="mb-s-200">
        Usuários do grupo de acesso (<span>{props.groupName}</span>)
      </h4>
      <Restricted context="users" resource="groups">
        <form
          autoComplete="off"
          className="col-sm-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input
            options={users.map((user) => user.name)}
            onOptionChange={(index) => {
              const user = users[index];
              if (!user) return;
              setValue('userName', user.name);
              setValue('userId', user.id);
            }}
            placeholder="Pesquisar usuário"
            form={register('userName')}
            suffixes={
              <InputAddon right>
                <Button
                  isLoading={isAddingUser}
                  type="submit"
                  weight="bold"
                  design="outlined"
                  prefixes={<Icon>group_add</Icon>}
                >
                  Adicionar
                </Button>
              </InputAddon>
            }
          />
        </form>
      </Restricted>
      <Table data={usersFromGroup} showColumnSelector={false}>
        <TableColumn
          fromKey="imageUrl"
          width="75px"
          header=""
          disableSorting
          render={(value) => {
            return (
              <div className="image-cropper border-radius-350">
                <img alt="user avatar" src={`${value || AccountSVG}`} />
              </div>
            );
          }}
        />
        <TableColumn
          key={`${props.groupId}-name`}
          header="Nome"
          width="15%"
          fromKey="name"
          container
          ellipsis
          cellAlign="start"
        />
        <TableColumn
          key={`${props.groupId}-email`}
          header="Email"
          fromKey="email"
          container
          ellipsis
          cellAlign="start"
        />
        <TableColumn
          key={`${props.groupId}-actions`}
          header="Ações"
          disableSorting
          width="6%"
          container
          cellAlign="end"
          fromKey="id"
          render={(userId) => (
            <div className="d-flex">
              <Restricted context="users" resource="groups">
                <Button
                  weight="bold"
                  design="transparent"
                  prefixes={<Icon>delete</Icon>}
                  onClick={async () => {
                    await UsersAPICaller.removeUserGroup(userId);
                    props.onUpdate();
                    await fetchUsersFromGroup();
                    await fetchUsers();
                  }}
                />
              </Restricted>
            </div>
          )}
        />
      </Table>
    </div>
  );
}
