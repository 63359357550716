import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Icon,
  Modal,
  PageHeader,
  Restricted,
  StatusChip,
  Table,
  TableColumn,
} from '@components';
import { PopOver, PopOverMenu } from '@components/PopOver';
import { usePermissionContext } from '@contexts/PermissionProvider';
import { useConfirmationModal } from '@hooks/useConfirmationModal';
import { Advertisement } from '@interfaces/Advertisement';
import { RepositoryParams } from '@services/api';
import advertisementAPICaller, {
  AdvertisementFilters,
} from '@services/api/advertisement';

import { AdvertisementForm } from './AdvertisementForm';

export default function AdvertisementPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<{ total: number; result: Advertisement[] }>({
    total: 0,
    result: [],
  });
  const [filters, setFilters] = useState<AdvertisementFilters>({
    order: '-plans.endsAt,-clicks',
    joins: ['plans'],
  });
  const [editId, setEditId] = useState('');
  const [isOpenModal, toggleModal] = useState(false);
  const confirmation = useConfirmationModal();

  const { hasPermission } = usePermissionContext();

  const navigate = useNavigate();

  const list = useCallback((filters: RepositoryParams) => {
    setIsLoading(true);

    advertisementAPICaller
      .list(filters)
      .then((values) => setData(values))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    list(filters);
  }, [filters, list]);

  const deleteAdvertisement = (id: string) => {
    confirmation({
      title: 'Excluir Anúncio',
      confirmButtonTitle: 'Excluir',
      description: 'Deseja realmente excluir esse registro?',
      onSubmit: () =>
        advertisementAPICaller.remove(id).then((_) => list(filters)),
    });
  };

  return (
    <>
      <div className="p-s-200">
        <PageHeader
          title="Anúncios"
          actions={
            <>
              <Restricted context="advertisement" resource="create">
                <Button
                  onClick={() => {
                    toggleModal(true);
                  }}
                  weight="bold"
                  prefixes={<Icon>add</Icon>}
                >
                  Cadastrar
                </Button>
              </Restricted>
            </>
          }
        />
      </div>

      <Table
        isLoading={isLoading}
        hoverable
        totalRecords={data.total}
        data={data.result}
        withPagination
        withSearchInput
        filters={filters}
        setFilters={setFilters}
        className="pointer"
        onRowClick={(row) => {
          if (hasPermission('advertisement', 'read')) {
            navigate(`/advertisement/${row.id}`);
          }
        }}
      >
        <TableColumn fromKey="name" header="Nome" />
        <TableColumn fromKey="clicks" header="Cliques" />
        <TableColumn fromKey="link" header="Link" ellipsis />
        <TableColumn
          fromKey="status"
          header="Status"
          render={(status: string) => (
            <StatusChip active={status === 'Ativo'} />
          )}
        />
        <TableColumn
          fromKey="plans"
          disableSorting
          header="Plano"
          render={(plans) => {
            if (Array.isArray(plans)) {
              return plans.map((it) => it.name).join(', ');
            }

            return plans?.name;
          }}
        />
        <TableColumn
          width="6%"
          fromKey="id"
          header="Ações"
          disableSorting
          headerAlign="center"
          render={(id) => {
            return (
              <div className="d-flex mr-s-200">
                <PopOver
                  fixedContent={<Icon>more_horiz</Icon>}
                  togglableContent={
                    <PopOverMenu
                      menu={[
                        {
                          onClick: () => {
                            navigate(`/advertisement/${id}`);
                          },
                          show: hasPermission('advertisement', 'read'),
                          content: 'Acessar',
                        },
                        {
                          onClick: () => {
                            setEditId(id);
                            toggleModal(true);
                          },
                          show: hasPermission('advertisement', 'update'),
                          content: 'Editar',
                        },
                        {
                          onClick: () => {
                            deleteAdvertisement(id);
                          },
                          content: 'Excluir',
                          show: hasPermission('advertisement', 'delete'),
                        },
                      ]}
                    />
                  }
                />
              </div>
            );
          }}
        />
      </Table>

      <Modal
        isOpen={isOpenModal}
        onClickAway={() => {
          setEditId('');
          toggleModal(false);
        }}
      >
        <AdvertisementForm
          editId={editId}
          onSave={() => list(filters)}
          closeModal={() => {
            setEditId('');
            toggleModal(false);
          }}
        />
      </Modal>
    </>
  );
}
