import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Icon,
  Modal,
  PopOver,
  PopOverMenu,
  Restricted,
  StatusChip,
  Table,
  TableColumn,
} from '@components';
import { usePermissionContext } from '@contexts/PermissionProvider';
import { Plan } from '@interfaces/Plan';
import UserClientsAPICaller from '@services/api/userClients';
import { encodeDate } from 'utils/string/dates';

import { AddPlanForm } from '../AddPlanForm';
import { HistoryPlan } from '../HistoryPlan';
import { RemovePlanForm } from '../RemovePlanForm';

type Props = {
  uuid?: string;
};

export function UserClientsPlans(props: Props) {
  const navigate = useNavigate();
  const [data, setData] = useState<Plan[]>([]);
  const [removePlan, setRemovePlan] = useState<string | null>(null);
  const [addPlan, setAddPlan] = useState<boolean>(false);
  const [viewHistory, setViewHistory] = useState<boolean>(false);

  const { hasPermission } = usePermissionContext();

  const fetchPlans = (uuid: string) => {
    UserClientsAPICaller.fetchPlans(uuid).then((values) => setData(values));
  };

  useEffect(() => {
    if (props.uuid) {
      fetchPlans(props.uuid);
    }
  }, [props.uuid, navigate]);

  return (
    <>
      <div
        className="card card__body p-s-350 bg-white"
        style={{ minHeight: '50vh' }}
      >
        <div className="d-flex justify-end mb-s-250" style={{ gap: 20 }}>
          <Button
            onClick={() => {
              setViewHistory(true);
            }}
            design="outlined"
            weight="bold"
            prefixes={<Icon>list_alt</Icon>}
            className="large-text-button"
          >
            Visualizar histórico
          </Button>
          <Restricted context="userClients" resource="addPlanToUserClient">
            <Button
              onClick={() => {
                setAddPlan(true);
              }}
              weight="bold"
              prefixes={<Icon>add</Icon>}
              className="large-text-button"
            >
              Liberar acesso
            </Button>
          </Restricted>
        </div>
        <div className="row">
          <Table
            data={data || []}
            className="pointer"
            showColumnSelector={false}
            hoverable
          >
            <TableColumn fromKey="name" header="Nome" />
            <TableColumn
              fromKey="endsAt"
              header="Término Vigência"
              render={(date) => {
                const isAfter =
                  new Date(encodeDate(date)).valueOf() >= new Date().valueOf();

                return (
                  <span style={{ color: isAfter ? 'green' : 'red' }}>
                    {date}
                  </span>
                );
              }}
            />
            <TableColumn
              fromKey="currentlyHasAccess"
              header="Possui acesso ao plano?"
              render={(currentlyHasAccess: boolean, { endsAt }) => {
                const isAfter =
                  new Date(encodeDate(endsAt)).valueOf() >=
                  new Date().valueOf();

                return (
                  <StatusChip
                    active={!!currentlyHasAccess && isAfter}
                    label={!!currentlyHasAccess && isAfter ? 'Sim' : 'Não'}
                  />
                );
              }}
            />
            <TableColumn
              fromKey="discountCouponId"
              header="Usou cupom?"
              render={(active: boolean) => (
                <StatusChip active={active} label={active ? 'Sim' : 'Não'} />
              )}
            />
            <TableColumn
              width="6%"
              fromKey="id"
              header="Ações"
              disableSorting
              render={(id, { currentlyHasAccess, discountCouponId }) => {
                return (
                  <>
                    <PopOver
                      fixedContent={<Icon>more_horiz</Icon>}
                      togglableContent={
                        <PopOverMenu
                          menu={[
                            {
                              onClick: () => {
                                navigate(
                                  `/discountCoupons/${discountCouponId}`
                                );
                              },
                              show: !!discountCouponId,
                              content: 'Acessar cupom usado',
                            },
                            {
                              onClick: () => {
                                setRemovePlan(id);
                              },
                              show:
                                currentlyHasAccess &&
                                hasPermission(
                                  'userClients',
                                  'removePlanFromUserClient'
                                ),
                              content: 'Cancelar acesso',
                            },
                          ]}
                        />
                      }
                    />
                  </>
                );
              }}
            />
          </Table>
        </div>
      </div>

      {Boolean(removePlan) && (
        <Modal isOpen={!!removePlan} onClickAway={() => setRemovePlan('')}>
          <RemovePlanForm
            planUUID={removePlan!}
            userClientUUID={props.uuid!}
            onSave={() => {
              fetchPlans(props.uuid!);
            }}
            closeModal={() => setRemovePlan('')}
          />
        </Modal>
      )}

      {Boolean(addPlan) && (
        <Modal isOpen={!!addPlan} onClickAway={() => setAddPlan(false)}>
          <AddPlanForm
            userClientUUID={props.uuid!}
            onSave={() => {
              fetchPlans(props.uuid!);
            }}
            closeModal={() => setAddPlan(false)}
          />
        </Modal>
      )}

      {Boolean(viewHistory) && (
        <Modal
          isOpen={!!viewHistory}
          size="large"
          onClickAway={() => setViewHistory(false)}
        >
          <HistoryPlan
            userClientUUID={props.uuid!}
            closeModal={() => setViewHistory(false)}
          />
        </Modal>
      )}
    </>
  );
}
