import { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Button,
  DataLoader,
  Icon,
  PopOver,
  PopOverMenu,
  StatusChip,
  Table,
  TableColumn,
} from '@components';
import { Filters } from '@components/Table/Table';
import { usePermissionContext } from '@contexts/PermissionProvider';
import { useFetchEntity } from '@hooks/useFetch';
import {
  EstablishmentUnit,
  WeekDaysWithCommemorativeDate,
} from '@interfaces/EstablishmentUnit';
import { UsableTimes, Voucher } from '@interfaces/Voucher';
import VoucherAPICaller from '@services/api/voucher';
import { downloadFile, fileToBlobURL } from '@services/io/file';
import { Substitution } from '@interfaces/Substitution';

interface Props {
  isLoading?: boolean;
  data: { total: number; result: Voucher[] };
  showPlans?: boolean;
  filters?: Filters;
  setFilters?: React.Dispatch<React.SetStateAction<Filters>>;
  setEditId: (editId: string) => void;
  deleteVoucher: (editId: string) => void;
  setIsOpenModal: (isOpen: boolean) => void;
}

export default function VoucherTable(props: Props) {
  const { uuid } = useParams();
  const { handleDynamicPermission } = usePermissionContext();
  const [expandedId, setExpandedId] = useState('');
  const { fetchData, data: voucher } = useFetchEntity(VoucherAPICaller.fetch);

  const fetchQRCode = async (voucherId: string, voucherName: string) => {
    const qrCode = await VoucherAPICaller.fetchQRCode(voucherId);

    return downloadFile(qrCode, 'application/zip', voucherName);
  };

  return (
    <Table
      isLoading={props.isLoading}
      hoverable
      className="pointer"
      totalRecords={props.data.total}
      data={props.data.result}
      withPagination={!!props.filters}
      showColumnSelector={!!props.filters}
      withSearchInput={!!props.filters}
      filters={props.filters}
      setFilters={props.setFilters}
      expandedRow={expandedId}
      onRowClick={(row) => {
        if (expandedId === row.id) {
          setExpandedId('');
        } else {
          setExpandedId(row.id);
          fetchData(row.id);
        }
      }}
      renderExpandedRow={(_) => (
        <div className="container mt-s-200">
          <div className="row grid-gap-1">
            <div className="col-md-4">
              <h4>Código</h4>
              <span>
                <DataLoader
                  data={voucher?.validationCode}
                  isLoading={!Boolean(voucher)}
                />
              </span>
            </div>
            <div className="col-md-4">
              <h4>Nome</h4>
              <span>
                <DataLoader
                  data={voucher?.name}
                  isLoading={!Boolean(voucher)}
                />
              </span>
            </div>
            <div className="col-md-4">
              <h4>Status</h4>
              <div className="row d-flex align-items-center">
                <span>
                  <DataLoader
                    data={voucher?.voucherStatus}
                    isLoading={!Boolean(voucher)}
                  />
                </span>
                <div
                  className={`little-ball-${
                    voucher?.voucherStatus === 'Ativo' ? 'green' : 'red'
                  }`}
                ></div>
              </div>
            </div>

            <div className="col-md-4">
              <h4>Plano</h4>
              <span>
                <DataLoader
                  data={voucher?.plan?.name}
                  isLoading={!Boolean(voucher)}
                />
              </span>
            </div>

            <div className="col-md-4">
              <h4>Categoria</h4>
              <span>
                <DataLoader
                  data={voucher?.category?.name}
                  isLoading={!Boolean(voucher)}
                />
              </span>
            </div>
            <div className="col-md-4">
              <h4>Estabelecimento</h4>
              <span>
                <DataLoader
                  data={voucher?.establishment?.name}
                  isLoading={!Boolean(voucher)}
                />
              </span>
            </div>
            <div className="col-md-12">
              <h4>Unidades</h4>
              <span>
                <DataLoader
                  data={voucher?.establishmentUnits
                    ?.map((it: EstablishmentUnit) => it?.name)
                    .join(', ')}
                  isLoading={!Boolean(voucher)}
                />
              </span>
            </div>

            <div className="col-md-4">
              <h4>Tipo do voucher</h4>
              <span>
                <DataLoader
                  data={voucher?.voucherType?.label}
                  isLoading={!Boolean(voucher)}
                />
              </span>
            </div>
            <div className="col-md-4">
              <h4>Na compra de</h4>
              <span>
                <DataLoader
                  data={voucher?.onPurchaseOf}
                  isLoading={!Boolean(voucher)}
                />
              </span>
            </div>
            <div className="col-md-4">
              <h4>Ganhe</h4>
              <span>
                <DataLoader
                  data={voucher?.reward?.label}
                  isLoading={!Boolean(voucher)}
                />
              </span>
            </div>

            <div className="col-md-12">
              <h4>Regras do estabelecimento</h4>
              <span>
                <DataLoader
                  data={voucher?.establishmentRules}
                  isLoading={!Boolean(voucher)}
                />
              </span>
            </div>

            <div className="col-md-12">
              <h4>Substituições permitidas</h4>
              <span>
                <DataLoader
                  data={voucher?.substitutions
                    ?.map((it: Substitution) => it.name)
                    .join('; ')}
                  isLoading={!Boolean(voucher)}
                />
              </span>
            </div>

            <div className="col-md-12">
              <hr className="pb-s-100" />
              <h4>Aceitação do benefício</h4>
              <div className="">
                <DataLoader
                  data={voucher?.usableTimes?.map(
                    (it: UsableTimes, index: number) => (
                      <>
                        {index !== 0 && <hr className="mt-s-100" />}
                        <div className="row pt-s-100">
                          <div className="col-md-6">
                            <h5>Hora Inicial</h5>
                            <span>{it.start}</span>
                          </div>
                          <div className="col-md-6">
                            <h5>Hora final</h5>
                            <span>{it.end}</span>
                          </div>
                        </div>
                        <div className="row pt-s-100">
                          <div className="col-md-12">
                            <h5>Dias</h5>
                            <div className="d-flex grid-gap-1">
                              {Object.values(WeekDaysWithCommemorativeDate).map(
                                (day) => {
                                  const classNames = ['circular-day'];

                                  if (
                                    it.days
                                      .map(
                                        (day_selected) =>
                                          WeekDaysWithCommemorativeDate[
                                            day_selected as keyof typeof WeekDaysWithCommemorativeDate
                                          ]
                                      )
                                      .includes(day)
                                  ) {
                                    classNames.push('day-active');
                                  }

                                  function formatWeekDays(day: string) {
                                    return day ===
                                      WeekDaysWithCommemorativeDate.holiday ||
                                      day ===
                                        WeekDaysWithCommemorativeDate.commemorativeDate
                                      ? day
                                      : day.charAt(0).toUpperCase();
                                  }

                                  return (
                                    <div
                                      className={classNames.join(' ')}
                                      title={day}
                                    >
                                      {formatWeekDays(day)}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  )}
                  isLoading={!Boolean(voucher)}
                />
              </div>
            </div>

            <div className="col-md-12">
              <hr className="pb-s-100" />
              <h4>Imagens</h4>
              <div className="row grid-gap-1">
                <DataLoader
                  data={voucher?.images?.map((it: File, i: number) => {
                    const img = fileToBlobURL(it);

                    return (
                      <div className="col-md-3" key={it.size + i}>
                        <div style={{ position: 'relative' }}>
                          {i === 0 && (
                            <div
                              style={{ fontSize: 12, padding: 4 }}
                              className="principal_image mb-s-450 ml-s-100 bg-white border-radius-100"
                            >
                              Imagem principal
                            </div>
                          )}

                          <div className="banner-image-container">
                            <img
                              src={img}
                              className="banner-image border-radius-100"
                              alt={`${it.name}`}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  isLoading={!Boolean(voucher)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    >
      <TableColumn fromKey="validationCode" header="Código" width="10%" />
      <TableColumn fromKey="name" header="Nome" />
      <TableColumn
        fromKey="establishment"
        nestedOrder="name"
        header="Estabelecimento"
        render={(it) => it?.name}
      />

      {props.showPlans ? (
        <TableColumn
          fromKey="plan"
          header="Plano"
          nestedOrder="name"
          render={(it) => it?.name}
        />
      ) : (
        <></>
      )}
      <TableColumn
        fromKey="voucherStatus"
        header="Status"
        render={(active: string) => <StatusChip active={active === 'Ativo'} />}
      />
      <TableColumn
        width="6%"
        fromKey="id"
        header="Ações"
        disableSorting
        render={(id, row) => {
          return (
            <div className="d-flex">
              <Button
                className="mr-s-200"
                weight="bold"
                design="transparent"
                prefixes={
                  <Icon>
                    {expandedId === id ? 'expand_less' : 'expand_more'}
                  </Icon>
                }
                onClick={() => {
                  if (expandedId === id) {
                    setExpandedId('');
                  } else {
                    setExpandedId(id);
                    fetchData(id);
                  }
                }}
              />
              <PopOver
                fixedContent={<Icon>more_horiz</Icon>}
                togglableContent={
                  <PopOverMenu
                    menu={[
                      {
                        onClick: () => {
                          fetchQRCode(id, row.name);
                        },
                        content: 'Gerar QrCode',
                        show: handleDynamicPermission({
                          context: 'vouchers',
                          resource: 'generateVoucherQRCode',
                          establishmentUUID: uuid,
                        }),
                      },
                      {
                        onClick: () => {
                          setExpandedId('');
                          props.setEditId(id);
                          props.setIsOpenModal(true);
                        },
                        content: 'Editar',
                        show: handleDynamicPermission({
                          context: 'vouchers',
                          resource: 'update',
                          establishmentUUID: uuid,
                        }),
                      },
                      {
                        onClick: () => {
                          setExpandedId('');
                          props.deleteVoucher(id);
                        },
                        content: 'Excluir',
                        show: handleDynamicPermission({
                          context: 'vouchers',
                          resource: 'delete',
                          establishmentUUID: uuid,
                        }),
                      },
                    ]}
                  />
                }
              />
            </div>
          );
        }}
      />
    </Table>
  );
}
