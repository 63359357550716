import { Category } from '@interfaces/Category';
import { objectToFormData } from '@services/io/file';

import { RepositoryParams, api } from '..';

export type CategoryFilters = Partial<Category & RepositoryParams>;

export const list = async (params: CategoryFilters) => {
  return api.get('/categories', { params });
};

export const listAll = async () => {
  return api.get('/categories/all');
};

export const create = async (params: Category) => {
  return api.post('/categories', objectToFormData(params), {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const update = async (params: Category) => {
  return api.patch(`/categories/${params.id}`, objectToFormData(params), {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const updateOrders = async (ids: string[]) => {
  return api.put('/categories/order', { newOrder: ids });
};

export const retrieve = async (id: string) => {
  return api.get(`/categories/${id}`);
};

export const remove = async (id: string) => {
  return api.delete(`/categories/${id}`);
};

export const includeGroup = async (categoryId: string, groupId: string) => {
  return api.put(`/categories/${categoryId}/groups/${groupId}`);
};

export const excludeGroup = async (categoryId: string, groupId: string) => {
  return api.delete(`/categories/${categoryId}/groups/${groupId}`);
};
