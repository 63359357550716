export const formatCurrency = (value: number) => {
  if (value === null) return null;

  return 'R$ ' + value.toFixed(2).replace('.', ',');
};

export const formatValue = (value: number | string) => {
  if (typeof value === 'number') return value;
  const sanitizedValue = value.replace(/[^0-9,]/g, '').replace(',', '.');
  return Number(sanitizedValue);
};
