import { PropsWithChildren } from 'react';

export type Props = PropsWithChildren<{
  type?: 'material';
  className?: string;
  style?: React.CSSProperties;
}>;

function iconType(iconType: Props['type']) {
  const type = iconType ?? 'material';
  const types = { material: 'material-symbols-outlined' };
  return types[type];
}

export default function Icon(props: Props) {
  const iconClasses = ['unselectable', iconType(props.type)];
  if (props.className) iconClasses.push(props.className);

  return (
    <span style={props.style} className={iconClasses.join(' ')}>
      {props.children}
    </span>
  );
}
