import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Icon,
  Modal,
  PageHeader,
  Restricted,
  SelectedKeys,
  StatusChip,
  Table,
  TableColumn,
} from '@components';
import { PopOver, PopOverMenu } from '@components/PopOver';
import { usePermissionContext } from '@contexts/PermissionProvider';
import { useConfirmationModal } from '@hooks/useConfirmationModal';
import { User, UserType } from '@interfaces/User';
import { RepositoryParams } from '@services/api';
import UsersAPICaller, { UserFilters } from '@services/api/users';
import { userTypeEnumToTypeName } from 'utils/users';

import UserPasswordForm from './UserPasswordForm/UserPasswordForm';
import UsersForm from './UsersForm/UsersForm';

export default function UsersPage() {
  const confirmation = useConfirmationModal();
  const [selectedUsers, setSelectedUsers] = useState<SelectedKeys>({});
  const [invertSelection, setInvertSelection] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<{ total: number; result: User[] }>({
    total: 0,
    result: [],
  });
  const [filters, setFilters] = useState<UserFilters>({ order: 'name' });
  const [isEditModalOpen, toggleEditModal] = useState(false);
  const [isPasswordModalOpen, togglePasswordModal] = useState(false);
  const navigate = useNavigate();

  const [editId, setEditId] = useState('');

  const { hasPermission } = usePermissionContext();

  const fetch = (filters: RepositoryParams) => {
    setIsLoading(true);
    UsersAPICaller.fetchUsers(filters)
      .then((values) => {
        setData(values);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetch(filters);
  }, [filters]);

  const setAdmin = (userUUID: string, userName: string) => {
    confirmation({
      title: 'Promover a administrador',
      description: `Deseja realmente promover ${userName} à administrador?`,
      confirmButtonTitle: 'Promover',
      onSubmit: () => {
        UsersAPICaller.setAdmin(userUUID, { admin: true }).then((_) =>
          fetch(filters)
        );
      },
    });
  };

  const removeAdmin = (userUUID: string, userName: string) => {
    confirmation({
      title: 'Remover administrador',
      description: `Deseja realmente remover ${userName} à administrador?`,
      confirmButtonTitle: 'Remover',
      onSubmit: () => {
        UsersAPICaller.setAdmin(userUUID, { admin: false }).then((_) =>
          fetch(filters)
        );
      },
    });
  };

  return (
    <>
      <div className="p-s-200">
        <PageHeader
          title="Usuários"
          actions={
            <>
              <Restricted context="users" resource="create">
                <Button
                  onClick={() => {
                    setEditId('');
                    toggleEditModal(true);
                  }}
                  weight="bold"
                  prefixes={<Icon>add</Icon>}
                >
                  Cadastrar
                </Button>
              </Restricted>
            </>
          }
        />

        <Table
          isLoading={isLoading}
          hoverable
          totalRecords={data.total}
          data={data.result}
          selectedKeys={selectedUsers}
          invertSelection={invertSelection}
          onSelectionChange={(selectedKeys, invertSelection) => {
            setSelectedUsers(selectedKeys);
            setInvertSelection(invertSelection);
          }}
          withPagination
          withSearchInput
          filters={filters}
          setFilters={setFilters}
          className="pointer"
          onRowClick={(row) => {
            if (hasPermission('users', 'read')) {
              navigate(`/backoffice_users/${row.id}`);
            }
          }}
        >
          <TableColumn fromKey="name" header="Nome" />
          <TableColumn fromKey="email" header="E-mail" />
          <TableColumn
            fromKey="userType"
            header="Tipo"
            render={(a) => userTypeEnumToTypeName(a)}
          />
          <TableColumn
            fromKey="active"
            header="Status"
            render={(active: boolean) => <StatusChip active={active} />}
          />
          <TableColumn
            width="6%"
            fromKey="id"
            header="Ações"
            disableSorting
            render={(id, row) => {
              return (
                <PopOver
                  fixedContent={<Icon>more_horiz</Icon>}
                  togglableContent={
                    <PopOverMenu
                      menu={[
                        {
                          onClick: () => {
                            navigate(`/backoffice_users/${id}`);
                          },
                          content: 'Acessar',
                          show: hasPermission('users', 'read'),
                        },

                        {
                          onClick: () => {
                            setEditId(id);
                            toggleEditModal(true);
                          },
                          content: 'Editar',
                          show: hasPermission('users', 'update'),
                        },
                        {
                          onClick: () => {
                            setEditId(id);
                            togglePasswordModal(true);
                          },
                          content: 'Alterar senha',
                          show: hasPermission('users', 'updatePassword'),
                        },
                        {
                          onClick: () => {
                            setAdmin(id, row.name);
                          },
                          content: 'Promover a administrador',
                          show:
                            hasPermission('users', 'setAdmin') &&
                            !row.admin &&
                            row.userType === UserType.Global,
                        },
                        {
                          onClick: () => {
                            removeAdmin(id, row.name);
                          },
                          content: 'Remover administrador',
                          show:
                            hasPermission('users', 'setAdmin') &&
                            row.admin &&
                            row.userType === UserType.Global,
                        },
                      ]}
                    />
                  }
                />
              );
            }}
          />
        </Table>
      </div>

      <Modal
        isOpen={isEditModalOpen}
        onClickAway={() => {
          setEditId('');
          toggleEditModal(false);
        }}
      >
        <UsersForm
          editId={editId}
          onSave={() => fetch(filters)}
          closeModal={() => {
            setEditId('');
            toggleEditModal(false);
          }}
        />
      </Modal>

      <Modal
        isOpen={isPasswordModalOpen}
        onClickAway={() => {
          setEditId('');
          togglePasswordModal(false);
        }}
      >
        <UserPasswordForm
          editId={editId}
          closeModal={() => {
            setEditId('');
            togglePasswordModal(false);
          }}
        />
      </Modal>
    </>
  );
}
