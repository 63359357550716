import { Notification } from '@interfaces/Notification';

import { RepositoryParams, api } from '..';

export type NotificationFilters = Partial<Notification & RepositoryParams>;

export const list = async (params: NotificationFilters) => {
  return api.get('/notifications', { params });
};

export const create = async (params: Notification) => {
  return api.post('/notifications', { ...params });
};

export const update = async (params: Notification) => {
  return api.patch(`/notifications/${params.id}`, { ...params });
};

export const retrieve = async (id: string) => {
  return api.get(`/notifications/${id}`);
};

export const remove = async (id: string) => {
  return api.delete(`/notifications/${id}`);
};
