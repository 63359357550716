import { FieldValues, UseFormSetError } from 'react-hook-form';

import { handleErrorForm } from '@services/api';
import { Tips, TipsType } from '@interfaces/Tips';
import { reverseKeyValueObject, toValueLabel } from 'utils/object';
import axios from 'axios';

import {
  TipsFilters,
  create,
  list,
  listComments,
  remove,
  retrieve,
  update,
  updateComment,
} from './calls';

export * from './calls';

export default class TipsAPICaller {
  static adaptFromAPI = async (data: Tips, shouldAdaptImages?: boolean) => {
    const tip = { ...data };

    tip.city = toValueLabel(data.city as string);
    tip.state = toValueLabel(data.state as string);
    tip.district = toValueLabel(data.district as string);
    tip.type = toValueLabel(
      TipsType[data.type as string as keyof typeof TipsType]
    );

    const images = [];

    if (shouldAdaptImages) {
      for (const it of data.images! as { url: string }[]) {
        const fileName = 'image';

        if (it.url) {
          const response = await axios.get(it?.url, {
            responseType: 'blob',
            headers: {
              'Content-type': 'application/octet-stream',
            },
          });

          const file = new File([response.data], fileName);
          images.push(file);
        }
      }

      tip.images = images;
    }

    return tip as FieldValues;
  };
  static adaptToAPI = (data: FieldValues) => {
    const tip = { ...data };

    tip.state = data.state.value;
    tip.city = data.city.value;
    tip.district = data.district.value;
    tip.type =
      reverseKeyValueObject(TipsType)[
        data.type?.value as keyof typeof TipsType
      ];

    return tip as Tips;
  };

  static listComments = async (filters: TipsFilters) => {
    const { data } = await listComments(filters);
    return data.tipComments;
  };
  static list = async (filters: TipsFilters) => {
    const {
      data: { tips },
    } = await list(filters);
    return {
      ...tips,
      result: await Promise.all(
        tips.result.map((item: Tips) => {
          const adaptedItem = this.adaptFromAPI(item);
          return adaptedItem;
        })
      ),
    };
  };

  static fetch = async (editId: string) => {
    const { data } = await retrieve(editId);
    return await this.adaptFromAPI(data.tip, true);
  };

  static createOrUpdate = async (
    data: FieldValues,
    setError?: UseFormSetError<FieldValues>
  ) => {
    const method = data.id ? update : create;

    const result = method(this.adaptToAPI(data)).catch((err) => {
      if (setError) handleErrorForm(setError);
      return err;
    });

    return result;
  };
  static updateComment = async (data: Partial<Tips>) => {
    const result = updateComment(data);

    return result;
  };

  static remove = async (id: string) => {
    const { data } = await remove(id);

    return data;
  };
}
