import { useEffect, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button, Input, LoginPageContainer, PasswordInput } from '@components';
import { useAuthContext } from '@contexts/AuthProvider';
import { handleErrorForm } from '@services/api';
import BannersAPICaller from '@services/api/banner';
import { formEmailPattern } from '@validations/email';

export default function LoginPage() {
  const {
    setError,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { login, user, isLoadingRequest } = useAuthContext();
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (!!user) navigate('/establishment');
    return;
  }, [user, navigate]);

  useEffect(() => {
    fetch();
  }, [setImageUrl]);

  const fetch = () => {
    BannersAPICaller.getBannerLogin().then((values) => setImageUrl(values));
  };

  const onSubmit = ((data: { email: string; password: string }) => {
    login(data.email.toLowerCase(), data.password).catch(
      handleErrorForm(setError)
    );
  }) as SubmitHandler<FieldValues>;

  return (
    <div className="bg-neutral-95">
      <div className="d-flex justify-center" style={{ height: '100vh' }}>
        <div className="col-sm-12 col-md-10 d-flex justify-center background-login-page">
          <form
            autoComplete="off"
            className="form-max-height"
            onSubmit={handleSubmit(onSubmit)}
          >
            <LoginPageContainer adImage={imageUrl}>
              <h2 className="!w100 pb-s-300">Bem-vindo</h2>
              <Input
                form={register('email', {
                  required: 'Obrigatório',
                  ...formEmailPattern,
                })}
                placeholder="Email"
                error={!!errors.email}
                caption={errors.email?.message as string}
              />
              <PasswordInput
                form={register('password', { required: 'Obrigatório' })}
                placeholder="Senha"
                error={!!errors.password}
                caption={errors.password?.message as string}
              />

              <Button
                type="button"
                design="transparent"
                color="secondary"
                className="!pl-0 !mb-s-200"
                textClassName="!ml-0"
                onClick={() => {
                  navigate('/recover_password');
                }}
              >
                Esqueceu a senha?
              </Button>

              <Button
                type="submit"
                isLoading={isLoadingRequest}
                className="!w-100"
                alignText="center"
              >
                Entrar
              </Button>
            </LoginPageContainer>
          </form>
        </div>
      </div>
    </div>
  );
}
