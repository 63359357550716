import { Banner } from '@interfaces/Banner';
import { objectToFormData } from '@services/io/file';

import { RepositoryParams, api } from '../';

export type BannerFilters = Partial<Banner & RepositoryParams>;

export const list = async (params: BannerFilters) => {
  return api.get('/login_banner', { params });
};

export const create = async (params: { [key: string]: any }) => {
  return api.post('/login_banner', objectToFormData(params), {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const remove = async (params: Partial<Banner>) => {
  return api.delete(`/login_banner/${params.id}`);
};

export const chosen = async (params: Partial<Banner>) => {
  return api.post(`/login_banner/chosen`, { ...params });
};

export const retrieve = async () => {
  return api.get(`/login_banner/chosen`);
};
