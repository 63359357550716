import { ClientAccessReason } from '@interfaces/ClientAccessReason';

import { RepositoryParams, api } from '..';

export type ClientAccessReasonFilters = Partial<
  ClientAccessReason & RepositoryParams
>;

export const list = async (params: ClientAccessReasonFilters) => {
  return api.get('/client_access_reasons', { params });
};

export const getOptions = async () => {
  return api.get('/client_access_reasons/options');
};

export const create = async (params: ClientAccessReason) => {
  return api.post('/client_access_reasons', { ...params });
};

export const update = async (params: ClientAccessReason) => {
  return api.patch(`/client_access_reasons/${params.id}`, { ...params });
};

export const retrieve = async (id: string) => {
  return api.get(`/client_access_reasons/${id}`);
};

export const remove = async (id: string) => {
  return api.delete(`/client_access_reasons/${id}`);
};

export const retrieveHistory = async (params: ClientAccessReasonFilters) => {
  return api.get(`/client_access_reasons/history`, { params });
};
