import { FieldValues, UseFormSetError } from 'react-hook-form';

import {
  UserClient,
  UserClientsGender,
  UserClientsStatus,
} from '@interfaces/UserClient';
import { RepositoryParams, handleErrorForm } from '@services/api';
import { toValueLabel } from 'utils/object';
import { formatCPF } from 'utils/string/cpf';
import { formatCurrency } from 'utils/string/currency';
import { encodeDate, parseDate, parseDateHour } from 'utils/string/dates';
import { formatPhone } from 'utils/string/phone';

import { clientAccessActionMap } from '../clientAccessReason';
import PlanAPICaller from '../plans';

import {
  UserClientsFilters,
  addPlan,
  addVoucherUsed,
  getUsableVouchers,
  list,
  listDevices,
  listPhysicalVouchers,
  listPlanHistory,
  listPlans,
  listPlansActive,
  listPlansBuyable,
  listVoucherHistory,
  listVouchers,
  remove,
  removePlan,
  removeVoucher,
  retrieve,
  update,
  updateDevice,
  updatePassword,
} from './calls';

export * from './calls';

export const voucherActionMap: { [key: string]: string } = {
  USE: 'Utilizado',
  UNUSE: 'Resetado',
};

export const deviceStatusMap: { [key: string]: string } = {
  APPROVED: 'Aprovado',
  PENDING: 'Pendente',
  DECLINED: 'Negado',
};

export default class UserClientsAPICaller {
  static adaptFromAPI = (data: UserClient) => {
    const userClient = {
      ...data,
    };

    userClient.cpf = formatCPF(data.cpf);
    userClient.phone = formatPhone(data.phone);

    userClient.userClientStatus = toValueLabel(
      UserClientsStatus[data.userClientStatus as keyof typeof UserClientsStatus]
    );

    userClient.gender = toValueLabel(
      UserClientsGender[data.gender as keyof typeof UserClientsGender]
    );

    userClient.dateOfBirth = parseDate(data.dateOfBirth);

    userClient.inactivationDate = parseDateHour(data.inactivationDate);

    return userClient as FieldValues;
  };

  static adaptToAPI = (data: FieldValues) => {
    const userClient = {
      ...data,
    };

    userClient.gender = data.gender?.value;
    userClient.userClientStatus = data.userClientStatus?.value;

    userClient.dateOfBirth = encodeDate(data.dateOfBirth);

    userClient.cpf = data.cpf?.replace(/\D/g, '');
    userClient.phone = data.phone?.replace(/\D/g, '');

    return userClient as UserClient;
  };

  static list = async (filters: UserClientsFilters) => {
    const {
      data: { userClients },
    } = await list(filters);

    return {
      ...userClients,
      result: userClients.result.map(this.adaptFromAPI),
    };
  };

  static fetch = async (editId: string) => {
    const {
      data: { userClient },
    } = await retrieve(editId);
    return this.adaptFromAPI(userClient);
  };

  static update = async (
    data: FieldValues,
    setError: UseFormSetError<FieldValues>
  ) => {
    const result = update(this.adaptToAPI(data)).catch(
      handleErrorForm(setError)
    );

    return result;
  };
  static updatePassword = async (
    data: { id: string; password: string; passwordConfirm?: string },
    setError: UseFormSetError<FieldValues>
  ) => {
    const result = updatePassword(data).catch(handleErrorForm(setError));

    return result;
  };

  static remove = async (id: string) => {
    const { data } = await remove(id);

    return data;
  };

  static fetchPlans = async (id: string) => {
    const {
      data: { plans },
    } = await listPlans(id);
    return plans.map(PlanAPICaller.adaptFromAPI);
  };

  static getUsableVouchers = async (id: string, filters: RepositoryParams) => {
    const {
      data: { usableVouchers },
    } = await getUsableVouchers(id, filters);
    return usableVouchers;
  };

  static fetchPlanHistory = async (
    filters: UserClientsFilters,
    userClientUUID: string
  ) => {
    const { data } = await listPlanHistory(filters, userClientUUID);
    return {
      ...data,
      result: data.result.map((it: { action: string; createdAt: string }) => ({
        ...it,
        action: clientAccessActionMap[it.action],
        createdAt: parseDateHour(it.createdAt),
      })),
    };
  };

  static removePlans = async (
    data: FieldValues,
    setError: UseFormSetError<FieldValues>
  ) => {
    const result = await removePlan(data).catch(handleErrorForm(setError));

    return result;
  };

  static addPlans = async (
    data: FieldValues,
    setError: UseFormSetError<FieldValues>
  ) => {
    const result = await addPlan(data).catch(handleErrorForm(setError));

    return result;
  };

  static addVoucherUsed = async (
    data: FieldValues,
    setError: UseFormSetError<FieldValues>
  ) => {
    const result = await addVoucherUsed(data).catch(handleErrorForm(setError));

    return result;
  };

  static listPlansBuyable = async (id: string) => {
    const {
      data: { plansBuyableByUser },
    } = await listPlansBuyable(id);

    return plansBuyableByUser;
  };

  static listPlansActive = async (id: string) => {
    const {
      data: { plansBuyableByUser },
    } = await listPlansActive(id);

    return plansBuyableByUser;
  };

  static fetchPhysicalVouchers = async (
    id: string,
    filters: RepositoryParams
  ) => {
    const {
      data: { physicalVouchersUsed },
    } = await listPhysicalVouchers(id, filters);

    return {
      ...physicalVouchersUsed,
      result: physicalVouchersUsed.result.map(
        (it: {
          action: string;
          createdAt: string;
          totalPrice: number;
          moneySaved: number;
        }) => ({
          ...it,
          totalPrice: formatCurrency(it.totalPrice),
          moneySaved: formatCurrency(it.moneySaved),
          createdAt: parseDateHour(it.createdAt),
          action: voucherActionMap[it.action],
        })
      ),
    };
  };

  static fetchVouchers = async (id: string, filters: RepositoryParams) => {
    const {
      data: { vouchersUsed },
    } = await listVouchers(id, filters);

    return {
      ...vouchersUsed,
      result: vouchersUsed.result.map(
        (it: {
          action: string;
          createdAt: string;
          totalPrice: number;
          moneySaved: number;
        }) => ({
          ...it,
          totalPrice: formatCurrency(it.totalPrice),
          moneySaved: formatCurrency(it.moneySaved),
          createdAt: parseDateHour(it.createdAt),
          action: voucherActionMap[it.action],
        })
      ),
    };
  };

  static removeVoucher = async (userClientUUID: string, voucherId: string) => {
    const { data } = await removeVoucher(userClientUUID, voucherId);

    return data;
  };

  static fetchVoucherHistory = async (
    filters: UserClientsFilters,
    userClientUUID: string
  ) => {
    const { data } = await listVoucherHistory(filters, userClientUUID);
    return {
      ...data,
      result: data.result.map((it: { action: string; createdAt: string }) => ({
        ...it,
        action: voucherActionMap[it.action],
        createdAt: parseDateHour(it.createdAt),
      })),
    };
  };

  static fetchDevices = async (
    filters: UserClientsFilters & { userClientId?: string }
  ) => {
    const { data } = await listDevices(filters);
    return {
      ...data,
      result: data.result.map(
        (it: {
          status: string;
          createdAt: string;
          updatedAt: string;
          userClient: UserClient;
        }) => ({
          ...it,
          status: deviceStatusMap[it.status],
          userClient: UserClientsAPICaller.adaptFromAPI(it.userClient),
          createdAt: parseDateHour(it.createdAt),
          updatedAt: parseDateHour(it.updatedAt),
        })
      ),
    };
  };

  static updateDevice = async (
    userClientUUID: string,
    deviceHistoryId: string,
    payload: { status: string }
  ) => {
    const { data } = await updateDevice(
      userClientUUID,
      deviceHistoryId,
      payload
    );

    return data;
  };
}
