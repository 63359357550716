import { FieldValues, UseFormSetError } from 'react-hook-form';

import { DiscountCoupon } from '@interfaces/DiscountCoupon';
import { UserClient } from '@interfaces/UserClient';
import { handleErrorForm } from '@services/api';
import { encodeDate, parseDate, parseDateHour } from 'utils/string/dates';

import UserClientsAPICaller from '../userClients';

import {
  DiscountCouponFilters,
  create,
  list,
  listUsed,
  remove,
  retrieve,
  update,
} from './calls';

export * from './calls';

export default class DiscountCouponAPICaller {
  static adaptFromAPI = (data: DiscountCoupon) => {
    const discount = {
      ...data,
      code: data.code!.toUpperCase(),
      expirationDate: parseDate(data.expirationDate),
    };

    return discount as FieldValues;
  };

  static adaptToAPI = (data: FieldValues) => {
    const discount = {
      ...data,
      code: data.code!.toUpperCase(),
      amount: Number(data.amount),
      discountPercentage: parseFloat(data.discountPercentage),
      expirationDate: encodeDate(data.expirationDate),
    };

    return discount as DiscountCoupon;
  };

  static list = async (filters: DiscountCouponFilters) => {
    const {
      data: { discountCoupons },
    } = await list(filters);

    return {
      ...discountCoupons,
      result: discountCoupons.result.map(this.adaptFromAPI),
    };
  };

  static listUsed = async (id: string, filters?: DiscountCouponFilters) => {
    const { data } = await listUsed(id, filters);
    return {
      ...data.uses,
      result: data.uses.result
        .filter((it: any) => it.action === 'GRANT')
        .map((it: { userClient: UserClient; createdAt: string }) => ({
          ...it,
          createdAt: parseDateHour(it.createdAt),
          userClient: UserClientsAPICaller.adaptFromAPI(it.userClient),
        })),
    };
  };

  static fetch = async (editId: string) => {
    const { data } = await retrieve(editId);
    return this.adaptFromAPI(data.discountCoupon);
  };

  static createOrUpdate = async (
    data: FieldValues,
    setError: UseFormSetError<FieldValues>
  ) => {
    const method = data.id ? update : create;

    const result = method(this.adaptToAPI(data)).catch(
      handleErrorForm(setError)
    );

    return result;
  };

  static remove = async (id: string) => {
    const { data } = await remove(id);

    return data;
  };
}
