import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';

import defaultImage from '@assets/defaultImage.png';
import { Button, DataLoader, Modal, Restricted } from '@components';
import { RestrictedDynamic } from '@components/Restricted/RestrictedDynamic';
import { useConfirmationModal } from '@hooks/useConfirmationModal';
import { Establishment } from '@interfaces/Establishment';
import { EstablishmentForm } from '@pages/EstablishmentPage/EstablishmentForm';
import EstablishmentAPICaller from '@services/api/establishment';

interface Props {
  establishmentInfo: Establishment | null;
  fetch: (uuid: string) => void;
}

export default function EstablishmentInfo(props: Props) {
  const [editId, setEditId] = useState('');
  const navigate = useNavigate();
  const confirmation = useConfirmationModal();

  const deleteEstablishment = (id: string) => {
    confirmation({
      title: 'Excluir Estabelecimento',
      confirmButtonTitle: 'Excluir',
      description: `Deseja realmente excluir o estabelecimento ${
        props.establishmentInfo!.name
      }`,
      onSubmit: () =>
        EstablishmentAPICaller.remove(id).then((_) => navigate(-1)),
    });
  };

  return (
    <>
      <div className="card card__body p-s-350 bg-white">
        <div className="row grid-gap-1">
          <div className="col-md-6  ">
            <h4>CNPJ</h4>
            <span>
              <DataLoader
                data={props.establishmentInfo?.cnpj}
                isLoading={!props.establishmentInfo}
              />
            </span>
          </div>

          <div className="col-md-6">
            <h4>Razão Social</h4>
            <span>
              <DataLoader
                data={props.establishmentInfo?.companyName}
                isLoading={!props.establishmentInfo}
              />
            </span>
          </div>

          <div className="col-md-6">
            <h4>Descrição</h4>
            <span>
              <DataLoader
                data={props.establishmentInfo?.description}
                isLoading={!props.establishmentInfo}
              />
            </span>
          </div>

          <div className="col-md-6 mt-s-250">
            <h4>Culinárias</h4>
            <span>
              <DataLoader
                data={props.establishmentInfo?.cooking
                  ?.map((it) => it.description)
                  .join(', ')}
                isLoading={!props.establishmentInfo}
              />
            </span>
          </div>
          <div className="col-md-6 mt-s-250">
            <h4>Categorias</h4>
            <span>
              <DataLoader
                data={props.establishmentInfo?.categories
                  ?.map((it) => it.name)
                  .join(', ')}
                isLoading={!props.establishmentInfo}
              />
            </span>
          </div>

          <div className="col-md-6 mt-s-250">
            <h4>Site</h4>
            <span>
              <DataLoader
                data={props.establishmentInfo?.site}
                isLoading={!props.establishmentInfo}
              />
            </span>
          </div>

          <div className="col-md-6 mt-s-250">
            <h4>Imagem</h4>
            <span>
              <DataLoader
                data={
                  <img
                    src={props.establishmentInfo?.imageUrl || defaultImage}
                    className="banner-image border-radius-100"
                    alt="establishmentImage"
                    height={150}
                  />
                }
                isLoading={!props.establishmentInfo}
              />
            </span>
          </div>

          <div className="col-md-6 mt-s-250">
            <h4>Contato</h4>
            <span>
              <DataLoader
                data={props.establishmentInfo?.contactPhone}
                isLoading={!props.establishmentInfo}
              />
            </span>
          </div>
        </div>

        {props.establishmentInfo ? (
          <div className="row mt-s-300">
            <RestrictedDynamic
              context="establishments"
              resource="update"
              establishmentUUID={props.establishmentInfo.id}
            >
              <div className="col mr-s-100">
                <Button
                  onClick={() => setEditId(props.establishmentInfo!.id!)}
                  design="outlined"
                  size="medium"
                >
                  Editar informações
                </Button>
              </div>
            </RestrictedDynamic>
            <Restricted context="establishments" resource="delete">
              <div className="col mr-s-100">
                <Button
                  onClick={() =>
                    deleteEstablishment(props.establishmentInfo!.id!)
                  }
                  design="outlined"
                  size="medium"
                  color="negative"
                >
                  Excluir estabelecimento
                </Button>
              </div>
            </Restricted>
          </div>
        ) : (
          <Skeleton height={40} style={{ width: '98%' }} className="mt-s-300" />
        )}
      </div>

      {!!(props.establishmentInfo?.id && editId) && (
        <Modal
          isOpen={!!editId}
          onClickAway={() => {
            setEditId('');
          }}
        >
          <EstablishmentForm
            editId={editId}
            onSave={() => {
              props.fetch(props.establishmentInfo!.id!);
              setEditId('');
            }}
            closeModal={() => {
              setEditId('');
            }}
          />
        </Modal>
      )}
    </>
  );
}
