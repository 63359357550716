import { useCallback } from 'react';

import EstablishmentUnitAPICaller, {
  EstablishmentUnitFilters,
} from '@services/api/establishmentUnit';
import { EstablishmentUnit } from '@interfaces/EstablishmentUnit';

import { useFetchList } from '../useFetch';

export const useFetchEstablishmentUnits = () => {
  const fetchEstablishmentUnitsMethod = useCallback(
    (filters: EstablishmentUnitFilters) => {
      return EstablishmentUnitAPICaller.list(filters) as Promise<{
        total: number;
        result: EstablishmentUnit[];
      }>;
    },
    []
  );

  const { data, total, fetching, fetchData } = useFetchList(
    fetchEstablishmentUnitsMethod
  );

  return {
    establishmentUnits: data,
    establishmentUnitsTotal: total,
    isFetchingEstablishmentUnits: fetching,
    fetchEstablishmentUnits: fetchData,
  };
};
