import { useCallback, useEffect, useState } from 'react';

import {
  Button,
  DataLoader,
  Icon,
  Modal,
  PopOver,
  PopOverMenu,
  Restricted,
  StatusChip,
  Table,
  TableColumn,
} from '@components';
import { usePermissionContext } from '@contexts/PermissionProvider';
import { useConfirmationModal } from '@hooks/useConfirmationModal';
import { Plan } from '@interfaces/Plan';
import { Voucher } from '@interfaces/Voucher';
import { RepositoryParams } from '@services/api';
import UserClientsAPICaller, {
  voucherActionMap,
} from '@services/api/userClients';

import { AddUsedVoucherForm } from '../AddUsedVoucherForm';

type Props = {
  uuid?: string;
};

export function UserClientsVouchers(props: Props) {
  const [addUsedVoucher, setAddUsedVoucher] = useState(false);
  const [filters, setFilters] = useState<RepositoryParams>({
    page: 1,
    order: '-createdAt',
  });
  const [data, setData] = useState<{ total: number; result: Voucher[] }>({
    total: 0,
    result: [],
  });

  const [expandedId, setExpandedId] = useState('');

  const confirmation = useConfirmationModal();
  const { hasPermission } = usePermissionContext();

  const fetchVouchers = useCallback(
    (uuid: string) => {
      UserClientsAPICaller.fetchVouchers(uuid, filters).then((values) =>
        setData(values)
      );
    },
    [filters]
  );

  useEffect(() => {
    if (props.uuid) {
      fetchVouchers(props.uuid);
    }
  }, [props.uuid, filters, fetchVouchers]);

  const deleteVoucher = (id: string) => {
    confirmation({
      title: 'Resetar o uso do voucher do usuário - cliente',
      confirmButtonTitle: 'Resetar',
      description:
        'Deseja realmente resetar o uso desse voucher do usuário - cliente?',
      onSubmit: () =>
        UserClientsAPICaller.removeVoucher(props.uuid!, id).then((_) =>
          fetchVouchers(props.uuid!)
        ),
    });
  };

  return (
    <>
      <div
        className="card card__body p-s-350 bg-white"
        style={{ minHeight: '50vh' }}
      >
        <div className="d-flex justify-end mb-s-250" style={{ gap: 20 }}>
          <Restricted
            context="userClients"
            resource="addVoucherUsedToUserClient"
          >
            <Button
              onClick={() => {
                setAddUsedVoucher(true);
              }}
              weight="bold"
              prefixes={<Icon>add</Icon>}
            >
              Adicionar voucher usado
            </Button>
          </Restricted>
        </div>
        <div>
          <Table
            data={data.result || []}
            totalRecords={data.total}
            withPagination
            showColumnSelector={false}
            hoverable
            filters={filters}
            setFilters={setFilters}
            expandedRow={expandedId}
            onRowClick={({ id }) => {
              if (expandedId === id) return setExpandedId('');
              else {
                setExpandedId(id);
              }
            }}
            className="pointer"
            renderExpandedRow={(data) => (
              <div className="container mt-s-200">
                <div className="row grid-gap-1">
                  <div className="col-md-6">
                    <h4>Estabelecimento</h4>
                    <span>
                      <DataLoader
                        data={data?.voucher?.establishment?.name}
                        isLoading={!data}
                      />
                    </span>
                  </div>
                  <div className="col-md-6">
                    <h4>Unidade</h4>
                    <span>{data?.establishmentUnit?.name}</span>
                  </div>
                  <div className="col-md-6">
                    <h4>Categoria</h4>
                    <span>{data?.voucher?.category?.name}</span>
                  </div>
                  <div className="col-md-6">
                    <h4>Avaliação</h4>
                    <span>{data?.rating ?? '-'}</span>
                  </div>
                  <div className="col-md-6">
                    <h4>Total da conta</h4>
                    <span>{data?.totalPrice ?? '-'}</span>
                  </div>
                  <div className="col-md-6">
                    <h4>Valor da economia</h4>
                    <span>{data?.moneySaved ?? '-'}</span>
                  </div>
                  <div className="col-md-6">
                    <h4>Primeira vez?</h4>
                    <span>
                      {data?.firstTime == null ? (
                        '-'
                      ) : (
                        <StatusChip
                          active={data.firstTime}
                          label={data.firstTime ? 'Sim' : 'Não'}
                        />
                      )}
                    </span>
                  </div>
                  <div className="col-md-6">
                    <h4>Comentários</h4>
                    <span>{data?.comments ?? '-'}</span>
                  </div>
                </div>
              </div>
            )}
          >
            <TableColumn
              width="6%"
              fromKey="voucher"
              nestedOrder="validationCode"
              header="Código"
              render={({ validationCode }) => <span>{validationCode}</span>}
            />
            <TableColumn
              fromKey="voucher"
              nestedOrder="name"
              header="Nome"
              ellipsis
              render={({ name }) => <span>{name}</span>}
            />
            <TableColumn
              fromKey="voucher"
              nestedOrder="plan.name"
              header="Plano"
              render={({ plan }: { plan: Plan }) => <span>{plan.name}</span>}
            />
            <TableColumn fromKey="createdAt" header="Data" />
            <TableColumn
              fromKey="action"
              header="Status"
              render={(status) => (
                <StatusChip
                  active={status === voucherActionMap['USE']}
                  label={status}
                />
              )}
            />
            <TableColumn
              width="6%"
              fromKey="id"
              header="Ações"
              disableSorting
              render={(id, { action, voucherId }) => {
                return (
                  <>
                    <div className="d-flex">
                      <Button
                        className="mr-s-200"
                        weight="bold"
                        design="transparent"
                        prefixes={
                          <Icon>
                            {expandedId === id ? 'expand_less' : 'expand_more'}
                          </Icon>
                        }
                        onClick={() => {
                          if (expandedId === id) {
                            setExpandedId('');
                          } else {
                            setExpandedId(id);
                          }
                        }}
                      />
                      <PopOver
                        fixedContent={<Icon>more_horiz</Icon>}
                        togglableContent={
                          <PopOverMenu
                            menu={[
                              {
                                onClick: () => {
                                  deleteVoucher(voucherId);
                                },
                                show:
                                  hasPermission(
                                    'userClients',
                                    'removeVoucherUsedFromUserClient'
                                  ) && action === voucherActionMap['USE'],
                                content: 'Resetar o uso do voucher',
                              },
                            ]}
                          />
                        }
                      />
                    </div>
                  </>
                );
              }}
            />
          </Table>
        </div>
      </div>

      {Boolean(addUsedVoucher) && (
        <Modal
          isOpen={!!addUsedVoucher}
          onClickAway={() => setAddUsedVoucher(false)}
        >
          <AddUsedVoucherForm
            userClientUUID={props.uuid!}
            onSave={() => {
              fetchVouchers(props.uuid!);
            }}
            closeModal={() => setAddUsedVoucher(false)}
          />
        </Modal>
      )}
    </>
  );
}
