import { Tips } from '@interfaces/Tips';
import { objectToFormData } from '@services/io/file';

import { RepositoryParams, api } from '..';

export type TipsFilters = Partial<Tips & RepositoryParams>;

export const listComments = async (params: TipsFilters) => {
  return api.get('/tips/comments', { params });
};

export const updateComment = async (params: Partial<Tips>) => {
  return api.patch(`/tips/comments/${params.id}`, params);
};

export const list = async (params: TipsFilters) => {
  return api.get('/tips', { params });
};

export const create = async (params: Tips) => {
  const payload = {
    images: params.images,
    payload: JSON.stringify(params),
  };

  return api.post('/tips', objectToFormData(payload), {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const update = async (params: Tips) => {
  const payload = {
    images: params.images,
    payload: JSON.stringify(params),
  };

  return api.patch(`/tips/${params.id}`, objectToFormData(payload), {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const retrieve = async (id: string) => {
  return api.get(`/tips/${id}`);
};

export const remove = async (id: string) => {
  return api.delete(`/tips/${id}`);
};
