import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import defaultImage from '@assets/defaultImage.png';
import {
  Button,
  DataLoader,
  Icon,
  Modal,
  PageHeader,
  Restricted,
  Tab,
} from '@components';
import { RestrictedDynamic } from '@components/Restricted/RestrictedDynamic';
import { useAuthContext } from '@contexts/AuthProvider';
import { usePermissionContext } from '@contexts/PermissionProvider';
import { Establishment } from '@interfaces/Establishment';
import { EstablishmentUnit } from '@interfaces/EstablishmentUnit';
import { Voucher } from '@interfaces/Voucher';
import { VouchersForm } from '@pages/VouchersPage/VouchersForm';
import EstablishmentAPICaller from '@services/api/establishment';
import { validateUUIDFormat } from 'utils/string/uuid';

import { EstablishmentUnitsForm } from '../EstablishmentUnitsForm';

import EstablishmentInfo from './EstablishmentInfo/EstablishmentInfo';
import EstablishmentUnits from './EstablishmentUnits/EstablishmentUnits';
import EstablishmentVouchers from './EstablishmentVouchers/EstablishmentVouchers';
import EstablishmentDashboard from './EstablishmentDashboard/EstablishmentDashboard';

export default function EstablishmentView() {
  const { uuid } = useParams();
  const { userIsEstablishment } = useAuthContext();
  const { establishmentAccess, unitAccess } = usePermissionContext();
  const [data, setData] = useState<Establishment | null>(null);
  const [isOpenEstablishmentUnitModal, setIsOpenEstablishmentUnitModal] =
    useState('');
  const [isOpenVoucherModal, setIsOpenVoucherModal] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const fetch = useCallback(
    (uuid: string) => {
      EstablishmentAPICaller.fetch(uuid).then((establishment) => {
        const params = new URLSearchParams(window.location.search);
        const newUnit = params.get('new_unit');
        if (newUnit) {
          window.history.replaceState({}, document.title, location.pathname);
          setIsOpenEstablishmentUnitModal(uuid);
        }

        establishment.vouchers = establishment.vouchers?.map((it: Voucher) => {
          it.establishment = establishment as Establishment;
          return it;
        });

        const establishmentsUser = establishmentAccess?.map(
          (it) => it.establishmentId!
        );

        if (userIsEstablishment && !establishmentsUser?.includes(uuid)) {
          const unitsId = unitAccess?.map((it) => it.unitId);
          const establishmentsUnits = establishment.units.filter(
            (it: EstablishmentUnit) => unitsId?.includes(it.id!)
          );

          establishment.units = establishmentsUnits;
          setData(establishment as Establishment);
        } else {
          setData(establishment as Establishment);
        }
      });
    },
    [establishmentAccess, location.pathname, unitAccess, userIsEstablishment]
  );

  useEffect(() => {
    if (uuid) {
      if (validateUUIDFormat(uuid)) {
        fetch(uuid);
      } else {
        toast.error('ID informado inválido');
        navigate(-1);
      }
    }
  }, [uuid, navigate, fetch]);

  return (
    <>
      <div className="p-s-200">
        <PageHeader
          backButton
          title="Estabelecimento"
          actions={
            <>
              <RestrictedDynamic
                context="vouchers"
                resource="create"
                establishmentUUID={uuid}
              >
                <Button
                  onClick={() => {
                    setIsOpenVoucherModal(data?.id!);
                  }}
                  design="outlined"
                  weight="bold"
                  prefixes={<Icon>add</Icon>}
                >
                  Cadastrar voucher
                </Button>
              </RestrictedDynamic>
              <RestrictedDynamic
                context="establishmentUnits"
                resource="create"
                establishmentUUID={uuid}
              >
                <Button
                  onClick={() => {
                    setIsOpenEstablishmentUnitModal(data?.id!);
                  }}
                  weight="bold"
                  prefixes={<Icon>add</Icon>}
                >
                  Cadastrar unidade
                </Button>
              </RestrictedDynamic>
            </>
          }
        />

        <div className="row">
          <div className="col-md-2 mb-s-150">
            <DataLoader
              data={
                <img
                  src={data?.logoUrl || defaultImage}
                  alt="logo"
                  height={120}
                  className="border-radius-100"
                />
              }
              height={120}
              isLoading={!data}
            />
          </div>
          <div className="col-md-10 align-center">
            <div className="col-md-12">
              <h4>Nome</h4>
              <span>
                <DataLoader data={data?.name} isLoading={!data} />
              </span>
            </div>
            <div className="col-md-6 mt-s-250">
              <h4>Status</h4>
              <div className="row d-flex">
                <span>{data?.status}</span>
                <div
                  className={`little-ball-${
                    data?.status === 'Ativo' ? 'green' : 'red'
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </div>

        <Tab
          tabSelected={0}
          tabs={[
            {
              component: (
                <EstablishmentInfo establishmentInfo={data} fetch={fetch} />
              ),
              title: 'Informações',
            },
            {
              component: (
                <EstablishmentUnits establishmentInfo={data} fetch={fetch} />
              ),
              title: 'Unidades',
            },
            {
              component: (
                <EstablishmentVouchers establishmentInfo={data} fetch={fetch} />
              ),
              title: 'Vouchers',
            },
            {
              component: (
                <Restricted context="report" resource="operational">
                  <EstablishmentDashboard establishmentName={data?.name!} />
                </Restricted>
              ),
              title: 'Análises',
            },
          ]}
        />
      </div>

      {!!isOpenEstablishmentUnitModal && (
        <Modal
          isOpen={!!isOpenEstablishmentUnitModal}
          onClickAway={() => setIsOpenEstablishmentUnitModal('')}
        >
          <EstablishmentUnitsForm
            establishmentId={data!.id!}
            onSave={() => {
              fetch(data!.id!);
            }}
            closeModal={() => setIsOpenEstablishmentUnitModal('')}
          />
        </Modal>
      )}

      {!!isOpenVoucherModal && (
        <Modal
          isOpen={!!isOpenVoucherModal}
          onClickAway={() => setIsOpenVoucherModal('')}
        >
          <VouchersForm
            establishmentId={data!.id!}
            onSave={() => {
              fetch(data!.id!);
            }}
            closeModal={() => setIsOpenVoucherModal('')}
          />
        </Modal>
      )}
    </>
  );
}
