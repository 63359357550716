import { FieldValues, UseFormSetError } from 'react-hook-form';

import { handleErrorForm } from '@services/api';
import { ClientAccessReason } from '@interfaces/ClientAccessReason';
import { reverseKeyValueObject } from 'utils/object/index';
import { parseDateHour } from 'utils/string/dates';

import {
  ClientAccessReasonFilters,
  create,
  getOptions,
  list,
  remove,
  retrieve,
  retrieveHistory,
  update,
} from './calls';

export * from './calls';

export const clientAccessActionMap: { [key: string]: string } = {
  GRANT: 'Liberação',
  REMOVE: 'Cancelamento',
};

export default class ClientAccessReasonAPICaller {
  static adaptFromAPI = (data: ClientAccessReason) => {
    return {
      ...data,
      clientAccessAction: clientAccessActionMap[data.clientAccessAction],
    } as FieldValues;
  };

  static adaptToAPI = (data: FieldValues) => {
    return {
      ...data,
      clientAccessAction: reverseKeyValueObject(clientAccessActionMap)[
        data.clientAccessAction.value
      ],
    } as ClientAccessReason;
  };

  static list = async (filters: ClientAccessReasonFilters) => {
    const {
      data: { clientAccessReasons },
    } = await list(filters);

    return {
      ...clientAccessReasons,
      result: clientAccessReasons.result.map(this.adaptFromAPI),
    };
  };

  static fetchOptions = async () => {
    const {
      data: { options },
    } = await getOptions();

    options.clientAccessAction = options.clientAccessAction.map(
      (caa: string) => clientAccessActionMap[caa]
    );

    return options;
  };

  static fetch = async (editId: string) => {
    const {
      data: { clientAccessReason },
    } = await retrieve(editId);

    clientAccessReason.clientAccessAction =
      clientAccessActionMap[clientAccessReason.clientAccessAction];

    return clientAccessReason;
  };

  static createOrUpdate = async (
    data: FieldValues,
    setError: UseFormSetError<FieldValues>
  ) => {
    const method = data.id ? update : create;

    const result = method(this.adaptToAPI(data)).catch(
      handleErrorForm(setError)
    );

    return result;
  };

  static remove = async (id: string) => {
    const { data } = await remove(id);

    return data;
  };

  static fetchHistory = async (filters: ClientAccessReasonFilters) => {
    const {
      data: { clientAccessReasonHistory },
    } = await retrieveHistory(filters);

    return {
      ...clientAccessReasonHistory,
      result: clientAccessReasonHistory.result.map(
        (it: { action: string; createdAt: string }) => ({
          ...it,
          createdAt: parseDateHour(it.createdAt),
        })
      ),
    };
  };
}
