export default function decimalMask({ nextState }: { nextState: any }) {
  const { value } = nextState || {};

  let numberFormatted = value?.replace?.(/\D/g, '');
  numberFormatted = numberFormatted?.replace?.(/^0+/g, '');

  if (numberFormatted?.length <= 3) {
    const formattedNumber = numberFormatted.padStart(3, '0');
    return {
      ...nextState,
      value: `${formattedNumber}`,
      selection: {
        start: formattedNumber.length + 2,
        end: formattedNumber.length + 2,
      },
    };
  }

  if (numberFormatted?.length > 3) {
    const integerPart = numberFormatted.slice(0, numberFormatted.length - 2);
    const decimalPart = numberFormatted.slice(-2);
    const formattedNumber = `${integerPart}.${decimalPart}`;
    return {
      ...nextState,
      value: `${formattedNumber}`,
      selection: {
        start: formattedNumber.length + 2,
        end: formattedNumber.length + 2,
      },
    };
  }

  return nextState;
}
