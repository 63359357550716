import { useForm } from 'react-hook-form';
import { useEffect } from 'react';

import { Button, Checkbox, Icon } from '@components';
import { Banner } from '@interfaces/Banner';

interface Props {
  deleteBanner: (id: string) => void;
  activeOrInactiveBanner: (id: string, isActive: boolean) => void;
}

export default function BannerCard(item: Banner & Props) {
  const { register, setValue } = useForm();

  useEffect(() => {
    setValue(item.id!, item.chosen);
  }, [item.chosen, item.id, setValue]);

  const cardClassName = ['card'];

  if (item.chosen) cardClassName.push('banner-chosen');

  return (
    <div className="col-md-4">
      <div className={cardClassName.join(' ')}>
        <div className="banner-image-container">
          <img
            src={item.imageUrl}
            className="banner-image"
            alt={`${item.description}`}
          />
        </div>
        <main className="card__body">
          <header className="card__header">
            <section className="card__header__title__container d-flex">
              <Checkbox
                label=""
                form={register(item.id!, {
                  onChange: (event) => {
                    item.activeOrInactiveBanner(item.id!, event.target.checked);
                  },
                })}
              />
              <h1 className="card__header__subtitle">{item.description}</h1>
            </section>
            <section className="card__header__checkbox__wrapper"></section>
          </header>
          <footer className="card__footer">
            <section className="card__footer__action__container card__footer__action__container--left">
              <Button
                design="outlined"
                prefixes={<Icon>delete</Icon>}
                onClick={() => item.deleteBanner(item.id!)}
                children={'Excluir'}
              />
            </section>
          </footer>
        </main>
      </div>
    </div>
  );
}
