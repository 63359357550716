import { FieldValues, UseFormSetError } from 'react-hook-form';

import { handleErrorForm } from '@services/api';
import { Benefit } from '@interfaces/Benefit';

import {
  BenefitFilters,
  create,
  list,
  listAll,
  remove,
  retrieve,
  update,
} from './calls';

export * from './calls';

export default class BenefitAPICaller {
  static adaptFromAPI = (data: Benefit) => data as FieldValues;
  static adaptToAPI = (data: FieldValues) => data as Benefit;

  static list = async (filters: BenefitFilters) => {
    const {
      data: { benefits },
    } = await list(filters);

    return { ...benefits, result: benefits.result.map(this.adaptFromAPI) };
  };

  static all = async () => {
    const { data } = await listAll();
    return data.benefits;
  };

  static fetch = async (editId: string) => {
    const response = await retrieve(editId);
    return response;
  };

  static createOrUpdate = async (
    data: FieldValues,
    setError: UseFormSetError<FieldValues>
  ) => {
    const method = data.id ? update : create;

    const result = method(this.adaptToAPI(data)).catch(
      handleErrorForm(setError)
    );

    return result;
  };

  static remove = async (id: string) => {
    const { data } = await remove(id);

    return data;
  };
}
