import { memo, useCallback, useEffect, useId, useMemo } from 'react';
import Select, {
  OnChangeValue,
  Props as SelectProps,
  SingleValue,
} from 'react-select';

import { useFetchUsers } from '@hooks/user/useFetchUsers';
import { UserWithId } from '@interfaces/User';
import { getArrayOfObjectFilterByNonUndefinedKey } from 'utils/array';
import { selectContainerClasses } from '@components/Select/Select';

type InputValue<IsMulti extends boolean> = IsMulti extends true
  ? string[]
  : IsMulti extends false
    ? string | null
    : never;

type ExtendableSelectProps<Option, IsMulti extends boolean> = Omit<
  SelectProps<Option, IsMulti>,
  | 'id'
  | 'aria-label'
  | 'value'
  | 'options'
  | 'onChange'
  | 'isLoading'
  | 'getOptionValue'
  | 'className'
  | 'classNamePrefix'
  | 'noOptionsMessage'
  | 'isMulti'
  | 'isDisabled'
  | 'aria-invalid'
>;

type Props<IsMulti extends boolean> = {
  usersFilter: Parameters<ReturnType<typeof useFetchUsers>['fetchUsers']>[0];
  value: InputValue<IsMulti>;
  isMulti?: IsMulti;
  label?: string;
  className?: string;
  disabled?: boolean;
  error?: boolean;
  caption?: string;
  onChange: (changedValue: InputValue<IsMulti>) => void;
} & ExtendableSelectProps<UserWithId, IsMulti>;

const getPlaceholder = (
  placeholder: Pick<SelectProps, 'placeholder'>['placeholder'],
  isMulti: boolean
) => {
  if (placeholder) {
    return placeholder;
  }

  return isMulti ? 'Escolher usuários' : 'Escolher usuário';
};

function UserSelectBase<IsMulti extends boolean = true>({
  usersFilter,
  isMulti = true as IsMulti,
  value,
  label,
  onChange,
  error,
  disabled,
  className,
  caption,
  getOptionLabel = ({ name }) => name,
  placeholder: placeholderProp,
  ...props
}: Props<IsMulti>) {
  const id = useId();

  const { fetchUsers, isFetchingUsers, users } = useFetchUsers();

  const usersWithId = useMemo(() => {
    return getArrayOfObjectFilterByNonUndefinedKey(users, 'id');
  }, [users]);

  const selectedUsers = useMemo(() => {
    if (Array.isArray(value)) {
      return usersWithId.filter(({ id }) => {
        return value.includes(id);
      });
    }

    return (
      usersWithId.find((user) => {
        return user.id === value;
      }) || null
    );
  }, [value, usersWithId]);

  const containerClasses = useMemo(() => {
    return selectContainerClasses({ error, disabled, className });
  }, [error, disabled, className]);

  const placeholder = useMemo(() => {
    return getPlaceholder(placeholderProp, isMulti);
  }, [placeholderProp, isMulti]);

  const handleOnChange = useCallback(
    (newValue: OnChangeValue<UserWithId, IsMulti>) => {
      if (Array.isArray(newValue) && isMulti) {
        return onChange(
          newValue.map(({ id }) => id) as InputValue<typeof isMulti>
        );
      }
      if (!newValue) {
        return onChange(null as InputValue<typeof isMulti>);
      }
      return onChange(
        (newValue as SingleValue<UserWithId>)?.id as InputValue<typeof isMulti>
      );
    },
    [isMulti, onChange]
  );

  useEffect(() => {
    fetchUsers(usersFilter);
  }, [fetchUsers, usersFilter]);

  return (
    <div className={containerClasses}>
      {label && (
        <label htmlFor={id} className="form-input__label">
          {label}
        </label>
      )}
      <Select<UserWithId, IsMulti>
        id={id}
        isMulti={isMulti}
        aria-label={label}
        value={selectedUsers}
        options={usersWithId}
        onChange={handleOnChange}
        isLoading={isFetchingUsers}
        isDisabled={disabled}
        aria-invalid={error}
        getOptionValue={({ id }) => id}
        getOptionLabel={getOptionLabel}
        className="form-select"
        classNamePrefix="form-select"
        noOptionsMessage={() => 'Nenhum usuário encontrado'}
        placeholder={placeholder}
        {...props}
      />
      {caption && (
        <span className="text-left form-input__caption">{caption}</span>
      )}
    </div>
  );
}

export const UserSelect = memo(UserSelectBase) as typeof UserSelectBase;
