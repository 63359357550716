import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  Button,
  Icon,
  Input,
  InputAddon,
  InputIcon,
  Modal,
  PageHeader,
} from '@components';
import { useConfirmationModal } from '@hooks/useConfirmationModal';
import { Banner } from '@interfaces/Banner';
import { RepositoryParams } from '@services/api';
import BannersAPICaller, { BannerFilters } from '@services/api/banner';
import { debounce } from 'lodash';

import { BannerCard } from './BannerCard';
import { BannersForm } from './BannerForm';

export default function BannersPage() {
  const [banner, setBanners] = useState<{ total: number; result: Banner[] }>({
    total: 0,
    result: [],
  });
  const [filters, setFilters] = useState<BannerFilters>({ order: '-chosen' });

  const [isOpenModal, toggleModal] = useState(false);
  const confirmation = useConfirmationModal();
  const { register } = useForm();

  const fetch = (filters: RepositoryParams) => {
    BannersAPICaller.fetchBanners(filters).then((values) => setBanners(values));
  };

  const listFilter = debounce((query) => {
    setFilters({ ...filters, q: query });
  }, 500);

  useEffect(() => {
    fetch(filters);
  }, [filters]);

  const deleteBanner = (id: string) => {
    confirmation({
      title: 'Excluir Banner',
      description: 'Deseja realmente excluir o banner de login?',
      confirmButtonTitle: 'Excluir',
      onSubmit: () =>
        BannersAPICaller.deleteBanner({ id }).then((_) => fetch(filters)),
    });
  };

  const activeOrInactiveBanner = (id: string, isActive = true) => {
    BannersAPICaller.activeOrInactiveBanner({ id, chosen: isActive }).then(
      (_) => fetch(filters)
    );
  };

  return (
    <>
      <div className="p-s-200">
        <PageHeader
          title="Banner do Login"
          actions={
            <Button
              onClick={() => {
                toggleModal(true);
              }}
              weight="bold"
              prefixes={<Icon>add</Icon>}
            >
              Cadastrar
            </Button>
          }
        />

        <Input
          className="w-100"
          type="search"
          placeholder="Pesquisar"
          form={register('search', {
            onChange: (e: any) => {
              listFilter(e.target.value);
            },
          })}
          prefixes={
            <InputAddon left>
              <InputIcon>search</InputIcon>
            </InputAddon>
          }
        />

        <div className="row grid-gap-1">
          {banner.result.map((item) => {
            return (
              <BannerCard
                key={item.id!}
                {...item}
                deleteBanner={deleteBanner}
                activeOrInactiveBanner={activeOrInactiveBanner}
              />
            );
          })}
        </div>
      </div>

      <Modal
        isOpen={isOpenModal}
        onClickAway={() => {
          toggleModal(false);
        }}
      >
        <BannersForm
          onSave={() => fetch(filters)}
          closeModal={() => {
            toggleModal(false);
          }}
        />
      </Modal>
    </>
  );
}
