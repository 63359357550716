import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Button, FileUpload, Input } from '@components';
import BannersAPICaller from '@services/api/banner';

interface Props {
  closeModal: () => void;
  onSave: () => void;
}

export default function BannersForm(props: Props) {
  const { createOrUpdate } = BannersAPICaller;

  const [isSaving, setIsSaving] = useState(false);

  const {
    register,
    setError,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <div className="container p-s-200">
      <div className="modal-title">
        <h3>{'Cadastrar banner do login'}</h3>
      </div>
      <form
        autoComplete="off"
        className="form-max-height"
        onSubmit={handleSubmit((data) => {
          setIsSaving(true);
          return createOrUpdate(data, setError)
            .then((res) => {
              if (res.data.errors) return;
              props.closeModal();
              props.onSave();
            })
            .finally(() => setIsSaving(false));
        })}
      >
        <div className="column grid-gap-1">
          <div className="col-md-12">
            <Input
              label="Descrição"
              error={!!errors.description}
              caption={errors.description?.message as string}
              placeholder="Descrição"
              form={register('description', { required: 'Obrigatório' })}
            />
          </div>

          <div className="col-md-12">
            <FileUpload
              uploadPreview
              label="Arraste ou clique aqui para enviar imagem"
              accept="image/*"
              maxSizeInBytes={5_000_000}
              onChange={([file]) => {
                setValue('image', file, { shouldValidate: true });
              }}
              error={errors.image?.message as string}
              form={register('image', { required: 'Obrigatório' })}
            />
          </div>
        </div>
        <div className="row justify-end pt-s-400" style={{ gap: 16 }}>
          <Button design="transparent" onClick={props.closeModal}>
            Cancelar
          </Button>
          <Button isLoading={isSaving} type="submit">
            Salvar
          </Button>
        </div>
      </form>
    </div>
  );
}
