import { useForm } from 'react-hook-form';

import { Button, Input } from '@components';
import UserClientsAPICaller from '@services/api/userClients';
import { formEmailPattern } from '@validations/email';

interface Props {
  closeModal: () => void;
  onSave: () => void;
  userClientUUID: string;
}

export default function UserClientForm(props: Props) {
  const { update } = UserClientsAPICaller;

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isLoading },
  } = useForm({
    defaultValues: async () => await fetch(),
  });

  const fetch = async () => {
    const data = await UserClientsAPICaller.fetch(props.userClientUUID);

    return data;
  };

  return (
    <div className="container p-s-200">
      <div className="modal-title">
        <h3>Editar Usuário - Cliente</h3>
      </div>
      <form
        autoComplete="off"
        onSubmit={handleSubmit((data) => {
          return update(data, setError).then((res) => {
            if (res.data.errors) return;
            props.closeModal();
            props.onSave();
          });
        })}
      >
        <div className="row grid-gap-1">
          <div className="col-md-12">
            <Input
              disabled={isSubmitting || isLoading}
              placeholder="Email"
              form={register('email', {
                required: 'Obrigatório',
                ...formEmailPattern,
              })}
              error={!!errors.email}
              caption={errors.email?.message as string}
            />
          </div>
        </div>

        <div className="row justify-end pt-s-400" style={{ gap: 16 }}>
          <Button design="transparent" onClick={props.closeModal}>
            Cancelar
          </Button>
          <Button isLoading={isSubmitting || isLoading} type="submit">
            Salvar
          </Button>
        </div>
      </form>
    </div>
  );
}
