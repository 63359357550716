import { ReactNode, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  Button,
  Checkbox,
  Chip,
  Drawer,
  FileUpload,
  Icon,
  Input,
  InputAddon,
  InputIcon,
  Map,
  Modal,
  RadioButton,
  RadioForm,
  SelectInput as Select,
  Switch,
  Table,
  TableColumn,
} from '@components';
import ColorPickerInput from '@components/Input/ColorPickerInput';
import DatePickerInput from '@components/Input/DatePickerInput';
import Tab from '@components/Tab/Tab';
import { SelectedKeys } from '@components/Table';
import { readFile } from '@services/io/file';
import { formColorPattern } from '@validations/color';
import GoogleMapsIcon from '@assets/gmaps.webp';
import { formDatePattern } from '@validations/date';
import logo from '@assets/logo.png';
import { useGeolocation } from '@hooks/useGeolocation';

function tableExampleData() {
  return [
    { name: 'John', age: 20, status: 'inactive' },
    { name: 'Jane', age: 21, status: 'active' },
    { name: 'Jack', age: 22, status: <Switch /> },
  ];
}

export default function Aquario() {
  const {
    watch: drawerOpen,
    setValue: setDrawerOpen,
    register: controlDrawer,
  } = useForm();
  const { getCurrentLocation, latLng } = useGeolocation();

  const [isOpenA, toggleA] = useState(false);
  const [isOpenB, toggleB] = useState(false);
  const [isMapModalOpen, setMapModalOpen] = useState(false);

  const [fileContent, setFileContent] = useState('');
  const [fileName, setFileName] = useState('');

  const [selectedKeys, setSelectedKeys] = useState<SelectedKeys>({});
  const [invertSelection, setInvertSelection] = useState(false);

  useEffect(() => {
    getCurrentLocation();
  }, [getCurrentLocation]);

  const {
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue('latitude', latLng.latitude);
    setValue('longitude', latLng.longitude);
  }, [latLng, setValue]);

  return (
    <>
      <Tab
        tabSelected={0}
        tabs={[
          {
            icon: 'mfg_nest_yale_lock',
            title: 'Estabelecimento',
            component: <div className="row">Estabelecimento</div>,
          },
          {
            title: 'Endereço',
            component: <div className="row">Endereço</div>,
          },
          {
            title: 'Voucher',
            component: <div className="row">Voucher</div>,
          },
        ]}
      />

      <Modal isOpen={isOpenA} onClickAway={() => toggleA((p) => !p)}>
        <div>A</div>
      </Modal>
      <Modal isOpen={isOpenB} onClickAway={() => toggleB((p) => !p)}>
        <div>B</div>
      </Modal>
      <Modal
        isOpen={isMapModalOpen}
        onClickAway={() => setMapModalOpen((p) => !p)}
      >
        <Map
          value={{
            lat: watch('latitude'),
            lng: watch('longitude'),
          }}
          onChange={(value) => {
            setValue('latitude', value.lat);
            setValue('longitude', value.lng);
          }}
        />
      </Modal>
      <div className="d-flex">
        <div className="mt-s-200">
          <h2>Inputs</h2>
          <Input
            label="Email"
            caption="Nice email!"
            prefixes={<InputAddon left>email here!</InputAddon>}
            suffixes={<InputAddon right>@gmail.com</InputAddon>}
            placeholder="username"
          ></Input>
          <Button
            color="negative"
            className="ml-s-100"
            size="small"
            onClick={() => alert('hi')}
          >
            Print hi
          </Button>
          <Input
            size="small"
            label="Small username"
            caption="Nice username!"
            prefixes={
              <>
                <InputAddon left>
                  <InputIcon>person</InputIcon>
                </InputAddon>
                <InputAddon center>user:</InputAddon>
              </>
            }
            placeholder="username"
          ></Input>
          <Input
            size="large"
            prefixes={<InputAddon left>can't type</InputAddon>}
            label="Large disabled input"
            disabled
          ></Input>

          <Input
            prefixes={
              <InputAddon left>
                <InputIcon>close</InputIcon>
              </InputAddon>
            }
            label="Error input"
            caption="Error input is invalid"
            error
          ></Input>
          <h2>Checkbox</h2>
          <Checkbox label="Default checkbox" />
          <Checkbox label="Selected checkbox" />
          <Checkbox indeterminate label="Indeterminate checkbox" />
          <Checkbox disabled label="Default checkbox disabled" />
          <h2>Radio</h2>
          <RadioForm
            name="radioForm"
            form={register('example', { value: 'value 3' })}
          >
            <RadioButton label="Enabled default unchecked" value="value 1" />
            <RadioButton label="Enabled default checked" value="value 2" />
            <RadioButton
              label="Disabled default checked"
              value="value 3"
              disabled
            />
          </RadioForm>
          <h2>Switch</h2>
          <Switch label="Default switch" name="switchExample" />
          <Switch label="Checked switch" name="switchExample" />
          <Switch
            label="Default switch disabled"
            disabled
            name="switchExample2"
          />
          <Switch
            label="Checked switch disabled"
            disabled
            name="switchExample2"
          />
          <h2>Drawers</h2>
          <Drawer
            closable
            float="left"
            size="xs"
            open={drawerOpen('1')}
            onClickAway={() => setDrawerOpen('1', false)}
          >
            Hi
          </Drawer>
          <Checkbox
            form={controlDrawer('1')}
            label="Open closable drawer (+overlay)"
          />
          <Drawer
            className="bg-secondary"
            overlay={false}
            open={drawerOpen('2')}
            onClickAway={() => setDrawerOpen('2', false)}
          >
            Content
          </Drawer>
          <Checkbox form={controlDrawer('2')} label="Open unclosable drawer" />
          <h2>Chips</h2>
          <div className="d-flex">
            <Chip className="ml-s-100">Text</Chip>
            <Chip className="ml-s-100" suffixes={<Icon>close</Icon>}>
              Text
            </Chip>
            <Chip className="ml-s-100" outlined>
              Text
            </Chip>
            <Chip className="ml-s-100" outlined suffixes={<Icon>close</Icon>}>
              Text
            </Chip>
            <Chip color="secondary" className="ml-s-100" outlined>
              Text
            </Chip>
            <Chip
              color="secondary"
              className="ml-s-100"
              outlined
              prefixes={<Icon>person</Icon>}
            >
              Text
            </Chip>
          </div>
          <div className="d-flex mt-s-100">
            <Chip color="tertiary" size="small" className="ml-s-100">
              Text
            </Chip>
            <Chip color="tertiary" size="small" outlined className="ml-s-100">
              Text
            </Chip>
            <Chip color="tertiary" size="medium" className="ml-s-100">
              Text
            </Chip>
            <Chip color="tertiary" className="ml-s-100">
              Text
            </Chip>
            <Chip disabled color="primary" className="ml-s-100">
              Text
            </Chip>
            <Chip disabled color="primary" outlined className="ml-s-100">
              Text
            </Chip>
            <Chip
              onClick={() => alert('alert')}
              color="alert"
              className="ml-s-100"
            >
              Text
            </Chip>
            <Chip href="#hi" target="_self" color="alert" className="ml-s-100">
              Text
            </Chip>
          </div>
          <h2>Buttons</h2>
          <div className="d-flex mb-s-100">
            <Button className="ml-s-100" size="small" onClick={toggleA}>
              Modal opener A
            </Button>
            <Button className="ml-s-100" onClick={toggleB}>
              Modal opener B
            </Button>
            <Button className="ml-s-100" size="large">
              Button
            </Button>
            <Button className="ml-s-100" disabled>
              Button
            </Button>
            <Button className="ml-s-100" design="transparent">
              Button
            </Button>
            <Button className="ml-s-100" design="outlined">
              Button
            </Button>
            <Button
              className="ml-s-100"
              prefixes={<Icon>close</Icon>}
              suffixes={<Icon>close</Icon>}
              design="filled"
            >
              Button
            </Button>
          </div>
          <div className="d-flex mb-s-100">
            <Button color="secondary" className="ml-s-100" size="small">
              Button
            </Button>
            <Button color="positive" className="ml-s-100">
              Button
            </Button>
            <Button color="secondary" className="ml-s-100" size="large">
              Button
            </Button>
            <Button color="secondary" className="ml-s-100" disabled>
              Button
            </Button>
            <Button color="secondary" className="ml-s-100" design="transparent">
              Button
            </Button>
            <Button color="secondary" className="ml-s-100" design="outlined">
              Button
            </Button>
            <Button
              color="secondary"
              className="ml-s-100"
              prefixes={<Icon>close</Icon>}
              suffixes={<Icon>close</Icon>}
              design="filled"
            >
              Button
            </Button>
          </div>
          <div className="d-flex mb-s-100">
            <Button color="tertiary" className="ml-s-100" size="small">
              Button
            </Button>
            <Button color="tertiary" className="ml-s-100">
              Button
            </Button>
            <Button color="tertiary" className="ml-s-100" size="large">
              Button
            </Button>
            <Button color="tertiary" className="ml-s-100" disabled>
              Button
            </Button>
            <Button color="tertiary" className="ml-s-100" design="transparent">
              Button
            </Button>
            <Button color="tertiary" className="ml-s-100" design="outlined">
              Button
            </Button>
            <Button
              color="tertiary"
              className="ml-s-100"
              prefixes={<Icon>close</Icon>}
              suffixes={<Icon>close</Icon>}
              design="filled"
            >
              Button
            </Button>
          </div>
          <div className="d-flex mb-s-100">
            <Button color="negative" className="ml-s-100" size="small">
              Button
            </Button>
            <Button color="alert" className="ml-s-100">
              Button
            </Button>
            <Button color="positive" className="ml-s-100" size="large">
              Button
            </Button>
            <Button color="neutral" className="ml-s-100" disabled>
              Button
            </Button>
            <Button color="negative" className="ml-s-100" design="transparent">
              Button
            </Button>
            <Button color="positive" className="ml-s-100" design="outlined">
              Button
            </Button>
            <Button
              color="alert"
              className="ml-s-100"
              prefixes={<Icon>close</Icon>}
              suffixes={<Icon>close</Icon>}
              design="filled"
            >
              Button
            </Button>
          </div>
          <h2>File Upload</h2>
          <div>
            <div className="row mb-s-200">
              <div className="col-sm-2">
                <FileUpload hideLabel />
              </div>
              <div className="col-sm-2 ml-s-200">
                <FileUpload hideLabel image={logo} isRemovable uploadPreview />
              </div>
              <div className="col-sm-6 ml-s-200">
                <FileUpload uploadPreview />
              </div>
            </div>
            <div className="row mb-s-200">
              <FileUpload
                accept="text/plain"
                onChange={([file]) => {
                  if (file) {
                    setFileName(file.name);
                    readFile(file).then((content) => setFileContent(content));
                  }
                }}
              />
            </div>
            <div className="row mb-s-200">
              <div className="col-sm-6">
                <p>File Name: {fileName}</p>
                <span>
                  File Content:{' '}
                  <pre className="bg-neutral text-neutral-90 p-s-200">
                    {fileContent}
                  </pre>
                </span>
              </div>
            </div>
          </div>
          <h2>Table</h2>
          <Table
            data={[
              {
                id: 1,
                name: 'John Doe',
                email: 'john@ateliware.com',
              },
            ]}
            showColumnSelector={true}
          >
            <TableColumn fromKey="id" header="Id" />
            <TableColumn fromKey="name" header="Name" />
            <TableColumn fromKey="email" header="E-mail" />
          </Table>
          <Table
            checkable
            hoverable
            layout="fixed"
            onAction={() => alert('bulk action')}
            data={tableExampleData()}
            dataKey="name"
            onSelectionChange={(selectedKeys, invertSelection) => {
              setSelectedKeys(selectedKeys);
              setInvertSelection(invertSelection);
            }}
          >
            <TableColumn
              fromKey="name"
              header="Account"
              container={true}
              filterType="text"
              disableSorting
              render={(name) => (
                <>
                  <div className="mr-s-200">
                    <span className="table__cell__icon material-symbols-outlined">
                      account_circle
                    </span>
                  </div>
                  <div>
                    <p className="table__cell__title">{name}</p>
                    <p className="table__cell__subtitle">{name}</p>
                  </div>
                </>
              )}
            />
            <TableColumn
              fromKey="name"
              header="Name"
              onAction={(column) => alert(`action ${column}`)}
            />
            <TableColumn fromKey="age" header="Age" />
            <TableColumn
              fromKey="status"
              header="Status"
              filterType="select"
              filterOptions={[
                { label: 'Ativo', value: 'true' },
                { label: 'Inativo', value: 'false' },
              ]}
              onAction={(column) => alert(`action ${column}`)}
              render={(status: ReactNode) => {
                const statusColor =
                  status === 'active' ? 'positive' : 'negative';
                return (
                  <Chip
                    color="transparent"
                    prefixes={
                      <Icon className={`text-${statusColor}`}>circle</Icon>
                    }
                  >
                    {status === 'active'}
                    <strong className={`text-${statusColor}`}>{status}</strong>
                  </Chip>
                );
              }}
            />
          </Table>
          <p className="mt-s-200 mb-s-200">
            <strong>Selected keys: </strong>
            {invertSelection && 'everyone except: '}
            {Object.keys(selectedKeys).join(', ')}
          </p>
          <h2>Integrations / APIs</h2>
          <div className="row mb-s-200">
            <div className="card card__body p-s-150">
              <b>Localização no mapa</b>
              <div className="row">
                <Input
                  label="Latitude"
                  form={register('latitude')}
                  error={!!errors.latitude}
                  caption={errors.latitude?.message as string}
                />
              </div>
              <div className="row">
                <Input
                  label="Longitude"
                  form={register('longitude')}
                  error={!!errors.longitude}
                  caption={errors.longitude?.message as string}
                />
              </div>
              <div className="row">
                <Chip
                  color="blue"
                  className="mt-s-200 font-weight-bold"
                  prefixes={
                    <img
                      alt="Google Maps icon"
                      width="25"
                      src={GoogleMapsIcon}
                    />
                  }
                  onClick={() => {
                    setMapModalOpen(true);
                  }}
                >
                  Google Maps
                </Chip>
              </div>
            </div>
          </div>
          <h2>Forms</h2>
          <form
            autoComplete="off"
            className="form-max-height"
            onSubmit={handleSubmit((data) => console.log(data))}
          >
            <Input
              label="Username"
              error={!!errors.username}
              caption={errors.username?.message as string}
              form={register('username', { required: 'Username is required' })}
            />
            <DatePickerInput
              label="Date"
              dateSelected={watch('datepicker')}
              error={!!errors.datepicker}
              caption={errors.datepicker?.message as string}
              form={register('datepicker', {
                required: 'Obrigatório',
                ...formDatePattern,
              })}
              onSelect={(date: String) => {
                setValue('datepicker', date);
              }}
            />
            <ColorPickerInput
              label="Color"
              colorSelected={watch('colorpicker')}
              error={!!errors.colorpicker}
              caption={errors.colorpicker?.message as string}
              form={register('colorpicker', {
                required: 'Obrigatório',
                ...formColorPattern,
              })}
              onSelect={(color: String) => {
                setValue('colorpicker', color);
              }}
            />
            <Input
              label="Message"
              disabled
              error={!!errors.message}
              caption={errors.message?.message as string}
              form={register('message', { required: 'Message is required' })}
            />

            <Select
              label="Select option"
              value={watch('option')}
              error={!!errors.option}
              caption={errors.option?.message as string}
              placeholder="Select an option"
              form={register('option', { required: 'Obrigatório' })}
              onSelect={(value) => {
                setValue('option', value);
              }}
              options={[
                { label: 'Option 1', value: 'option 1' },
                { label: 'Option 2', value: 'option 2' },
              ]}
            />

            <Checkbox
              label="I agree to the terms and conditions"
              error={errors.agree?.message as string}
              form={register('agree', { required: 'You must agree' })}
            />

            <Switch
              label="Switch it on to continue"
              error={errors.continue?.message as string}
              form={register('continue', { required: 'You must switch it on' })}
            />

            <RadioForm
              name="radioForm"
              error={errors.radio?.message as string}
              form={register('radio', {
                value: 'value 3',
                validate: (value) =>
                  value !== 'value 2' || 'Select another option!',
              })}
            >
              <RadioButton label="Enabled default unchecked" value="value 1" />
              <RadioButton label="Dont select this one" value="value 2" />
              <RadioButton
                label="Disabled default checked"
                value="value 3"
                disabled
              />
            </RadioForm>

            <div className="mt-s-200 mb-s-200 col-sm-3">
              <FileUpload
                uploadPreview
                hideLabel
                error={errors.files?.message as string}
                fileName={watch('files')?.name}
                onChange={([file]) => {
                  setValue('files', file, { shouldValidate: true });
                }}
                form={register('files', { required: 'File is required' })}
              />
            </div>

            <Button className="float-right mb-s-200" type="submit">
              Submit
            </Button>
          </form>
        </div>
      </div>
    </>
  );
}
