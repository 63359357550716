import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Button, SelectInput as Select } from '@components';
import { SelectOptions } from '@interfaces/Utils';
import EmphasisAPICaller from '@services/api/emphasis';

interface Props {
  closeModal: () => void;
  onSave: () => void;
  emphasisId: string;
}

export default function EmphasisVoucherForm(props: Props) {
  const { addEmphasisedVoucher } = EmphasisAPICaller;
  const [vouchers, setVouchers] = useState<SelectOptions[]>([]);

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting, isLoading },
  } = useForm({
    defaultValues: async () => await fetchData(),
  });

  const fetchData = async () => {
    await EmphasisAPICaller.listVouchersEmphasisable(props.emphasisId).then(
      (values) => {
        setVouchers(values);
      }
    );

    return null;
  };

  return (
    <div className="container p-s-200">
      <div className="modal-title">
        <h3>Incluir voucher</h3>
      </div>
      <form
        autoComplete="off"
        onSubmit={handleSubmit((data) => {
          addEmphasisedVoucher(props.emphasisId, data.voucher.value).then(
            (res) => {
              if (res.data.errors) return;
              props.closeModal();
              props.onSave();
            }
          );
        })}
      >
        <div className="row grid-gap-1">
          <div className="col-md-12">
            <Select
              value={watch('voucher')}
              placeholder="Selecionar voucher que deseja vincular"
              disabled={isLoading}
              options={vouchers}
              onSelect={(value) => {
                setValue('voucher', value);
              }}
              form={register('voucher', { required: 'Obrigatório' })}
              error={!!errors.voucher}
              caption={errors.voucher?.message as string}
              label="Voucher"
              fromKey="name"
            />
          </div>
        </div>

        <div className="row justify-end pt-s-400" style={{ gap: 16 }}>
          <Button design="transparent" onClick={props.closeModal}>
            Cancelar
          </Button>
          <Button isLoading={isSubmitting || isLoading} type="submit">
            Salvar
          </Button>
        </div>
      </form>
    </div>
  );
}
