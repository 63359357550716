import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button, LoginPageContainer } from '@components';
import AuthAPICaller from '@services/api/auth';

export default function EmailSentPage() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [email] = useState(searchParams.get('email') || '');

  const handleRecover = async (email: string) => {
    setIsLoading(true);
    await AuthAPICaller.sendEmailRecover(email);
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  };

  return (
    <div className="bg-neutral-95">
      <div className="d-flex justify-center" style={{ height: '100vh' }}>
        <div className="col-sm-12 col-md-10 d-flex justify-center background-login-page">
          <LoginPageContainer>
            <h3 className="!w100 d-flex justify-center p-s-300 text-center">
              E-mail enviado com sucesso
            </h3>

            <span className="!w100 d-flex justify-center p-s-050 text-center">
              Enviamos um link de recuperação para o e-mail abaixo
            </span>

            <span className="!w100 d-flex justify-center p-s-300 text-center">
              {email}
            </span>

            <span className="!w100 font-weight-bold">
              Não recebeu o e-mail?
            </span>

            <Button
              isLoading={isLoading}
              className="!w-100 !mt-s-100"
              alignText="center"
              color="secondary"
              design="outlined"
              onClick={async () => await handleRecover(email)}
            >
              Reenviar link
            </Button>

            <hr className="!mt-s-300" />

            <Button
              design="transparent"
              color="primary"
              className="!pl-0 !mt-s-100"
              alignText="center"
              textClassName="!ml-0"
              onClick={() => navigate('/login')}
            >
              Voltar ao login
            </Button>
          </LoginPageContainer>
        </div>
      </div>
    </div>
  );
}
