import { fetchZipCode } from './calls';

export * from './calls';

export default class GeneralAPICaller {
  static searchZipCode = async (zipCode: string) => {
    const { data } = await fetchZipCode(zipCode);

    return data;
  };
}
