import { FieldValues, UseFormSetError } from 'react-hook-form';

import { AccessGroup } from '@interfaces/AccessGroup';
import { Group } from '@interfaces/Group';
import { Permissions } from '@interfaces/Permissions';
import { RepositoryParams, handleErrorForm } from '@services/api';

import { create, list, listPermissions, retrieve, update } from './calls';
export * from './calls';

export const contexts = {
  users: 'Acesso ao Dashboard',
  accessGroups: 'Grupos de Acesso',
  logs: 'Logs',
  faqs: 'FAQs',
  benefits: 'Benefícios',
  regions: 'Regiões',
  cooking: 'Culinária',
  groups: 'Grupos',
  loginBanner: 'Banner de Login',
  clientAccessReasons: 'Motivos de alteração de acesso',
  shoppingCenters: 'Centro de comércios',
  plans: 'Planos',
  conveniences: 'Conveniências',
  categories: 'Categorias',
  establishments: 'Estabelecimentos',
  establishmentUnits: 'Unidade de Estabelecimentos',
  fixedPermissions: 'Permissões fixas',
  vouchers: 'Vouchers',
  advertisement: 'Anúncio',
  userClients: 'Usuários - Clientes',
  notification: 'Notificação',
  discountCoupons: 'Cupons de desconto',
  tips: 'Dicas',
  tipComments: 'Avaliações e comentários das dicas',
  notifications: 'Notificações',
  emphasis: 'Destaques',
  report: 'Relatórios',
} as { [key: string]: string };

const resources = {
  read: 'Ler',
  create: 'Criar',
  update: 'Editar',
  delete: 'Excluir',
  accessGroups: 'Def. grupo',
  updatePassword: 'Mudar senha',
  setChosen: 'Definir',
  updateOrders: 'Ordenar',
  groups: 'Grupos',
  establishments: 'Estabelecimentos',
  establishmentUnits: 'Unidade de Estabelecimento',
  generateVoucherQRCode: 'Gerar QrCode',
  addPlanToUserClient: 'Liberar acesso',
  addVoucherUsedToUserClient: 'Adicionar voucher usado',
  removePlanFromUserClient: 'Cancelar acesso',
  removeVoucherUsedFromUserClient: 'Resetar o uso do voucher',
  updateStatusUserClientMobileDevice: 'Atualizar status do aparelho',
  addEmphasisedVoucher: 'Adicionar voucher em destaque',
  removeEmphasisedVoucher: 'Remover voucher em destaque',
  getDashboardReport: 'Dashboard',
  getUsageDataReport: 'Dados das utilizações',
  getUsageDataEstablishmentReport: 'Dados das utilizações por estabelecimento',
  getUsageDataByUserEstablishmentReport:
    'Dados das utilizações por usuário estabelecimento',
  getUsageDataByUnitEstablishmentReport:
    'Dados das utilizações por usuário Unidade',
  getUsageDataByUserEstablishmentPhysicalReport:
    'Dados das utilizações por usuário estabelecimento - Físico',
  getUsageDataByUnitEstablishmentPhysicalReport:
    'Dados das utilizações por usuário Unidade - Físico',
  getUsageDataByUnitReport: 'Vouchers por unidade',
  getDiscountCouponReport: 'Cupom de desconto',
  getUsageByUserClientReport: 'Utilizações por cliente',
  dashboard: 'Dashboard',
  general: 'Visão Geral',
  subscription: 'Visão por Assinatura',
  operational: 'Visão por Operação',
} as { [key: string]: string };

const reverseContexts = Object.fromEntries(
  Object.entries(contexts).map(([key, value]) => [value, key])
);

const reverseResources = Object.fromEntries(
  Object.entries(resources).map(([key, value]) => [value, key])
);

export default class AccessGroupAPICaller {
  static adaptFromAPI = (data: AccessGroup) => {
    return {
      ...data,
      permissions: this.adaptPermissionsFromAPI(data.permissions),
    } as FieldValues;
  };
  static adaptToAPI = (data: FieldValues) => {
    return {
      ...data,
      permissions: this.adaptPermissionsToAPI(data.permissions),
    } as AccessGroup;
  };

  static adaptPermissionsListFromAPI = (data: {
    [context: string]: string[];
  }) => {
    const permissions = Object.fromEntries(
      Object.entries(data).map(([context, contextResources]) => [
        contexts[context] ?? context,
        contextResources.map((resource) => resources[resource]),
      ])
    );
    return permissions;
  };

  static adaptPermissionsFromAPI = (data: Permissions) => {
    const permissions = Object.fromEntries(
      Object.entries(data).map(([context, contextResources]) => [
        contexts[context] ?? context,
        Object.fromEntries(
          Object.entries(contextResources).map(([resource, value]) => [
            resources[resource] ?? resource,
            value,
          ])
        ),
      ])
    );
    return permissions;
  };

  static adaptPermissionsToAPI = (data: Permissions) => {
    const permissions = Object.fromEntries(
      Object.entries(data).map(([context, contextResources]) => [
        reverseContexts[context] ?? context,
        Object.fromEntries(
          Object.entries(contextResources).map(([resource, value]) => [
            reverseResources[resource] ?? resource,
            value,
          ])
        ),
      ])
    );
    return permissions;
  };

  static fetchGroups = async (filters: Partial<Group & RepositoryParams>) => {
    const { data } = await list(filters);
    return data.groups;
  };

  static fetchGroup = async (editId: string) => {
    const {
      data: { group },
    } = await retrieve(editId);
    return this.adaptFromAPI(group);
  };

  static fetchPermissions = async () => {
    const { data } = await listPermissions();
    const permissions = this.adaptPermissionsListFromAPI(data.permissions);
    return permissions;
  };

  static createOrUpdate = async (
    data: FieldValues,
    setError: UseFormSetError<FieldValues>
  ) => {
    const method = data.id ? update : create;

    const result = method(this.adaptToAPI(data)).catch(
      handleErrorForm(setError)
    );

    return result;
  };
}
