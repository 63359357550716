import { useCallback, useEffect, useRef, useState } from 'react';

import MetabaseAPICaller from '@services/api/metabase';

type Props = {
  establishmentName: string;
};

export default function EstablishmentDashboard(props: Props) {
  const [iframeUrl, setIframeUrl] = useState('');
  const dashboardEl = useRef(null);

  const fetch = useCallback((establishmentName: string) => {
    const dashParams: { establishment?: string[] | null } = {
      establishment: [establishmentName],
    };

    MetabaseAPICaller.fetchOperational(dashParams).then((iframeUrl) => {
      setIframeUrl(iframeUrl);
    });
  }, []);

  useEffect(() => {
    fetch(props.establishmentName);
  }, [fetch, props.establishmentName]);

  return (
    <div className="row" ref={dashboardEl}>
      <iframe
        src={iframeUrl}
        title="metabase"
        width={(dashboardEl?.current as unknown as HTMLElement)?.offsetWidth}
        height="800"
        allowTransparency
      ></iframe>
    </div>
  );
}
