import { useEffect, useLayoutEffect, useState } from 'react';
import { FieldValues, useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import {
  Button,
  Checkbox,
  FileUpload,
  Icon,
  Input,
  InputTextArea,
  SelectInput as Select,
} from '@components';
import { useFetchEstablishments } from '@hooks/establishment/useFetchEstablishments';
import { useFetchEstablishmentUnits } from '@hooks/establishmentUnit/useFetchEstablishmentUnits';
import { Benefit } from '@interfaces/Benefit';
import { WeekDaysWithCommemorativeDate } from '@interfaces/EstablishmentUnit';
import { Plan } from '@interfaces/Plan';
import { Substitution } from '@interfaces/Substitution';
import { SelectOptions } from '@interfaces/Utils';
import { VoucherStatus, VoucherType } from '@interfaces/Voucher';
import BenefitAPICaller from '@services/api/benefits';
import CategoryAPICaller from '@services/api/categories';
import PlanAPICaller from '@services/api/plans';
import VoucherAPICaller from '@services/api/voucher';
import { fileToBlobURL } from '@services/io/file';
import { formHourPattern } from '@validations/hour';
import { Categories } from 'emoji-picker-react';
import { handleSelectedAll } from 'utils/hasSelectedAll';
import { toValueLabel } from 'utils/object';
import SubstitutionAPICaller from '@services/api/substitutions';

interface Props {
  closeModal: () => void;
  onSave: () => void;
  editId?: string;
  establishmentId?: string;
}

export default function VouchersForm(props: Props) {
  const normalAspect = { width: 1400, height: 650 };
  const advertisingAspect = { width: 1200, height: 325 };
  const { createOrUpdate, fetch } = VoucherAPICaller;
  const [plans, setPlans] = useState<Plan[]>([]);
  const [benefits, setBenefits] = useState<Benefit[]>([]);
  const [categories, setCategories] = useState<Categories[]>([]);
  const [voucherSubstitutions, setVoucherSubstitutions] = useState<
    Substitution[]
  >([]);
  const [substitutions, setSubstitutions] = useState<Substitution[]>([]);

  const { fetchEstablishments, establishments } = useFetchEstablishments();

  const { fetchEstablishmentUnits, establishmentUnits } =
    useFetchEstablishmentUnits();

  const [images, setImages] = useState<File[]>([]);

  const {
    register,
    setError,
    watch,
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting, isLoading },
  } = useForm<
    FieldValues & {
      usableTimes: [{ start: string; end: string; days?: string }];
    }
  >({ defaultValues: async () => fetchData() });

  const {
    fields: FieldsUsableTimes,
    remove: removeUsableTimes,
    append: appendUsableTimes,
  } = useFieldArray({
    control,
    name: 'usableTimes',
  });

  const fetchData = async () => {
    if (props.editId) {
      const voucher = await fetch(props.editId);
      setImages(voucher.images);
      setVoucherSubstitutions(voucher.substitutions);
      await fetchEstablishmentUnits({
        establishmentId: voucher.establishment.id,
        size: 9999,
      });
      return voucher;
    }

    return {
      voucherStatus: toValueLabel(VoucherStatus.active),
      voucherType: toValueLabel(VoucherType.normal),
      usableTimes: [
        {
          start: '',
          end: '',
        },
      ],
    };
  };

  useLayoutEffect(() => {
    fetchEstablishments({ size: 10000, status: 'active' });
    CategoryAPICaller.all().then((values) => setCategories(values));
    PlanAPICaller.all().then((values) => setPlans(values));
    BenefitAPICaller.all().then((values) => setBenefits(values));
    SubstitutionAPICaller.all().then((values) => setSubstitutions(values));
  }, [fetchEstablishments]);

  useEffect(() => {
    if (props.establishmentId) {
      const establishment = establishments.find(
        (it) => it.id === props.establishmentId
      );

      if (establishment) {
        setValue('establishmentId', {
          value: establishment.id!,
          label: establishment.name,
        });

        fetchEstablishmentUnits({
          establishmentId: establishment.id!,
          size: 9999,
        });
      }
    }
  }, [
    props.establishmentId,
    establishments,
    setValue,
    fetchEstablishmentUnits,
  ]);

  const handleCheckboxChange = (substitution: Substitution) => {
    const isSelected = voucherSubstitutions.some(
      (sub) => sub.id === substitution.id
    );

    const updatedSelection = isSelected
      ? voucherSubstitutions.filter((sub) => sub.id !== substitution.id)
      : [...voucherSubstitutions, substitution];

    setVoucherSubstitutions(updatedSelection);
  };

  return (
    <div className="container p-s-200">
      <div className="modal-title">
        <h3>{props.editId ? 'Editar ' : 'Cadastrar '}Voucher</h3>
      </div>
      <form
        autoComplete="off"
        className="form-max-height"
        onSubmit={handleSubmit((data) => {
          data.substitutions = voucherSubstitutions;
          data.images = images;
          if (watch('voucherType')?.value === VoucherType.advertising) {
            data.reward = {
              label: 'ganhe outro igual',
              value: 'ganhe outro igual',
            };
          }

          data.establishmentUnits = handleSelectedAll(
            data.establishmentUnits,
            establishmentUnits as unknown as SelectOptions[]
          );

          return createOrUpdate(data, setError).then((res) => {
            if (res.data.errors) return;
            props.closeModal();
            props.onSave();
          });
        })}
      >
        <div className="row grid-gap-1">
          <div className="col-md-6">
            <Input
              disabled={true}
              error={!!errors.validationCode}
              caption={errors.validationCode?.message as string}
              label="Código de validação"
              placeholder="Código de validação"
              form={register('validationCode')}
            />
          </div>
          <div className="col-md-6">
            <Input
              disabled={isLoading}
              error={!!errors.name}
              caption={errors.name?.message as string}
              label="Nome"
              placeholder="Nome"
              form={register('name', { required: 'Obrigatório' })}
            />
          </div>

          <div className="col-md-6">
            <Select
              value={watch('planId')}
              placeholder="Plano"
              disabled={isLoading}
              options={plans}
              onSelect={(value) => {
                setValue('planId', value);
              }}
              form={register('planId', { required: 'Obrigatório' })}
              error={!!errors.planId}
              caption={errors.planId?.message as string}
              label="Plano"
              fromKey="name"
            />
          </div>
          <div className="col-md-6">
            <Select
              value={watch('categoryId')}
              placeholder="Categoria"
              disabled={isLoading}
              options={categories}
              onSelect={(value) => setValue('categoryId', value)}
              form={register('categoryId', { required: 'Obrigatório' })}
              error={!!errors.categoryId}
              caption={errors.categoryId?.message as string}
              label="Categoria"
              fromKey="name"
            />
          </div>

          <div className="col-md-6">
            <Select
              value={watch('establishmentId')}
              placeholder="Estabelecimento"
              disabled={isLoading || !!props.editId}
              options={establishments}
              onSelect={(value) => {
                setValue('establishmentId', value);
                fetchEstablishmentUnits({
                  establishmentId: value.value || value.id,
                  size: 9999,
                });
              }}
              form={register('establishmentId', {
                required: 'Obrigatório',
              })}
              afterChange={() => setValue('establishmentUnits', [])}
              error={!!errors.establishmentId}
              caption={errors.establishmentId?.message as string}
              label="Estabelecimento"
              fromKey="name"
            />
          </div>

          <div className="col-md-6">
            <Select
              value={watch('establishmentUnits')}
              placeholder="Selecione uma ou mais"
              disabled={isLoading || !watch('establishmentId')}
              options={[
                {
                  id: 'all',
                  name: 'Todos',
                },
                ...establishmentUnits,
              ]}
              isMulti
              onSelect={(value) => {
                setValue('establishmentUnits', value);
              }}
              form={register('establishmentUnits', { required: 'Obrigatório' })}
              error={!!errors.establishmentUnits}
              caption={errors.establishmentUnits?.message as string}
              label="Unidades"
              fromKey="name"
            />
          </div>

          <div className="col-md-6">
            <Select
              value={watch('voucherType')}
              placeholder="Tipo"
              disabled={isLoading}
              options={Object.values(VoucherType)}
              onSelect={(value) => setValue('voucherType', value)}
              form={register('voucherType', { required: 'Obrigatório' })}
              error={!!errors.voucherType}
              caption={errors.voucherType?.message as string}
              label="Tipo do Voucher"
            />
          </div>

          <div className="col-md-6">
            <Select
              value={watch('voucherStatus')}
              placeholder="Status"
              disabled={isLoading}
              options={Object.values(VoucherStatus)}
              onSelect={(value) => setValue('voucherStatus', value)}
              form={register('voucherStatus', { required: 'Obrigatório' })}
              error={!!errors.voucherStatus}
              caption={errors.voucherStatus?.message as string}
              label="Status do Voucher"
            />
          </div>

          {watch('voucherType')?.value === VoucherType.normal && (
            <>
              <div className="col-md-6">
                <Input
                  disabled={isLoading}
                  error={!!errors.onPurchaseOf}
                  caption={errors.onPurchaseOf?.message as string}
                  label="Na compra de"
                  placeholder="Na compra de"
                  form={register('onPurchaseOf', { required: 'Obrigatório' })}
                />
              </div>
              <div className="col-md-6">
                <Select
                  value={watch('reward')}
                  options={benefits.map((it: any) => toValueLabel(it.name))}
                  onSelect={(value) => setValue('reward', value)}
                  disabled={isLoading}
                  error={!!errors.reward}
                  caption={errors.reward?.message as string}
                  label="Ganhe"
                  placeholder="Ganhe"
                  form={register('reward', { required: 'Obrigatório' })}
                />
              </div>
            </>
          )}

          <div className="col-md-12">
            <InputTextArea
              rows={3}
              disabled={isLoading}
              error={!!errors.establishmentRules}
              caption={errors.establishmentRules?.message as string}
              label="Regras do estabelecimento"
              form={register('establishmentRules')}
            />
          </div>
        </div>

        <div>
          <div className="col-md-12 pt-s-200">
            <hr className="pb-s-200" />
            <h4>Ordenação</h4>
          </div>

          <div className="grid-gap-1">
            <div className="col-md-12 row grid-gap-1">
              <Checkbox
                label="Destacar na categoria"
                form={register('featured')}
              />

              <Input
                mask="onlyNumbers"
                disabled={isLoading}
                error={!!errors.categoryPriority}
                caption={errors.categoryPriority?.message as string}
                placeholder="Posição"
                form={register(`categoryPriority`, {
                  required: watch('featured') && 'Obrigatório',
                })}
              />
            </div>
          </div>
        </div>

        <div>
          <div className="col-md-12 pt-s-200">
            <hr className="pb-s-200" />
            <h4>Horário de Utilização</h4>
          </div>

          {FieldsUsableTimes.map((_, index: number) => (
            <div className="row grid-gap-1" key={`usableTimes_${index}`}>
              <div className="col-md-5">
                <Input
                  mask="hour"
                  key={`startHour_${index}`}
                  disabled={isLoading}
                  error={!!errors.usableTimes?.[index]?.start}
                  caption={
                    errors.usableTimes?.[index]?.start?.message as string
                  }
                  label="Hora inicial"
                  placeholder="Ex:. 07:00"
                  form={register(`usableTimes.${index}.start`, {
                    required: 'Obrigatório',
                    ...formHourPattern,
                  })}
                />
              </div>
              <div className="col-md-5">
                <Input
                  disabled={isLoading}
                  mask="hour"
                  key={`endHour_${index}`}
                  error={!!errors.usableTimes?.[index]?.end}
                  caption={errors.usableTimes?.[index]?.end?.message as string}
                  label="Hora final"
                  placeholder="Ex:. 23:00"
                  form={register(`usableTimes.${index}.end`, {
                    required: 'Obrigatório',
                    ...formHourPattern,
                  })}
                />
              </div>

              <div
                className="col-md-2 d-flex justify-center pr-s-100"
                style={{ alignItems: 'end', marginRight: 0 }}
              >
                <Button
                  title="Remover horário"
                  color="negative"
                  key={`remove_${index}`}
                  design="outlined"
                  disabled={FieldsUsableTimes.length === 1}
                  onClick={() => removeUsableTimes(index)}
                  prefixes={<Icon>delete</Icon>}
                />
              </div>

              <div
                className="col-md-8"
                style={{
                  display: 'grid',
                  gridTemplateColumns: '50% 50% 50%',
                  gap: 16,
                }}
              >
                {Object.entries(WeekDaysWithCommemorativeDate).map(
                  ([field, value]) => (
                    <Checkbox
                      key={`${field}_${value}`}
                      label={value}
                      form={register(`usableTimes.${index}.${field}`)}
                    />
                  )
                )}
              </div>

              {!!errors.usableTimes?.[index]?.days && (
                <span className="text-left form-input__caption form-input__caption--error">
                  {errors.usableTimes?.[index]?.days?.message as string}
                </span>
              )}

              {index !== FieldsUsableTimes.length - 1 && (
                <div className="col-md-12">
                  <hr />
                </div>
              )}
            </div>
          ))}

          <div className="col-md-12 pt-s-200">
            <h5 className="d-flex align-items-center justify-center">
              <hr
                className="mr-s-200"
                style={{
                  flex: 1,
                }}
              />
              <Button
                onClick={() => {
                  appendUsableTimes({
                    start: '',
                    end: '',
                  });
                }}
                suffixes={<Icon>add</Icon>}
                design="outlined"
              >
                Adicionar mais um horário
              </Button>
              <hr
                className="ml-s-200"
                style={{
                  flex: 1,
                }}
              />
            </h5>
          </div>
        </div>

        <div className="col-md-12 pt-s-200">
          <h4>Substituições</h4>
        </div>

        <div
          className="col-md-8"
          style={{
            display: 'grid',
            gridTemplateColumns: '50% 50% 50%',
            gap: 16,
          }}
        >
          {Object.entries(substitutions).map(([field, value]) => (
            <>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={`checkbox-${value.id}`}
                  checked={voucherSubstitutions.some(
                    (sub) => sub.id === value.id
                  )}
                  onChange={() => handleCheckboxChange(value)}
                />
                <label
                  htmlFor={`checkbox-${value.id}`}
                  className="form-check-label"
                >
                  {value.name}
                </label>
              </div>
            </>
          ))}
        </div>

        <div>
          <div className="col-md-12 pt-s-200">
            <h4>Imagens do Voucher</h4>
          </div>

          <div className="row grid-gap-1">
            <div className="col-md-12">
              {watch('voucherType')?.value === VoucherType.normal ? (
                <FileUpload
                  image={''}
                  accept="image/*"
                  maxSizeInBytes={5_000_000}
                  disclaimer={`${normalAspect.width}x${normalAspect.height}px com até 5MB`}
                  cropProps={{
                    aspect: normalAspect.width / normalAspect.height,
                  }}
                  openCropImageModal
                  onChange={(file) => {
                    setImages((images) => [...images, ...file]);
                    toast.success('Imagem adicionada com sucesso!');
                  }}
                  label="Escolher uma ou mais imagens"
                  error={errors.images?.message as string}
                />
              ) : (
                <FileUpload
                  image={''}
                  accept="image/*"
                  maxSizeInBytes={5_000_000}
                  disclaimer={`${advertisingAspect.width}x${advertisingAspect.height}px com até 5MB`}
                  cropProps={{
                    aspect: advertisingAspect.width / advertisingAspect.height,
                  }}
                  openCropImageModal
                  onChange={(file) => {
                    setImages((images) => [...images, ...file]);
                    toast.success('Imagem adicionada com sucesso!');
                  }}
                  label="Escolher uma ou mais imagens"
                  error={errors.images?.message as string}
                />
              )}
            </div>

            {!!images.length && (
              <div className="col-md-12">
                <div className="col-md-12">Imagens selecionadas</div>
                <div className="col-md-12 mt-s-200 d-flex align-items-center">
                  <Icon
                    style={{
                      color: 'GrayText',
                      marginRight: 10,
                      fontSize: 20,
                    }}
                  >
                    info
                  </Icon>
                  <span style={{ fontSize: 12 }}>
                    É possível adicionar mais de uma imagem, utilize essa função
                    para mostrar mais opções do cardápio. Para definir a imagem
                    principal do voucher, selecione o marcador do canto superior
                    esquerdo da imagem
                  </span>
                </div>
                <hr
                  className="mt-s-200 mb-s-200"
                  style={{
                    flex: 1,
                  }}
                />

                <div className="row grid-gap-1">
                  {images.map((it, i) => {
                    const img = fileToBlobURL(it);
                    const deleteImage = () => {
                      setImages((current) =>
                        current.filter((_, index) => index !== i)
                      );
                    };

                    const setPrincipalImage = () => {
                      setImages((current) => {
                        const principalImage = current[i];

                        const newImages = current.filter(
                          (_, index) => index !== i
                        );

                        newImages.unshift(principalImage);

                        return newImages;
                      });
                    };

                    return (
                      <div className="col-md-6" key={it.size + i}>
                        <div style={{ position: 'relative' }}>
                          <div className="checkbox-button ml-s-100 mt-s-250">
                            <div className="bg-white border-radius-100 p-s-100 d-flex align-items-center justify-center">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                onChange={setPrincipalImage}
                                checked={i === 0}
                              />
                            </div>
                          </div>
                          <div className="remove-button mr-s-200 mt-s-250">
                            <div onClick={deleteImage}>
                              <Icon
                                style={{ fontSize: 20, padding: 4 }}
                                className="bg-white border-radius-100"
                              >
                                delete
                              </Icon>
                            </div>
                          </div>
                          {i === 0 && (
                            <div
                              style={{ fontSize: 12, padding: 4 }}
                              className="principal_image mb-s-250 ml-s-100 bg-white border-radius-100"
                            >
                              Imagem principal
                            </div>
                          )}

                          <div className="banner-image-container">
                            <img
                              src={img}
                              className="banner-image border-radius-100"
                              alt={`${it.name}`}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="row justify-end pt-s-400" style={{ gap: 16 }}>
          <Button design="transparent" onClick={props.closeModal}>
            Cancelar
          </Button>
          <Button isLoading={isSubmitting || isLoading} type="submit">
            Salvar
          </Button>
        </div>
      </form>
    </div>
  );
}
