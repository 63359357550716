import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button, DataLoader, Modal, PageHeader, Restricted } from '@components';
import { useConfirmationModal } from '@hooks/useConfirmationModal';
import { Plan } from '@interfaces/Plan';
import PlanAPICaller from '@services/api/plans';
import { validateUUIDFormat } from 'utils/string/uuid';
import { SelectOptions } from '@interfaces/Utils';

import { PlansForm } from '../PlansForm';

export default function PlansView() {
  const { uuid } = useParams();
  const [data, setData] = useState<Plan | null>(null);
  const [editId, setEditId] = useState('');
  const navigate = useNavigate();
  const confirmation = useConfirmationModal();

  const fetch = (uuid: string) => {
    PlanAPICaller.fetch(uuid).then((values) => setData(values as Plan));
  };

  useEffect(() => {
    if (uuid) {
      if (validateUUIDFormat(uuid)) {
        fetch(uuid);
      } else {
        toast.error('ID informado inválido');
        navigate(-1);
      }
    }
  }, [uuid, navigate]);

  const deletePlan = (id: string) => {
    confirmation({
      title: 'Excluir Plano',
      confirmButtonTitle: 'Excluir',
      description: `Deseja realmente excluir o plano ${data!.name}`,
      onSubmit: () => PlanAPICaller.remove(id).then((_) => navigate(-1)),
    });
  };

  return (
    <>
      <div className="p-s-200">
        <PageHeader backButton title="Plano" />
        <div className="card card__body p-s-350 bg-white">
          <div className="row">
            <div className="col-md-6">
              <h4>Nome</h4>
              <span>
                <DataLoader data={data?.name} isLoading={!data} />
              </span>
            </div>

            <div className="col-md-6">
              <h4>Economia</h4>
              <span>
                <DataLoader data={data?.economy} isLoading={!data} />
              </span>
            </div>

            <div className="col-md-6 mt-s-250">
              <h4>Valor</h4>
              <span>
                <DataLoader data={data?.value} isLoading={!data} />
              </span>
            </div>
            <div className="col-md-6  mt-s-250">
              <h4>Quantidade</h4>
              <span>
                <DataLoader data={data?.amount} isLoading={!data} />
              </span>
            </div>

            <div className="col-md-6 mt-s-250">
              <h4>Início Vigência</h4>
              <span>
                <DataLoader data={data?.startsAt} isLoading={!data} />
              </span>
            </div>
            <div className="col-md-6  mt-s-250">
              <h4>Término Vigência</h4>
              <span>
                <DataLoader data={data?.endsAt} isLoading={!data} />
              </span>
            </div>

            <div className="col-md-6 mt-s-250">
              <h4>Estado</h4>
              <span>
                <DataLoader
                  data={(data?.state as SelectOptions)?.value}
                  isLoading={!data}
                />
              </span>
            </div>
            <div className="col-md-6  mt-s-250">
              <h4>Cidade</h4>
              <span>
                <DataLoader
                  data={(data?.city as SelectOptions)?.value}
                  isLoading={!data}
                />
              </span>
            </div>

            <div className="col-md-6  mt-s-250">
              <h4>Ativo venda</h4>
              <div className="row d-flex align-items-center">
                <span>{data?.activeSale ? 'Ativo' : 'Inativo'}</span>
                <div
                  className={`little-ball-${
                    data?.activeSale ? 'green' : 'red'
                  }`}
                ></div>
              </div>
            </div>
            <div className="col-md-6  mt-s-250">
              <h4>Status</h4>
              <div className="row d-flex align-items-center">
                <span>{data?.active ? 'Ativo' : 'Inativo'}</span>
                <div
                  className={`little-ball-${data?.active ? 'green' : 'red'}`}
                ></div>
              </div>
            </div>
          </div>
          {data ? (
            <div className="row mt-s-300">
              <Restricted context="plans" resource="update">
                <div className="col mr-s-100">
                  <Button
                    onClick={() => setEditId(uuid!)}
                    design="outlined"
                    size="medium"
                  >
                    Editar informações
                  </Button>
                </div>
              </Restricted>
              <Restricted context="plans" resource="delete">
                <div className="col mr-s-100">
                  <Button
                    onClick={() => deletePlan(uuid!)}
                    design="outlined"
                    size="medium"
                    color="negative"
                  >
                    Excluir plano
                  </Button>
                </div>
              </Restricted>
            </div>
          ) : (
            <Skeleton
              height={40}
              style={{ width: '98%' }}
              className="mt-s-300"
            />
          )}
        </div>
      </div>

      {!!(uuid && editId) && (
        <Modal
          isOpen={!!editId}
          onClickAway={() => {
            setEditId('');
          }}
        >
          <PlansForm
            editId={editId}
            onSave={() => {
              fetch(uuid);
              setEditId('');
            }}
            closeModal={() => {
              setEditId('');
            }}
          />
        </Modal>
      )}
    </>
  );
}
