import { FieldValues, UseFormSetError } from 'react-hook-form';

import { handleErrorForm } from '@services/api';
import { Group } from '@interfaces/Group';

import { GroupFilters, create, list, remove, retrieve, update } from './calls';

export * from './calls';

export default class GroupAPICaller {
  static adaptFromAPI = (data: Group) => data as FieldValues;
  static adaptToAPI = (data: FieldValues) => data as Group;

  static list = async (filters: GroupFilters) => {
    const {
      data: { groups },
    } = await list(filters);

    return { ...groups, result: groups.result.map(this.adaptFromAPI) };
  };

  static fetch = async (editId: string) => {
    const response = await retrieve(editId);
    return response;
  };

  static createOrUpdate = async (
    data: FieldValues,
    setError: UseFormSetError<FieldValues>
  ) => {
    const method = data.id ? update : create;

    const result = method(this.adaptToAPI(data)).catch(
      handleErrorForm(setError)
    );

    return result;
  };

  static remove = async (id: string) => {
    const { data } = await remove(id);

    return data;
  };
}
